import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, List, message, Popconfirm, Space, Table, Tag } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import ContentWithErrorHandler from '../../../app/ContentWithErrorHandler';
import { basicSortDateString, TableColumnTextFilterConfig } from '../../../common/TableHelper';
import { useGetAllLoanSubmissionQuery } from './loanSubmissionAPI';
import SubmissionStatusChangeForm from './SubmissionStatusChangeForm';
import {
  LoanSubmissionStatus,
  LoanSubmissionStatusColor,
  LoanSubmissionStatusText,
  LoanSubmissionStatusTextValue,
  SubmissionForm,
  SubmissionList,
} from './types';
import { getId } from './util';

function LoanSubmissionList() {
  const navigate = useNavigate();
  const { data: loanSubmissionList, error: loanSubmissionError, isFetching: loanSubmissionFetch } = useGetAllLoanSubmissionQuery();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [selectedIdDeleteModal, setSelectedIdDeleteModal] = useState<string>();
  const [defaultFilter, setDefaultFilter] = useState<string[]>([
    LoanSubmissionStatus.submitted,
    LoanSubmissionStatus.processed,
    LoanSubmissionStatus.waiting_for_interview,
    LoanSubmissionStatus.approved,
    LoanSubmissionStatus.approved_with_changes,
    LoanSubmissionStatus.waiting_disbursement,
    LoanSubmissionStatus.disbursed,
  ]);

  const toggleDefaultFilter = (isOngoing: boolean, filter: string[] | null) => {
    if (isOngoing) {
      setDefaultFilter([
        LoanSubmissionStatus.submitted,
        LoanSubmissionStatus.processed,
        LoanSubmissionStatus.waiting_for_interview,
        LoanSubmissionStatus.approved,
        LoanSubmissionStatus.approved_with_changes,
        LoanSubmissionStatus.waiting_disbursement,
        LoanSubmissionStatus.disbursed,
      ]);
    } else if (filter) {
      setDefaultFilter(filter);
    }
  };

  return (
    <ContentWithErrorHandler isError={loanSubmissionError} isLoading={false} withBox={false}>
      <Space style={{ marginBottom: 10 }}>
        <Button onClick={() => toggleDefaultFilter(true, null)}>Filter Pengajuan Berlangsung</Button>
        <Button onClick={() => toggleDefaultFilter(false, [LoanSubmissionStatus.completed])}>Filter Pengajuan Selesai</Button>
        <Button onClick={() => toggleDefaultFilter(false, [LoanSubmissionStatus.cancelled, LoanSubmissionStatus.rejected])}>
          Filter Pengajuan Dibatalkan/Ditolak
        </Button>
      </Space>
      <Card
        style={{ marginBottom: 10 }}
        title="Data Pengajuan Pinjaman"
        size="small"
        extra={
          <div>
            <Button style={{ borderRadius: 5 }}>
              <Link to="create">+ Tambah Baris</Link>
            </Button>
          </div>
        }
      >
        <Table
          loading={loanSubmissionFetch}
          dataSource={loanSubmissionList}
          bordered
          size="small"
          pagination={{ pageSize: 20 }}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              width: '10%',
              sorter: (a, b) => basicSortDateString(a.id, b.id),
              defaultSortOrder: 'descend',
              render: (text, record) => {
                return getId(record);
              },
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              width: '20%',
              sorter: (a, b) => basicSortDateString(a.status, b.status),
              render: (status) => {
                return <Tag color={LoanSubmissionStatusColor[status]}>{LoanSubmissionStatusText[status]}</Tag>;
              },
              filters: LoanSubmissionStatusTextValue,
              filterMultiple: true,
              filteredValue: defaultFilter,
              onFilter: (value, record) => record.status == value,
            },
            {
              title: 'Nomor Anggota',
              dataIndex: 'memberId',
              key: 'memberId',
              width: '20%',
              sorter: (a, b) => basicSortDateString(a.memberId, b.memberId),
              ...TableColumnTextFilterConfig<SubmissionList>('memberId'),
            },
            {
              title: 'Nama Anggota',
              dataIndex: 'memberName',
              key: 'memberName',
              width: '20%',
              sorter: (a, b) => basicSortDateString(a.memberName, b.memberName),
              ...TableColumnTextFilterConfig<SubmissionList>('memberName'),
            },
            {
              title: 'Aksi',
              key: 'x',
              width: '10%',
              render: (text, record) => (
                <div>
                  <SubmissionStatusChangeForm
                    id={selectedIdDeleteModal}
                    status={LoanSubmissionStatus.cancelled}
                    isModalVisible={cancelModalOpen}
                    setModalVisible={setCancelModalOpen}
                    allowStatusChange={false}
                  />
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    style={{ borderRadius: 5 }}
                    onClick={() => window.open(`${window.location}/detail/${record.id}`)}
                  />
                  <Button
                    type="link"
                    danger
                    icon={<DeleteOutlined />}
                    style={{ borderRadius: 5 }}
                    onClick={() => {
                      setSelectedIdDeleteModal(record.id);
                      setCancelModalOpen(true);
                    }}
                  />
                </div>
              ),
            },
          ]}
        />
      </Card>
    </ContentWithErrorHandler>
  );
}

export default LoanSubmissionList;
