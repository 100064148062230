import { ReadOutlined } from "@ant-design/icons";
import { Col, Divider, List, Row, Table, Tag } from 'antd';
import { Form, Input, InputNumber, Select } from 'formik-antd';
import moment from 'moment';
import ContentWithErrorHandler from '../../../app/ContentWithErrorHandler';
import { Member } from '../../member/types';

const DATE_FORMAT = 'DD/MM/YYYY';

const { Option } = Select;

type Props = {
  selectedMember: Member | undefined;
};

function DebtDataForm(props: Props) {
  return (
    <ContentWithErrorHandler isError={false} isLoading={false} withBox={false}>
      <Row gutter={[8, 0]}>
        <Col span={24}>
          <Divider plain>Riwayat Pinjaman Di Madani</Divider>
          <Table
            size="small"
            dataSource={props.selectedMember?.loans}
            columns={[
              {
                title: 'Nomor',
                dataIndex: 'accountId',
                key: 'accountId',
              },
              {
                title: 'Nominal',
                dataIndex: 'borrowAmount',
                key: 'borrowAmount',
                render: (value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              },
              {
                title: 'Sisa Pinjaman',
                dataIndex: 'remainingAmount',
                key: 'remainingAmount',
                render: (value, data) =>
                  `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ` (${(data.remainingAmount / data.borrowAmount) * 100} %)`,
              },
              {
                title: 'Cicilan',
                dataIndex: 'installment',
                key: 'installment',
              },
              {
                title: 'Tanggal Pinjam',
                dataIndex: 'borrowDate',
                key: 'borrowDate',
                render: (value) => moment(value).format('DD/MM/YYYY'),
              },
              {
                title: 'Lunas',
                dataIndex: 'isPaid',
                key: 'isPaid',
                render: (isPaid) => <Tag color={isPaid ? 'green' : 'red'}>{isPaid ? 'Lunas' : 'Belum Lunas'}</Tag>,
              },
            ]}
          />

          <Divider plain>Riwayat Pinjaman Di Tempat Lain</Divider>

          <Form.Item name="debtData.bankLoan" label="Sisa Pinjaman Di Bank">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              name="debtData.bankLoan"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item name="debtData.otherCoopLoan" label="Sisa Pinjaman Di Koperasi Lain">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              name="debtData.otherCoopLoan"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item name="debtData.friendLoan" label="Sisa Pinjaman Di Teman">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              name="debtData.friendLoan"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item name="debtData.dealerLoan" label="Sisa Pinjaman Di Dealer">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              name="debtData.dealerLoan"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item name="debtData.arisan" label="Arisan Perbulan">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              name="debtData.arisan"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
    </ContentWithErrorHandler>
  );
}

export default DebtDataForm;
