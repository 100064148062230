import { Button, Card, Modal, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { useGetLoanReportGraphQuery } from './loanAPI';
import { ArrowsAltOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const today = moment();
const prevDate = moment().subtract(6, 'months');

function LoanTimeSeries(props) {
  const [param, setParam] = useState<any>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const { data, error, isFetching } = useGetLoanReportGraphQuery(param, {
    skip: param ? false : true,
  });
  const [downloadData, setDownloadData] = useState<any>([]);
  const [downloadDataStructure, setDownloadDataStructure] = useState<any>([]);

  useEffect(() => {
    var params: any = {};
    if (props.type == 'npl-account') params = { isAmount: false, type: 'npl' };
    if (props.type == 'npl-amount') params = { isAmount: true, type: 'npl' };
    if (props.type == 'npl-pl-account') params = { isAmount: false, type: 'merge' };
    if (props.type == 'npl-pl-amount') params = { isAmount: true, type: 'merge' };
    if (props.type == 'new-loan-type-amount') params = { isAmount: true, type: 'realization-type' };
    if (props.type == 'new-loan-type-account') params = { isAmount: false, type: 'realization-type' };

    setParam(params);
  }, []);

  useEffect(() => {
    if (data != null && data != undefined) {
      download();
    }
  }, [data]);

  const download = () => {
    let result: any[] = [];
    let structure: any[] = ['Tanggal'];
    if (data == undefined || data == null) return;

    for (let idx in data.labels) {
      let item: any = {};
      for (let dataset of data.datasets) {
        item[dataset.label] = dataset.data[idx];

        if (idx == '0') {
          structure.push(dataset.label);
        }
      }

      result.push({
        Tanggal: data.labels[idx],
        ...item,
      });
    }
    setDownloadDataStructure(structure);
    setDownloadData(result);
  };

  const options: any = {
    scales: {
      yAxes: [{ ticks: { beginAtZero: true } }],
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      title: {
        display: false,
      },
    },
    datasets: {
      line: {
        pointRadius: 0, // disable for all `'line'` datasets
      },
    },
  };

  const title = {
    'npl-account': 'Grafik NPL (Rekening)',
    'npl-amount': 'Grafik NPL (Rupiah)',
    'npl-pl-account': 'Grafik NPL vs PL (Rekening)',
    'npl-pl-amount': 'Grafik NPL vs PL (Rupiah)',
    'new-loan-type-account': 'Grafik Pinjaman Baru (Rekening)',
    'new-loan-type-amount': 'Grafik Pinjaman Baru (Rupiah)',
  };

  return (
    <>
      <Card
        size="small"
        title={title[props.type]}
        bodyStyle={{ padding: 15, paddingTop: 0, height: '45vh' }}
        bordered={false}
        extra={
          <Space>
            <Button type="default" size="small" icon={<ArrowsAltOutlined size={16} />} onClick={() => setModalVisible(true)}>
              Zoom
            </Button>
            <ExcelFile
              filename={`${title[props.type]} ${prevDate.format('DD-MM-YYYY')} sampai ${today.format('DD-MM-YYYY')}`}
              element={
                <Button size="small" icon={<DownloadOutlined size={16} />}>
                  Ekspor
                </Button>
              }
            >
              <ExcelSheet data={downloadData} name="map Perkiraan Penerimaan Cicilan">
                {downloadDataStructure.map((e) => (
                  <ExcelColumn label={e} value={e} />
                ))}
              </ExcelSheet>
            </ExcelFile>
          </Space>
        }
      >
        <ContentWithErrorHandler isError={error} isLoading={isFetching} withBox={false} fullScreen={false}>
          {data ? <Line data={data} options={options} /> : <></>}
        </ContentWithErrorHandler>
      </Card>
      <Modal
        title={title[props.type]}
        visible={modalVisible}
        width={'80vw'}
        onCancel={() => setModalVisible(!modalVisible)}
        destroyOnClose
        okButtonProps={{ hidden: true }}
        cancelText="Tutup"
        footer={[]}
        centered
      >
        {data ? <Line data={data} options={options} style={{ height: '70vh' }} /> : <></>}
      </Modal>
    </>
  );
}

export default LoanTimeSeries;
