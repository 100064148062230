import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Col, Divider, message, Modal, Row, Space, Upload } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form, Input, InputNumber, Select, SubmitButton } from 'formik-antd';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { useGetAllMemberQuery } from '../member/membersAPI';
import {
  useCreateTransactionMutation,
  useFinalizeTransactionMutation,
  useGetAllTransactionAccountQuery,
  useGetTransactionQuery,
  useUpdateTransactionMutation,
  useValidateTransactionMutation,
} from './transactionAPI';
import { TransactionFile, TransactionItem, TransactionItemFE } from './types';

const { Option } = Select;

const DATE_FORMAT = 'DD/MM/YYYY';

interface InitialData {
  memoNumber: string;
  memoDescription: string;
  legalNote: string;
  recipientName: string;
  isFinalized: boolean;
}

const ADDITIONAL_INFO_TEXT_MAPPING = {
  restructurePolicy: 'Kebijakan Resturktur',
  commitment: 'Komitmen Anggota',
};

function TransactionDetail() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [memoDate, setMemoDate] = useState<Moment>(moment());
  const [fileList, setFileList] = useState<any[]>([]);
  const [memoItem, setMemoItem] = useState<TransactionItemFE[]>([]);
  const [recipientEmail, setRecipientEmail] = useState('');
  const [approval, setApproval] = useState({
    'Pengurus/pengawas': { checked: false, name: '' },
    CEO: { checked: false, name: '' },
    'CFO/CDO': { checked: false, name: '' },
  });
  const [initialData, setInitialData] = useState<InitialData>({
    memoNumber: '',
    memoDescription: '',
    legalNote: '',
    recipientName: '',
    isFinalized: false,
  });
  const { data: memberListData, isLoading: memberListDataIsLoading } = useGetAllMemberQuery(true);
  const { data: accountData, isLoading: accountDataIsLoading } = useGetAllTransactionAccountQuery();
  const {
    data,
    isLoading: transactionDataIsLoading,
    isError: transactionDataIsError,
  } = useGetTransactionQuery(id || '', {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });
  const [debitCreditDiff, setDebitCreditDiff] = useState<number>(0);

  useEffect(() => {
    if (data) {
      setInitialData({
        memoNumber: data.memoNumber,
        memoDescription: data.memoDescription,
        legalNote: data.legalNote,
        recipientName: data.recipientName,
        isFinalized: data.isFinalized,
      });

      setMemoDate(moment(data.memoDate, 'YYYY-MM-DD'));
      setMemoItem(transformMemoItemFEModel(data.memoItem));
      setApproval(transformApprovalFEModel(data.approval));
      recalculateDiff();
      if (data.additionalFile) {
        setFileList(
          data.additionalFile.map((element, index) => {
            return {
              uid: index,
              name: element.filename,
              status: 'done',
              url: element.data,
              imageUrl: element.imageUrl,
            };
          }),
        );
      }
    }
  }, [data]);

  const [updateTransaction] = useUpdateTransactionMutation();
  const [createTransaction] = useCreateTransactionMutation();
  const [validateTransaction] = useValidateTransactionMutation();
  const [finalizeSendEmail] = useFinalizeTransactionMutation();

  const validate = (value) => {
    let error;
    if (!value) {
      error = 'Tidak boleh kosong';
    }
    return error;
  };

  const transformMemoItemFEModel = (data) => {
    let result: TransactionItemFE[] = [];
    for (let item of data) {
      let amount = item.debitAmount == '' ? item.creditAmount : item.debitAmount;
      result.push({
        itemType: item.debitAmount == '' ? 'credit' : 'debit',
        account: item.debitAccount == '' ? item.creditAccount : item.debitAccount,
        amount: parseFloat(amount.replace(/,/g, '')),
      });
    }
    return result;
  };

  const transformApprovalFEModel = (data) => {
    let result = {
      'Pengurus/pengawas': { checked: false, name: '' },
      CEO: { checked: false, name: '' },
      'CFO/CDO': { checked: false, name: '' },
    };

    if (data != null) {
      for (let value of data) {
        let approverName = value.match(/[^()]+(?=\))/g) == null ? '' : value.match(/[^()]+(?=\))/g)[0];
        if (value.includes('Manajer') || value.includes('CEO')) {
          result['CEO'] = { checked: true, name: approverName };
        } else if (value.includes('Pengurus/pengawas')) {
          result['Pengurus/pengawas'] = { checked: true, name: approverName };
        } else if (value.includes('Kepala Bagian Keuangan') || value.includes('CFO/CDO')) {
          result['CFO/CDO'] = { checked: true, name: approverName };
        }
      }
    }

    return result;
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const transformMemoItemBEModel = () => {
    var result: TransactionItem[] = [];
    for (var item of memoItem) {
      result.push({
        debitAccount: item.itemType == 'debit' ? item.account : '',
        creditAccount: item.itemType == 'credit' ? item.account : '',
        debitAmount: item.itemType == 'debit' ? numberWithCommas(item.amount) : '',
        creditAmount: item.itemType == 'credit' ? numberWithCommas(item.amount) : '',
      });
    }
    return result;
  };

  const transformApprovalBEModel = () => {
    var result: string[] = [];
    for (var key in approval) {
      if (approval[key].checked) {
        var str = key;
        if (approval[key].name != '') str += ` (${approval[key].name})`;
        result.push(str);
      }
    }
    return result;
  };

  const isMemoItemValid = () => {
    var sumDebit = 0;
    var sumCredit = 0;
    for (var item of memoItem) {
      if (item.itemType == 'debit') {
        sumDebit += item.amount;
      } else {
        sumCredit += item.amount;
      }
    }
    return sumCredit == sumDebit && sumCredit > 0 && sumDebit > 0;
  };

  const handleDownloadPdfTransaction = async () => {
    const hide = message.loading('Loading', 0);
    if (!data) {
      hide();
      message.error('Gagal!');
    }

    var path = 'transaction/download/' + data?.memoNumber;
    const url = process.env.REACT_APP_API_URL + path;

    var downloadBlob = await fetch(url, { credentials: 'include' });
    downloadBlob.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = data?.memoNumber.replace(/[^\w\d\.-]/g, '-') + '.pdf';
      a.click();
    });
    hide();
  };

  const handleFinalizeSendEmailMemo = () => {
    const hide = message.loading('Loading', 0);
    finalizeSendEmail({
      isSendEmail: true,
      id: id,
      recipientEmail: recipientEmail,
    })
      .unwrap()
      .then(() => {
        hide();
        message.success('Berhasil!');
      })
      .catch(() => {
        hide();
        message.error('Gagal!');
      });
  };

  const handleValidateMemo = () => {
    const hide = message.loading('Loading', 0);
    validateTransaction(id)
      .unwrap()
      .then(() => {
        hide();
        message.success('Berhasil!');
      })
      .catch(() => {
        hide();
        message.error('Gagal!');
      });
  };

  const save = async (value: InitialData, closeLoading: any) => {
    if (!isMemoItemValid()) {
      closeLoading();
      message.error('Jumlah tidak sama!');
      return;
    }

    var transformedMemoItem = transformMemoItemBEModel();
    var transformedApproval = transformApprovalBEModel();

    const formData = new FormData();
    formData.append('memoNumber', value.memoNumber);
    formData.append('recipientName', value.recipientName);
    formData.append('legalNote', value.legalNote);
    formData.append('memoDate', memoDate.format('YYYY-MM-DD'));
    formData.append('memoDescription', value.memoDescription);
    formData.append('memoItem', JSON.stringify(transformedMemoItem));
    formData.append('approval', JSON.stringify(transformedApproval));

    var existingFile: TransactionFile[] = [];
    if (fileList.length != 0) {
      await fileList.map((item) => {
        if (item.originFileObj != undefined) {
          formData.append('uploadedFiles', item.originFileObj);
        } else {
          existingFile.push({ filename: item.name, imageUrl: item.imageUrl });
        }
      });
    }
    if (existingFile.length > 0) {
      formData.append('additionalFile', JSON.stringify(existingFile));
    }

    let promise;
    if (id) {
      formData.append('id', id);
      promise = updateTransaction(formData);
    } else {
      promise = createTransaction(formData);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success('Berhasil!');
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  const addItem = (type) => {
    if (memoItem.length > 16) {
      message.warn('Gagal menambah item debit/kredit. Melebihi batas maksimal 16');
      return;
    }
    setMemoItem([
      ...memoItem,
      {
        itemType: type,
        account: `${accountData?.[0].accountNumber} - ${accountData?.[0].accountName}`,
        amount: 0,
      },
    ]);
  };

  const deleteItem = async (id) => {
    let copyItem = [...memoItem];
    copyItem.splice(id, 1);
    setMemoItem(copyItem);
  };

  const setItem = (id, type, value) => {
    let copyItem = [...memoItem];
    copyItem[id][type] = value;
    setMemoItem(copyItem);
  };

  const handleApproval = (attr, type, value) => {
    let copyItem = { ...approval };
    copyItem[type][attr] = value;
    setApproval(copyItem);
  };

  const handleUploadChange = async (info) => {
    let fileList = [...info.fileList];

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(fileList);
  };

  const handleFilePreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;

    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const recalculateDiff = () => {
    var sumDebit = 0;
    var sumCredit = 0;
    for (var item of memoItem) {
      if (item.itemType == 'debit') {
        sumDebit += item.amount;
      } else {
        sumCredit += item.amount;
      }
    }
    setDebitCreditDiff(Math.abs(sumDebit - sumCredit));
  };

  const renderDiff = () => {
    if (debitCreditDiff != 0) {
      return <Alert message={`Sisa uang untuk alokasi Rp ${debitCreditDiff}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} type="error" />;
    }
    return <Alert message="Tidak ada sisa uang untuk di alokasi" type="success" />;
  };

  return (
    <Formik
      initialValues={initialData}
      onSubmit={async (values, actions) => {
        const hide = message.loading('Loading', 0);
        await save(values, hide);
        actions.setSubmitting(false);
      }}
      enableReinitialize={true}
      render={() => (
        <Modal
          title={`Detail Memo: ${data?.memoNumber}`}
          width={800}
          visible={true}
          destroyOnClose={true}
          onCancel={() => navigate('/transaction')}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          footer={null}
        >
          <ContentWithErrorHandler isError={transactionDataIsError} isLoading={transactionDataIsLoading} withBox={false}>
            <Form layout="vertical">
              {data?.validation != null || data?.validation != undefined ? (
                <Alert
                  style={{ marginBottom: '1em' }}
                  message={`Telah divalidasi pada ${data?.validation?.date} oleh ${data?.validation?.name}`}
                  type="success"
                  showIcon
                />
              ) : (
                <></>
              )}

              <Row gutter={[8, 0]}>
                <Col span={18}>
                  <Form.Item name="memoNumber" label="Nomor Memo" validate={validate}>
                    <Input name="memoNumber" placeholder="Masukkan nomor memo" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="memoDate" label="Tanggal Memo">
                    <DatePicker name="memoDate" value={memoDate} format={DATE_FORMAT} onChange={(date: any, dateString) => setMemoDate(date)} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="recipientName" label="Nama Penerima">
                    <Select
                      showSearch
                      name="recipientName"
                      style={{ width: '100%' }}
                      placeholder="Pilih penerima"
                      optionFilterProp="children"
                      filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      <Option value={'-'}>{'-'}</Option>
                      {memberListData?.map((member) => {
                        var key = member.memberId + ' - ' + member.name;
                        return <Option value={key}>{key}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="recipientEmail" label="Email Penerima">
                    <Input
                      name="recipientEmail"
                      placeholder="Masukkan email penerima"
                      value={recipientEmail}
                      onChange={(event) => setRecipientEmail(event.target.value)}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="memoDescription" label="Deskripsi" validate={validate}>
                    <Input.TextArea name="memoDescription" placeholder="Masukkan deskripsi memo" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="legalNote" label="Catatan Validasi">
                    <Input.TextArea name="legalNote" placeholder="Masukkan catatan validasi" />
                  </Form.Item>
                </Col>
                <Col span={24}>{renderDiff()}</Col>

                <Divider plain>Debit</Divider>
                {memoItem.map((element, i) => {
                  if (element.itemType == 'debit') {
                    return (
                      <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Select
                          showSearch
                          name="debit"
                          value={element.account}
                          style={{ width: '350px' }}
                          placeholder="Nomor Akun"
                          onChange={(event) => setItem(i, 'account', event)}
                          optionFilterProp="children"
                          filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          {accountData?.map((element) => {
                            return (
                              <Option
                                value={`${element.accountNumber} - ${element.accountName}`}
                              >{`${element.accountNumber} - ${element.accountName}`}</Option>
                            );
                          })}
                        </Select>
                        <InputNumber
                          name="debitAmount"
                          value={element.amount}
                          formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          onChange={(value) => {
                            setItem(i, 'amount', value);
                            recalculateDiff();
                          }}
                          parser={(value: any) => value.replace(/Rp\s?|(,*)/g, '')}
                          style={{ width: '250px' }}
                        />
                        <MinusCircleOutlined onClick={() => deleteItem(i)} />
                      </Space>
                    );
                  } else {
                    return <div></div>;
                  }
                })}
                <Button type="dashed" onClick={() => addItem('debit')} block icon={<PlusOutlined />}>
                  Tambah debit
                </Button>
                <Divider plain>Kredit</Divider>
                {memoItem.map((element, i) => {
                  if (element.itemType == 'credit') {
                    return (
                      <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          name="credit"
                          value={element.account}
                          style={{ width: '350px' }}
                          placeholder="Nomor Akun"
                          onChange={(event) => setItem(i, 'account', event)}
                        >
                          {accountData?.map((element) => {
                            return (
                              <Option
                                value={`${element.accountNumber} - ${element.accountName}`}
                              >{`${element.accountNumber} - ${element.accountName}`}</Option>
                            );
                          })}
                        </Select>
                        <InputNumber
                          name="creditAmount"
                          value={element.amount}
                          formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          onChange={(value) => {
                            setItem(i, 'amount', value);
                            recalculateDiff();
                          }}
                          parser={(value: any) => value.replace(/Rp\s?|(,*)/g, '')}
                          style={{ width: '250px' }}
                        />
                        <MinusCircleOutlined onClick={() => deleteItem(i)} />
                      </Space>
                    );
                  } else {
                    return <div></div>;
                  }
                })}
                <Button type="dashed" onClick={() => addItem('credit')} block icon={<PlusOutlined />}>
                  Tambah kredit
                </Button>

                <Divider plain>Foto Pendukung</Divider>
                <Upload
                  onChange={handleUploadChange}
                  onPreview={handleFilePreview}
                  beforeUpload={(file) => {
                    setFileList((oldState) => [...oldState, file]);
                    return false;
                  }}
                  listType="picture-card"
                  accept=".png, .jpg, .jpeg"
                  fileList={fileList}
                >
                  {fileList.length < 5 && '+ Upload'}
                </Upload>

                <Divider plain>Persetujuan</Divider>
                {Object.keys(approval).map((key) => {
                  return (
                    <Col span={24}>
                      <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Checkbox checked={approval[key].checked} onChange={(e) => handleApproval('checked', key, e.target.checked)}>
                          {key}
                        </Checkbox>
                        <Input
                          name="approval"
                          hidden={!approval[key].checked}
                          value={approval[key].name}
                          placeholder={`Nama ${key} (Tidak Wajib)`}
                          onChange={(event) => handleApproval('name', key, event.target.value)}
                          style={{ width: '300px' }}
                        />
                      </Space>
                    </Col>
                  );
                })}
              </Row>
              <Space
                style={{
                  marginTop: 20,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Button type="dashed" onClick={handleDownloadPdfTransaction}>
                  Generate PDF
                </Button>
                <Button danger type="primary" hidden={!id} onClick={handleFinalizeSendEmailMemo}>
                  Finalisasi & Kirim Email
                </Button>
                <SubmitButton>Simpan</SubmitButton>
                <Button danger type="dashed" hidden={!id || data?.validation != null} onClick={handleValidateMemo}>
                  Validasi
                </Button>
              </Space>
            </Form>
          </ContentWithErrorHandler>
        </Modal>
      )}
    />
  );
}

export default TransactionDetail;
