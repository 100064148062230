import { Button, Col, DatePicker, Layout, Row, Space } from "antd";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { useTransactionReportQuery } from "./transactionAPI";
import TransactionReportCategory from "./TransactionReportCategory";
import TransactionReportSummary from "./TransactionReportSummary";
import { RangeValue } from "rc-picker/lib/interface.d";

const { Content } = Layout;
const { RangePicker } = DatePicker;

function TransactionReport() {
  const [date, setDate] = useState<RangeValue<Moment>>([moment().startOf("day"), moment().endOf("day")]);
  const [param, setParam] = useState<any>({ start: date?.[0]?.format("DD-MM-YYYY"), end: date?.[1]?.format("DD-MM-YYYY") });
  const { data, isError, isLoading } = useTransactionReportQuery(param);

  const filter = async () => {
    setParam({ start: date?.[0]?.format("DD-MM-YYYY"), end: date?.[1]?.format("DD-MM-YYYY") });
  };

  return (
    <ContentWithErrorHandler isError={isError} isLoading={isLoading} withBox={false}>
      <Row style={{ marginBottom: 12 }}>
        <Col span={24}>
          <Space wrap style={{ display: "flex", justifyContent: "flex-end" }}>
            <RangePicker value={date} style={{ width: "225px" }} onChange={setDate} />
            <Button onClick={filter}>Filter</Button>
          </Space>
        </Col>
      </Row>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <TransactionReportSummary data={data?.overall || null} startDate={date?.[0]} endDate={date?.[1]} />
        </Col>
        <Col span={24}>
          <TransactionReportCategory
            accountTypeMap={data?.accountTypeMap || null}
            perAccount={data?.accountMap || null}
            perOperator={data?.userMap || null}
          />
        </Col>
      </Row>
    </ContentWithErrorHandler>
  );
}

export default TransactionReport;
