import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import React from "react";
import { useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { useGetContactUsQuery } from "./contactUsAPI";

function ContactUsDetail() {
  const { id } = useParams();

  const { data: contactUsData, isLoading: contactUsDataIsLoading, isError: contactUsDataIsError } = useGetContactUsQuery(id || "", {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  return (
    <div>
      <ContentWithErrorHandler isError={contactUsDataIsError} isLoading={contactUsDataIsLoading} withBox={true}>
        <Formik
          initialValues={{
            name: contactUsData?.name || "",
            email: contactUsData?.email || "",
            subject: contactUsData?.subject || "",
            message: contactUsData?.message || "",
          }}
          onSubmit={() => {}}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              <Form.Item name="name" label="Nama Pengirim">
                <Input name="name" placeholder="-" readOnly />
              </Form.Item>
              <Form.Item name="email" label="Email Pengirim">
                <Input name="email" placeholder="-" readOnly />
              </Form.Item>
              <Form.Item name="subject" label="Subjek Pesan">
                <Input name="subject" placeholder="-" readOnly />
              </Form.Item>
              <Form.Item name="message" label="Isi Pesan">
                <Input.TextArea name="message" placeholder="-" readOnly />
              </Form.Item>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default ContactUsDetail;
