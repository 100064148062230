import { message, Modal } from "antd";
import { Formik } from "formik";
import { Form, Input, InputNumber, Select, SubmitButton } from "formik-antd";
import { useNavigate, useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import {
  useCreateQuestionaireResultConfigMutation,
  useGetQuestionaireResultConfigQuery,
  useUpdateQuestionaireResultConfigMutation,
} from "./financialLiteracyAPI";

const { Option } = Select;

function FinancialLiteracyQuestionResultConfigDetail() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, isLoading, isError } = useGetQuestionaireResultConfigQuery(id || "", {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const [updateConfig] = useUpdateQuestionaireResultConfigMutation();
  const [createConfig] = useCreateQuestionaireResultConfigMutation();

  const validate = (value) => {
    let error;
    if (isNaN(value) && !value) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  const save = async (value: any, closeLoading: any) => {
    let data: any = { ...value };

    let promise;
    if (id) {
      data.id = id;
      promise = updateConfig(data);
    } else {
      promise = createConfig(data);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success("Berhasil!");
      })
      .catch(() => {
        closeLoading();
        message.error("Gagal!");
      });
  };

  return (
    <Modal
      title={`Detail Hasil Kuisioner`}
      width={1000}
      visible={true}
      destroyOnClose={true}
      onCancel={() => navigate("/fl/questionaire/result-config")}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
    >
      <ContentWithErrorHandler isError={isError} isLoading={isLoading} withBox={false}>
        <Formik
          initialValues={{
            type: data?.type || "saving",
            description: data?.description || "",
            sign: data?.sign || "greater_than",
            value1: data?.value1 || null,
            value2: data?.value2 || null,
          }}
          onSubmit={async (values, actions) => {
            const hide = message.loading("Loading", 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={({ values }) => (
            <Form layout="vertical">
              <Form.Item name="type" label="Tipe" validate={validate}>
                <Select showSearch name="type" style={{ width: "100%" }} placeholder="Pilih tipe" optionFilterProp="children">
                  <Option value="saving">Simpanan</Option>
                  <Option value="expense">Pengeluaran</Option>
                  <Option value="debt">Hutang</Option>
                </Select>
              </Form.Item>
              <Form.Item name="sign" label="Operator" validate={validate}>
                <Select showSearch name="sign" style={{ width: "100%" }} placeholder="Pilih operator" optionFilterProp="children">
                  <Option value="greater_than">Lebih Dari</Option>
                  <Option value="greater_than_equal">Lebih Dari Sama Dengan</Option>
                  <Option value="less_than">Kurang Dari</Option>
                  <Option value="less_than_equal">Kurang Dari Sama Dengan</Option>
                  <Option value="between">Antara</Option>
                  <Option value="between_equal">Antara Sama Dengan</Option>
                </Select>
              </Form.Item>

              <Form.Item name="value1" label="Nilai 1" validate={validate}>
                <InputNumber name="value1" style={{ width: "100%" }} placeholder="Nilai 1" />
              </Form.Item>

              {values.sign.includes("between") ? (
                <Form.Item name="value2" label="Nilai 2" validate={validate}>
                  <InputNumber name="value2" style={{ width: "100%" }} placeholder="Nilai 2" />
                </Form.Item>
              ) : (
                <></>
              )}

              <Form.Item name="description" label="Teks" validate={validate}>
                <Input.TextArea name="description" style={{ width: "100%" }} placeholder="Masukkan teks" />
              </Form.Item>
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </Modal>
  );
}

export default FinancialLiteracyQuestionResultConfigDetail;
