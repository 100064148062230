import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { Formik } from "formik";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { useCreateNewTagMutation, useGetTagByTypeQuery } from "../tags/tagsAPI";
import {
  useCreateDocumentMutation,
  useGetDocumentQuery,
  useUpdateDocumentMutation,
} from "./documentAPI";

const { Option } = Select;

interface InitialData {
  name: string;
  isPublic: boolean;
  isPublished: boolean;
  tags: any[];
}

function DocumentDetail() {
  const { id } = useParams();

  const [fileList, setFileList] = useState<any>([]);
  const [initialData, setInitialData] = useState<InitialData>({
    name: "",
    isPublic: false,
    isPublished: false,
    tags: [],
  });

  const { data: documentTags } = useGetTagByTypeQuery("documentTag");
  const {
    data: documentData,
    isLoading: documentDataIsLoading,
    isError: documentDataIsError,
  } = useGetDocumentQuery(id || "", {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const [createTag] = useCreateNewTagMutation();
  const [updateDocument] = useUpdateDocumentMutation();
  const [createDocument] = useCreateDocumentMutation();

  useEffect(() => {
    if (documentData) {
      setInitialData({
        name: documentData.name,
        isPublic: documentData.isPublic,
        isPublished: documentData.isPublished,
        tags: documentData.tags.map((e) => e.name),
      });
      setFileList(
        documentData.fileUrl != null
          ? [
              {
                uid: "-1",
                name: documentData.name,
                status: "done",
                url: documentData.fileUrl,
              },
            ]
          : []
      );
    }
  }, [documentData]);

  const handleUploadChange = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(fileList);
  };

  const validate = (value) => {
    let error;
    if (!value) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  const save = async (value: InitialData, closeLoading: any) => {
    var tagsNotExist: string[] = [];
    var existingTags = documentTags?.map((e) => e.name) || [];

    await value.tags.forEach((userInputTag: any) => {
      if (!existingTags.includes(userInputTag)) {
        tagsNotExist.push(userInputTag);
      }
    });

    await tagsNotExist.map(async (tagToAdd) => {
      createTag({ name: tagToAdd, type: "documentTag" })
        .unwrap()
        .catch((e) => {
          message.error("Gagal membuat tag!");
        });
    });

    if (id == undefined && fileList.length == 0) {
      closeLoading();
      message.error("File tidak boleh kosong");
      return;
    }

    const formData = new FormData();
    await fileList.map((item) => {
      if (item.originFileObj) {
        formData.append("file", item.originFileObj);
      }
    });

    formData.append("name", value.name);
    formData.append("isPublished", String(value.isPublished));
    formData.append("isPublic", String(value.isPublic));

    let tagIds = await value.tags.map((tagName) => {
      let tagObject = documentTags?.find((e) => e.name == tagName);
      if (tagObject) {
        let id = tagObject.id;
        return id;
      }
    });
    formData.append("tagIds", JSON.stringify(tagIds.map((e) => e)));

    let promise;
    if (id) {
      formData.append("id", id);
      promise = updateDocument(formData);
    } else {
      promise = createDocument(formData);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success("Berhasil!");
      })
      .catch(() => {
        closeLoading();
        message.error("Gagal!");
      });
  };

  return (
    <div>
      <ContentWithErrorHandler
        isError={documentDataIsError}
        isLoading={documentDataIsLoading}
        withBox={true}
      >
        <Formik
          initialValues={initialData}
          onSubmit={async (values, actions) => {
            const hide = message.loading("Loading", 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              <Form.Item name="file" label="File">
                <Upload
                  beforeUpload={(file) => {
                    setFileList((oldState) => [...oldState, file]);
                    return false;
                  }}
                  onChange={handleUploadChange}
                  multiple={false}
                  listType="text"
                  accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
                  fileList={fileList}
                >
                  <Button>
                    <UploadOutlined /> Unggah
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item name="name" label="Nama Dokumen" validate={validate}>
                <Input name="name" placeholder="Masukkan nama dokumen" />
              </Form.Item>
              <Form.Item name="tags" label="Kategori">
                <Select name="tags" mode="tags" placeholder="Masukkan kategori">
                  {documentTags?.map((tag) => (
                    <Option key={tag.id} value={tag.name}>
                      {tag.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="isPublic" label="Akses">
                <Select name="isPublic">
                  <Option value={true}>Semua Orang</Option>
                  <Option value={false}>Khusus Anggota</Option>
                </Select>
              </Form.Item>
              <Form.Item name="isPublished" label="Status">
                <Select name="isPublished">
                  <Option value={true}>Terbit</Option>
                  <Option value={false}>Draf</Option>
                </Select>
              </Form.Item>
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default DocumentDetail;
