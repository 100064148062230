import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Card, message, Popconfirm, Table, Tag } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { RootState } from "../../app/store";
import { basicSortDateString } from "../../common/TableHelper";
import { Admin } from "../auth/types";
import { useGetAllContactUsQuery, useUpdateContactUsMutation } from "./contactUsAPI";

interface TagItem {
  text: string;
  value: string;
}

function ContactUsList() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetAllContactUsQuery();
  const [updateContactUs] = useUpdateContactUsMutation();

  const auth = useSelector((state: RootState) => state.auth);
  const admin = (auth.admin as unknown) as Admin;

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Card title="Data Pesan Masuk" size="small">
        <Table
          loading={isLoading}
          dataSource={data}
          bordered
          size="small"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              render: (text, record) => {
                if (!record.isResolved) {
                  return (
                    <Tag color="red">
                      <CloseCircleOutlined /> TERJAWAB
                    </Tag>
                  );
                } else {
                  return (
                    <>
                      <Tag color="green">
                        <CheckCircleOutlined /> TERJAWAB
                      </Tag>
                      <br />
                      oleh: {record.resolver.name}
                    </>
                  );
                }
              },
              width: "15%",
            },
            {
              title: "Nama Pengirim",
              dataIndex: "name",
              key: "name",
              width: "20%",
              sorter: (a, b) => basicSortDateString(a.name, b.name),
              render: (text, record) => (
                <div>
                  <span style={{ fontWeight: "bold" }}>{record.name}</span>
                  <br /> {record.email}
                </div>
              ),
            },
            {
              title: "Subjek Pesan",
              dataIndex: "subject",
              key: "subject",
              width: "20%",
            },
            {
              title: "Isi Pesan",
              dataIndex: "message",
              key: "message",
              width: "40%",
              render: (text, record) => <div dangerouslySetInnerHTML={{ __html: record.message }} />,
            },
            {
              title: "Aksi",
              key: "x",
              width: "5%",
              render: (text, record) => (
                <div>
                  <Button type="link" icon={<EyeOutlined />} style={{ borderRadius: 5 }} onClick={() => navigate(`detail/${record.id}`)} />
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading("Loading", 0);
                      let data: any = {
                        id: record.id,
                        resolver: {
                          id: admin.id,
                        },
                        isResolved: true,
                      };
                      updateContactUs(data)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success("Berhasil!");
                        })
                        .catch(() => {
                          hideLoading();
                          message.error("Gagal!");
                        });
                    }}
                    okText="Tandai Selesai"
                    cancelText="Batal"
                  >
                    <Button type="text" icon={<CheckOutlined />} style={{ borderRadius: 5, color: "green" }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
    </ContentWithErrorHandler>
  );
}

export default ContactUsList;
