import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { Formik } from 'formik';
import { Form, Input, InputNumber, Select, SubmitButton } from 'formik-antd';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import quillConfig from '../../common/quillConfig';
import {
  useCreateGeneralInfoMutation,
  useGetGeneralConfigQuery,
  useUpdateGeneralInfoMutation,
  useUpsertGeneralConfigMutation,
} from './generalInfoAPI';
import { GeneralConfig } from './types';

const { Option } = Select;
const key = 'config_general-basic_config';

function GeneralInfoConfig() {
  const [initialData, setInitialData] = useState<GeneralConfig>({
    countEntrepreneur: 0,
  });

  const { data, isFetching, isError } = useGetGeneralConfigQuery(key || '', {
    skip: key ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const [upsert] = useUpsertGeneralConfigMutation();

  useEffect(() => {
    if (data) {
      setInitialData({ countEntrepreneur: data.countEntrepreneur });
    }
  }, [data]);

  const save = async (value: GeneralConfig, closeLoading: any) => {
    upsert({
      key: key,
      value: JSON.stringify(value),
    })
      .then(() => {
        closeLoading();
        message.success('Berhasil!');
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  return (
    <div>
      <ContentWithErrorHandler isError={isError} isLoading={isFetching} withBox={true}>
        <Formik
          initialValues={initialData}
          onSubmit={async (values, actions) => {
            const hide = message.loading('Loading', 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              <Form.Item name="countEntrepreneur" label={`Jumlah anggota wirausaha`}>
                <Input name="countEntrepreneur" addonAfter="orang" placeholder={`Masukkan jumlah anggota wirausaha`} />
              </Form.Item>
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default GeneralInfoConfig;
