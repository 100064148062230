import axios from "axios";

type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export async function requestApi(method: Method, path: string, data?: any) {
  const url = process.env.REACT_APP_API_URL + path;
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  let res;
  switch (method) {
    case "GET":
      res = axios.get(url, options);
      break;
    case "DELETE":
      res = axios.delete(url, options);
      break;
    case "PATCH":
      res = axios.patch(url, data, options);
      break;
    case "POST":
      res = axios.post(url, data, options);
      break;
    case "PUT":
      res = axios.post(url, data, options);
      break;
  }

  return await res;
}

export async function requestFormData(method: Method, path: string, form: any) {
  const url = process.env.REACT_APP_API_URL + path;
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
  };

  let res;
  switch (method) {
    case "PATCH":
      res = axios.patch(url, form, options);
      break;
    case "POST":
      res = axios.post(url, form, options);
      break;
    case "PUT":
      res = axios.post(url, form, options);
      break;
  }

  return await res;
}
