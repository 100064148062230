import { message } from "antd";
import { Formik } from "formik";
import { Form, Input, InputNumber, Select, SubmitButton } from "formik-antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import quillConfig from "../../common/quillConfig";
import { splitAndGetIndex } from "../../common/tokenizer";
import {
  useCreateOrganizationStructureMutation,
  useGetAllOrganizationStructureQuery,
  useGetOrganizationStructureQuery,
  useUpdateOrganizationStructureMutation,
} from "./organizationStructureAPI";

const { Option } = Select;

function OrganizationStructureDetail() {
  const { id } = useParams();

  const { data: singleData, isLoading: singleDataIsLoading, isError: singleDataIsError } = useGetOrganizationStructureQuery(id || "", {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const { data, error, isLoading } = useGetAllOrganizationStructureQuery(undefined, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const [updateOrganizationStructure] = useUpdateOrganizationStructureMutation();
  const [createOrganizationStructure] = useCreateOrganizationStructureMutation();

  const validate = (value) => {
    let error;
    if (!value) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  const save = async (value: any, closeLoading: any) => {
    let data: any = { ...value };

    let promise;
    if (id) {
      data.id = id;
      promise = updateOrganizationStructure(data);
    } else {
      promise = createOrganizationStructure(data);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success("Berhasil!");
      })
      .catch(() => {
        closeLoading();
        message.error("Gagal!");
      });
  };

  return (
    <div>
      <ContentWithErrorHandler isError={singleDataIsError} isLoading={singleDataIsLoading} withBox={true}>
        <Formik
          initialValues={{
            name: singleData?.name,
            hasChild: singleData?.hasChild,
            parentId: singleData?.parentId,
            position: singleData?.position,
            role: singleData?.role,
          }}
          onSubmit={async (values, actions) => {
            const hide = message.loading('Loading', 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              <Form.Item name="name" label="Nama">
                <Input name="name" placeholder="Masukkan Nama" />
              </Form.Item>
              <Form.Item name="role" label="Peran">
                <Select name="role">
                  <Option value="Manajemen">Manajemen</Option>
                  <Option value="Pengurus">Pengurus</Option>
                  <Option value="Pengawas">Pengawas</Option>
                </Select>
              </Form.Item>
              <Form.Item name="position" label="Jabatan">
                <Input name="position" placeholder="Masukkan Jabatan" />
              </Form.Item>
              <Form.Item name="hasChild" label="Punya tim?">
                <Select name="hasChild">
                  <Option value={false}>Tidak</Option>
                  <Option value={true}>Ya</Option>
                </Select>
              </Form.Item>
              <Form.Item name="parentId" label="Atasan">
                <Select name="parentId" placeholder="Pilih atasan">
                  <Option value={'null'}>Tidak Ada</Option>
                  {data?.map((e) => {
                    return <Option value={e.id}>{e.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default OrganizationStructureDetail;
