export function splitAndGetIndex(stringToSplit: string, index: number): string {
  let arr = stringToSplit.split(".");
  return arr[index];
}

export function toCurrencyString(data: any): string {
  if (isNaN(data)) return 'Rp -';
  return ('Rp ' + data).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function isEmpty(data: any): boolean {
  if (data == null) return true;
  if (data == undefined) return true;
  return false;
}