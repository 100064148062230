import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, List, message, Popconfirm, Table, Tag } from "antd";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ContentWithErrorHandler from "../../../app/ContentWithErrorHandler";
import { basicSortDateString } from "../../../common/TableHelper";
import { useGetAllAdminQuery } from '../../admin/adminAPI';
import ApprovalSignatureConfig from './ApprovalSignatureConfig';
import {
  useDeleteSubmissionAssessmentMutation,
  useGetFiveCBusinessAssessmentQuery,
  useGetFiveCWorkAssessmentQuery,
  useGetPersonalAssessmentQuery,
} from './submissionConfigurationAPI';
import { SubmissionAssessmentConfigTypeText } from './type';

function SubmissionConfigList() {
  const navigate = useNavigate();
  const { data: adminData, error: adminError, isLoading: adminLoading } = useGetAllAdminQuery();
  const { data: personalConfigData, error: personalConfigError, isFetching: personalConfigFetch } = useGetPersonalAssessmentQuery();
  const { data: fiveCWorkConfigData, error: fiveCWorkConfigError, isFetching: fiveCWorkConfigFetch } = useGetFiveCWorkAssessmentQuery();
  const {
    data: fiveCBusinessConfigData,
    error: fiveCBusinessConfigError,
    isFetching: fiveCBusinessConfigFetch,
  } = useGetFiveCBusinessAssessmentQuery();
  const [deleteSubmissionAssessment] = useDeleteSubmissionAssessmentMutation();

  const handleDelete = (id: number) => {
    const hideLoading = message.loading('Loading', 0);
    deleteSubmissionAssessment(id)
      .unwrap()
      .then(() => {
        hideLoading();
        message.success('Berhasil!');
      })
      .catch(() => {
        hideLoading();
        message.error('Gagal!');
      });
  };
  return (
    <ContentWithErrorHandler isError={personalConfigError || fiveCWorkConfigError} isLoading={false} withBox={false}>
      <Card
        style={{ marginBottom: 10 }}
        title="Data Konfigurasi Assessment Personal"
        size="small"
        extra={
          <div>
            <Button style={{ borderRadius: 5 }}>
              <Link to="assessment-create/PersonalAssessment">+ Tambah Baris</Link>
            </Button>
          </div>
        }
      >
        <Table
          loading={personalConfigFetch}
          dataSource={personalConfigData}
          bordered
          size="small"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              width: '5%',
            },
            {
              title: 'Indikator',
              dataIndex: 'indicator',
              key: 'indicator',
              sorter: (a, b) => basicSortDateString(a.indicator, b.indicator),
            },
            {
              title: 'Aksi',
              key: 'x',
              width: '10%',
              render: (text, record) => (
                <div>
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    style={{ borderRadius: 5 }}
                    onClick={() => navigate(`assessment-detail/PersonalAssessment/${record.id}`)}
                  />
                  <Popconfirm title="Apakah anda yakin?" onConfirm={() => handleDelete(record.id)} okText="Hapus" cancelText="Batal">
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
      <Card
        style={{ marginBottom: 10 }}
        title="Data Konfigurasi Assessment 5C - Tidak Memiliki Usaha"
        size="small"
        extra={
          <div>
            <Button style={{ borderRadius: 5 }}>
              <Link to="assessment-create/Character">+ Tambah Baris</Link>
            </Button>
          </div>
        }
      >
        <Table
          loading={fiveCWorkConfigFetch}
          dataSource={fiveCWorkConfigData}
          bordered
          size="small"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              width: '3%',
            },
            {
              title: 'Tipe',
              dataIndex: 'type',
              key: 'type',
              width: '10%',
              sorter: (a, b) => basicSortDateString(a.type, b.type),
              render: (type) => {
                return SubmissionAssessmentConfigTypeText[type];
              },
            },
            {
              title: 'Indikator',
              dataIndex: 'indicator',
              key: 'indicator',
              width: '15%',
              sorter: (a, b) => basicSortDateString(a.indicator, b.indicator),
            },
            {
              title: 'Akses',
              dataIndex: 'allowedUser',
              key: 'allowedUser',
              width: '8%',
              render: (data) => {
                if (data == null || data == undefined) return 'Semua';
                let name = data.map((d) => {
                  if (d == -1) return 'Semua';
                  return adminData?.find((e) => e.id == d)?.name;
                });
                return name.join(', ');
              },
            },
            {
              title: 'Deskripsi',
              dataIndex: 'descriptions',
              key: 'descriptions',
              width: '20%',
              render: (descriptions) => {
                return (
                  <>
                    {descriptions.map((e, i) => (
                      <List.Item key={i} style={{ border: 'lightgray 1px solid', paddingLeft: '6px', paddingRight: '6px', marginBottom: '6px' }}>
                        <span style={{ fontWeight: 'bold' }}>{i + 1}.</span>
                        {' ' + e}
                      </List.Item>
                    ))}
                  </>
                );
              },
            },
            {
              title: 'Aksi',
              key: 'x',
              width: '5%',
              render: (text, record) => (
                <div>
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    style={{ borderRadius: 5 }}
                    onClick={() => navigate(`assessment-detail/${record.type}/${record.id}`)}
                  />
                  <Popconfirm title="Apakah anda yakin?" onConfirm={() => handleDelete(record.id)} okText="Hapus" cancelText="Batal">
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
      <Card
        style={{ marginBottom: 10 }}
        title="Data Konfigurasi Assessment 5C - Memiliki Usaha"
        size="small"
        extra={
          <div>
            <Button style={{ borderRadius: 5 }}>
              <Link to="assessment-create/Character_Business">+ Tambah Baris</Link>
            </Button>
          </div>
        }
      >
        <Table
          loading={fiveCBusinessConfigFetch}
          dataSource={fiveCBusinessConfigData}
          bordered
          size="small"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              width: '3%',
            },
            {
              title: 'Tipe',
              dataIndex: 'type',
              key: 'type',
              width: '10%',
              sorter: (a, b) => basicSortDateString(a.type, b.type),
              render: (type) => {
                return SubmissionAssessmentConfigTypeText[type];
              },
            },
            {
              title: 'Indikator',
              dataIndex: 'indicator',
              key: 'indicator',
              width: '15%',
              sorter: (a, b) => basicSortDateString(a.indicator, b.indicator),
            },
            {
              title: 'Akses',
              dataIndex: 'allowedUser',
              key: 'allowedUser',
              width: '8%',
              render: (data) => {
                if (data == null || data == undefined) return 'Semua';
                let name = data.map((d) => {
                  if (d == -1) return 'Semua';
                  return adminData?.find((e) => e.id == d)?.name;
                });
                return name.join(', ');
              },
            },
            {
              title: 'Deskripsi',
              dataIndex: 'descriptions',
              key: 'descriptions',
              width: '20%',
              render: (descriptions) => {
                return (
                  <>
                    {descriptions.map((e, i) => (
                      <List.Item key={i} style={{ border: 'lightgray 1px solid', paddingLeft: '6px', paddingRight: '6px', marginBottom: '6px' }}>
                        <span style={{ fontWeight: 'bold' }}>{i + 1}.</span>
                        {' ' + e}
                      </List.Item>
                    ))}
                  </>
                );
              },
            },
            {
              title: 'Aksi',
              key: 'x',
              width: '5%',
              render: (text, record) => (
                <div>
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    style={{ borderRadius: 5 }}
                    onClick={() => navigate(`assessment-detail/${record.type}/${record.id}`)}
                  />
                  <Popconfirm title="Apakah anda yakin?" onConfirm={() => handleDelete(record.id)} okText="Hapus" cancelText="Batal">
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
      <Card title="Data Konfigurasi Tanda Tangan" size="small">
        <ApprovalSignatureConfig />
      </Card>
      <Outlet />
    </ContentWithErrorHandler>
  );
}

export default SubmissionConfigList;
