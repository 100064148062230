import { LoadingOutlined } from '@ant-design/icons';
import { Layout, Result, Spin } from 'antd';
import { ReactNode } from 'react';

interface ContentWithErrorHandlerProps {
  isError: any;
  isLoading?: any;
  withBox?: boolean;
  customErrorMessage?: string;
  fullScreen?: boolean;
  children: ReactNode;
  show?: boolean;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function ContentWithErrorHandler({
  isLoading,
  isError,
  withBox,
  customErrorMessage,
  children,
  fullScreen = true,
  show = true,
}: ContentWithErrorHandlerProps) {
  return (
    <>
      {show ? (
        isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: fullScreen ? 'calc(100vh - 130px)' : '100%',
            }}
          >
            <Spin indicator={antIcon} />
          </div>
        ) : (
          <ContentWithErrorHandlerInternal isError={isError} isLoading={isLoading} withBox={withBox} customErrorMessage={customErrorMessage}>
            {children}
          </ContentWithErrorHandlerInternal>
        )
      ) : (
        <></>
      )}
    </>
  );
}

function ContentWithErrorHandlerInternal({ isError, withBox, customErrorMessage, children }: ContentWithErrorHandlerProps) {
  return (
    <>
      {isError ? (
        <div>
          <Result
            style={{
              background: '#fff7e6',
              borderColor: '#ffc069',
              borderWidth: '1px',
              borderStyle: 'solid',
            }}
            status="warning"
            title="Terjadi kesalahan!"
            subTitle={
              customErrorMessage ? customErrorMessage : 'Anda tidak memiliki akses untuk melihat halaman ini, atau terjadi kendala pada server'
            }
          />
        </div>
      ) : withBox ? (
        <div style={{ background: 'white', padding: '20px', marginBottom: '20px' }}>{children}</div>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export default ContentWithErrorHandler;
