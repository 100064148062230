import { message } from "antd";
import { Formik } from "formik";
import { Form, Input, InputNumber, Select, SubmitButton } from "formik-antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import quillConfig from "../../common/quillConfig";
import { splitAndGetIndex } from "../../common/tokenizer";
import { useCreateGeneralInfoMutation, useGetGeneralInfoQuery, useUpdateGeneralInfoMutation } from "./generalInfoAPI";

const { Option } = Select;

interface InitialData {
  title: string;
  viewOrder: number;
}

interface Param {
  id: string;
  serviceType: string;
}

function GeneralInfoItemDetail() {
  const { id, serviceType } = useParams();

  const [benefit, setBenefit] = useState("");
  const [prerequisite, setPrerequisite] = useState("");
  const [initialData, setInitialData] = useState<InitialData>({
    title: "",
    viewOrder: 1,
  });

  const { data: generalInfoTitle, isLoading: generalInfoTitleIsLoading, isError: generalInfoTitleIsError } = useGetGeneralInfoQuery(
    id?.split(",")[0] || "",
    {
      skip: id?.split(",")[0] ? false : true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  const {
    data: generalInfoPrerequisite,
    isLoading: generalInfoPrerequisiteIsLoading,
    isError: generalInfoPrerequisiteIsError,
  } = useGetGeneralInfoQuery(id?.split(",")[1] || "", {
    skip: id?.split(",")[1] ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const { data: generalInfoBenefit, isLoading: generalInfoBenefitIsLoading, isError: generalInfoBenefitIsError } = useGetGeneralInfoQuery(
    id?.split(",")[2] || "",
    {
      skip: id?.split(",")[2] ? false : true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const [updateGeneralInfo] = useUpdateGeneralInfoMutation();
  const [createGeneralInfo] = useCreateGeneralInfoMutation();

  useEffect(() => {
    if (generalInfoTitle && generalInfoPrerequisite && generalInfoBenefit) {
      setInitialData({
        title: generalInfoTitle.title,
        viewOrder: parseInt(splitAndGetIndex(generalInfoTitle.type, 2)),
      });
      setBenefit(generalInfoPrerequisite.content);
      setPrerequisite(generalInfoBenefit.content);
    }
  }, [generalInfoTitle, generalInfoPrerequisite, generalInfoBenefit]);

  const validate = (value) => {
    let error;
    if (!value) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  const save = async (value: InitialData, closeLoading: any) => {
    if (benefit.length == 0) {
      closeLoading();
      message.error("Konten tidak boleh kosong");
      return;
    }

    let promises: any[] = [];

    // Save Title
    let idStrArr = id || "";
    promises.push(constructSavePromise(idStrArr.split(",")[0], "title", value.title, value.viewOrder, ""));
    promises.push(constructSavePromise(idStrArr.split(",")[1], "prerequisite", value.title, value.viewOrder, prerequisite));
    promises.push(constructSavePromise(idStrArr.split(",")[2], "benefit", value.title, value.viewOrder, benefit));

    let successState = true;
    for (let promise of promises) {
      promise
        .unwrap()
        .then(() => {
          successState = successState && true;
        })
        .catch(() => {
          successState = successState && false;
        });
    }

    closeLoading();
    if (successState) {
      message.success("Berhasil!");
    } else {
      message.error("Gagal!");
    }
  };

  const constructSavePromise = (id: string, type: string, title: string, viewOrder: number, content: string) => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("type", `${serviceType}.item.${viewOrder}.${type}`);
    formData.append("isHeader", String(false));
    formData.append("content", content);

    if (id) {
      formData.append("id", id);
      return updateGeneralInfo(formData);
    } else {
      return createGeneralInfo(formData);
    }
  };

  return (
    <div>
      <ContentWithErrorHandler
        isError={generalInfoTitleIsError || generalInfoPrerequisiteIsError || generalInfoBenefitIsError}
        isLoading={generalInfoTitleIsLoading || generalInfoPrerequisiteIsLoading || generalInfoBenefitIsLoading}
        withBox={true}
      >
        <Formik
          initialValues={initialData}
          onSubmit={async (values, actions) => {
            const hide = message.loading("Loading", 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              <Form.Item name="title" label="Nama Layanan" validate={validate}>
                <Input name="title" placeholder="Masukkan nama layanan" />
              </Form.Item>
              <Form.Item name="viewOrder" label="Urutan">
                <InputNumber name="viewOrder" placeholder="Masukkan urutan" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item name="content" label="Syarat dan Ketentuan">
                <ReactQuill
                  modules={quillConfig}
                  style={{
                    background: "white",
                  }}
                  onChange={(content) => setPrerequisite(content)}
                  value={prerequisite}
                />
              </Form.Item>
              <Form.Item name="benefit" label="Bentuk Santunan">
                <ReactQuill
                  modules={quillConfig}
                  style={{
                    background: "white",
                  }}
                  onChange={(content) => setBenefit(content)}
                  value={benefit}
                />
              </Form.Item>
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default GeneralInfoItemDetail;
