import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, List, message, Popconfirm, Popover, Table } from "antd";
import { Link, useNavigate } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { basicSort } from "../../common/TableHelper";
import { useDeleteQuestionaireMutation, useGetAllQuestionaireQuery } from "./financialLiteracyAPI";
import { PreviousId, Questionaire } from "./types";

function FinancialLiteracyQuestionList() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetAllQuestionaireQuery();
  const [deleteQuestionaire] = useDeleteQuestionaireMutation();

  const parsePreviousId = (previousData: string) => {
    let result: PreviousId[] = [];

    if (previousData.length > 0) {
      let splitByQuestions: string[] = previousData.split("|");
      for (let splitByQuestion of splitByQuestions) {
        let split: string[] = splitByQuestion.split("#");
        result.push({
          questionId: Number(split[0]),
          answerIndex: isNaN(Number(split[1])) ? -100 : Number(split[1]),
        });
      }
    }

    return result;
  };

  const getQuestionAnswerContent = (previousId: PreviousId) => {
    let temp: Questionaire | undefined = data?.filter((e) => e.id == previousId.questionId)[0];
    if (previousId.answerIndex != null && previousId.answerIndex != undefined && temp != null) {
      if (previousId.answerIndex != -100) {
        return (
          <div>
            <span style={{ fontWeight: "bold" }}> Pertanyaan: </span> {temp.question}
            <br />
            <span style={{ fontWeight: "bold" }}> Jawaban:</span> {temp.answers[previousId.answerIndex].text}
          </div>
        );
      } else {
        return (
          <div>
            <span style={{ fontWeight: "bold" }}> Pertanyaan:</span> {temp.question}
          </div>
        );
      }
    }
    return <></>;
  };

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Card
        title="Data Pertanyaan Kuisioner"
        size="small"
        extra={
          <div>
            <Button style={{ borderRadius: 5 }}>
              <Link to="create">+ Tambah Baris</Link>
            </Button>
          </div>
        }
      >
        <Table
          loading={isLoading}
          dataSource={data}
          bordered
          size="small"
          pagination={{ pageSize: 20 }}
          columns={[
            {
              title: "ID",
              dataIndex: "id",
              key: "id",
              sorter: (a, b) => basicSort(a.id, b.id),
              width: "5%",
            },
            {
              title: "Pertanyaan\nSebelumnya",
              dataIndex: "previousId",
              key: "previousId",
              width: "20%",
              render: (prevId) => {
                return (
                  <>
                    {parsePreviousId(prevId).map((e, i) => (
                      <List.Item
                        key={prevId + i}
                        style={{ border: "lightgray 1px solid", paddingLeft: "6px", paddingRight: "6px", marginBottom: "6px" }}
                      >
                        <Popover content={getQuestionAnswerContent(e)}>
                          Pertanyaan: <span style={{ fontWeight: "bold" }}>{e.questionId} </span>
                          <br />
                          Jawaban: <span style={{ fontWeight: "bold" }}>{e.answerIndex == -100 ? "Semua" : e.answerIndex + 1}</span>
                        </Popover>
                      </List.Item>
                    ))}
                  </>
                );
              },
            },
            {
              title: "Pertanyaan",
              dataIndex: "question",
              key: "question",
              width: "25%",
            },
            {
              title: "Pilihan Jawaban",
              dataIndex: "answers",
              key: "anwers",
              width: "45%",
              render: (answers) => {
                return (
                  <>
                    {answers.map((e, i) => (
                      <List.Item
                        key={e.text}
                        style={{ border: "lightgray 1px solid", paddingLeft: "6px", paddingRight: "6px", marginBottom: "6px" }}
                      >
                        <span style={{ fontWeight: "bold" }}>{i + 1}.</span>
                        {" " + e.text}
                      </List.Item>
                    ))}
                  </>
                );
              },
            },
            {
              title: "Aksi",
              key: "x",
              width: "5%",
              render: (text, record) => (
                <div>
                  <Button type="link" icon={<EditOutlined />} style={{ borderRadius: 5 }} onClick={() => navigate(`detail/${record.id}`)} />
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading("Loading", 0);
                      deleteQuestionaire(record.id)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success("Berhasil!");
                        })
                        .catch(() => {
                          hideLoading();
                          message.error("Gagal!");
                        });
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
    </ContentWithErrorHandler>
  );
}

export default FinancialLiteracyQuestionList;
