import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Row, Upload } from 'antd';
import { Form, Select } from 'formik-antd';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import ContentWithErrorHandler from '../../../app/ContentWithErrorHandler';
import quillConfig from '../../../common/quillConfig';
import { SubmissionForm } from './types';

const DATE_FORMAT = 'DD/MM/YYYY';

const { Option } = Select;
const { Dragger } = Upload;

type Props = {
  formValue: SubmissionForm;
  setFieldValue: any;
  upload: {
    ceoUpload: any;
    setCeoUpload: any;
    handleCeoUpload: any;
  };
};

function CeoAnalysisForm(props: Props) {
  const [updateNote, setUpdateNote] = useState('');

  useEffect(() => {
    props.setFieldValue('ceoAnalysisForm.notes', updateNote);
  }, [updateNote]);

  useEffect(() => {
    if (props.formValue.ceoAnalysisForm?.notes) {
      setUpdateNote(props.formValue.ceoAnalysisForm.notes);
    }
  }, [props.formValue.ceoAnalysisForm?.notes]);
  return (
    <ContentWithErrorHandler isError={false} isLoading={false} withBox={false}>
      <Row gutter={[8, 0]}>
        <Col span={24}>
          <Form.Item name="ceoAnalysisForm.notes" label="Catatan">
            <ReactQuill
              modules={quillConfig}
              style={{
                background: 'white',
                height: '360px',
                marginBottom: '30px',
              }}
              onChange={(note) => setUpdateNote(note)}
              value={updateNote}
            />
          </Form.Item>
          <Form.Item name="dummy" label="Upload">
            <Dragger
              beforeUpload={(file) => {
                props.upload.setCeoUpload((oldState) => [...oldState, file]);
                return false;
              }}
              onChange={props.upload.handleCeoUpload}
              multiple={false}
              listType="picture"
              accept=".png, .jpg, .jpeg"
              fileList={props.upload.ceoUpload}
            >
              <Button>
                <UploadOutlined /> Unggah
              </Button>
            </Dragger>
          </Form.Item>
        </Col>
      </Row>
    </ContentWithErrorHandler>
  );
}

export default CeoAnalysisForm;
