import { DislikeTwoTone, LikeTwoTone } from "@ant-design/icons";
import { Card, Space, Statistic } from "antd";
import React from "react";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { useGetLoanReportSummaryQuery } from "./loanAPI";

function LoanSummary() {
  const { data, error, isLoading } = useGetLoanReportSummaryQuery();

  const renderGoodOrBad = (value, target) => {
    if (value == null) return "";
    if (value <= target) {
      return <LikeTwoTone twoToneColor="#52c41a" />;
    } else {
      return <DislikeTwoTone twoToneColor="#eb2f96" />;
    }
  };

  return (
    <Card size="small" title="Ringkasan hari ini" bordered={false}>
      <ContentWithErrorHandler isError={error} isLoading={isLoading} withBox={false} fullScreen={false}>
        <Space wrap size={"large"}>
          <Statistic title="Pinjaman Aktif" value={data?.totalCount} suffix={<div style={{ fontSize: 16, color: "#6c757d" }}>akun</div>} />
          <Statistic title="Nominal Pinjaman Aktif" value={data?.totalAmount} prefix="Rp" />
          <Statistic
            title="Non-performing Loan"
            value={data?.nplCount}
            suffix={<div style={{ fontSize: 16, color: "#6c757d" }}>akun</div>}
          />
          <Statistic title="Nominal Non-performing Loan" value={data?.nplAmount} prefix="Rp" />
          <Statistic
            title="Persentase NPL (Akun)"
            value={data?.nplPercentAccount}
            precision={2}
            prefix={renderGoodOrBad(data?.nplPercentAccount, 2)}
            suffix="%"
          />
          <Statistic
            title="Persentase NPL (Rupiah)"
            value={data?.nplPercentAmount}
            precision={2}
            prefix={renderGoodOrBad(data?.nplPercentAmount, 2)}
            suffix="%"
          />
          <Statistic title="Perkiraan Penerimaan Cicilan" value={data?.currentDayForecast} prefix="Rp" />
        </Space>
      </ContentWithErrorHandler>
    </Card>
  );
}

export default LoanSummary;
