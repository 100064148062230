import { message } from "antd";
import { Formik } from "formik";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import quillConfig from "../../common/quillConfig";
import { useCreateOfficeLocationMutation, useGetOfficeLocationQuery, useUpdateOfficeLocationMutation } from "./officeLocationAPI";

const { Option } = Select;

interface InitialData {
  name: string;
  phone: string[];
  address: string;
  type: string;
  mapUrl: string;
}

function OfficeLocationDetail() {
  const { id } = useParams();

  const [address, setAddress] = useState("");

  const {
    data: officelocationData,
    isLoading: officelocationDataIsLoading,
    isError: officelocationDataIsError,
  } = useGetOfficeLocationQuery(id || "", {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const [updateOfficeLocation] = useUpdateOfficeLocationMutation();
  const [createOfficeLocation] = useCreateOfficeLocationMutation();

  useEffect(() => {
    if (officelocationData) {
      setAddress(officelocationData.address);
    }
  }, [officelocationData]);

  const validate = (value) => {
    let error;
    if (!value || value.length == 0) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  const save = async (value: InitialData, closeLoading: any) => {
    if (address.length == 0) {
      closeLoading();
      message.error("Alamat tidak boleh kosong");
      return;
    }

    let dataToUpdate: any = {
      ...value,
      address: address,
    };

    let promise;
    if (id) {
      dataToUpdate.id = id;
      promise = updateOfficeLocation(dataToUpdate);
    } else {
      promise = createOfficeLocation(dataToUpdate);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success("Berhasil!");
      })
      .catch(() => {
        closeLoading();
        message.error("Gagal!");
      });
  };

  return (
    <div>
      <ContentWithErrorHandler isError={officelocationDataIsError} isLoading={officelocationDataIsLoading} withBox={true}>
        <Formik
          initialValues={{
            name: officelocationData?.name || "",
            phone: officelocationData?.phone || [],
            address: officelocationData?.address || "",
            type: officelocationData?.type || "main",
            mapUrl: officelocationData?.mapUrl || "",
          }}
          onSubmit={async (values, actions) => {
            const hide = message.loading("Loading", 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              <Form.Item name="type" label="Tipe Kantor">
                <Select name="type">
                  <Option value="main">Kantor Utama</Option>
                  <Option value="branch">Kantor Cabang</Option>
                </Select>
              </Form.Item>
              <Form.Item name="name" label="Nama Kantor Layanan" validate={validate}>
                <Input name="name" placeholder="Masukkan nama kantor layanan" />
              </Form.Item>
              <Form.Item name="mapUrl" label="Link Google Map" validate={validate}>
                <Input.TextArea name="mapUrl" placeholder="Masukkan link google map" />
              </Form.Item>
              <Form.Item name="phone" label="Telepon" validate={validate}>
                <Select name="phone" mode="tags" placeholder="Masukkan telepon">
                  {officelocationData?.phone.map((phone) => (
                    <Option key={phone} value={phone}>
                      {phone}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="address" label="Alamat">
                <ReactQuill
                  modules={quillConfig}
                  style={{
                    background: "white",
                  }}
                  onChange={(address) => setAddress(address)}
                  value={address}
                />
              </Form.Item>
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default OfficeLocationDetail;
