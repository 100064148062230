import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, message, Popconfirm, Table } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { basicSort, TableColumnTextFilterConfig } from "../../common/TableHelper";
import { useDeleteAdminMutation, useGetAllAdminQuery } from "./adminAPI";
import { Admin } from "./types";

function AdminList() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetAllAdminQuery();
  const [deleteAdmin] = useDeleteAdminMutation();

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Card
        title="Data Officer"
        size="small"
        extra={
          <div>
            <Button style={{ borderRadius: 5 }}>
              <Link to="create">+ Tambah Baris</Link>
            </Button>
          </div>
        }
      >
        <Table
          loading={isLoading}
          dataSource={data}
          bordered
          size="small"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              title: "Nama",
              dataIndex: "name",
              key: "name",
              width: "30%",
              sorter: (a, b) => basicSort(a.name, b.name),
              ...TableColumnTextFilterConfig<Admin>("name"),
            },
            {
              title: "Username",
              dataIndex: "username",
              key: "username",
              width: "15%",
              sorter: (a, b) => basicSort(a.username, b.username),
              ...TableColumnTextFilterConfig<Admin>("username"),
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
              width: "20%",
              sorter: (a, b) => basicSort(a.email, b.email),
              ...TableColumnTextFilterConfig<Admin>("email"),
            },
            {
              title: "Jabatan",
              key: "positionName",
              width: "25%",
              sorter: (a, b) => basicSort(a.position.name, b.position.name),
              render: (text, record) => <div>{record.position.name}</div>,
            },
            {
              title: "Aksi",
              key: "x",
              width: "10%",
              render: (text, record) => (
                <div>
                  <Button type="link" icon={<EditOutlined />} style={{ borderRadius: 5 }} onClick={() => navigate(`detail/${record.id}`)} />
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading("Loading", 0);
                      deleteAdmin(record.id)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success("Berhasil!");
                        })
                        .catch(() => {
                          hideLoading();
                          message.error("Gagal!");
                        });
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
    </ContentWithErrorHandler>
  );
}

export default AdminList;
