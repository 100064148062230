import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Empty, InputNumber, message, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { basicSort, DynamicFilter } from '../../common/TableHelper';
import { useGenerateKpiExecutionMutation } from './adminAPI';
import { IndicatorTypeText, KPIBaseEntity, KPIExecutionEntity, KPITarget, MONTHS, OperatorTypeText, TargetType } from './types';
import { calculateRealization } from './Util';

const { Text } = Typography;

type Props = {
  data: KPIExecutionEntity[];
  setData: any;
  isFetching: any;
  refetch: any;
  month: number;
  year: number;
  adminId: number | undefined;
};

function PerformanceIndicatorExecutionsDetail(props: Props) {
  const [generateKpiExecution] = useGenerateKpiExecutionMutation();

  const handleValueChange = (id: number, itemKey: string, rawValue: number | null | boolean) => {
    let value = rawValue == null ? 0 : Number(rawValue);
    props.setData((prev) => {
      let result = prev.map((e) => {
        if (e.id == id) {
          return {
            ...e,
            items: e.items.map((i) => {
              if (i.key == itemKey) {
                return {
                  key: i.key,
                  value: value,
                };
              }
              return i;
            }),
          };
        }
        return e;
      });
      return result;
    });
  };

  const handleGenerateExecution = () => {
    const hide = message.loading('Loading', 0);
    generateKpiExecution({ id: props.adminId })
      .unwrap()
      .then(() => {
        hide();
        props.refetch();
        message.success('Berhasil!');
      })
      .catch((e) => {
        hide();
        message.error(e.data.message);
      });
  };

  const renderColumn = (): ColumnsType<KPIExecutionEntity> | undefined => {
    if (props.data?.length == 0) return [];
    if (props.data?.[0]?.items == undefined) return [];

    let result: ColumnsType<KPIExecutionEntity> = [
      {
        title: 'Tipe',
        dataIndex: ['kpi', 'indicatorType'],
        key: 'kpi.indicatorType',
        width: 90,
        fixed: 'left',
        sorter: (a, b) => basicSort(a.kpi.indicatorType, a.kpi.indicatorType),
        render: (item) => IndicatorTypeText[item],
        ...DynamicFilter<KPIExecutionEntity>(props.data, ['kpi', 'indicatorType'], true),
      },
      {
        title: 'Indikator',
        dataIndex: ['kpi', 'indicator'],
        key: 'kpi.indicator',
        width: 100,
        fixed: 'left',
      },
      {
        title: 'Bobot',
        dataIndex: ['kpi', 'weight'],
        key: 'kpi.weight',
        width: 55,
        fixed: 'left',
        render: (weight) => weight + ' %',
      },
      {
        title: 'Base/Stretch',
        dataIndex: ['kpi'],
        key: 'kpi.base',
        width: 120,
        fixed: 'left',
        render: (kpi: KPIBaseEntity) => {
          let base = kpi.base;
          let text = 'Tidak Ada';
          let text2 = 'Tidak Ada';
          if (base.type == TargetType.NUMBER.toString()) {
            text = `${base.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            text2 = `${base.value2}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          } else if (base.type == TargetType.PERCENTAGE.toString()) {
            text = `${base.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' %';
            text2 = `${base.value2}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' %';
          } else if (base.type == TargetType.TEXT.toString()) {
            text = `${base.text}`;
          }

          if (base.operator == 'BETWEEN') {
            text = text + ' - ' + text2;
          }

          let stretch = kpi.stretch;
          let stretchText = 'Tidak Ada';
          if (stretch.type == TargetType.NUMBER.toString()) {
            stretchText = `${stretch.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          } else if (stretch.type == TargetType.PERCENTAGE.toString()) {
            stretchText = `${stretch.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' %';
          } else if (stretch.type == TargetType.TEXT.toString()) {
            stretchText = `${stretch.text}`;
          } else {
            stretchText = '-';
          }

          if (base.type == TargetType.NUMBER.toString() || base.type == TargetType.PERCENTAGE.toString()) {
            return (
              <Tooltip title={OperatorTypeText[base.operator]}>
                <Text underline>
                  <span>
                    <b>Base:&nbsp;</b>
                  </span>
                  {text} <QuestionCircleOutlined />
                </Text>
                <Text>
                  <span>
                    <b>Stretch:&nbsp;</b>
                  </span>
                  {stretchText}
                </Text>
              </Tooltip>
            );
          }
          return text;
        },
      },
    ];

    for (let datum of props.data?.[0]?.items) {
      result.push({
        title: datum.key,
        dataIndex: 'items',
        key: datum.key,
        width: 120,

        render: (_, record) => {
          let dataToShow = record.items.find((e) => e.key == datum.key)?.value;
          if (record.kpi.base.type == 'TEXT') {
            return <Checkbox checked={Boolean(dataToShow)} onChange={(e) => handleValueChange(record.id, datum.key, e.target.checked)}></Checkbox>;
          } else if (record.kpi.base.type == 'PERCENTAGE') {
            return (
              <InputNumber
                style={{ width: '100%' }}
                size="small"
                controls={false}
                value={dataToShow}
                addonAfter="%"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                onChange={(e) => handleValueChange(record.id, datum.key, e)}
              ></InputNumber>
            );
          } else if (record.kpi.base.type == 'NUMBER') {
            return (
              <InputNumber
                style={{ width: '100%' }}
                size="small"
                controls={false}
                value={dataToShow}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                onChange={(e) => handleValueChange(record.id, datum.key, e)}
              ></InputNumber>
            );
          } else {
            return '-';
          }
        },
      });
    }

    result.push({
      title: 'Realisasi',
      key: 'realization',
      fixed: 'right',
      width: 95,
      render: (_, record) => {
        let calculation = calculateRealization(record);
        return `${calculation.total} (${Math.round(calculation.percentage * 100)} %)`;
      },
    });
    result.push({
      title: 'Hasil',
      key: 'result',
      fixed: 'right',
      width: 55,
      render: (_, record) => {
        let calculation = calculateRealization(record);
        return `${calculation.weightPercentage} %`;
      },
    });
    return result;
  };

  const getGenerateDescription = () => {
    let month = props.month;
    let year = props.year;
    let prevMonth = (month + 12 - 1) % 12;

    if (new Date().getMonth() != month) {
      return (
        <span>
          KPI bulan
          <b>
            &nbsp;{MONTHS[month].longString} {year}&nbsp;
          </b>
          belum dapat digenerate.
          <br /> KPI dapat digenerate mulai dari tanggal <b>26 {MONTHS[prevMonth].longString}</b> hingga tanggal&nbsp;
          <b>25 {MONTHS[month].longString}</b>
        </span>
      );
    }
    return (
      <span>
        KPI bulan
        <b>
          &nbsp;{MONTHS[month].longString} {year}&nbsp;
        </b>
        belum ada. Silahkan menekan tombol generate.
      </span>
    );
  };

  const renderDetail = () => {
    if (!props.data || props.data.length == 0) {
      return (
        <Empty description={getGenerateDescription()}>
          <Button disabled={new Date().getMonth() != props.month} onClick={() => handleGenerateExecution()} type="primary">
            Generate KPI
          </Button>
        </Empty>
      );
    }
    return (
      <Table
        scroll={{ x: 1300 }}
        style={{ marginBottom: 10 }}
        loading={props.isFetching}
        dataSource={props.data}
        bordered
        size="small"
        pagination={{ pageSize: 8, hideOnSinglePage: true }}
        columns={renderColumn()}
      />
    );
  };

  return <>{renderDetail()}</>;
}

export default PerformanceIndicatorExecutionsDetail;
