import { MinusCircleOutlined, PlusSquareFilled } from '@ant-design/icons';
import { Button, Col, Divider, Row, Space, Table } from 'antd';
import { FieldArray } from 'formik';
import { Form, Input, InputNumber, Select, Checkbox } from 'formik-antd';
import { floor } from 'lodash';
import ContentWithErrorHandler from '../../../app/ContentWithErrorHandler';
import { Member } from '../../member/types';
import { SubmissionAssetItem, SubmissionForm } from './types';

const DATE_FORMAT = 'DD/MM/YYYY';

const { Option } = Select;

type Props = {
  formValue: SubmissionForm;
};

function CollateralDataForm(props: Props) {
  const renderAssetForm = (type: string, index: number, item: SubmissionAssetItem) => {
    if (type == 'sertifikat_hak_milik' || type == 'sertifikat_hak_guna' || type == 'hak_guna_usaha') {
      const ownerName = `collateralData.assets.[${index}].houseOrLand.ownerName`;
      const ownerAddress = `collateralData.assets[${index}].houseOrLand.ownerAddress`;
      const certificateNumber = `collateralData.assets[${index}].houseOrLand.certificateNumber`;

      const passableByCar = `collateralData.assets[${index}].houseOrLand.checklist.passableByCar`;
      const cleanWater = `collateralData.assets[${index}].houseOrLand.checklist.cleanWater`;
      const electricity = `collateralData.assets[${index}].houseOrLand.checklist.electricity`;
      const floodFree = `collateralData.assets[${index}].houseOrLand.checklist.floodFree`;

      const landArea = `collateralData.assets[${index}].houseOrLand.value.land.areaInMeter`;
      const landValue = `collateralData.assets[${index}].houseOrLand.value.land.valuePerMeter`;
      const buildingArea = `collateralData.assets[${index}].houseOrLand.value.building.areaInMeter`;
      const buildingValue = `collateralData.assets[${index}].houseOrLand.value.building.valuePerMeter`;

      if (item.houseOrLand == null) {
        item.bpkb = null;
        item.gold = null;
        item.houseOrLand = {
          ownerName: '',
          ownerAddress: '',
          certificateNumber: '',
          checklist: {
            cleanWater: false,
            electricity: false,
            floodFree: false,
            passableByCar: false,
          },
          value: {
            building: {
              areaInMeter: 0,
              valuePerMeter: 0,
            },
            land: {
              areaInMeter: 0,
              valuePerMeter: 0,
            },
          },
        };
      }

      return (
        <>
          <Form.Item name={ownerName} label="Nama Pemilik">
            <Input fast name={ownerName} placeholder="Lengkapi Nama Pemilik" />
          </Form.Item>
          <Form.Item name={ownerAddress} label="Alamat Pemilik">
            <Input fast name={ownerAddress} placeholder="Lengkapi Alamat Pemilik" />
          </Form.Item>
          <Form.Item name={certificateNumber} label="Nomor Sertifikat">
            <Input fast name={certificateNumber} placeholder="Nomor Sertifikat" />
          </Form.Item>
          <Divider plain>Fasilitas</Divider>
          <Space direction="vertical">
            <Checkbox name={passableByCar}>Jalan bisa dilewati mobil</Checkbox>
            <Checkbox name={cleanWater}>Air bersih</Checkbox>
            <Checkbox name={electricity}>Listrik</Checkbox>
            <Checkbox name={floodFree}>Bebas banjir</Checkbox>
          </Space>

          <Divider plain>Nilai Aset</Divider>
          <Row gutter={[4, 4]}>
            <Col span="5">
              <Form.Item name={landArea} label="L. Tanah (m2)">
                <InputNumber defaultValue={0} name={landArea} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span="6">
              <Form.Item name={landValue} label="Harga (per m2)">
                <InputNumber
                  formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                  name={landValue}
                  defaultValue={0}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span="5">
              <Form.Item name="dummy" label="Total">
                <InputNumber
                  formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                  value={floor(item.houseOrLand?.value?.land?.areaInMeter * item.houseOrLand?.value?.land?.valuePerMeter)}
                  name="dummy"
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item name="dummy" label="Nilai (Total x 2 x 70%)">
                <InputNumber
                  formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                  value={floor(item.houseOrLand?.value?.land?.areaInMeter * item.houseOrLand?.value?.land?.valuePerMeter * 1.4)}
                  name="dummy"
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[4, 4]}>
            <Col span="5">
              <Form.Item name={buildingArea} label="L. Bangunan (m2)">
                <InputNumber defaultValue={0} name={buildingArea} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span="6">
              <Form.Item name={buildingValue} label="Harga (per m2)">
                <InputNumber
                  formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                  name={buildingValue}
                  defaultValue={0}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span="5">
              <Form.Item name="dummy" label="Total">
                <InputNumber
                  formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                  value={floor(item.houseOrLand?.value?.building?.areaInMeter * item.houseOrLand?.value?.building?.valuePerMeter)}
                  name="dummy"
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item name="dummy" label="Nilai (Total x 2 x 70%)">
                <InputNumber
                  formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                  value={floor(item.houseOrLand?.value?.building?.areaInMeter * item.houseOrLand?.value?.building?.valuePerMeter * 1.4)}
                  name="dummy"
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="dummy" label="Total Nilai Jaminan">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              value={
                floor(item.houseOrLand?.value?.building?.areaInMeter * item.houseOrLand?.value?.building?.valuePerMeter * 1.4) +
                floor(item.houseOrLand?.value?.land?.areaInMeter * item.houseOrLand?.value?.land?.valuePerMeter * 1.4)
              }
              name="dummy"
              readOnly
              style={{ width: '100%' }}
            />
          </Form.Item>
        </>
      );
    } else if (type == 'emas') {
      const form = `collateralData.assets.[${index}].gold.form`;
      const estimatedValue = `collateralData.assets[${index}].gold.estimatedValue`;
      const weightInGram = `collateralData.assets[${index}].gold.weightInGram`;

      if (item.gold == null) {
        item.bpkb = null;
        item.gold = {
          estimatedValue: 0,
          form: '',
          weightInGram: 0,
        };
        item.houseOrLand = null;
      }

      return (
        <>
          <Form.Item name={form} label="Bentuk (Cincin, Kalung, Batang)">
            <Input fast name={form} placeholder="Lengkapi bentuk emas" />
          </Form.Item>
          <Form.Item name={weightInGram} label="Berat (gram)">
            <InputNumber defaultValue={0} name={weightInGram} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name={estimatedValue} label="Nilai Jaminan">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              name={estimatedValue}
              defaultValue={0}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </>
      );
    } else {
      const estimatedValue = `collateralData.assets.[${index}].bpkb.estimatedValue`;
      const monthlyServiceCost = `collateralData.assets[${index}].bpkb.monthlyServiceCost`;
      const vehicleAgeInMonth = `collateralData.assets[${index}].bpkb.vehicleAgeInMonth`;

      const ownerName = `collateralData.assets[${index}].bpkb.ownerName`;
      const ownerAddress = `collateralData.assets[${index}].bpkb.ownerAddress`;
      const vehicleType = `collateralData.assets[${index}].bpkb.vehicleType`;
      const manufacture = `collateralData.assets[${index}].bpkb.manufacture`;
      const color = `collateralData.assets[${index}].bpkb.color`;
      const vehicleNumber = `collateralData.assets[${index}].bpkb.vehicleNumber`;
      const bpkbNumber = `collateralData.assets[${index}].bpkb.bpkbNumber`;
      const vehicleYear = `collateralData.assets[${index}].bpkb.vehicleYear`;
      const notes = `collateralData.assets[${index}].bpkb.notes`;

      if (item.bpkb == null) {
        item.bpkb = {
          estimatedValue: 0,
          monthlyServiceCost: 50000,
          vehicleAgeInMonth: 0,

          ownerName: '',
          ownerAddress: '',
          vehicleType: '',
          manufacture: '',
          color: '',
          vehicleNumber: '',
          bpkbNumber: '',
          vehicleYear: 2022,
          notes: '',
        };
        item.gold = null;
        item.houseOrLand = null;
      }

      return (
        <>
          <Form.Item name={ownerName} label="Nama Pemilik">
            <Input fast name={ownerName} placeholder="Lengkapi Nama Pemilik" />
          </Form.Item>
          <Form.Item name={ownerAddress} label="Alamat Pemilik">
            <Input fast name={ownerAddress} placeholder="Lengkapi Alamat Pemilik" />
          </Form.Item>
          <Form.Item name={vehicleType} label="Tipe Kendaraan">
            <Input fast name={vehicleType} placeholder="Lengkapi Tipe Kendaraan" />
          </Form.Item>
          <Form.Item name={manufacture} label="Merek/Manufaktur">
            <Input fast name={manufacture} placeholder="Lengkapi Merk/Manufaktur Kendaraan" />
          </Form.Item>
          <Form.Item name={color} label="Warna">
            <Input fast name={color} placeholder="Lengkapi Warna Kendaraan" />
          </Form.Item>
          <Form.Item name={vehicleNumber} label="Nomor TNKB (Nomor Plat)">
            <Input fast name={vehicleNumber} placeholder="Lengkapi TNKB (Nomor Plat) Kendaraan" />
          </Form.Item>
          <Form.Item name={bpkbNumber} label="Nomor BPKB">
            <Input fast name={bpkbNumber} placeholder="Lengkapi BPKB Kendaraan" />
          </Form.Item>
          <Form.Item name={vehicleYear} label="Tahun Pembuatan">
            <InputNumber defaultValue={2022} name={vehicleYear} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name={notes} label="Catatan">
            <Input fast name={notes} placeholder="Lengkapi catatan lain" />
          </Form.Item>

          <Divider plain>Nilai Aset</Divider>
          <Row gutter={[4, 4]}>
            <Col span="9">
              <Form.Item name={estimatedValue} label="Estimasi Harga">
                <InputNumber
                  formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                  name={estimatedValue}
                  defaultValue={0}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span="9">
              <Form.Item name={monthlyServiceCost} label="Biaya Service (perbulan)">
                <InputNumber
                  formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                  name={monthlyServiceCost}
                  defaultValue={50000}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

            <Col span="6">
              <Form.Item name={vehicleAgeInMonth} label="Usia Kendaraan (bulan)">
                <InputNumber defaultValue={0} name={vehicleAgeInMonth} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="dummy" label="Nilai Jaminan">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              value={floor(0.5 * item.bpkb?.estimatedValue - item.bpkb?.monthlyServiceCost * item.bpkb?.vehicleAgeInMonth)}
              name="dummy"
              readOnly
              style={{ width: '100%' }}
            />
          </Form.Item>
        </>
      );
    }
  };

  return (
    <ContentWithErrorHandler isError={false} isLoading={false} withBox={false}>
      <Row gutter={[8, 0]}>
        <Col span={24}>
          <Divider plain>Simpanan Milik Pribadi di Madani</Divider>
          <Table
            size="small"
            dataSource={props.formValue.collateralData?.savings}
            columns={[
              {
                title: 'Nomor',
                dataIndex: 'accountId',
                key: 'accountId',
              },
              {
                title: 'Tipe',
                dataIndex: 'type',
                key: 'type',
              },
              {
                title: 'Jumlah Simpanan',
                dataIndex: 'amount',
                key: 'amount',
                render: (value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              },
            ]}
            footer={(e) => {
              let total = props.formValue.collateralData?.savings.map((e) => e.amount).reduce((a, b) => a + b, 0);
              let totalInString = `Rp ${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return `Total Simpanan : ${totalInString}`;
            }}
          />

          <Divider plain>Simpanan Lainnya</Divider>
          <Form.Item name="collateralData.totalParentsSaving" label="Total Simpanan Orang Tua di Madani">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              name="collateralData.totalParentsSaving"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item name="collateralData.totalRelativesSaving" label="Total Simpanan Saudara di Madani">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              name="collateralData.totalRelativesSaving"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item name="collateralData.totalChildrenSaving" label="Total Simpanan Anak di Madani">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              name="collateralData.totalChildrenSaving"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item name="collateralData.totalSpouseSaving" label="Total Simpanan Suami/Istri di Madani">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              name="collateralData.totalSpouseSaving"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item name="collateralData.totalGroupSaving" label="Total Simpanan Kelompok/Komunitas Tanggung Renteng">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              name="collateralData.totalGroupSaving"
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Divider plain>Aset-aset</Divider>
          <FieldArray name="collateralData.assets">
            {({ push, remove }) => (
              <div>
                {props.formValue.collateralData.assets.map((item, index) => {
                  const type = `collateralData.assets[${index}].type`;

                  return (
                    <div
                      key={item.id}
                      style={{ background: '#F6F6F6', padding: 10, marginBottom: 25, borderColor: 'black', borderStyle: 'solid', borderWidth: 1 }}
                    >
                      <Form.Item name={type} label="Tipe aset">
                        <Select fast name={type} value={item.type} style={{ width: '100%' }}>
                          <Option value="sertifikat_hak_milik">Sertifikat Hak Milik</Option>
                          <Option value="sertifikat_hak_guna">Sertifikat Hak Guna</Option>
                          <Option value="hak_guna_usaha">Hak Guna Usaha</Option>
                          <Option value="bpkb_mobil">BPKP Mobil</Option>
                          <Option value="bpkb_motor">BPKP Motor</Option>
                          <Option value="emas">Emas</Option>
                        </Select>
                      </Form.Item>
                      {renderAssetForm(item.type, index, item)}
                      <Button danger block icon={<MinusCircleOutlined />} onClick={() => remove(index)}>
                        Hapus Aset
                      </Button>
                    </div>
                  );
                })}
                <Button block icon={<PlusSquareFilled />} onClick={() => push({ id: new Date().getTime(), type: 'sertifikat_hak_milik' })}>
                  Tambah Aset
                </Button>
              </div>
            )}
          </FieldArray>
        </Col>
      </Row>
    </ContentWithErrorHandler>
  );
}

export default CollateralDataForm;
