import { ArrowRightOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Input, message, Popconfirm, Row, Select, Table, Tag } from 'antd';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { RangeValue } from 'rc-picker/lib/interface.d';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { basicSort, TableColumnTextFilterConfig } from '../../common/TableHelper';
import { useDeleteTransactionMutation, useGetAllTransactionQuery } from './transactionAPI';
import { Transaction } from './types';
import ReactExport from 'react-export-excel';

const STATUS_FILTER = [
  { value: 'Draf', text: 'Draf' },
  { value: 'Final', text: 'Final' },
  { value: 'Sudah Divalidasi', text: 'Sudah Divalidasi' },
  { value: 'Belum Divalidasi', text: 'Belum Divalidasi' },
];

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function TransactionList() {
  const navigate = useNavigate();

  const [params, setParams] = useState<any>(null);
  const [filterQuery, setFilterQuery] = useState('');
  const [date, setDate] = useState<RangeValue<Moment>>([moment().subtract(1, 'months'), moment()]);

  const { data, error, isFetching } = useGetAllTransactionQuery(params);
  const [deleteTransaction] = useDeleteTransactionMutation();

  const exportData = () => {
    if (data == undefined) return [];

    let result: any[] = [];
    for (let trx of data) {
      let copyArray = [...trx.memoItem];
      let sorted = copyArray.sort((a, b) => {
        let debitA = parseInt(a.debitAmount.replace(/,/g, '')) ? parseInt(a.debitAmount.replace(/,/g, '')) : 0;
        let debitB = parseInt(b.debitAmount.replace(/,/g, '')) ? parseInt(b.debitAmount.replace(/,/g, '')) : 0;

        let creditA = parseInt(a.creditAmount.replace(/,/g, '')) ? parseInt(a.creditAmount.replace(/,/g, '')) : 0;
        let creditB = parseInt(b.creditAmount.replace(/,/g, '')) ? parseInt(b.creditAmount.replace(/,/g, '')) : 0;

        return creditA - creditB || debitA - debitB;
      });
      for (let item of sorted) {
        result.push({
          id: trx.id,
          memoNumber: trx.memoNumber,
          memoDate: trx.memoDate,
          memoDescription: trx.memoDescription,
          recipientName: trx.recipientName,
          officerName: trx.admin.name,
          debitAmount: parseInt(item.debitAmount.replace(/,/g, '')) ? parseInt(item.debitAmount.replace(/,/g, '')) : 0,
          debitAccount: item.debitAccount,
          creditAmount: parseInt(item.creditAmount.replace(/,/g, '')) ? parseInt(item.creditAmount.replace(/,/g, '')) : 0,
          creditAccount: item.creditAccount,
        });
      }
    }
    return result;
  };

  const applyFilter = () => {
    setParams({
      query: filterQuery == '' ? undefined : filterQuery,
      start: date?.[0]?.format('DD-MM-YYYY'),
      end: date?.[1]?.format('DD-MM-YYYY'),
    });
  };

  const handleFilterStatus = (value, record) => {
    let statusList: string[] = [];
    !record.isFinalized ? statusList.push('Draf') : statusList.push('Final');
    record.validation == null ? statusList.push('Belum Divalidasi') : statusList.push('Sudah Divalidasi');
    return statusList.includes(value);
  };

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Card title={'Data Transaksi Memo per ' + new Date().toLocaleString()} size="small" extra={<div></div>}>
        <Row>
          <Col
            span={18}
            style={{
              marginBottom: 15,
            }}
          >
            <span style={{ marginRight: 6 }}>Filter</span>
            <Search placeholder="Cari slip..." onChange={(e) => setFilterQuery(e.target.value)} style={{ width: 200 }} />
            <RangePicker style={{ marginInline: 6 }} value={date} onChange={setDate} />
            <Button type="primary" onClick={applyFilter}>
              Terapkan
            </Button>
          </Col>

          <Col
            span={6}
            style={{
              marginBottom: 15,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <ExcelFile filename={'Data Transaksi per ' + new Date().toDateString()} element={<Button style={{ marginRight: 6 }}>Ekspor Data</Button>}>
              <ExcelSheet data={exportData()} name={'Data Transaksi per ' + new Date().toLocaleString()}>
                <ExcelColumn label="Id" value="id" />
                <ExcelColumn label="Tanggal" value="memoDate" />
                <ExcelColumn label="Nomor Memo" value="memoNumber" />
                <ExcelColumn label="Akun Debit" value="debitAccount" />
                <ExcelColumn label="Nominal Debit" value="debitAmount" />
                <ExcelColumn label="Akun Kredit" value="creditAccount" />
                <ExcelColumn label="Nominal Kredit" value="creditAmount" />
                <ExcelColumn label="Keterangan" value="memoDescription" />
                <ExcelColumn label="Penerima" value="recipientName" />
                <ExcelColumn label="Officer" value="officerName" />
              </ExcelSheet>
            </ExcelFile>
            <Button>
              <Link to="create">+ Tambah Baris</Link>
            </Button>
          </Col>
        </Row>
        <Table
          loading={isFetching}
          dataSource={data}
          bordered
          size="small"
          pagination={{}}
          columns={[
            {
              title: 'Status',
              width: '8%',
              filters: STATUS_FILTER,
              onFilter: (value, record) => handleFilterStatus(value, record),
              render: (text, record) => {
                return (
                  <div>
                    <Tag color={!record.isFinalized ? 'volcano' : 'green'}>
                      {record.isFinalized ? <CheckOutlined /> : <CloseOutlined />} &nbsp;
                      {!record.isFinalized ? 'Draf' : 'Final'}
                    </Tag>
                    <br />
                    <Tag style={{ marginTop: '0.7em' }} color={record.validation == null ? 'volcano' : 'green'}>
                      {record.validation != null ? <CheckOutlined /> : <CloseOutlined />} &nbsp;
                      {'Validasi'}
                    </Tag>
                  </div>
                );
              },
            },
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              width: '4%',
              sorter: (a, b) => basicSort(a.id, b.id),
            },
            {
              title: 'Nomor Memo',
              dataIndex: 'memoNumber',
              key: 'memoNumber',
              width: '15%',
            },
            {
              title: 'Tanggal',
              dataIndex: 'memoDate',
              key: 'memoDate',
              width: '8%',
              sorter: (a, b) => basicSort(moment(a.memoDate, 'YYYY-MM-DD').valueOf(), moment(b.memoDate, 'YYYY-MM-DD').valueOf()),
              render: (text, record) => {
                return <div>{moment(record.memoDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</div>;
              },
            },
            {
              title: 'Item',
              key: 'item',
              width: '35%',
              render: (text, record) => {
                let copyArray = [...record.memoItem];
                let sorted = copyArray.sort((a, b) => {
                  let debitA = parseInt(a.debitAmount.replace(/,/g, '')) ? parseInt(a.debitAmount.replace(/,/g, '')) : 0;
                  let debitB = parseInt(b.debitAmount.replace(/,/g, '')) ? parseInt(b.debitAmount.replace(/,/g, '')) : 0;

                  let creditA = parseInt(a.creditAmount.replace(/,/g, '')) ? parseInt(a.creditAmount.replace(/,/g, '')) : 0;
                  let creditB = parseInt(b.creditAmount.replace(/,/g, '')) ? parseInt(b.creditAmount.replace(/,/g, '')) : 0;

                  return creditA - creditB || debitA - debitB;
                });

                return sorted.map((elem) => {
                  if (parseInt(elem.debitAmount) > 0) {
                    return (
                      <div style={{ textAlign: 'left', color: 'darkgreen' }}>
                        <div>{elem.debitAccount}</div>
                        <div style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Rp {elem.debitAmount}</div>
                      </div>
                    );
                  } else {
                    return (
                      <div style={{ textAlign: 'right', color: 'darkred' }}>
                        <div>{elem.creditAccount}</div>
                        <div style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Rp {elem.creditAmount}</div>
                      </div>
                    );
                  }
                });
              },
            },
            {
              title: 'Keterangan',
              dataIndex: 'memoDescription',
              key: 'memoDescription',
              width: '10%',
            },
            {
              title: 'Penerima',
              dataIndex: 'recipientName',
              key: 'recipientName',
              width: '10%',
              ...TableColumnTextFilterConfig<Transaction>('recipientName'),
            },
            {
              title: 'Operator',
              dataIndex: ['admin', 'name'],
              key: 'admin.name',
              width: '5%',
              sorter: (a, b) => basicSort(a.admin.name, b.admin.name),
              ...TableColumnTextFilterConfig<Transaction>(['admin', 'name']),
            },
            {
              title: 'Aksi',
              key: 'x',
              width: '10%',
              render: (text, record) => (
                <div>
                  <Button type="link" icon={<EditOutlined />} style={{ borderRadius: 5 }} onClick={() => navigate(`detail/${record.id}`)} />
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading('Loading', 0);
                      deleteTransaction(record.id)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success('Berhasil!');
                        })
                        .catch(() => {
                          hideLoading();
                          message.error('Gagal!');
                        });
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
      <Outlet />
    </ContentWithErrorHandler>
  );
}

export default TransactionList;
