import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { RootState } from '../../app/store';
import { useUpdatePasswordMutation, useUpdateProfileMutation, whoAmI } from './authAPI';
import { Admin } from './types';

function Profile() {
  const auth = useSelector((state: RootState) => state.auth);
  const admin = auth.admin as unknown as Admin;

  const { id } = useParams();
  const [fileList, setFileList] = useState<any>([]);

  const [updateProfile] = useUpdateProfileMutation();
  const [updatePassword] = useUpdatePasswordMutation();

  const dispatch = useDispatch();

  const handleUploadChange = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(fileList);
  };

  const save = async (value: any, closeLoading: any) => {
    const formData = new FormData();
    await fileList.map((item) => {
      if (item.originFileObj) {
        formData.append('signatureFile', item.originFileObj);
      }
    });

    formData.append('name', value.name);

    updateProfile(formData)
      .unwrap()
      .then(() => {
        dispatch(whoAmI());
        closeLoading();
        message.success('Berhasil! Silahkan login ulang');
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  const handleUpdatePassword = async (value: any, closeLoading: any) => {
    updatePassword(value)
      .unwrap()
      .then(() => {
        dispatch(whoAmI());
        closeLoading();
        message.success('Berhasil! Silahkan login ulang');
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal! Periksa kembali apakah password lama anda benar');
      });
  };

  return (
    <div>
      <ContentWithErrorHandler isError={auth == null} withBox={true}>
        <Formik
          initialValues={{
            name: admin?.name || '',
          }}
          onSubmit={async (values, actions) => {
            const hide = message.loading('Loading', 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              <Form.Item name="name" label="Nama">
                <Input name="name" placeholder="-" />
              </Form.Item>
              <Form.Item name="photo" label="Tanda Tangan">
                <Upload
                  beforeUpload={(file) => {
                    setFileList((oldState) => [...oldState, file]);
                    return false;
                  }}
                  onChange={handleUploadChange}
                  multiple={false}
                  listType="picture"
                  accept=".png, .jpg, .jpeg"
                  fileList={fileList}
                >
                  <Button>
                    <UploadOutlined /> Unggah
                  </Button>
                </Upload>
              </Form.Item>
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>

      <ContentWithErrorHandler isError={auth == null} withBox={true}>
        <Formik
          initialValues={{
            oldPassword: '',
            newPassword: '',
          }}
          onSubmit={async (values, actions) => {
            const hide = message.loading('Loading', 0);
            if (values.oldPassword.length < 8 || values.newPassword.length < 8) {
              message.info('Password minimal 8 karakter');
            }

            await handleUpdatePassword(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              <Form.Item name="oldPassword" label="Password Lama">
                <Input.Password type="password" name="oldPassword" placeholder="-" />
              </Form.Item>
              <Form.Item name="newPassword" label="Password Baru">
                <Input.Password type="password" name="newPassword" placeholder="-" />
              </Form.Item>
              <SubmitButton>Perbarui Password</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default Profile;
