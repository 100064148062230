import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Form, message, Modal, Popconfirm, Select, Table } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { basicSort, basicSortDateString, TableColumnTextFilterConfig } from '../../common/TableHelper';
import { useGetAllMemberQuery } from '../member/membersAPI';
import { useCreateEventParticipantMutation, useDeleteEventParticipantMutation, useGetEventScheduleQuery } from './eventAPI';
import { EventParticipant, REGISTRATION_METHOD, REGISTRATION_METHOD_TEXT } from './types';

const { Option } = Select;

interface InitialData {
  name: string;
  isActive: boolean;
}

function EventScheduleDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [showAddParticipant, setShowAddParticipant] = useState(false);
  const [memberId, setMemberId] = useState<number>(0);

  const { data, isFetching, isError, refetch } = useGetEventScheduleQuery(id || '', {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });
  const { data: memberListData, isFetching: memberListDataIsLoading, isError: memberListDataIsError } = useGetAllMemberQuery(true);

  const [addParticipantAction] = useCreateEventParticipantMutation();
  const [removeParticipantAction] = useDeleteEventParticipantMutation();

  const addParticipant = async () => {
    const closeLoading = message.loading('Loading', 0);

    if (memberId == null || memberId == undefined) {
      closeLoading();
      message.error('Pilih anggota');
      return;
    }

    addParticipantAction({ member: { id: memberId }, eventSchedule: { id: id } })
      .unwrap()
      .then(() => {
        closeLoading();
        message.success('Berhasil!');
        refetch();
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  const renderAddParticipantModal = () => {
    return (
      <Modal
        title="Detail Item"
        visible={showAddParticipant}
        onCancel={() => setShowAddParticipant(false)}
        onOk={addParticipant}
        okText="Simpan"
        cancelText="Batal"
      >
        <Form.Item name="memberId" label="Pilih anggota">
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Masukkan nomor/nama anggota"
            optionFilterProp="children"
            value={memberId}
            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(value) => setMemberId(value)}
          >
            {memberListData?.map((member, index) => {
              return (
                <Option key={index} value={member.id}>
                  {`${member.memberId} - ${member.name}`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Modal>
    );
  };

  return (
    <div>
      <ContentWithErrorHandler isError={isError || memberListDataIsError} isLoading={isFetching || memberListDataIsLoading} withBox={false}>
        <ContentWithErrorHandler isError={false} isLoading={false} withBox={true}>
          <Descriptions bordered size="small" column={2}>
            <Descriptions.Item label="Tanggal">{moment.unix(data?.date || 0).format('DD MMMM YYYY')}</Descriptions.Item>
            <Descriptions.Item label="Waktu">
              {moment(data?.startTime, 'HH:mm').format('HH:mm')} s/d {moment(data?.endTime, 'HH:mm').format('HH:mm')}
            </Descriptions.Item>
            <Descriptions.Item label="Nama Acara">{data?.event?.name}</Descriptions.Item>
            <Descriptions.Item label="Tempat">{data?.location || '-'}</Descriptions.Item>
            <Descriptions.Item label="Kuota">{data?.quota}</Descriptions.Item>
            <Descriptions.Item label="Pendaftar">{data?.participants?.length ?? '-'}</Descriptions.Item>
            <Descriptions.Item label="Cara Mendaftar">{REGISTRATION_METHOD_TEXT[data?.registrationMethod || '']}</Descriptions.Item>
            <Descriptions.Item label="Link Registrasi">
              {data?.registrationLink ? (
                <a href={data?.registrationLink} target="_blank">
                  {data?.registrationLink}
                </a>
              ) : (
                '-'
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Terbuka untuk umum?">{data?.openToPublic ? 'Ya' : 'Tidak'}</Descriptions.Item>
          </Descriptions>
        </ContentWithErrorHandler>
        {data?.registrationMethod != REGISTRATION_METHOD.INTERNAL || (
          <Card
            title="Data Peserta Acara"
            size="small"
            extra={
              <div>
                <Button key="addMedia" style={{ marginBottom: 10 }} onClick={() => setShowAddParticipant(!showAddParticipant)}>
                  + Tambah Partisipan
                </Button>
              </div>
            }
          >
            {renderAddParticipantModal()}
            <Table
              loading={isFetching}
              dataSource={data?.participants || []}
              bordered
              size="small"
              pagination={{ pageSize: 10 }}
              columns={[
                {
                  title: 'Waktu Registrasi',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  width: '20%',
                  render: (item) => moment(item).format('DD/MM/YYYY HH:mm:ss'),
                  sorter: (a, b) => basicSortDateString(a.createdAt, b.createdAt),
                },
                {
                  title: 'NBA',
                  dataIndex: ['member', 'memberId'],
                  key: 'member.memberId',
                  width: '20%',
                  sorter: (a, b) => basicSort(a?.member?.memberId, b?.member?.memberId),
                },
                {
                  title: 'Nama',
                  dataIndex: ['member', 'name'],
                  key: 'member.name',
                  width: '20%',
                  sorter: (a, b) => basicSort(a?.member?.name, b?.member?.name),
                },
                {
                  title: 'Telp',
                  dataIndex: ['member', 'phone'],
                  key: 'member.phone',
                  width: '20%',
                  render: (data) => {
                    return data.join(', ');
                  },
                },
                {
                  title: 'Aksi',
                  key: 'x',
                  width: '10%',
                  render: (text, record) => (
                    <div>
                      <Popconfirm
                        title="Apakah anda yakin?"
                        onConfirm={() => {
                          const hideLoading = message.loading('Loading', 0);
                          removeParticipantAction(record.id || -1)
                            .unwrap()
                            .then(() => {
                              hideLoading();
                              message.success('Berhasil!');
                              refetch();
                            })
                            .catch(() => {
                              hideLoading();
                              message.error('Gagal!');
                            });
                        }}
                        okText="Hapus"
                        cancelText="Batal"
                      >
                        <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                      </Popconfirm>
                    </div>
                  ),
                },
              ]}
            />
          </Card>
        )}
      </ContentWithErrorHandler>
    </div>
  );
}

export default EventScheduleDetail;
