import { MinusCircleOutlined, PlusSquareFilled } from "@ant-design/icons";
import { Button, message, Modal, Space } from "antd";
import { FieldArray, Formik } from "formik";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import { useNavigate, useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../../app/ContentWithErrorHandler";
import { useGetAllAdminQuery } from '../../admin/adminAPI';
import {
  useCreaseSubmissionAssessmentMutation,
  useGetSubmissionAssessmentConfigQuery,
  useUpdateSubmissionAssessmentMutation,
} from './submissionConfigurationAPI';
import { SubmissionAssessmentConfigTypeList, SubmissionAssessmentConfigTypeText } from './type';

const { Option } = Select;

interface InitialData {
  indicator: string;
  descriptions: string[];
  type: string;
  allowedUser: number[];
}

function SubmissionConfigDetail() {
  const navigate = useNavigate();
  const { id, type } = useParams();

  const { data, isFetching, isError } = useGetSubmissionAssessmentConfigQuery(id || '', {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });
  const { data: adminData, error: adminError, isLoading: adminLoading } = useGetAllAdminQuery();

  const [update] = useUpdateSubmissionAssessmentMutation();
  const [create] = useCreaseSubmissionAssessmentMutation();

  const validate = (value) => {
    let error;
    if (!value || value.length == 0) {
      error = 'Tidak boleh kosong';
    }
    return error;
  };

  const save = async (value: InitialData, closeLoading: any) => {
    let promise;
    if (id) {
      promise = update({ id, ...value });
    } else {
      promise = create(value);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success('Berhasil!');
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  return (
    <Modal
      title={null}
      width={800}
      visible={true}
      destroyOnClose={true}
      onCancel={() => navigate('/loan-submission/config')}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
    >
      <ContentWithErrorHandler isError={isError} isLoading={isFetching} withBox={true}>
        <Formik
          initialValues={{
            type: data?.type || type || '',
            descriptions: data?.descriptions || [],
            indicator: data?.indicator || '',
            allowedUser: data?.allowedUser || [-1],
          }}
          onSubmit={async (values, actions) => {
            const hide = message.loading('Loading', 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
        >
          {({ values, touched, errors, handleChange, handleBlur, isValid }) => (
            <Form layout="vertical">
              <Form.Item name="type" label="Tipe">
                <Select name="type">
                  {SubmissionAssessmentConfigTypeList.map((e) => (
                    <Option value={e}>{SubmissionAssessmentConfigTypeText[e]}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="indicator" label="Indikator" validate={validate}>
                <Input name="indicator" placeholder="Masukkan indikator" />
              </Form.Item>
              {values.type != 'PersonalAssessment' ? (
                <>
                  <Form.Item name="allowedUser" label="Yang dapat mengisi assessment ini" validate={validate}>
                    <Select
                      mode="multiple"
                      placeholder="Yang dapat mengisi assessment ini"
                      style={{ width: '100%' }}
                      loading={adminLoading}
                      name="allowedUser"
                    >
                      <Option value={-1}>Semua</Option>
                      {adminData?.map((element) => {
                        return <Option value={element.id}>{element.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="descriptions" label="Deskripsi">
                    <FieldArray name="descriptions">
                      {({ push, remove }) => (
                        <div>
                          {values.descriptions.map((item: string, index) => {
                            const description = `descriptions[${index}]`;
                            return (
                              <Space key={index} style={{ display: 'flex', width: '100%', marginBottom: 10 }} align="baseline">
                                <Input.TextArea style={{ width: '500px' }} name={description} placeholder="Masukkan indikator" />
                                <MinusCircleOutlined onClick={() => remove(index)} />
                              </Space>
                            );
                          })}
                          <Button block icon={<PlusSquareFilled />} onClick={() => push('')}>
                            Tambah Deskripsi
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </Form.Item>
                </>
              ) : (
                <></>
              )}

              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        </Formik>
      </ContentWithErrorHandler>
    </Modal>
  );
}

export default SubmissionConfigDetail;
