import { MinusCircleOutlined, PlusSquareFilled, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Space, Upload } from "antd";
import { FieldArray } from "formik";
import { AutoComplete, Form, Input, InputNumber, Select } from 'formik-antd';
import { useNavigate, useParams } from 'react-router-dom';
import ContentWithErrorHandler from '../../../app/ContentWithErrorHandler';
import { Member } from '../../member/types';
import { HomeOwnershipStatusList, HomeOwnershipStatusText, SimpleBiodata, SubmissionForm } from './types';

const DATE_FORMAT = 'DD/MM/YYYY';
const { Dragger } = Upload;

const { Option } = Select;
type Props = {
  memberListData: Member[] | undefined;
  selectedMember: Member | undefined;
  onMemberIdChange: any;
  formValues: SubmissionForm;
  setFieldValue: any;
  signature: {
    applicantSignature: any;
    setApplicantSignature: any;
    handleUploadApplicantSignature: any;
    relativeSignature: any;
    setRelativeSignature: any;
    handleUploadRelativeSignature: any;
    referenceSignature: any;
    setReferenceSignature: any;
    handleUploadReferenceSignature: any;
  };
};

function BiodataForm(props: Props) {
  const navigate = useNavigate();
  const { id } = useParams();

  const pasteImage = (event: React.ClipboardEvent, type: string) => {
    event.preventDefault();
    const file = event.clipboardData.files?.[0];

    if (file) {
      const reader = new FileReader();
      let thumbUrl;
      reader.addEventListener(
        'load',
        function () {
          thumbUrl = reader.result;
        },
        false,
      );
      reader.readAsDataURL(file);

      let uid = new Date().getMilliseconds();
      const fileList = [
        {
          lastModified: file.lastModified,
          name: 'copy-paste-file',
          size: file.size,
          type: file.type,
          uid: uid,
          originFileObj: file,
          percent: 100,
          key: uid,
          status: 'done',
          thumbUrl: thumbUrl,
        },
      ];
      const data = { fileList: fileList };

      if (type == 'relatives') {
        props.signature.handleUploadRelativeSignature(data);
      } else if (type == 'reference') {
        props.signature.handleUploadReferenceSignature(data);
      } else if (type == 'applicant') {
        props.signature.handleUploadApplicantSignature(data);
      }
    }
  };

  return (
    <ContentWithErrorHandler isError={false} isLoading={false} withBox={false}>
      <Row gutter={[8, 0]}>
        <Col span={24}>
          <Form.Item name="biodata.memberId" label="Nomor Buku Anggota">
            <Select
              fast
              showSearch
              name="biodata.memberId"
              style={{ width: '100%' }}
              placeholder="Masukkan nomor anggota"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => {
                const member = props.memberListData?.find((e) => e.memberId == value);
                props.onMemberIdChange(member?.id);
              }}
            >
              {props.memberListData?.map((member, index) => {
                return (
                  <Option key={index} value={member.memberId}>
                    {member.memberId}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="biodata.memberName" label="Nama Anggota">
            <Select
              fast
              showSearch
              name="biodata.memberName"
              style={{ width: '100%' }}
              placeholder="Masukkan nama anggota"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => {
                const member = props.memberListData?.find((e) => e.memberId == value);
                props.onMemberIdChange(member?.id);
              }}
            >
              {props.memberListData?.map((member, index) => {
                return (
                  <Option key={`name.${index}`} value={member.memberId}>
                    {member.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="biodata.nickname" label="Nama Panggilan">
            <Input fast name="biodata.nickname" placeholder="Lengkapi nama panggilan anggota" />
          </Form.Item>
          <Form.Item name="biodata.phones" label="Nomor Telepon/Handphone">
            <Select fast mode="tags" name="biodata.phones" style={{ width: '100%' }} placeholder="Masukkan nomor telepon">
              {props.selectedMember?.phone.map((e) => (
                <Option value={e}>{e}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="biodata.address" label="Alamat Saat Ini">
            <Input.TextArea name="biodata.address" placeholder="Lengkapi alamat anggota" />
          </Form.Item>
          <Form.Item name="biodata.homeOwnershipStatus" label="Status Rumah">
            <Select fast name="biodata.homeOwnershipStatus" style={{ width: '100%' }} placeholder="Lengkapi status kepemilikan rumah">
              {HomeOwnershipStatusList.map((e) => (
                <Option value={e}>{HomeOwnershipStatusText[e]}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="biodata.dependents" label="Jumlah tanggungan">
            <InputNumber name="biodata.dependents" style={{ width: '100%' }} placeholder="Lengkapi jumlah tanggungan" />
          </Form.Item>
          <Form.Item name="dummy" label="Tanda Tangan Anggota">
            <Input name="dummy" onPaste={(e) => pasteImage(e, 'applicant')} placeholder="Paste disini"></Input>
            <Dragger
              beforeUpload={(file) => {
                props.signature.setApplicantSignature((oldState) => [...oldState, file]);
                return false;
              }}
              onChange={props.signature.handleUploadApplicantSignature}
              multiple={false}
              listType="picture"
              accept=".png, .jpg, .jpeg"
              fileList={props.signature.applicantSignature}
            >
              <Button>
                <UploadOutlined /> Pilih / Drag & Drop File
              </Button>
            </Dragger>
          </Form.Item>

          <Divider plain>Data Keluarga</Divider>
          <Form.Item name="biodata.family.type" label="Status">
            <Select fast allowClear name="biodata.family.type" style={{ width: '100%' }} placeholder="Masukkan status">
              <Option value="husband">Suami</Option>
              <Option value="wife">Istri</Option>
              <Option value="father">Ayah</Option>
              <Option value="mother">Ibu</Option>
            </Select>
          </Form.Item>
          <Form.Item name="biodata.family.name" label="Nama">
            <AutoComplete
              fast
              showSearch
              name="biodata.family.name"
              style={{ width: '100%' }}
              placeholder="Lengkapi nama"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => {
                const member = props.memberListData?.find((e) => e.name == value);
                props.setFieldValue('biodata.family.memberId', member?.memberId);
              }}
            >
              {props.memberListData?.map((member, index) => {
                return (
                  <Option key={index} value={member.name}>
                    {member.name}
                  </Option>
                );
              })}
            </AutoComplete>
          </Form.Item>
          <Form.Item name="biodata.family.memberId" label="Member Id">
            <AutoComplete
              fast
              showSearch
              name="biodata.family.memberId"
              style={{ width: '100%' }}
              placeholder="Lengkapi nomor anggota (jika ada)"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => {
                const member = props.memberListData?.find((e) => e.memberId == value);
                props.setFieldValue('biodata.family.name', member?.name);
              }}
            >
              {props.memberListData?.map((member, index) => {
                return (
                  <Option key={index} value={member.memberId}>
                    {member.memberId}
                  </Option>
                );
              })}
            </AutoComplete>
          </Form.Item>
          <Form.Item name="dummy" label="Tanda Tangan">
            <Input name="dummy" onPaste={(e) => pasteImage(e, 'relatives')} placeholder="Paste disini"></Input>
            <Dragger
              beforeUpload={(file) => {
                props.signature.setRelativeSignature((oldState) => [...oldState, file]);
                return false;
              }}
              onChange={props.signature.handleUploadRelativeSignature}
              multiple={false}
              listType="picture"
              accept=".png, .jpg, .jpeg"
              fileList={props.signature.relativeSignature}
            >
              <Button>
                <UploadOutlined /> Pilih / Drag & Drop File
              </Button>
            </Dragger>
          </Form.Item>

          <Divider plain>Data Referensi</Divider>
          <Form.Item name="biodata.reference.type" label="Status">
            <Select fast allowClear name="biodata.reference.type" style={{ width: '100%' }} placeholder="Masukkan status">
              <Option value="group-leader">Ketua Kelompok</Option>
            </Select>
          </Form.Item>
          <Form.Item name="biodata.reference.name" label="Nama">
            <AutoComplete
              fast
              showSearch
              name="biodata.reference.name"
              style={{ width: '100%' }}
              placeholder="Lengkapi nama"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => {
                const member = props.memberListData?.find((e) => e.name == value);
                props.setFieldValue('biodata.reference.memberId', member?.memberId);
              }}
            >
              {props.memberListData?.map((member, index) => {
                return (
                  <Option key={index} value={member.name}>
                    {member.name}
                  </Option>
                );
              })}
            </AutoComplete>
          </Form.Item>
          <Form.Item name="biodata.reference.memberId" label="Member Id">
            <AutoComplete
              fast
              showSearch
              name="biodata.reference.memberId"
              style={{ width: '100%' }}
              placeholder="Lengkapi nomor anggota"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => {
                const member = props.memberListData?.find((e) => e.memberId == value);
                props.setFieldValue('biodata.reference.name', member?.name);
              }}
            >
              {props.memberListData?.map((member, index) => {
                return (
                  <Option key={index} value={member.memberId}>
                    {member.memberId}
                  </Option>
                );
              })}
            </AutoComplete>
          </Form.Item>
          <Form.Item name="dummy" label="Tanda Tangan">
            <Input name="dummy" onPaste={(e) => pasteImage(e, 'reference')} placeholder="Paste disini"></Input>
            <Dragger
              beforeUpload={(file) => {
                props.signature.setReferenceSignature((oldState) => [...oldState, file]);
                return false;
              }}
              onChange={props.signature.handleUploadReferenceSignature}
              multiple={false}
              listType="picture"
              accept=".png, .jpg, .jpeg"
              fileList={props.signature.referenceSignature}
            >
              <Button>
                <UploadOutlined /> Pilih / Drag & Drop File
              </Button>
            </Dragger>
          </Form.Item>

          <Divider plain>Data Saudara</Divider>
          <FieldArray name="biodata.relatives">
            {({ push, remove }) => (
              <Col span={24}>
                {props.formValues.biodata.relatives.map((item: SimpleBiodata, index) => {
                  const name = `biodata.relatives[${index}].name`;
                  const memberId = `biodata.relatives[${index}].memberId`;
                  const phone = `biodata.relatives[${index}].phone`;
                  return (
                    <Space key={item.id} style={{ display: 'flex' }} align="baseline">
                      <Form.Item name={name} label={`Nama ${index + 1}`}>
                        <AutoComplete
                          fast
                          showSearch
                          name={name}
                          style={{ width: '250px' }}
                          placeholder="Lengkapi nama"
                          optionFilterProp="children"
                          filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          onChange={(value) => {
                            const member = props.memberListData?.find((e) => e.name == value);
                            if (member) {
                              props.setFieldValue(memberId, member?.memberId);
                              props.setFieldValue(phone, member?.phone.at(0));
                            }
                          }}
                        >
                          {props.memberListData?.map((member, index) => {
                            return (
                              <Option key={index} value={member.name}>
                                {member.name}
                              </Option>
                            );
                          })}
                        </AutoComplete>
                      </Form.Item>
                      <Form.Item name={memberId} label={`NBA ${index + 1}`}>
                        <AutoComplete
                          fast
                          showSearch
                          name={memberId}
                          style={{ width: '200px' }}
                          placeholder="Lengkapi NBA"
                          optionFilterProp="children"
                          filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          onChange={(value) => {
                            const member = props.memberListData?.find((e) => e.memberId == value);
                            if (member) {
                              props.setFieldValue(name, member?.name);
                              props.setFieldValue(phone, member?.phone.at(0));
                            }
                          }}
                        >
                          {props.memberListData?.map((member, index) => {
                            return (
                              <Option key={index} value={member.memberId}>
                                {member.memberId}
                              </Option>
                            );
                          })}
                        </AutoComplete>
                      </Form.Item>
                      <Form.Item name={phone} label={`Telp ${index + 1}`}>
                        <Input fast name={phone} style={{ width: '200px' }} placeholder={`Lengkapi Telp saudara ${index + 1}`} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(index)} />
                    </Space>
                  );
                })}
                <Button block icon={<PlusSquareFilled />} onClick={() => push({ id: new Date().getTime(), name: '', phone: '' })}>
                  Tambah Data Saudara
                </Button>
              </Col>
            )}
          </FieldArray>

          <Divider plain>Data Organisasi / Kelompok / Tanggung Renteng</Divider>
          <FieldArray name="biodata.groups">
            {({ push, remove }) => (
              <Col span={24}>
                {props.formValues.biodata.groups.map((item: SimpleBiodata, index) => {
                  const name = `biodata.groups[${index}].name`;
                  const memberId = `biodata.groups[${index}].memberId`;
                  const phone = `biodata.groups[${index}].phone`;
                  return (
                    <Space key={item.id} style={{ display: 'flex' }} align="baseline">
                      <Form.Item name={name} label={`Nama ${index + 1}`}>
                        <AutoComplete
                          fast
                          showSearch
                          name={name}
                          style={{ width: '250px' }}
                          placeholder="Lengkapi nama"
                          optionFilterProp="children"
                          filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          onChange={(value) => {
                            const member = props.memberListData?.find((e) => e.name == value);
                            if (member) {
                              props.setFieldValue(memberId, member?.memberId);
                              props.setFieldValue(phone, member?.phone.at(0));
                            }
                          }}
                        >
                          {props.memberListData?.map((member, index) => {
                            return (
                              <Option key={index} value={member.name}>
                                {member.name}
                              </Option>
                            );
                          })}
                        </AutoComplete>
                      </Form.Item>
                      <Form.Item name={memberId} label={`NBA ${index + 1}`}>
                        <AutoComplete
                          fast
                          showSearch
                          name={memberId}
                          style={{ width: '200px' }}
                          placeholder="Lengkapi NBA"
                          optionFilterProp="children"
                          filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          onChange={(value) => {
                            const member = props.memberListData?.find((e) => e.memberId == value);
                            if (member) {
                              props.setFieldValue(name, member?.name);
                              props.setFieldValue(phone, member?.phone.at(0));
                            }
                          }}
                        >
                          {props.memberListData?.map((member, index) => {
                            return (
                              <Option key={index} value={member.memberId}>
                                {member.memberId}
                              </Option>
                            );
                          })}
                        </AutoComplete>
                      </Form.Item>
                      <Form.Item name={phone} label={`Telp ${index + 1}`}>
                        <Input fast name={phone} style={{ width: '200px' }} placeholder={`Lengkapi Telp saudara ${index + 1}`} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(index)} />
                    </Space>
                  );
                })}
                <Button block icon={<PlusSquareFilled />} onClick={() => push({ id: new Date().getTime(), name: '', phone: '' })}>
                  Tambah Data Komunitas
                </Button>
              </Col>
            )}
          </FieldArray>
        </Col>
      </Row>
    </ContentWithErrorHandler>
  );
}

export default BiodataForm;
