import { message, Tabs } from "antd";
import { Formik } from "formik";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import quillConfig from "../../common/quillConfig";
import { useCreateMemberMutation, useGetMemberQuery, useUpdateMemberMutation } from "./membersAPI";

const { Option } = Select;
const { TabPane } = Tabs;

function MemberDetail() {
  const { id } = useParams();

  const [notes, setNotes] = useState("");
  const { data: memberData, isLoading: memberDataIsLoading, isError: memberDataIsError } = useGetMemberQuery(id || "", {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const [updateMember] = useUpdateMemberMutation();
  const [createMember] = useCreateMemberMutation();

  useEffect(() => {
    setNotes(memberData?.notes || "");
  }, [memberData]);

  const validate = (value) => {
    let error;
    if (!value) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  const save = async (value: any, closeLoading: any) => {
    closeLoading();
    message.error("Not implemented!");
    // let data = { ...value };
    // data.notes = notes;

    // let promise;
    // if (id) {
    //   promise = updateMember(data);
    // } else {
    //   promise = createMember(data);
    // }

    // promise
    //   .unwrap()
    //   .then(() => {
    //     closeLoading();
    //     message.success("Berhasil!");
    //   })
    //   .catch(() => {
    //     closeLoading();
    //     message.error("Gagal!");
    //   });
  };

  return (
    <div>
      <ContentWithErrorHandler isError={memberDataIsError} isLoading={memberDataIsLoading} withBox={true}>
        <Formik
          initialValues={{
            memberId: memberData?.memberId || "",
            name: memberData?.name || "",
            email: memberData?.email || "",
            dob: memberData?.dob || "",
            phone: memberData?.phone || "",
            isActive: memberData?.isActive || true,
            madanianAccountEnabled: memberData?.madanianAccountEnabled || false,
            group: memberData?.group || "",
            servicePlace: memberData?.servicePlace || "",
            gender: memberData?.gender || "",
            referenceNotes: memberData?.referenceNotes || "",
            notes: memberData?.notes || "",
            religion: memberData?.religion || "",
            job: memberData?.jobTitle || "",
            lastEducation: memberData?.lastEducation || "",
          }}
          onSubmit={async (values, actions) => {
            const hide = message.loading("Loading", 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              <Tabs defaultActiveKey="1" size="small">
                <TabPane tab="Informasi Umum" key="1">
                  <Form.Item name="memberId" label="Nomor Anggota" validate={validate}>
                    <Input name="memberId" placeholder="-" readOnly />
                  </Form.Item>
                  <Form.Item name="name" label="Nama Anggota" validate={validate}>
                    <Input name="name" placeholder="-" readOnly />
                  </Form.Item>
                  <Form.Item name="email" label="Email">
                    <Input name="email" placeholder="Masukkan Email" />
                  </Form.Item>
                  <Form.Item name="notes" label="Catatan Khusus">
                    <ReactQuill
                      modules={quillConfig}
                      style={{
                        background: "white",
                      }}
                      onChange={(content) => setNotes(content)}
                      value={notes}
                    />
                  </Form.Item>
                </TabPane>
                <TabPane tab="Tab 2" key="2">
                  <Form.Item name="isHeadline" label="Berita Utama">
                    <Select name="isHeadline">
                      <Option value={true}>Ya</Option>
                      <Option value={false}>Tidak</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="isPublished" label="Status">
                    <Select name="isPublished">
                      <Option value={true}>Terbit</Option>
                      <Option value={false}>Draf</Option>
                    </Select>
                  </Form.Item>
                </TabPane>
                <TabPane tab="Tab 3" key="3">
                  <Form.Item name="content" label="Isi Berita">
                    <ReactQuill
                      modules={quillConfig}
                      style={{
                        background: "white",
                      }}
                      onChange={(content) => setNotes(content)}
                      value={notes}
                    />
                  </Form.Item>
                </TabPane>
              </Tabs>

              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default MemberDetail;
