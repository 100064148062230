import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, message, Popconfirm, Table } from "antd";
import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { basicSort } from "../../common/TableHelper";
import { useDeleteTransactionAccountMutation, useGetAllTransactionAccountQuery } from "./transactionAPI";

const ACCOUNT_TYPE = ["Aktiva Lancar", "Penyertaan", "Aktiva Tetap", "Hutang", "Modal", "Pendapatan", "Biaya", "Pajak"];
const ACCOUNT_TYPE_FILTER = ACCOUNT_TYPE.map((e) => {
  return { value: e, text: e };
});

function TransactionAccountList() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetAllTransactionAccountQuery();
  const [deleteTransactionAccountPosition] = useDeleteTransactionAccountMutation();

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Card
        title="Daftar Akun Transaksi"
        size="small"
        extra={
          <div>
            <Button style={{ borderRadius: 5 }}>
              <Link to="create">+ Tambah Baris</Link>
            </Button>
          </div>
        }
      >
        <Table
          loading={isLoading}
          dataSource={data}
          bordered
          size="small"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              title: "Tipe",
              dataIndex: "accountType",
              key: "accountType",
              filters: ACCOUNT_TYPE_FILTER,
              onFilter: (value: any, record) => record.accountType == value,
            },
            {
              title: "Nomor Akun",
              dataIndex: "accountNumber",
              key: "accountNumber",
              sorter: (a, b) => basicSort(a.accountNumber, b.accountNumber),
            },
            {
              title: "Nama Akun",
              dataIndex: "accountName",
              key: "accountName",
              sorter: (a, b) => basicSort(a.accountName, b.accountName),
            },
            {
              title: "Aksi",
              key: "x",
              width: "10%",
              render: (text, record) => (
                <div>
                  <Button type="link" icon={<EditOutlined />} style={{ borderRadius: 5 }} onClick={() => navigate(`detail/${record.id}`)} />
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading("Loading", 0);
                      deleteTransactionAccountPosition(record.id)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success("Berhasil!");
                        })
                        .catch(() => {
                          hideLoading();
                          message.error("Gagal!");
                        });
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
      <Outlet />
    </ContentWithErrorHandler>
  );
}

export default TransactionAccountList;
