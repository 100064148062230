import { MinusCircleOutlined, PlusSquareFilled } from '@ant-design/icons';
import { Button, Col, Divider, List, Row, Space, Statistic, Switch, Table } from 'antd';
import { FieldArray } from 'formik';
import { Checkbox, DatePicker, Form, Input, InputNumber, Select } from 'formik-antd';
import { type } from 'os';
import { useSelector } from 'react-redux';
import ContentWithErrorHandler from '../../../app/ContentWithErrorHandler';
import { RootState } from '../../../app/store';
import { Admin } from '../../admin/types';
import { SubmissionAssessmentConfigTypeColor, SubmissionAssessmentConfigTypeText } from '../submissionConfiguration/type';
import { FiveCAssessmentItem, FiveCType, IncomeType, PersonalAssessmentItem, SimpleIncomeData, SubmissionForm } from './types';
import { useEffect, useState } from 'react';

const DATE_FORMAT = 'DD/MM/YYYY';

const { Option } = Select;

type Props = {
  formValue: SubmissionForm;
};

function AssessmentForm(props: Props) {
  const auth = useSelector((state: RootState) => state.auth);
  const admin = auth.admin as unknown as Admin;

  const [isBusiness, setIsBusiness] = useState<boolean>(false);
  const [isRegular, setIsRegular] = useState<boolean>(false);

  useEffect(() => {
    let totalBusiness = parseInt(
      getTotal(props.formValue.assessment.fiveCBusiness, [
        FiveCType.Character,
        FiveCType.Capacity,
        FiveCType.Capital,
        FiveCType.Collateral,
        FiveCType.Condition,
      ]),
    );
    if (totalBusiness != 0) {
      setIsBusiness(true);
    }

    let totalWork = parseInt(
      getTotal(props.formValue.assessment.fiveCWork, [
        FiveCType.Character,
        FiveCType.Capacity,
        FiveCType.Capital,
        FiveCType.Collateral,
        FiveCType.Condition,
      ]),
    );
    if (totalWork != 0) {
      setIsRegular(true);
    }
  }, []);

  const getTotal = (items: FiveCAssessmentItem[], type: FiveCType[]) => {
    let total = 0;
    for (let item of items) {
      if (type.includes(item.type)) {
        total += item.score;
      }
    }
    return total.toFixed(2);
  };

  const getValue = (items: PersonalAssessmentItem[], type: string) => {
    let totalTrue = 0;
    let totalFalse = 0;
    for (let item of items) {
      if (item.eligible) totalTrue++;
      if (!item.eligible) totalFalse++;
    }

    if (type == 'true') return totalTrue.toFixed(2);
    if (type == 'false') return totalFalse.toFixed(2);
    if (type == 'percentage') return ((totalTrue / (totalFalse + totalTrue)) * 100).toFixed(2);
  };

  const canEditAssessment = (allowedUser: number[]) => {
    if (allowedUser == undefined || allowedUser == null || allowedUser.includes(-1)) return true;
    else return allowedUser.includes(admin.id);
  };

  return (
    <ContentWithErrorHandler isError={false} isLoading={false} withBox={false}>
      <Row gutter={[8, 0]}>
        <Col span={24}>
          <Divider plain>Asesmen Personal</Divider>
          <div style={{ width: '100%', display: 'flex', background: '#F6F6F6', padding: 10, marginBottom: 10 }}>
            <Statistic style={{ flexGrow: 1 }} title="Total Pertanyaan" value={props.formValue.assessment.personal.length} />
            <Statistic style={{ flexGrow: 1 }} title="Total Ya" value={getValue(props.formValue.assessment.personal, 'true')} />
            <Statistic style={{ flexGrow: 1 }} title="Total Tidak" value={getValue(props.formValue.assessment.personal, 'false')} />
            <Statistic style={{ flexGrow: 1 }} title="Nilai" value={getValue(props.formValue.assessment.personal, 'percentage')} />
          </div>
          <FieldArray name="assessment.personal">
            {() => (
              <div>
                {props.formValue.assessment.personal.map((item: PersonalAssessmentItem, index) => {
                  const eligible = `assessment.personal[${index}].eligible`;
                  return (
                    <Space key={item.id} style={{ display: 'flex', marginBottom: 10 }}>
                      <Checkbox name={eligible}>{item.indicator}</Checkbox>
                    </Space>
                  );
                })}
              </div>
            )}
          </FieldArray>

          <div>
            Analisis 5C Tidak memiliki usaha&nbsp;
            <Switch checked={isRegular} onChange={setIsRegular} />
          </div>
          <div>
            Analisis 5C memiliki usaha&nbsp;
            <Switch checked={isBusiness} onChange={setIsBusiness} />
          </div>

          {isRegular ? (
            <div>
              <Divider plain>Asesmen 5C - Tidak Memiliki Usaha</Divider>
              <div style={{ width: '100%', display: 'flex', background: '#F6F6F6', padding: 10, marginBottom: 10 }}>
                <Statistic style={{ flexGrow: 1 }} title="Character" value={getTotal(props.formValue.assessment.fiveCWork, [FiveCType.Character])} />
                <Statistic style={{ flexGrow: 1 }} title="Capacity" value={getTotal(props.formValue.assessment.fiveCWork, [FiveCType.Capacity])} />
                <Statistic style={{ flexGrow: 1 }} title="Capital" value={getTotal(props.formValue.assessment.fiveCWork, [FiveCType.Capital])} />
                <Statistic
                  style={{ flexGrow: 1 }}
                  title="Collateral"
                  value={getTotal(props.formValue.assessment.fiveCWork, [FiveCType.Collateral])}
                />
                <Statistic style={{ flexGrow: 1 }} title="Condition" value={getTotal(props.formValue.assessment.fiveCWork, [FiveCType.Condition])} />
                <Statistic
                  style={{ flexGrow: 1 }}
                  title="Total"
                  value={getTotal(props.formValue.assessment.fiveCWork, [
                    FiveCType.Character,
                    FiveCType.Capacity,
                    FiveCType.Capital,
                    FiveCType.Collateral,
                    FiveCType.Condition,
                  ])}
                />
              </div>

              <Table
                pagination={false}
                dataSource={props.formValue.assessment.fiveCWork}
                columns={[
                  {
                    title: 'Tipe',
                    dataIndex: 'type',
                    key: 'type',
                    width: '15%',

                    render: (value, row, index) => {
                      let prev = index == 0 ? null : props.formValue.assessment.fiveCWork.at(index - 1)?.type;
                      const obj: any = {
                        children: SubmissionAssessmentConfigTypeText[value],
                        props: {
                          style: { background: SubmissionAssessmentConfigTypeColor[value] },
                        },
                      };
                      if (!(prev !== value)) {
                        obj.props.rowSpan = 0;
                        return obj;
                      }
                      const count = props.formValue.assessment.fiveCWork.filter((item) => item.type === value);

                      obj.props.rowSpan = count.length;
                      return obj;
                    },
                  },
                  {
                    title: 'Indikator',
                    dataIndex: 'indicator',
                    key: 'indicator',
                    width: '30%',
                  },
                  {
                    title: 'Deskripsi',
                    dataIndex: 'description',
                    key: 'description',
                    width: '40%',
                    render: (value, record, index) => {
                      return (
                        <List>
                          {record.description.map((e) => (
                            <List.Item style={{ margin: 0, padding: 0 }}>{e}</List.Item>
                          ))}
                        </List>
                      );
                    },
                  },
                  {
                    title: 'Nilai',
                    dataIndex: 'value',
                    key: 'value',
                    width: '15%',
                    render: (value, record, index) => {
                      const score = `assessment.fiveCWork[${index}].score`;
                      return <InputNumber disabled={!canEditAssessment(record.allowedUser)} style={{ width: '100%' }} name={score} fast />;
                    },
                  },
                ]}
              ></Table>
            </div>
          ) : (
            <></>
          )}

          {isBusiness ? (
            <div>
              <Divider plain>Asesmen 5C - Memiliki Usaha</Divider>
              <div style={{ width: '100%', display: 'flex', background: '#F6F6F6', padding: 10, marginBottom: 10 }}>
                <Statistic
                  style={{ flexGrow: 1 }}
                  title="Character"
                  value={getTotal(props.formValue.assessment.fiveCBusiness, [FiveCType.Character_Business])}
                />
                <Statistic
                  style={{ flexGrow: 1 }}
                  title="Capacity"
                  value={getTotal(props.formValue.assessment.fiveCBusiness, [FiveCType.Capacity_Business])}
                />
                <Statistic
                  style={{ flexGrow: 1 }}
                  title="Capital"
                  value={getTotal(props.formValue.assessment.fiveCBusiness, [FiveCType.Capital_Business])}
                />
                <Statistic
                  style={{ flexGrow: 1 }}
                  title="Collateral"
                  value={getTotal(props.formValue.assessment.fiveCBusiness, [FiveCType.Collateral_Business])}
                />
                <Statistic
                  style={{ flexGrow: 1 }}
                  title="Condition"
                  value={getTotal(props.formValue.assessment.fiveCBusiness, [FiveCType.Condition_Business])}
                />
                <Statistic
                  style={{ flexGrow: 1 }}
                  title="Total"
                  value={getTotal(props.formValue.assessment.fiveCBusiness, [
                    FiveCType.Character_Business,
                    FiveCType.Capacity_Business,
                    FiveCType.Capital_Business,
                    FiveCType.Collateral_Business,
                    FiveCType.Condition_Business,
                  ])}
                />
              </div>
              <Table
                pagination={false}
                dataSource={props.formValue.assessment.fiveCBusiness}
                columns={[
                  {
                    title: 'Tipe',
                    dataIndex: 'type',
                    key: 'type',
                    width: '15%',

                    render: (value, row, index) => {
                      let prev = index == 0 ? null : props.formValue.assessment.fiveCBusiness.at(index - 1)?.type;
                      const obj: any = {
                        children: SubmissionAssessmentConfigTypeText[value],
                        props: {
                          style: { background: SubmissionAssessmentConfigTypeColor[value] },
                        },
                      };
                      if (!(prev !== value)) {
                        obj.props.rowSpan = 0;
                        return obj;
                      }
                      const count = props.formValue.assessment.fiveCBusiness.filter((item) => item.type === value);

                      obj.props.rowSpan = count.length;
                      return obj;
                    },
                  },
                  {
                    title: 'Indikator',
                    dataIndex: 'indicator',
                    key: 'indicator',
                    width: '30%',
                  },
                  {
                    title: 'Deskripsi',
                    dataIndex: 'description',
                    key: 'description',
                    width: '40%',
                    render: (value, record, index) => {
                      return (
                        <List>
                          {record.description.map((e) => (
                            <List.Item style={{ margin: 0, padding: 0 }}>{e}</List.Item>
                          ))}
                        </List>
                      );
                    },
                  },
                  {
                    title: 'Nilai',
                    dataIndex: 'value',
                    key: 'value',
                    width: '15%',
                    render: (value, record, index) => {
                      const score = `assessment.fiveCBusiness[${index}].score`;
                      return <InputNumber disabled={!canEditAssessment(record.allowedUser)} style={{ width: '100%' }} name={score} fast />;
                    },
                  },
                ]}
              ></Table>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </ContentWithErrorHandler>
  );
}

export default AssessmentForm;
