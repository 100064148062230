import { message, Modal } from "antd";
import { Formik } from "formik";
import { Form, Input, InputNumber, Select } from 'formik-antd';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import quillConfig from '../../../common/quillConfig';
import { useApproveLoanSubmissionStatusMutation } from './loanSubmissionAPI';
import { FinalDecisionList, LoanSubmissionStatus } from './types';

const { Option } = Select;

interface Props {
  id: string | undefined;
  status: LoanSubmissionStatus | undefined;
  isModalVisible: boolean;
  setModalVisible: any;
  dueDate: number | undefined;
}

interface InitialData {
  dueDate: number;
  finalDecision: string;
  approvedAmount: number;
  provisionFee: number;
  adminFee: number;
  installment: number;
  interest: number;
  interestType: string;
  interestIsPerYear: boolean;
  accountId: string;
}

function SubmissionAprroveForm(props: Props) {
  const [updateNote, setUpdateNote] = useState('');

  const [update] = useApproveLoanSubmissionStatusMutation();

  const save = async (value: InitialData, closeLoading: any) => {
    if (props.id == null || props.id == undefined) {
      closeLoading();
      message.error('Tidak ada ID');
      return;
    }
    if (updateNote.length == 0) {
      closeLoading();
      message.error('Catatan update tidak boleh kosong');
      return;
    }

    let dataToUpdate: any = {
      ...value,
      id: props.id,
      note: updateNote,
    };

    update(dataToUpdate)
      .unwrap()
      .then(() => {
        closeLoading();
        message.success('Berhasil!');
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  return (
    <Formik
      initialValues={{
        finalDecision: '',
        dueDate: props.dueDate || 0,
        approvedAmount: 0,
        provisionFee: 0,
        adminFee: 0,
        installment: 0,
        interest: 0,
        interestType: 'declining',
        interestIsPerYear: true,
        accountId: '',
      }}
      onSubmit={async (values, actions) => {
        const hide = message.loading('Loading', 0);
        await save(values, hide);
        actions.setSubmitting(false);
      }}
      enableReinitialize={true}
    >
      {({ values, touched, errors, handleChange, handleBlur, isValid, submitForm }) => (
        <Modal title="Ubah status" visible={props.isModalVisible} onOk={submitForm} onCancel={() => props.setModalVisible(false)}>
          <Form layout="vertical">
            <Form.Item name="finalDecision" label="Keputusan akhir">
              <Select name="finalDecision" style={{ width: '100%' }} placeholder="Lengkapi keputusan akhir">
                {FinalDecisionList.map((e) => (
                  <Option value={e}>{FinalDecisionList[e]}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="accountId" label="Nomor akun pinjaman">
              <Input name="accountId" />
            </Form.Item>
            <Form.Item name="approvedAmount" label="Nominal pinjaman yang disetujui">
              <InputNumber
                name="approvedAmount"
                formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value: any) => value.replace(/Rp\s?|(,*)/g, '')}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item name="provisionFee" label="Biaya provisi">
              <InputNumber
                name="provisionFee"
                formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value: any) => value.replace(/Rp\s?|(,*)/g, '')}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item name="adminFee" label="Biaya admin">
              <InputNumber
                name="adminFee"
                formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value: any) => value.replace(/Rp\s?|(,*)/g, '')}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item name="installment" label="Lama Cicilan">
              <InputNumber name="installment" style={{ width: '100%' }} placeholder="Lengkapi lama masa cicilan" />
            </Form.Item>
            <Form.Item name="interest" label="Bunga pinjaman">
              <InputNumber name="interest" style={{ width: '100%' }} placeholder="Lengkapi bunga pinjaman" />
            </Form.Item>
            <Form.Item name="dueDate" label="Tanggal Jatuh Tempo">
              <InputNumber name="dueDate" style={{ width: '100%' }} placeholder="Lengkapi tanggal jatuh tempo" />
            </Form.Item>
            <Form.Item name="interestType" label="Tipe Pembayaran Angsuran Dan Bunga Pinjaman">
              <Select name="interestType" style={{ width: '100%' }} placeholder="Pilih tipe pembayaran">
                <Option value="declining">Menurun</Option>
                <Option value="effective">Efektif</Option>
                <Option value="flat">Flat</Option>
              </Select>
            </Form.Item>
            <Form.Item name="updateNote" label="Catatan untuk ditampilkan ke anggota (harus diisi)">
              <ReactQuill
                modules={quillConfig}
                style={{
                  background: 'white',
                  height: '180px',
                  marginBottom: '20px',
                }}
                onChange={(note) => setUpdateNote(note)}
                value={updateNote}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default SubmissionAprroveForm;
