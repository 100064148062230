import { Card, Divider, Select, Tabs } from "antd";
import React, { useState } from "react";
import TransactionReportCategoryItem from "./TransactionReportCategoryItem";
import { TransactionRecordPerAccountType, TransactionRecordPerCategory } from "./types";

const { TabPane } = Tabs;
const { Option } = Select;

type Props = {
  accountTypeMap: Map<String, TransactionRecordPerAccountType> | null;
  perOperator: Map<String, TransactionRecordPerCategory> | null;
  perAccount: Map<String, TransactionRecordPerCategory> | null;
};

function TransactionReportCategory(props: Props) {
  const [type, setType] = useState("operator");

  const renderTabPaneTitle = (str) => {
    var chunked = str.match(/.{1,20}(\s|$)/g);
    chunked.map((e) => e.replace(/((\s*\S+)*)\s*/, "$1"));
    return (
      <div style={{ textAlign: "left" }}>
        {chunked.map((e) => (
          <div>{e}</div>
        ))}
      </div>
    );
  };

  const renderInnerTab = (element) => {
    if (type == "account") {
      return (
        <Tabs tabPosition="left" destroyInactiveTabPane={true}>
          {element.accountList.map((account) => {
            return (
              <TabPane tab={renderTabPaneTitle(account)} key={account}>
                <TransactionReportCategoryItem data={props.perAccount?.[account] || null} isPerCategory={true} />
              </TabPane>
            );
          })}
        </Tabs>
      );
    } else {
      return (
        <div style={{ paddingLeft: 20 }}>
          <TransactionReportCategoryItem data={element ? element : null} isPerCategory={false} />
        </div>
      );
    }
  };

  const getRelatedData = () => {
    if (type == "operator") {
      return Object.entries(props?.perOperator || {});
    } else {
      return Object.entries(props?.accountTypeMap || {});
    }
  };

  return (
    <div>
      <Tabs
        destroyInactiveTabPane={true}
        tabBarExtraContent={{
          left: (
            <span style={{ marginRight: 10, fontSize: 16 }}>
              <Select value={type} style={{ width: 140 }} onChange={setType}>
                <Option value={"operator"}>Per Operator</Option>
                <Option value={"account"}>Per Akun</Option>
              </Select>
              <Divider type="vertical" style={{ height: 20, borderWidth: 2, borderColor: "gray" }} />
            </span>
          ),
        }}
      >
        {getRelatedData().map((entry) => {
          return (
            <TabPane tab={entry[0]} key={entry[0] as string}>
              <Card bodyStyle={{ paddingLeft: 0, paddingTop: 20, paddingBottom: 10 }} bordered={false}>
                {renderInnerTab(entry[1])}
              </Card>
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
}

export default TransactionReportCategory;
