import { DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Card, List, message, Popconfirm, Table, Tag } from 'antd';
import moment from 'moment';
import { Outlet, useNavigate } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { basicSort, TableColumnTextFilterConfig } from '../../common/TableHelper';
import { useDeleteQuestionaireResultMutation, useGetAllQuestionaireResultQuery } from './financialLiteracyAPI';
import { QuestionaireResult } from './types';

function FinancialLiteracyQuestionResultList() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetAllQuestionaireResultQuery();
  const [deleteQuestionaire] = useDeleteQuestionaireResultMutation();

  const handleDownloadDocument = async (id: number, name: string) => {
    const hide = message.loading('Loading', 0);

    let path = 'fl/questionaire-result/download/' + id;
    const url = process.env.REACT_APP_API_URL + path;

    let downloadBlob = await fetch(url, { credentials: 'include' });
    downloadBlob
      .blob()
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${name} - ${id}.pdf`;
        a.click();
        hide();
      })
      .catch((e) => {
        hide();
        message.error('Gagal!');
      });
  };

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Card title="Data Jawaban Kuisioner" size="small">
        <Table
          loading={isLoading}
          dataSource={data}
          bordered
          size="small"
          pagination={{ pageSize: 15 }}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              sorter: (a, b) => basicSort(a.id, b.id),
              defaultSortOrder: 'descend',
              width: '5%',
            },
            {
              title: 'Status',
              dataIndex: 'isComplete',
              key: 'isComplete',
              width: '5%',
              sorter: (a, b) => basicSort(a.isComplete, b.isComplete),
              render: (item: any) => {
                return <Tag color={item ? 'green' : 'blue'}>{item ? 'Selesai' : 'Belum'}</Tag>;
              },
            },
            {
              title: 'Tanggal',
              dataIndex: 'updatedAt',
              key: 'updatedAt',
              width: '10%',
              sorter: (a, b) => basicSort(a.updatedAt, b.updatedAt),
              render: (item: any) => moment(item).format('DD-MM-YYYY HH:mm'),
            },
            {
              title: 'Nama Anggota',
              dataIndex: ['member', 'name'],
              key: 'member.name',
              width: '20%',
              sorter: (a, b) => basicSort(a.member.name, a.member.name),
              ...TableColumnTextFilterConfig<QuestionaireResult>(['member', 'name']),
            },
            {
              title: 'Nomor Anggota',
              dataIndex: ['member', 'memberId'],
              key: 'member.memberId',
              sorter: (a, b) => basicSort(a.member.memberId, a.member.memberId),
              ...TableColumnTextFilterConfig<QuestionaireResult>(['member', 'memberId']),
              width: '20%',
            },
            {
              title: 'Aksi',
              key: 'x',
              width: '10%',
              render: (text, record) => (
                <div>
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    style={{ borderRadius: 5 }}
                    onClick={() => handleDownloadDocument(record.id, record.member.name)}
                  />
                  <Button type="link" icon={<EyeOutlined />} style={{ borderRadius: 5 }} onClick={() => navigate(`detail/${record.id}`)} />
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading('Loading', 0);
                      deleteQuestionaire(record.id)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success('Berhasil!');
                        })
                        .catch(() => {
                          hideLoading();
                          message.error('Gagal!');
                        });
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
      <Outlet />
    </ContentWithErrorHandler>
  );
}

export default FinancialLiteracyQuestionResultList;
