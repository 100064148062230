import React from "react";
import { Layout, Col, Row } from "antd";
import LoanSummary from "./LoanSummary";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import LoanTimeSeries from "./LoanTimeSeries";
import LoanPaymentForecast from "./LoanPaymentForecast";

function LoanReport(props) {
  return (
    <ContentWithErrorHandler isError={false} isLoading={false} withBox={false}>
      <Row gutter={[12, 24]}>
        <Col span={12}>
          <LoanSummary />
        </Col>
        <Col span={12}>
          <LoanPaymentForecast />
        </Col>
        <Col span={12}>
          <LoanTimeSeries type="new-loan-type-account" />
        </Col>
        <Col span={12}>
          <LoanTimeSeries type="new-loan-type-amount" />
        </Col>
        <Col span={12}>
          <LoanTimeSeries type="npl-account" />
        </Col>
        <Col span={12}>
          <LoanTimeSeries type="npl-amount" />
        </Col>
        <Col span={12}>
          <LoanTimeSeries type="npl-pl-account" />
        </Col>
        <Col span={12}>
          <LoanTimeSeries type="npl-pl-amount" />
        </Col>
      </Row>
    </ContentWithErrorHandler>
  );
}

export default LoanReport;
