import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { FirebaseAuthProvider, IfFirebaseAuthed } from "@react-firebase/auth";
import { Button, Col, Divider, Form, Input, Layout, message, Modal, Popconfirm, Row, Select, Space, Spin, Table, Tabs, Tag } from "antd";
import { ColumnType } from "antd/lib/table";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v1 as uuidv1 } from "uuid";
import { RootState } from "../../app/store";
import { config, queueListRef, todayCountRef, user } from "../../common/firebase";
import { Admin } from "../auth/types";

const antIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

const { Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;

const queueType = [
  { type: "teller", name: "Tarik/Setor", code: "T" },
  { type: "loan", name: "Pengajuan Pinjaman", code: "P" },
  { type: "administration", name: "Administrasi Anggota", code: "A" },
  { type: "other", name: "Lainnya", code: "L" },
];
function QueueAdmin(props) {
  const authStore = useSelector((state: RootState) => state.auth);
  const admin = (authStore.admin as unknown) as Admin;

  const [counterList, setCounterList] = useState<any[]>([]);
  const [myCounter, setMyCounter] = useState<any>(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [counterModelVisible, setCounterModelVisible] = useState(false);
  const [tabActiveKey, setTabActiveKey] = useState("teller");

  const [activeData, setActiveData] = useState([]);

  const [counterId, setCounterId] = useState("");
  const [counterName, setCounterName] = useState("");
  const [counterType, setCounterType] = useState("");
  const [counterIsOpen, setCounterIsOpen] = useState(false);

  const [lastCount, setLastCount] = useState(-1);
  const [currentCount, setCurrentCount] = useState(-1);

  const markInProcess = (data) => {
    var load = message.loading("Loading...", 0);
    queueListRef
      .child(data.type)
      .child(data.id)
      .transaction(
        (currentData) => {
          return { ...currentData, handledBy: admin.name, handleTimestamp: moment().unix() };
        },
        function (error, committed, snapshot) {
          if (error || !committed) {
            setTimeout(load, 0);
            message.error("Terjadi kesalahan");
            console.log("Something went wrong: " + error);
          }

          todayCountRef
            .child(data.type)
            .child("counters")
            .child(myCounter.id)
            .transaction((currentData) => {
              return { ...currentData, lastQueue: data.queueCode };
            });

          setTimeout(load, 0);
          message.success("Berhasil");
        }
      );
  };

  const markFinish = (data) => {
    var load = message.loading("Loading...", 0);
    queueListRef
      .child(data.type)
      .child(data.id)
      .transaction(
        (currentData) => {
          return { ...currentData, finishTimestamp: moment().unix() };
        },
        function (error, committed, snapshot) {
          if (error || !committed) {
            setTimeout(load, 0);
            message.error("Terjadi kesalahan");
            console.log("Something went wrong: " + error);
          }
          setTimeout(load, 0);
          message.success("Berhasil");
        }
      );
  };

  const columns: ColumnType<any>[] = [
    {
      title: "Nomor",
      align: "center",
      render: (text, record) => {
        var data = queueType.filter((element) => element.type == record.type)[0];
        record.queueCode = data.code + String(record.number).padStart(3, "0");
        return record.queueCode;
      },
    },
    {
      title: "Waktu Antri",
      align: "center",
      render: (text, record) => {
        return record.queueTimestamp.format("HH:mm:ss DD-MM-YYYY");
      },
    },
    {
      title: "Waktu Diproses",
      align: "center",
      render: (text, record) => {
        return record.handleTimestamp != null ? record.handleTimestamp.format("HH:mm:ss DD-MM-YYYY") : "-";
      },
    },
    {
      title: "Waktu Selesai",
      align: "center",
      render: (text, record) => {
        return record.finishTimestamp != null ? record.finishTimestamp.format("HH:mm:ss DD-MM-YYYY") : "-";
      },
    },
    {
      title: "Status",
      align: "center",
      render: (text, record) => {
        if (record.handleTimestamp != null && record.finishTimestamp != null) {
          return (
            <Tag color="green">
              <CheckCircleOutlined /> &nbsp; Selesai
            </Tag>
          );
        } else if (record.handleTimestamp != null) {
          return (
            <Tag color="blue">
              <Spin indicator={antIcon} /> &nbsp; Diproses
            </Tag>
          );
        } else {
          return <Tag color="orange">Menunggu</Tag>;
        }
      },
    },
    {
      title: "Aksi",
      align: "center",
      render: (text, record) => {
        if (record.handledBy != null && record.finishTimestamp != null) {
          return record.handledBy;
        } else if (record.handledBy != null) {
          return (
            <div>
              <Button
                size="small"
                type="dashed"
                disabled={!myCounter || myCounter.type != tabActiveKey || record.handledBy != admin.name}
                onClick={() => markFinish(record)}
              >
                Selesai
              </Button>
              <br></br>
              {record.handledBy}
            </div>
          );
        } else {
          return (
            <Button
              size="small"
              type="primary"
              disabled={!myCounter || myCounter.type != tabActiveKey}
              onClick={() => markInProcess(record)}
            >
              Proses
            </Button>
          );
        }
      },
    },
  ];

  // Sign In
  useEffect(() => {
    if (!isSignedIn)
      firebase
        .auth()
        .signInWithEmailAndPassword(user.username, user.password)
        .then(() => {
          setIsSignedIn(true);
        })
        .catch((error) => {
          message.error("Autentikasi gagal");
        });
  }, [isSignedIn]);

  // Counter List
  useEffect(() => {
    todayCountRef.on(
      "value",
      (snapshot) => {
        if (snapshot.val() != null) {
          var current: any = [];
          for (var key in snapshot.val()) {
            var queuePerType = snapshot.val()[key];
            if (queuePerType.hasOwnProperty("counters")) {
              for (var elementKey in queuePerType.counters) {
                if (queuePerType.counters[elementKey]) {
                  current.push({
                    id: elementKey,
                    type: key,
                    name: queuePerType.counters[elementKey].name,
                    operator: queuePerType.counters[elementKey].operator,
                    lastQueue: queuePerType.counters[elementKey].lastQueue,
                    isOpen: queuePerType.counters[elementKey].isOpen,
                  });
                }
              }
            }
          }
          setCounterList(current);
        }
      },
      (errorObject) => {
        console.log("The read failed: " + errorObject);
      }
    );
  }, [isSignedIn, isSignedIn]);

  useEffect(() => {
    var myCounterList = counterList.filter((element) => element.operator == admin.name && element.isOpen == true);
    if (myCounterList != undefined && myCounterList != null) {
      setMyCounter(myCounterList[0]);
    } else {
      setMyCounter(null);
    }
  }, [counterList, isSignedIn]);

  // Get Queue List
  useEffect(() => {
    setActiveData([]);
    queueListRef
      .child(tabActiveKey)
      .limitToLast(100)
      .on(
        "value",
        async (snapshot) => {
          if (snapshot.val() != null) {
            var current: any = [];
            for (var key in snapshot.val()) {
              var queueItem = snapshot.val()[key];
              current.push({
                id: key,
                type: tabActiveKey,
                number: `${queueItem.queueNumber}`,
                queueTimestamp: moment.unix(queueItem.queueTimestamp),
                handleTimestamp: queueItem.handleTimestamp == null ? null : moment.unix(queueItem.handleTimestamp),
                finishTimestamp: queueItem.finishTimestamp == null ? null : moment.unix(queueItem.finishTimestamp),
                handledBy: queueItem.handledBy,
              });
            }
            await current.sort((a, b) => b.queueTimestamp - a.queueTimestamp);
            setActiveData(current);
          }
        },
        (errorObject) => {
          console.log("The read failed: " + errorObject.name);
        }
      );
  }, [tabActiveKey, isSignedIn]);

  useEffect(() => {
    if (myCounter) {
      todayCountRef
        .child(myCounter.type)
        .child("total")
        .on(
          "value",
          async (snapshot) => {
            if (snapshot.val() != null) {
              setCurrentCount(snapshot.val());
            }
          },
          (errorObject) => {
            console.log("The read failed: " + errorObject.name);
          }
        );
    }
  }, [myCounter, isSignedIn]);

  useEffect(() => {
    if (currentCount == -1 || lastCount == -1) {
      setLastCount(currentCount);
    } else if (currentCount != lastCount) {
      handleNotificationOnShow();
      setLastCount(currentCount);
    }
  }, [currentCount]);

  const updateCounter = () => {
    if (myCounter != null && counterIsOpen == true) {
      message.error("Hapus/non-aktifkan counter anda sebelum membuka baru");
      return;
    }

    var load = message.loading("Loading...", 0);
    todayCountRef
      .child(counterType)
      .child("counters")
      .child(counterId)
      .transaction(
        (currentData) => {
          return { ...currentData, name: counterName, operator: admin.name, isOpen: counterIsOpen };
        },
        function (error, committed, snapshot) {
          if (error || !committed) {
            setTimeout(load, 0);
            message.error("Terjadi kesalahan");
            console.log("Something went wrong: " + error);
            return;
          }
          setTimeout(load, 0);
          message.success("Sukses mengubah/menambah");
          setCounterModelVisible(false);
        }
      );
  };

  const onCounterModalOpen = (data) => {
    if (data) {
      setCounterId(data.id);
      setCounterName(data.name);
      setCounterType(data.type);
      setCounterIsOpen(data.isOpen);
    } else {
      setCounterId(uuidv1());
      setCounterName("");
      setCounterType("");
      setCounterIsOpen(false);
    }
    setCounterModelVisible(true);
  };

  const resetQueue = () => {
    if (counterList.length > 0) {
      var load = message.loading("Loading...", 0);
      for (var i = 0; i < counterList.length; i++) {
        var isError = false;
        todayCountRef
          .child(counterList[i].type)
          .child("counters")
          .child(counterList[i].id)
          .transaction(
            (currentData) => {
              return { ...currentData, lastQueue: null, isOpen: false };
            },
            function (error, committed, snapshot) {
              if (error || !committed) {
                isError = true;
                setTimeout(load, 0);
                message.error("Terjadi kesalahan");
                console.log("Something went wrong: " + error);
              }
            }
          );

        todayCountRef.child(counterList[i].type).transaction(
          (currentData) => {
            return { ...currentData, total: null };
          },
          function (error, committed, snapshot) {
            if (error || !committed) {
              isError = true;
              setTimeout(load, 0);
              message.error("Terjadi kesalahan");
              console.log("Something went wrong: " + error);
            }
          }
        );

        if (isError) break;
      }
      setTimeout(load, 0);
      message.success("Berhasil");
    }
  };

  const removeCounter = () => {
    var load = message.loading("Loading...", 0);
    todayCountRef
      .child(counterType)
      .child("counters")
      .child(counterId)
      .transaction(
        (currentData) => {
          return null;
        },
        function (error, committed, snapshot) {
          if (error || !committed) {
            setTimeout(load, 0);
            message.error("Terjadi kesalahan");
            console.log("Something went wrong: " + error);
            return;
          }
          setTimeout(load, 0);
          message.success("Sukses menghapus counter");
          setCounterModelVisible(false);
        }
      );
  };

  const handleNotificationOnShow = () => {
    let audio = new Audio("https://assets.mixkit.co/sfx/preview/mixkit-musical-reveal-961.mp3");
    audio.play();
    if ("Notification" in window) {
      if (window.Notification.permission === "granted") {
        new window.Notification("Antrian Baru!", {
          body: "Ada antrian masuk",
          icon: "https://img.techpowerup.org/200522/logo-madani.jpg",
        });
      } else {
        window.Notification.requestPermission();
      }
    }
  };

  return (
    <div>
      <Spin spinning={!isSignedIn} style={{ textAlign: "center", width: "100%", height: "100%" }} size="large" />
      <FirebaseAuthProvider {...config} firebase={firebase}>
        <IfFirebaseAuthed>
          {() => (
            <Row gutter={[12, 24]} align="middle">
              {counterList.map((elem) => {
                return (
                  <Col span={5} style={{ display: "inline-flex", alignSelf: "stretch" }}>
                    <Button
                      size="large"
                      block
                      style={{ height: "100%", fontSize: "1.5em", whiteSpace: "normal" }}
                      onClick={() => onCounterModalOpen(elem)}
                    >
                      <div style={{ fontSize: "0.8em" }}>{elem.name}</div>
                      <div style={{ fontSize: "0.6em" }}>{elem.operator}</div>
                      <Divider style={{ marginTop: 0, marginBottom: 6 }} />
                      <div style={{ fontSize: "1.2em" }}>{elem.isOpen ? (elem.lastQueue ? elem.lastQueue : "-") : "Tutup"}</div>
                    </Button>
                  </Col>
                );
              })}
              <Col span={4} style={{ display: "inline-flex", alignSelf: "stretch" }}>
                <Space direction="vertical">
                  <Button
                    size="large"
                    block
                    style={{ height: "100%", fontSize: "1.2em", whiteSpace: "normal" }}
                    onClick={() => onCounterModalOpen(null)}
                  >
                    <div style={{ fontSize: "1em" }}>Tambah Konter</div>
                  </Button>

                  <Popconfirm
                    title="Are you sure to delete this task?"
                    onConfirm={(e) => {
                      e?.preventDefault();
                      resetQueue();
                    }}
                    okText="Ya"
                    cancelText="Batal"
                  >
                    <Button size="large" block style={{ height: "100%", fontSize: "1.2em", whiteSpace: "normal" }}>
                      <div style={{ fontSize: "1em" }}>Reset Antrian</div>
                    </Button>
                  </Popconfirm>
                </Space>
              </Col>
            </Row>
          )}
        </IfFirebaseAuthed>
        <Tabs defaultActiveKey="teller" centered onChange={setTabActiveKey}>
          <TabPane tab="Antrian Teller" key="teller">
            <Table columns={columns} dataSource={activeData} size="small" pagination={{ pageSize: 10 }} />
          </TabPane>
          <TabPane tab="Antrian Pinjaman" key="loan">
            <Table columns={columns} dataSource={activeData} size="small" pagination={{ pageSize: 10 }} />
          </TabPane>
          <TabPane tab="Antrian Administrasi" key="administration">
            <Table columns={columns} dataSource={activeData} size="small" pagination={{ pageSize: 10 }} />
          </TabPane>
          <TabPane tab="Antrian Lain-lain" key="other">
            <Table columns={columns} dataSource={activeData} size="small" pagination={{ pageSize: 10 }} />
          </TabPane>
        </Tabs>
        <Modal
          title="Detail Konter"
          visible={counterModelVisible}
          onOk={updateCounter}
          onCancel={() => setCounterModelVisible(false)}
          footer={[
            <Button key="remove" danger onClick={removeCounter}>
              Hapus
            </Button>,
            <Button key="back" onClick={() => setCounterModelVisible(false)}>
              Kembali
            </Button>,
            <Button key="submit" type="primary" onClick={updateCounter}>
              Simpan
            </Button>,
          ]}
        >
          <Form.Item label="Tipe">
            <Select value={counterType} onChange={setCounterType}>
              {queueType.map((elem) => {
                return <Option value={elem.type}>{elem.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Nama Konter">
            <Input value={counterName} onChange={(event) => setCounterName(event.target.value)} />
          </Form.Item>
          <Form.Item label="Buka?">
            <Select value={counterIsOpen} onChange={setCounterIsOpen}>
              <Option value={true}>Buka</Option>
              <Option value={false}>Tutup</Option>
            </Select>
          </Form.Item>
        </Modal>
      </FirebaseAuthProvider>
    </div>
  );
}

export default QueueAdmin;
