import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const applicationApi = createApi({
  tagTypes: [
    'News',
    'Tags',
    'Documents',
    'GeneralInfos',
    'OrganizationStructures',
    'Gallery',
    'GeneralConfig',
    'Event',
    'EventSchedule',
    'EventParticipant',
    'OfficeLocation',
    'ContactUs',
    'Admin',
    'AdminPosition',
    'AdminPositionCode',
    'Loan',
    'Members',
    'MemoTransaction',
    'MemoTransactionAccount',
    'Questionaire',
    'QuestionaireResult',
    'QuestionaireResultConfig',
    'SubmissionConfig-Personal',
    'SubmissionConfig-FiveC',
    'LoanSubmission',
    'LoanSubmissionHistory',
    'ApprovalSignatureConfig',
    'KPI',
    'KPIExecution',
    'QuizQuestions',
    'QuizRecords',
    'QuizToday',
    'QuizMontlyReport',
  ],
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL, credentials: 'include' }),
  endpoints: () => ({}),
});
