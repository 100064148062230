import { HomeOutlined, LeftCircleOutlined, LogoutOutlined, RightCircleOutlined, SmileOutlined } from "@ant-design/icons";
import { Breadcrumb, Col, Layout, Menu, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { authLogout } from "../features/auth/authAPI";
import { Admin } from "../features/auth/types";
import { breadcrumbObject } from "./BreadcrumbObject";
import { navbarMenu } from "./NavbarObject";
import { RootState } from "./store";

const { Header, Content } = Layout;
const { SubMenu } = Menu;

const rightStyle: React.CSSProperties = { position: "absolute", top: 0, right: 0 };
const leftStyle: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  borderBottom: "none",
};
const centerStyle: React.CSSProperties = {
  position: "relative",
  display: "flex",
  justifyContent: "center",
};

type DefaultNavBarState = {
  opened: string;
  selected: string;
};

function BaseLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);
  const auth = useSelector((state: RootState) => state.auth);
  const admin = (auth.admin as unknown) as Admin;

  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((item, index, arr) => item && !arr[index - 1].includes("detail"));

  const getMenuGroupFromSnippet = (): DefaultNavBarState => {
    const pathSnippets = location.pathname
      .split("/")
      .filter((item, index, arr) => item && item != "detail" && item != "create" && !arr[index - 1].includes("detail"));
    if (pathSnippets.length == 0) {
      return { opened: "/", selected: "/" };
    }

    for (let topGroup of navbarMenu) {
      for (let menuGroup of topGroup.menu) {
        if (menuGroup.subMenu) {
          for (let subMenu of menuGroup.subMenu) {
            if (subMenu.path.includes("/" + pathSnippets.join("/"))) {
              return { opened: menuGroup.title, selected: subMenu.path };
            }
          }
        } else if (menuGroup.path?.includes("/" + pathSnippets.join("/"))) {
          return { opened: menuGroup.title, selected: menuGroup.path };
        }
      }
    }
    return { opened: "/", selected: "/" };
  };

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    if (breadcrumbObject.get(url)?.skipRender) return <></>;

    return (
      <Breadcrumb.Item key={url}>
        {index == pathSnippets.length - 1 || breadcrumbObject.get(url)?.isNotPage ? (
          <>{breadcrumbObject.get(url)?.text}</>
        ) : (
          <Link to={url}>{breadcrumbObject.get(url)?.text}</Link>
        )}
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="Beranda">
      <Link to="/">Beranda</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <Layout>
      <Header style={{ background: "#327CB0", padding: 0, position: "fixed", zIndex: 99, width: "100%" }}>
        <div className="logo" />
        <Menu mode="horizontal" style={centerStyle}>
          <Menu.Item key="collapse" style={leftStyle}>
            <div onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? (
                <RightCircleOutlined style={{ fontSize: 24, color: "black", alignSelf: "center" }} />
              ) : (
                <LeftCircleOutlined style={{ fontSize: 24, alignSelf: "center" }} />
              )}
            </div>
          </Menu.Item>
          <Menu.Item key="centerTitle" style={{ borderBottom: "none" }}>
            <div
              style={{
                fontSize: "2em",
                fontWeight: "bolder",
                color: "#ffffff",
                alignSelf: "center",
              }}
            >
              <span style={{ color: "#FDC300" }}>
                <span style={{ color: "#2F2E2C" }}>#MILIK</span>BERSAMA
              </span>
            </div>
          </Menu.Item>
          <SubMenu
            key="profileSubMenu"
            style={rightStyle}
            title={
              <>
                <SmileOutlined /> &nbsp;
                {admin.name}
              </>
            }
          >
            <Menu.Item key="myprofile">
              <Link to="/myprofile">
                <SmileOutlined /> &nbsp; Profil Saya
              </Link>
            </Menu.Item>
            <Menu.Item
              key="logout"
              onClick={() => {
                dispatch(authLogout());
                navigate("/login");
              }}
            >
              <LogoutOutlined /> &nbsp; Keluar
            </Menu.Item>
          </SubMenu>
        </Menu>
        <Row>
          <Col span={6}>
            <div
              style={{
                width: collapsed ? 80 : 256,
                overflow: "auto",
                height: "92vh",
                position: "fixed",
                left: 0,
              }}
            >
              <Menu
                defaultOpenKeys={[getMenuGroupFromSnippet().opened]}
                defaultSelectedKeys={[getMenuGroupFromSnippet().selected]}
                mode="inline"
                theme="light"
                inlineCollapsed={collapsed}
              >
                <Menu.Item key="home" icon={<HomeOutlined />}>
                  <Link to="/">Beranda</Link>
                </Menu.Item>
                {navbarMenu.map((element, index) => {
                  return (
                    <>
                      <p
                        key={index}
                        style={{
                          color: "#666666",
                          fontSize: 16,
                          marginBottom: 0,
                          textAlign: "center",
                          display: collapsed ? "none" : "block",
                        }}
                      >
                        {element.groupName}
                      </p>
                      {element.menu.map((element) => {
                        if (element.subMenu) {
                          return (
                            <SubMenu key={element.title} icon={element.icon} title={element.title}>
                              {element.subMenu.map((subMenu) => {
                                return (
                                  <Menu.Item key={subMenu.path}>
                                    <Link to={subMenu.path}>{subMenu.title}</Link>
                                  </Menu.Item>
                                );
                              })}
                            </SubMenu>
                          );
                        } else {
                          return (
                            <Menu.Item key={element.path} icon={element.icon}>
                              <Link to={element.path || ""}>{element.title}</Link>
                            </Menu.Item>
                          );
                        }
                      })}
                    </>
                  );
                })}
                <Menu.Item key="madaniImg" style={{ height: 100 }} disabled>
                  <img src="https://img.techpowerup.org/200522/logo-madani.jpg" style={{ width: collapsed ? 16 : 50 }} />
                </Menu.Item>
              </Menu>
            </div>
          </Col>
        </Row>
      </Header>
      <Content>
        <div
          style={{
            height: "100%",
            marginTop: 64,
            marginLeft: collapsed ? 80 : 256,
            padding: 15,
            paddingBottom: 30,
            background: "#f0f2f5",
          }}
        >
          <Breadcrumb style={{ marginBottom: 10 }}>{breadcrumbItems}</Breadcrumb>
          <Outlet />
        </div>
      </Content>
    </Layout>
  );
}

export default BaseLayout;
