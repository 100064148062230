import { MemberSaving } from '../../member/types';

export const AssetText = {
  sertifikat_hak_milik: 'Sertifikat Hak Milik',
  sertifikat_hak_guna: 'Sertifikat Hak Guna',
  hak_guna_usaha: 'Sertifikat Hak Guna Usaha',
  bpkb_mobil: 'BPKB Mobil',
  bpkb_motor: 'BPKB Motor',
  emas: 'Emas',
};

export enum FinalDecision {
  no_need_guarantee = 'Disetujui, tanpa barang jaminan',
  equal_to_saving = 'Permohonan pinjaman disetujui sebesar tabungan',
  need_guarantee_and_saving = 'Disetujui, tetapi memerlukan barang jaminan dari peiminjam, memiliki jumlah tabungan yang memadai',
  need_guarantee = 'Disetujui, dengan barang jaminan',
}

export enum LoanSubmissionStatus {
  submitted = 'submitted',
  processed = 'processed',
  waiting_for_interview = 'waiting_for_interview',
  rejected = 'rejected',
  approved = 'approved',
  approved_with_changes = 'approved_with_changes',
  cancelled = 'cancelled',
  waiting_disbursement = 'waiting_disbursement',
  disbursed = 'disbursed',
  completed = 'completed',
}

export const LoanSubmissionStatusColor = {
  submitted: 'gray',
  processed: 'blue',
  waiting_for_interview: 'purple',
  rejected: 'red',
  approved: 'green',
  approved_with_changes: 'green',
  cancelled: '#141414',
  waiting_disbursement: 'green',
  disbursed: 'green',
  completed: 'black',
};

export const LoanSubmissionStatusText = {
  submitted: 'Diajukan',
  processed: 'Diproses',
  waiting_for_interview: 'Menunggu Wawancara',
  rejected: 'Pengajuan Ditolak',
  approved: 'Pengajuan Disetujui',
  approved_with_changes: 'Disetujui Dengan Perubahan',
  cancelled: 'Pengajuan Dibatalkan',
  waiting_disbursement: 'Penjadwalan Pencairan',
  disbursed: 'Dicairkan',
  completed: 'Selesai',
};

export const LoanSubmissionStatusDefaultNotes = {
  processed: '<p>Pengajuan pinjaman anda sedang dalam proses analisis internal.</p>',
  waiting_for_interview: 'Silakan datang ke koperasi sesuai dengan jadwal wawancara, untuk pertanyaan lebih lanjut harap hubungi m-Care.</p>',
  rejected: '<p>Mohon maaf, dengan berat hati pengajuan pinjaman anda kami tolak karena tidak memenuhi persyaratan.</p>',
  approved: '<p>Pengajuan pinjaman disetujui sesuai dengan nominal yang diajukan.</p>',
  approved_with_changes: '<p>Pengajuan pinjaman disetujui dengan beberapa perubahan.</p>',
  cancelled: '<p>Nomor pengajuan pinjaman telah dibatalkan</p>',
  waiting_disbursement: 'Silakan datang ke koperasi sesuai dengan jadwal pencairan pinjaman, untuk pertanyaan lebih lanjut harap hubungi m-Care.</p>',
  disbursed: '<p>Pengajuan pinjaman telah dicairkan</p>',
  completed: '<p>Proses pengajuan pinjaman telah selesai</p>',
};

export const InterestTypeText = {
  declining: 'menurun',
  flat: 'flat',
  effective: 'efektif',
};

export enum IncomeType {
  cash_in = 'cash_in',
  cash_out = 'cash_out',
}

export enum HomeOwnershipStatus {
  self_owned = 'self_owned',
  family_owned = 'family_owned',
  rent = 'rent',
  state_or_company_owned = 'state_or_company_owned',
}

export enum FiveCType {
  Character = 'Character',
  Capacity = 'Capacity',
  Capital = 'Capital',
  Collateral = 'Collateral',
  Condition = 'Condition',
  Character_Business = 'Character_Business',
  Capacity_Business = 'Capacity_Business',
  Capital_Business = 'Capital_Business',
  Collateral_Business = 'Collateral_Business',
  Condition_Business = 'Condition_Business',
}

export const HomeOwnershipStatusText = {
  self_owned: 'Milik sendiri',
  family_owned: 'Milik keluarga/orang tua',
  rent: 'Sewa/kontrak',
  state_or_company_owned: 'Milik negara/perusahaan',
};

export const IncomeTypeList = Object.values(IncomeType).filter((v) => isNaN(Number(v)));
export const HomeOwnershipStatusList = Object.values(HomeOwnershipStatus).filter((v) => isNaN(Number(v)));
export const LoanSubmissionStatusList = Object.values(LoanSubmissionStatus).filter((v) => isNaN(Number(v)));
export const FinalDecisionList = Object.values(FinalDecision).filter((v) => isNaN(Number(v)));
export const LoanSubmissionStatusTextValue = Object.entries(LoanSubmissionStatusText).map((v) => {
  return {
    text: v[1],
    value: v[0],
  };
});

export type PersonalAssessmentItem = {
  id: number;
  indicator: string;
  eligible?: boolean;
};

export type FiveCAssessmentItem = {
  id: number;
  indicator: string;
  type: FiveCType;
  score: number;
  description: string[];
  allowedUser: number[];
};

export type SimpleBiodata = {
  id: number;
  name: string;
  phone: string;
};

export type SimpleIncomeData = {
  id: number;
  type: IncomeType;
  text: string;
  amount: number;
};

export type SubmissionList = {
  id: string;
  status: string;
  memberId: string;
  memberName: string;
  updatedAt: string;
  updatedBy: string;
  updateNote: string;
  createdAt: string;
};

export type LoanSubmissionStatusData = {
  status: LoanSubmissionStatus;
  updatedBy: string;
  updatedAt: string;
  note: string;
};

export type LoanSubmissionApprovedData = {
  approvedAmount: number;
  provisionFee: number;
  adminFee: number;
  installment: number;
  insterest: number;
  interestType: string;
  interestIsPerYear: boolean;
  accountId: string;
};

export type SubmissionFormWrapper = {
  id: string;
  member: { id: string };
  status: LoanSubmissionStatus;
  statusData: LoanSubmissionStatusData;
  approvedData: LoanSubmissionApprovedData;
  data: SubmissionForm;
  version: number;
  updateNote: string;
  updatedBy: string;
  updatedAt: string;
  createdAt: string;
};

export type SubmissionAssetItem = {
  id: number;
  type: string;
  bpkb: null | {
    estimatedValue: number;
    monthlyServiceCost: number;
    vehicleAgeInMonth: number;

    ownerName: string;
    ownerAddress: string;
    vehicleType: string;
    manufacture: string;
    color: string;
    vehicleNumber: string;
    bpkbNumber: string;
    vehicleYear: number;
    notes: string;
  };
  houseOrLand: null | {
    ownerName: string;
    ownerAddress: string;
    certificateNumber: string;

    checklist: {
      passableByCar: boolean;
      cleanWater: boolean;
      electricity: boolean;
      floodFree: boolean;
    };

    value: {
      land: {
        areaInMeter: number;
        valuePerMeter: number;
      };
      building: {
        areaInMeter: number;
        valuePerMeter: number;
      };
    };
  };
  gold: null | {
    weightInGram: number;
    form: string;
    estimatedValue: number;
  };
};

export type CfoAnalysisForm = {
  analysisDate: string;
  savingAbility: {
    lastThreeMonth: number;
    savingType: string;
    goalFl: number;
    notes: null | string;
  };
  loanRepayment: {
    goalFl: number;
    repaymentSource: string;
    notes: null | string;
    isCapable: boolean;
  };
  consumption: {
    goalFl: number;
    notes: null | string;
  };
  twtjLastLoan: {
    count: number;
    notes: null | string;
  };
  others: {
    badRecordNotes: string;
    badRecord: boolean;
    socialRelationCollateral: boolean;
    familySignature: boolean;
    addressOnGmaps: boolean;
    validation: boolean;
    referenceSignature: boolean;
    basicCoopEducationScore: number;
    financialLiteracy: boolean;
    praRat: boolean;
    notes: null | string;
  };
};

export type SubmissionForm = {
  loanAgreementDocument: string | null;
  biodata: {
    memberId: string;
    memberName: string;
    phones: string[];
    address: string;
    nickname: string;
    homeOwnershipStatus: HomeOwnershipStatus;
    dependents: number;
    relatives: SimpleBiodata[] | [];
    groups: SimpleBiodata[] | [];
    signature: string | null;
    reference: {
      name: string;
      memberId?: string;
      type: string;
      signature: string | null;
    };
    family: {
      name: string;
      memberId?: string;
      type: string;
      signature: string | null;
    };
  };
  submissionData: {
    installment: number;
    installmentType: string;
    amount: number;
    purpose: string;
    dueDate: number;
    notes: string;
  };
  collateralData: {
    savings: [] | MemberSaving[];
    totalParentsSaving: number;
    totalRelativesSaving: number;
    totalChildrenSaving: number;
    totalSpouseSaving: number;
    totalGroupSaving: number;
    assets: [] | SubmissionAssetItem[];
  };
  debtData: {
    bankLoan: number;
    otherCoopLoan: number;
    friendLoan: number;
    dealerLoan: number;
    arisan: number;
  };
  incomeData: {
    estimation: {
      incomePerMonth: number;
      expensesPerMonth: number;
      otherIncomePerMonth: number;
    };
    business: {
      startAt: string;
      name: string;
      type: string;
      item: SimpleIncomeData[] | [];
    };
    job: {
      startAt: string;
      name: string;
      position: string;
      item: SimpleIncomeData[] | [];
    };
  };
  assessment: {
    personal: PersonalAssessmentItem[] | [];
    fiveCWork: FiveCAssessmentItem[] | [];
    fiveCBusiness: FiveCAssessmentItem[] | [];
  };
  cfoAnalysisForm: CfoAnalysisForm;
  ceoAnalysisForm: {
    upload: string | null;
    notes: string | null;
  };
  interviewAgreement: {
    peopleInvolved: string[] | [];
    items:
      | [
          {
            id: number;
            agreement: string;
            description: string;
          },
        ]
      | [];
  };
  verificationData: {
    checkedAt: null | string;
    checkedBy: null | {
      name: string;
      position: string;
      id: number;
    };
    verifiedAt: null | string;
    verifiedBy: null | {
      name: string;
      position: string;
      id: number;
    };
  };
};

export type History = {
  label: LoanSubmissionStatus;
  note: string;
  title: string;
  updatedAt: string;
};
