import { message, Modal } from "antd";
import { Formik } from "formik";
import { DatePicker, Form, Select } from 'formik-antd';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import quillConfig from '../../../common/quillConfig';
import { isEmpty } from '../../../common/tokenizer';
import { useUpdateLoanSubmissionStatusMutation } from './loanSubmissionAPI';
import { LoanSubmissionStatus, LoanSubmissionStatusDefaultNotes, LoanSubmissionStatusList, LoanSubmissionStatusText } from './types';

const { Option } = Select;

interface Props {
  id: string | undefined;
  status: LoanSubmissionStatus | undefined;
  isModalVisible: boolean;
  setModalVisible: any;
  allowStatusChange: boolean;
}

interface InitialData {
  status: LoanSubmissionStatus;
  date: null | string;
}

function SubmissionStatusChangeForm(props: Props) {
  const [updateNote, setUpdateNote] = useState<string>(LoanSubmissionStatusDefaultNotes[props.status ?? LoanSubmissionStatus.submitted]);

  const [update] = useUpdateLoanSubmissionStatusMutation();

  const save = async (value: InitialData, closeLoading: any) => {
    if (props.id == null || props.id == undefined) {
      closeLoading();
      message.error('Tidak ada ID');
      return;
    }

    if (
      value.status != LoanSubmissionStatus.waiting_for_interview &&
      value.status != LoanSubmissionStatus.waiting_disbursement &&
      updateNote.length == 0
    ) {
      closeLoading();
      message.error('Catatan update tidak boleh kosong');
      return;
    }

    if (
      (value.status == LoanSubmissionStatus.waiting_for_interview || value.status == LoanSubmissionStatus.waiting_disbursement) &&
      isEmpty(value.date)
    ) {
      closeLoading();
      message.error('Tanggal tidak boleh kosong');
      return;
    }

    let tempNote = updateNote;
    if (value.status == LoanSubmissionStatus.waiting_for_interview) {
      tempNote = `<p>Wawancara dijadwalkan pada tanggal ${moment(value?.date).format('DD/MM/YYYY')}.</p>${updateNote}`;
    } else if (value.status == LoanSubmissionStatus.waiting_disbursement) {
      tempNote = `<p>Pencairan pinjaman dijadwalkan pada tanggal ${moment(value?.date).format('DD/MM/YYYY')}.</p>${updateNote}`;
    }

    let dataToUpdate: any = {
      ...value,
      id: props.id,
      note: tempNote,
    };

    update(dataToUpdate)
      .unwrap()
      .then(() => {
        closeLoading();
        message.success('Berhasil!');
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  const isOkButtonDisabled = (e) => {
    return e == LoanSubmissionStatus.approved || e == LoanSubmissionStatus.approved_with_changes;
  };

  return (
    <Formik
      initialValues={{
        status: props.status || LoanSubmissionStatus.submitted,
        date: null,
      }}
      onSubmit={async (values, actions) => {
        const hide = message.loading('Loading', 0);
        await save(values, hide);
        actions.setSubmitting(false);
      }}
      enableReinitialize={true}
    >
      {({ values, touched, errors, handleChange, handleBlur, isValid, submitForm }) => (
        <Modal
          title="Ubah status"
          visible={props.isModalVisible}
          onOk={submitForm}
          okButtonProps={{ disabled: isOkButtonDisabled(values.status) }}
          onCancel={() => props.setModalVisible(false)}
        >
          <Form layout="vertical">
            <Form.Item name="status" label="Status Pengajuan">
              <Select
                name="status"
                disabled={props.allowStatusChange ? false : true}
                onChange={(e) => {
                  setUpdateNote(LoanSubmissionStatusDefaultNotes[e]);
                }}
              >
                {LoanSubmissionStatusList.map((e) => (
                  <Option value={e}>{LoanSubmissionStatusText[e]}</Option>
                ))}
              </Select>
            </Form.Item>
            {values.status == LoanSubmissionStatus.waiting_for_interview || values.status == LoanSubmissionStatus.waiting_disbursement ? (
              <Form.Item name="date" label="Tanggal">
                <DatePicker name="date" />
              </Form.Item>
            ) : (
              <></>
            )}

            <Form.Item name="updateNote" label="Catatan untuk ditampilkan ke anggota (harus diisi)">
              <ReactQuill
                modules={quillConfig}
                style={{
                  background: 'white',
                  height: '180px',
                  marginBottom: '20px',
                }}
                onChange={(note) => setUpdateNote(note)}
                value={updateNote || ''}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default SubmissionStatusChangeForm;
