import { CopyOutlined, DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Card, message, Popconfirm, Table, Tooltip, Typography } from 'antd';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { basicSort, DynamicFilter } from '../../common/TableHelper';
import { useDeleteKpiMutation, useGetKpiListQuery } from './adminAPI';
import { IndicatorTypeText, KPIBaseEntity, KPITarget, OperatorTypeText, TargetType } from './types';

const { Text } = Typography;

function ManagePerformanceEdit() {
  const navigate = useNavigate();

  const { data, error, isFetching } = useGetKpiListQuery();
  const [deleteKpi] = useDeleteKpiMutation();

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Alert
        style={{ marginBottom: 12 }}
        message={
          <>
            <b> Mengubah indikator (terutama bobot/base/stretch) dapat merubah KPI yang telah ada pada bulan/tahun sebelumnya.</b>
            <br />
            Disarankan untuk menduplikat indikator lalu menghapus indikator yang lama
          </>
        }
        type="warning"
        showIcon
        closable
      />
      <Card
        title="Pengaturan Indikator"
        size="small"
        extra={
          <div>
            <Button style={{ borderRadius: 5 }}>
              <Link to="create">+ Tambah Baris</Link>
            </Button>
          </div>
        }
      >
        <Table
          loading={isFetching}
          dataSource={data}
          bordered
          size="small"
          pagination={{ pageSize: 12 }}
          columns={[
            {
              title: 'Nama',
              dataIndex: ['admin', 'name'],
              key: 'admin.name',
              width: '10%',
              sorter: (a, b) => basicSort(a.admin.name, b.admin.name),
              ...DynamicFilter<KPIBaseEntity>(data, ['admin', 'name'], true),
            },
            {
              title: 'Tipe',
              dataIndex: 'indicatorType',
              key: 'indicatorType',
              width: '5%',
              sorter: (a, b) => basicSort(a.indicatorType, b.indicatorType),
              render: (item) => IndicatorTypeText[item],
              ...DynamicFilter<KPIBaseEntity>(data, 'indicatorType', true),
            },
            {
              title: 'Indikator',
              dataIndex: 'indicator',
              key: 'indicator',
              width: '10%',
            },
            {
              title: 'Bobot',
              dataIndex: 'weight',
              width: '5%',
              key: 'weight',
              render: (weight) => weight + ' %',
            },
            {
              title: 'Base',
              dataIndex: 'base',
              key: 'base',
              width: '10%',
              render: (base: KPITarget) => {
                let text = 'Tidak Ada';
                let text2 = 'Tidak Ada';
                if (base.type == TargetType.NUMBER.toString()) {
                  text = `${base.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  text2 = `${base.value2}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                } else if (base.type == TargetType.PERCENTAGE.toString()) {
                  text = `${base.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' %';
                  text2 = `${base.value2}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' %';
                } else if (base.type == TargetType.TEXT.toString()) {
                  text = `${base.text}`;
                }

                if (base.operator == 'BETWEEN') {
                  text = text + ' - ' + text2;
                }

                if (base.type == TargetType.NUMBER.toString() || base.type == TargetType.PERCENTAGE.toString()) {
                  return (
                    <Tooltip title={OperatorTypeText[base.operator]}>
                      <Text underline>
                        {text} <QuestionCircleOutlined />
                      </Text>
                    </Tooltip>
                  );
                }
                return text;
              },
            },
            {
              title: 'Stretch',
              dataIndex: 'stretch',
              key: 'stretch',
              width: '10%',
              render: (base: KPITarget) => {
                let text = 'Tidak Ada';
                if (base.type == TargetType.NUMBER.toString()) {
                  text = `${base.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                } else if (base.type == TargetType.PERCENTAGE.toString()) {
                  text = `${base.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' %';
                } else if (base.type == TargetType.TEXT.toString()) {
                  text = `${base.text}`;
                }

                return text;
              },
            },
            {
              title: 'Deskripsi',
              dataIndex: 'description',
              key: 'description',
              width: '20%',
            },
            {
              title: 'Aksi',
              key: 'x',
              width: '7%',
              render: (text, record) => (
                <div style={{ padding: 0, margin: 0 }}>
                  <Button type="link" icon={<EditOutlined />} size="small" onClick={() => navigate(`detail/${record.id}`)} />
                  <Button type="link" icon={<CopyOutlined />} size="small" onClick={() => navigate(`create/${record.id}`)} />
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading('Loading', 0);
                      deleteKpi(record.id)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success('Berhasil!');
                        })
                        .catch(() => {
                          hideLoading();
                          message.error('Gagal!');
                        });
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} size="small" />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
      <Outlet />
    </ContentWithErrorHandler>
  );
}

export default ManagePerformanceEdit;
