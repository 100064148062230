import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { ColumnType } from "antd/lib/table";
import Highlighter from "react-highlight-words";

export function basicSort(a: any, b: any) {
  return a < b ? -1 : 1;
}

export function basicSortDateString(a: any, b: any) {
  return basicSort(new Date(a).getMilliseconds(), new Date(b).getMilliseconds());
}

function dynamicFilter<T>(data: T[] | undefined, dataIndex: string | string[]) {
  if (data == undefined) {
    return [];
  }

  let result: any[] = [];
  if (typeof dataIndex == 'string') {
    for (let item of data) {
      result.push(item[dataIndex]);
    }
  } else {
    for (let item of data) {
      let temp = item;
      for (let dataIndexItem of dataIndex) {
        temp = temp[dataIndexItem];
      }
      result.push(temp);
    }
  }
  let distinct = Array.from(new Set(result));
  return distinct.map((e) => {
    return { text: e, value: e };
  });
}

function onFilter(value, record, dataIndex: string | string[]) {
  if (typeof dataIndex == 'string') {
    return record[dataIndex]?.toString().toLowerCase().includes(value.toString().toLowerCase()) || false;
  } else {
    let temp = record;
    for (let dataIndexItem of dataIndex) {
      temp = temp[dataIndexItem];
    }
    let dataIndexStringValue = temp as unknown as string;
    return dataIndexStringValue.toString().toLowerCase().includes(value.toString().toLowerCase()) || false;
  }
}

export function DynamicFilter<T>(data: T[] | undefined, dataIndex: string | string[], multiple: boolean): ColumnType<T> {
  return {
    filters: dynamicFilter<T>(data, dataIndex),
    filterMultiple: multiple,
    onFilter: (value, record) => onFilter(value, record, dataIndex),
  };
}

export function TableColumnTextFilterConfig<T>(dataIndex: string | string[]): ColumnType<T> {
  const searchInputHolder: { current: any | null } = { current: null };
  const [searchedText, setSearchedText] = useState('');

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => (searchInputHolder.current = node)}
            placeholder={'Search'}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              setSearchedText(e.target.value);
            }}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            Search
          </Button>
          <Button size="small" style={{ width: 90 }} onClick={clearFilters}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputHolder.current?.select());
      }
    },
    onFilter: (value, record) => onFilter(value, record, dataIndex),
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchedText]}
        autoEscape
        textToHighlight={text?.toString() || ''}
      />
    ),
  };
}
