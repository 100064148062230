import { Button, Card, Col, Divider, message, Row, Select, Statistic, Tabs, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import TabPane from 'antd/lib/tabs/TabPane';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { RootState } from '../../app/store';
import { useGetKpiExecutionQuery, useSaveKpiExecutionItemMutation } from './adminAPI';
import PerformanceIndicatorExecutionsDetail from './PerformanceIndicatorExecutionDetail';
import { Admin, KPIExecutionEntity, MONTHS } from './types';
import { calculateRealization } from './Util';

const { Option } = Select;

function PerformanceIndicatorExecutions() {
  const auth = useSelector((state: RootState) => state.auth);
  const admin = auth.admin as unknown as Admin;

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [data, setData] = useState<KPIExecutionEntity[]>([]);
  const [notes, setNotes] = useState('');

  const {
    data: apiData,
    error,
    isFetching,
    refetch,
  } = useGetKpiExecutionQuery(
    { month, year },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    },
  );
  const [saveKpiExecutionItem] = useSaveKpiExecutionItemMutation();

  useEffect(() => {
    if (apiData) {
      setData(apiData);
      setNotes(apiData.at(0)?.note ?? '');
    }
  }, [apiData]);

  const calculateTotalAchievement = () => {
    let total = 0;
    for (let datum of data) {
      total += calculateRealization(datum).weightPercentage;
    }
    return total;
  };

  const handleSaveExecutions = async () => {
    const hide = message.loading('Loading', 0);
    let dataToSave = await data.map((e) => {
      return { ...e, note: notes };
    });

    saveKpiExecutionItem(dataToSave)
      .unwrap()
      .then(() => {
        hide();
        refetch();
        message.success('Berhasil!');
      })
      .catch((e) => {
        hide();
        message.error(e.data.message);
      });
  };

  return (
    <ContentWithErrorHandler isError={false} isLoading={false} customErrorMessage="Halaman ini belum selesai. Sedang dalam perbaikan">
      <Tabs
        destroyInactiveTabPane={true}
        activeKey={month.toString()}
        onChange={(month) => setMonth(Number(month))}
        tabBarExtraContent={{
          left: (
            <span style={{ marginRight: 10, fontSize: 16 }}>
              <Select value={year} style={{ width: 140 }} onChange={setYear}>
                <Option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</Option>
                <Option value={new Date().getFullYear()}>{new Date().getFullYear()}</Option>
                <Option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</Option>
                <Option value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</Option>
              </Select>
              <Divider type="vertical" style={{ height: 20, borderWidth: 2, borderColor: 'gray' }} />
            </span>
          ),
        }}
      >
        {MONTHS.map((monthElem) => {
          return (
            <TabPane tab={monthElem.value} key={monthElem.key} destroyInactiveTabPane>
              <Card title="Ringkasan" size="small" style={{ marginBottom: 10 }}>
                <Statistic
                  title="Total Pencapaian KPI"
                  value={calculateTotalAchievement()}
                  precision={2}
                  valueStyle={{ color: calculateTotalAchievement() >= 80 ? '#3f8600' : '#cf1322' }}
                  suffix="%"
                />
                <div>
                  {data.at(0)?.approvedAt ? `'KPI disetujui pada ${new Date(data.at(0)?.approvedAt ?? 0).toDateString()}` : 'KPI belum disetujui'}{' '}
                </div>
              </Card>
              <Card title="Detail" size="small" style={{ marginBottom: 10 }}>
                <PerformanceIndicatorExecutionsDetail
                  data={data}
                  isFetching={isFetching}
                  month={month}
                  refetch={refetch}
                  setData={setData}
                  year={year}
                  adminId={admin.id}
                />
                <Divider />
                <p>Catatan Lain</p>
                <TextArea rows={4} value={notes} onChange={(event) => setNotes(event.target.value)} />
              </Card>
              <Col>
                <Row justify="end" style={{ marginTop: 10 }}>
                  <Button type="primary" disabled={data.length == 0} onClick={() => handleSaveExecutions()}>
                    Simpan
                  </Button>
                </Row>
              </Col>
            </TabPane>
          );
        })}
      </Tabs>
    </ContentWithErrorHandler>
  );
}

export default PerformanceIndicatorExecutions;
