import { message, Modal } from "antd";
import { Formik } from "formik";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { useCreateTransactionAccountMutation, useGetTransactionAccountQuery, useUpdateTransactionAccountMutation } from "./transactionAPI";

const ACCOUNT_TYPE = ["Aktiva Lancar", "Penyertaan", "Aktiva Tetap", "Hutang", "Modal", "Pendapatan", "Biaya", "Pajak"];

const { Option } = Select;

function TransactionAccountDetail() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, isLoading, isError } = useGetTransactionAccountQuery(id || "", {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const [updateTransactionAccount] = useUpdateTransactionAccountMutation();
  const [createTransactionAccount] = useCreateTransactionAccountMutation();

  const validate = (value) => {
    let error;
    if (!value) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  const save = async (value: any, closeLoading: any) => {
    let data: any = { ...value };

    let promise;
    if (id) {
      data.id = id;
      promise = updateTransactionAccount(data);
    } else {
      promise = createTransactionAccount(data);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success("Berhasil!");
      })
      .catch(() => {
        closeLoading();
        message.error("Gagal!");
      });
  };

  return (
    <Modal
      title="Detail Akun Transaksi"
      width={800}
      visible={true}
      destroyOnClose={true}
      onCancel={() => navigate("/transaction-account")}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
    >
      <ContentWithErrorHandler isError={isError} isLoading={isLoading} withBox={false}>
        <Formik
          initialValues={{
            accountType: data?.accountType || "",
            accountName: data?.accountName || "",
            accountNumber: data?.accountNumber || "",
          }}
          onSubmit={async (values, actions) => {
            const hide = message.loading("Loading", 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              <Form.Item name="accountType" label="Tipe Akun" validate={validate}>
                <Select name="accountType" style={{ width: "100%" }}>
                  {ACCOUNT_TYPE.map((element) => {
                    return <Option value={element}>{element}</Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item name="accountName" label="Nama Akun" validate={validate}>
                <Input name="accountName" placeholder="Masukkan Nama Akun" />
              </Form.Item>

              <Form.Item name="accountNumber" label="Nomor Akun" validate={validate}>
                <Input name="accountNumber" placeholder="Masukkan Nomor Akun" />
              </Form.Item>

              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </Modal>
  );
}

export default TransactionAccountDetail;
