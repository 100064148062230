export type Breadcrumb = {
  isNotPage?: boolean;
  skipRender?: boolean;
  text: string;
};

export const breadcrumbObject: Map<string, Breadcrumb> = new Map(
  Object.entries({
    // Berita
    '/news': { text: 'Berita' },
    '/news/detail': { text: 'Detail Berita' },
    '/news/create': { text: 'Buat Berita' },

    // Dokumen
    '/document': { text: 'Dokumen' },
    '/document/detail': { text: 'Detail Dokumen' },
    '/document/create': { text: 'Buat Dokumen' },

    // Gallery
    '/gallery': { text: 'Galeri' },
    '/gallery/detail': { text: 'Detail Galeri' },
    '/gallery/create': { text: 'Buat Galeri' },

    // Layanan
    '/service': { text: 'Layanan', isNotPage: true },

    // Layanan Pinjaman
    '/service/loan': { text: 'Pinjaman' },
    '/service/loan/master': { text: 'Tajuk' },
    '/service/loan/type-create': { text: 'Tambah Tipe Pinjaman' },
    '/service/loan/type-detail': { text: 'Detail Tipe Pinjaman' },
    '/service/loan/step-create': { text: 'Tambah Tahapan Pinjaman' },
    '/service/loan/step-detail': { text: 'Detail Tahapan Pinjaman' },

    // Layanan Simpanan
    '/service/saving': { text: 'Simpanan' },
    '/service/saving/master': { text: 'Tajuk' },
    '/service/saving/type-create': { text: 'Tambah Tipe Simpanan' },
    '/service/saving/type-detail': { text: 'Detail Tipe Simpanan' },
    '/service/saving/step-create': { text: 'Tambah Tahapan Simpanan' },
    '/service/saving/step-detail': { text: 'Detail Tahapan Simpanan' },

    // Layanan Anggota
    '/service/memberService': { text: 'Layanan Anggota' },
    '/service/memberService/master': { text: 'Tajuk' },
    '/service/memberService/item-create': { text: 'Tambah Item' },
    '/service/memberService/item-detail': { text: 'Detail Item' },

    // Profil
    '/profile': { text: 'Profil', isNotPage: true },

    // Profil Visi Misi
    '/profile/org.profile': { text: 'Visi, Misi, Nilai' },
    '/profile/org.profile/master': { text: 'Tajuk', skipRender: true },
    '/profile/org.profile/master/vision': { text: 'Visi' },
    '/profile/org.profile/master/mission': { text: 'Misi' },
    '/profile/org.profile/value-create': { text: 'Tambah Nilai-nilai' },
    '/profile/org.profile/value-detail': { text: 'Detail Nilai-nilai' },

    // Profil Sejarah
    '/profile/history': { text: 'Sejarah' },
    '/profile/history/master': { text: 'Tajuk' },
    '/profile/history/milestone-create': { text: 'Tambah Linimasa' },
    '/profile/history/milestone-detail': { text: 'Detail Linimasa' },

    // Profil Struktur Organisasi
    '/profile/org-structure': { text: 'Struktur Organisasi' },
    '/profile/org-structure/create': { text: 'Tambah Anggota' },
    '/profile/org-structure/detail': { text: 'Detail Anggota' },

    // Lokasi Kantor
    '/office-location': { text: 'Lokasi Kantor' },
    '/office-location/detail': { text: 'Detail Lokasi Kantor' },
    '/office-location/create': { text: 'Tambah Lokasi Kantor' },

    // Kontak Kami
    '/contact-us': { text: 'Kontak Kami' },
    '/contact-us/detail': { text: 'Detail' },

    // Kontak Kami
    '/event': { text: 'Acara' },
    '/event/detail': { text: 'Detail' },
    '/event/create': { text: 'Tambah' },
    '/event-schedule': { text: 'Jadwal Acara' },
    '/event-schedule/detail': { text: 'Detail' },

    // -----------------------------
    //  MENU OPERASIONAL
    // -----------------------------

    // Profile
    '/myprofile': { text: 'Profil Saya' },

    // Pengaturan Umum
    '/config': { text: 'Pengaturan Umum' },

    // Pengaturan Pengguna
    '/admin': { text: 'Pengaturan', isNotPage: true },
    '/admin/staff': { text: 'Daftar Officer' },
    '/admin/staff/detail': { text: 'Detail Officer' },
    '/admin/staff/create': { text: 'Tambah Officer' },
    '/admin/position': { text: 'Daftar Jabatan' },
    '/admin/position/detail': { text: 'Detail Jabatan' },
    '/admin/position/create': { text: 'Tambah Jabatan' },
    '/admin/management': { text: 'Manage KPI' },
    '/kpi': { text: 'KPI Officer' },

    // Berita
    '/member': { text: 'Anggota Koperasi' },
    '/member/detail': { text: 'Detail Anggota' },
    '/member/create': { text: 'Tambah Anggota' },

    // Pinjaman
    '/loan': { text: 'Pinjaman' },
    '/loan/detail': { text: 'Detail Pinjaman' },
    '/loan/create': { text: 'Tambah Pinjaman' },
    '/loan/report': { text: 'Laporan Pinjaman' },

    '/loan-submission': { text: 'Pengajuan Pinjaman', isNotPage: true },
    '/loan-submission/data': { text: 'Data' },
    '/loan-submission/data/create': { text: 'Buat Pengajuan' },
    '/loan-submission/data/detail': { text: 'Detail Pengajuan' },
    '/loan-submission/config': { text: 'Pengaturan' },
    '/loan-submission/config/assessment-create': { text: 'Tambah Konfigurasi Asesment' },
    '/loan-submission/config/assessment-detail': { text: 'Detail Konfigurasi Asesment' },

    // Transaction
    '/transaction': { text: 'Transaksi' },
    '/transaction/detail': { text: 'Detail Transaksi' },
    '/transaction/create': { text: 'Tambah Transaksi' },
    '/transaction/report': { text: 'Laporan' },

    '/transaction-account': { text: 'Akun Transaksi' },
    '/transaction-account/detail': { text: 'Detail Akun' },
    '/transaction-account/create': { text: 'Tambah Akun' },

    // Antrian
    '/queue-admin': { text: 'Admin Antrian' },
    '/queue-producer': { text: 'Klien Antrian' },

    // Berita
    '/fl': { text: 'Financial Literacy', isNotPage: true },
    '/fl/questionaire': { text: 'Kuisioner' },
    '/fl/questionaire/detail': { text: 'Detail' },
    '/fl/questionaire/create': { text: 'Tambah' },
    '/fl/questionaire/result': { text: 'Hasil Kuisioner' },
    '/fl/questionaire/result/detail': { text: 'Detail' },
    '/fl/questionaire/result-config': { text: 'Pengaturan Hasil' },
    '/fl/questionaire/result-config/create': { text: 'Tambah' },
    '/fl/questionaire/result-config/detail': { text: 'Detail' },

    // Quiz
    '/quiz': { text: 'Quiz', isNotPage: true },
    '/quiz/question': { text: 'Pertanyaan' },
    '/quiz/question/detail': { text: 'Detail' },
    '/quiz/question/create': { text: 'Tambah' },
    '/quiz/record': { text: 'Hasil' },
  }),
);
