import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Row, Select, Table, Tag } from "antd";
import copy from "copy-to-clipboard";
import React, { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { basicSort, TableColumnTextFilterConfig } from "../../common/TableHelper";
import { useGetAllLoanQuery } from "./loanAPI";
import { Loan } from "./types";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const REGULAR_FILTER = [
  { text: "Ada", value: true },
  { text: "Tidak Ada", value: false },
];

interface TagItem {
  text: string;
  value: string;
}

const { Option } = Select;

function LoanList() {
  const navigate = useNavigate();

  const [filterObject, setFilterObject] = useState({
    start: 1,
    end: 31,
    type: "default",
  });
  const [filterStatus, setFilterStatus] = useState("default");
  const [filterStartDate, setFilterStartDate] = useState(1);
  const [filterEndDate, setFilterEndDate] = useState(31);

  const { data, error, isFetching } = useGetAllLoanQuery(filterObject);

  const checkAdditionalInfoExist = (record, type) => {
    var exist = false;
    if (record.additionalInfo) {
      record.additionalInfo.map((element) => {
        if (element.isActive && element[type] != null && element[type] != "" && element[type] != undefined) {
          exist = true;
        }
      });
    }
    return exist;
  };

  const renderAdditionalInfoColumn = (exist) => {
    if (exist) {
      return (
        <Tag color="green" style={{ margin: 0 }}>
          Ada
        </Tag>
      );
    }
    return "-";
  };

  const getSelectionDate = (start) => {
    var date: any[] = [];
    for (let index = start ? start : 1; index <= 31; index++) {
      date.push(index);
    }
    return date;
  };

  const exportToExcel = () => {
    message.error("Not implemented");
    return;
  };

  const copyPhoneNumberToClipboard = () => {
    let allHandphone: string[] = [];
    data?.map((record) => {
      let phone = record.member.phone;
      if (phone[0] && phone[0].includes("08")) {
        allHandphone.push(phone[0]);
      } else if (phone[1] && phone[1].includes("08")) {
        allHandphone.push(phone[1]);
      } else if (phone[2] && phone[2].includes("08")) {
        allHandphone.push(phone[2]);
      }
    });
    copy(allHandphone.join("; "));
    message.success("Nomor telepon berhasil disalin.");
  };

  const applyFilter = () => {
    setFilterObject({
      start: filterStartDate,
      end: filterEndDate,
      type: filterStatus,
    });
  };

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Card title={"Data Pinjaman per " + new Date().toLocaleString()} size="small" extra={<div></div>}>
        <Row>
          <Col
            span={18}
            style={{
              marginBottom: 15,
            }}
          >
            <span style={{ marginRight: 6 }}>Filter</span>
            <Select defaultValue="default" style={{ marginRight: 6, borderRadius: 5, width: 130 }} onChange={setFilterStatus}>
              <Option value="default">Default</Option>
              <Option value="all">Semua</Option>
              <Option value="paid">Lunas</Option>
              <Option value="not-paid">Belum Lunas</Option>
            </Select>

            {filterStatus != "default" ? (
              <span>
                <span style={{ marginLeft: 12, marginRight: 6 }}>Tanggal</span>
                <Select value={filterStartDate} style={{ marginRight: 6, borderRadius: 5, width: 60 }} onChange={setFilterStartDate}>
                  {getSelectionDate(1).map((element) => {
                    return <Option value={element}>{element}</Option>;
                  })}
                </Select>
                <ArrowRightOutlined style={{ marginRight: 6 }} />
                <Select value={filterEndDate} style={{ marginRight: 6, borderRadius: 5, width: 60 }} onChange={setFilterEndDate}>
                  {getSelectionDate(filterStartDate).map((element) => {
                    return <Option value={element}>{element}</Option>;
                  })}
                </Select>
              </span>
            ) : null}
            {/*Filter Button*/}
            <Button type="primary" style={{ marginRight: 6 }} onClick={applyFilter}>
              Terapkan
            </Button>
            {/* Copy nomor hape */}
          </Col>

          <Col
            span={6}
            style={{
              marginBottom: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button style={{ marginRight: 6 }} onClick={() => copyPhoneNumberToClipboard()}>
              Salin Telepon
            </Button>
            <ExcelFile
              filename={"Data Jatuh Tempo per " + new Date().toDateString()}
              element={<Button style={{ marginRight: 6 }}>Ekspor Data</Button>}
            >
              <ExcelSheet data={data} name={"Data Jatuh Tempo per " + new Date().toLocaleString()}>
                <ExcelColumn label="Nomor" value={(record) => record.member.memberId} />
                <ExcelColumn label="Nomor Rekening" value="accountId" />
                <ExcelColumn label="Nama" value={(record) => record.member.name} />
                <ExcelColumn label="Nomor Telepon" value={(record) => record.member.phone.join(", ")} />
                <ExcelColumn label="Cicilan" value="installment" />
                <ExcelColumn label="Pinjaman Awal" value="borrowAmount" />
                <ExcelColumn label="Sisa Saldo Pinjaman" value="remainingAmount" />
                <ExcelColumn label="Tanggal Pinjam" value="borrowDate" />
                <ExcelColumn label="Transaksi Terakhir" value="lastTransaction" />
                <ExcelColumn label="Tanggal Jatuh Tempo" value="dueDate" />
                <ExcelColumn label="Selisih Jatuh Tempo" value="paymentDateDiff" />
              </ExcelSheet>
            </ExcelFile>
            <Button>
              <Link to="create">+ Tambah Baris</Link>
            </Button>
          </Col>
        </Row>
        <Table
          loading={isFetching}
          dataSource={data}
          bordered
          size="small"
          pagination={{}}
          onRow={(record, rowIndex) => {
            return {
              onClick: async (event) => navigate(`detail/${record.id}`),
            };
          }}
          columns={[
            {
              title: "Nomor",
              dataIndex: ["member", "memberId"],
              key: "member.memberId",
              align: "center",
              fixed: "left",
              sorter: (a, b) => basicSort(a.member.memberId, b.member.memberId),
              ...TableColumnTextFilterConfig<Loan>(["member", "memberId"]),
            },
            {
              title: "Rekening",
              dataIndex: "accountId",
              key: "accountId",
              fixed: "left",
              align: "center",
              ...TableColumnTextFilterConfig<Loan>("accountId"),
            },
            {
              title: "Nama",
              dataIndex: ["member", "name"],
              key: "member.name",
              fixed: "left",
              sorter: (a, b) => basicSort(a.member.name, b.member.name),
              ...TableColumnTextFilterConfig<Loan>(["member", "name"]),
            },
            {
              title: "Telepon",
              dataIndex: ["member", "phone"],
              key: "member.phone",
              align: "center",
              render: (text, record) => {
                for (var element of record.member.phone) {
                  if (element !== null && element.includes("08")) return record.member.phone[0];
                }
                return "-";
              },
            },
            {
              title: "Tgl",
              dataIndex: "dueDate",
              key: "dueDate",
              align: "center",
              sorter: (a, b) => a.dueDate - b.dueDate,
              ...TableColumnTextFilterConfig<Loan>("dueDate"),
            },
            {
              title: "Jth Tempo",
              dataIndex: "paymentDateDiff",
              key: "paymentDateDiff",
              align: "center",
              sorter: (a, b) => basicSort(a.paymentDateDiff, b.paymentDateDiff),
              ...TableColumnTextFilterConfig<Loan>("paymentDateDiff"),
              render: (text, record) => {
                if (record.isPaid) return "-";
                return text + " Hari";
              },
            },
            {
              title: "Restruktur",
              align: "center",
              filters: REGULAR_FILTER,
              filterMultiple: false,
              onFilter: (value, record) => checkAdditionalInfoExist(record, "restructurePolicy") == value,
              render: (text, record) => {
                var hasRestructure = checkAdditionalInfoExist(record, "restructurePolicy");
                return renderAdditionalInfoColumn(hasRestructure);
              },
            },
            {
              title: "Komitmen",
              align: "center",
              filters: REGULAR_FILTER,
              filterMultiple: false,
              onFilter: (value, record) => checkAdditionalInfoExist(record, "commitment") == value,
              render: (text, record) => {
                var hasCommitment = checkAdditionalInfoExist(record, "commitment");
                return renderAdditionalInfoColumn(hasCommitment);
              },
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              align: "center",
              render: (text, record) => {
                return (
                  <Tag color={record.statusColor} key={record.statusText}>
                    {record.statusText}
                  </Tag>
                );
              },
              filters: [
                {
                  text: "Saldo Cukup",
                  value: "Saldo Cukup",
                },
                {
                  text: "Saldo Kurang",
                  value: "Saldo Kurang",
                },
                {
                  text: "Lunas",
                  value: "LUNAS",
                },
              ],
              filterMultiple: true,
              onFilter: (value, record) => record.statusText == value,
            },
          ]}
        />
      </Card>
      <Outlet />
    </ContentWithErrorHandler>
  );
}

export default LoanList;
