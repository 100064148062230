import { Formik } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import { Row, Col, Image, message, Typography } from "antd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authLogin } from "./authAPI";

const { Title } = Typography;

interface LocationState {
  from: {
    pathname: string;
  };
}

export default function Auth() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const validate = (value) => {
    let error;
    if (!value) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  const locationHistory = (location.state as LocationState)?.from?.pathname || "/";

  return (
    <>
      <Row justify="center" align="middle" style={{ height: "100vh", background: "white" }}>
        <Col lg={12} md={24} style={{ padding: 100 }}>
          <Title level={2}>Credit Union Madani</Title>
          <Formik
            initialValues={{ username: "", password: "" }}
            onSubmit={async (values, actions) => {
              const result: any = await dispatch(authLogin(values));
              if (authLogin.fulfilled.match(result)) {
                actions.setSubmitting(false);
                message.success("Berhasil masuk!");
                navigate(locationHistory, { replace: true });
              } else {
                actions.setSubmitting(false);
                message.error("Gagal masuk!");
              }
            }}
            render={() => (
              <Form layout="vertical">
                <Form.Item name="username" label="Username" validate={validate}>
                  <Input name="username" placeholder="Username" />
                </Form.Item>
                <Form.Item name="password" label="Kata sandi" validate={validate}>
                  <Input.Password name="password" placeholder="Kata sandi" type="password" />
                </Form.Item>
                <SubmitButton>Masuk</SubmitButton>
              </Form>
            )}
          />
        </Col>
        <Col xs={0} sm={0} md={0} lg={12} xl={12}>
          <Image
            alt={"Login Image"}
            height="100vh"
            style={{ objectFit: "cover" }}
            preview={false}
            src={
              "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80"
            }
          />
        </Col>
      </Row>
    </>
  );
}
