import { Col, Row } from "antd";
import { Form, Input, InputNumber, Select } from "formik-antd";
import ContentWithErrorHandler from "../../../app/ContentWithErrorHandler";

const DATE_FORMAT = "DD/MM/YYYY";

const { Option } = Select;

function SubmissionDataForm() {
  return (
    <ContentWithErrorHandler isError={false} isLoading={false} withBox={false}>
      <Row gutter={[8, 0]}>
        <Col span={24}>
          <Form.Item name="submissionData.type" label="Produk Pinjaman">
            <Select fast name="submissionData.type" style={{ width: '100%' }} placeholder="Pilih produk pinjaman">
              <Option value="pinjaman_usaha_produktif">Pinjaman Usaha Produktif</Option>
              <Option value="pinjaman_reguler">Pinjaman Reguler</Option>
              <Option value="pinjaman_ziarah_rohani">Pinjaman Ziarah Rohani</Option>
              <Option value="pinjaman_darurat">Pinjaman Darurat</Option>
              <Option value="pinjaman_kendaraan">Pinjaman Kendaraan</Option>
              <Option value="pinjaman_kepemilikan_rumah">Pinjaman Kepemilikan Rumah</Option>
              <Option value="pinjaman_pendidikan">Pinjaman Pendidikan</Option>
              <Option value="pinjaman_kapitalisasi">Pinjaman Kapitalisasi</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="submissionData.installmentType" label="Tipe Pembayaran Angsuran Dan Bunga Pinjaman">
            <Select fast name="submissionData.installmentType" style={{ width: '100%' }} placeholder="Pilih tipe pembayaran">
              <Option value="declining">Menurun</Option>
              <Option value="effective">Efektif</Option>
              <Option value="flat">Flat</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="submissionData.amount" label="Jumlah Pengajuan">
            <InputNumber
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              name="submissionData.amount"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="submissionData.installment" label="Jangka Waktu Pengembalian (Bulan)">
            <Select
              fast
              showSearch
              name="submissionData.installment"
              style={{ width: '100%' }}
              placeholder="Pilih tipe pembayaran"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toString().indexOf(input.toLowerCase()) >= 0}
            >
              {[...Array(500)].map((elementInArray, index) => (
                <Option key={`installment#${index}`} value={index}>
                  {index} kali
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="submissionData.purpose" label="Tujuan Pinjaman">
            <Input.TextArea fast name="submissionData.purpose" placeholder="Masukkan tujuan pinjaman" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="submissionData.dueDate" label="Tanggal Jatuh Tempo">
            <Select
              fast
              showSearch
              name="submissionData.dueDate"
              style={{ width: '100%' }}
              placeholder="Pilih tanggal jatuh tempo"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toString().indexOf(input.toLowerCase()) >= 0}
            >
              {[...Array(31)].map((elementInArray, index) => (
                <Option key={`dueDate#${index + 1}`} value={index + 1}>
                  Setiap tanggal {index + 1}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="submissionData.notes" label="Catatan">
            <Input.TextArea fast name="submissionData.notes" placeholder="Masukkan catatan" />
          </Form.Item>
        </Col>
      </Row>
    </ContentWithErrorHandler>
  );
}

export default SubmissionDataForm;
