import { Modal, Timeline } from "antd";
import { Select } from "formik-antd";
import { useEffect, useState } from 'react';
import { useGetLoanSubmissionHistoryQuery } from "./loanSubmissionAPI";
import { LoanSubmissionStatusColor, LoanSubmissionStatusText } from "./types";

const { Option } = Select;

interface Props {
  id: string | undefined;
  type: string;
  isModalVisible: boolean;
  setModalVisible: any;
  setSubmissionHistory: any;
}

function SubmissionHistory(props: Props) {
  const { data, isLoading, isError } = useGetLoanSubmissionHistoryQuery(
    {
      id: props.id,
      type: props.type,
    },
    {
      skip: props.id == undefined ? true : false,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  );

  useEffect(() => {
    if (data && props.type == 'Status') {
      props.setSubmissionHistory(data);
    }
  }, [data]);

  const getLabel = (label: string) => {
    return LoanSubmissionStatusText[label] != undefined && LoanSubmissionStatusText[label] != null ? LoanSubmissionStatusText[label] : label;
  };

  return (
    <Modal
      title={`History ${props.type}`}
      visible={props.isModalVisible}
      onOk={() => props.setModalVisible(false)}
      onCancel={() => props.setModalVisible(false)}
    >
      <Timeline mode="left">
        {data?.map((e, index) => (
          <Timeline.Item key={index} color={LoanSubmissionStatusColor[e.label]} label={getLabel(e.label)}>
            <p style={{ fontWeight: 'bold' }}>{e.title}</p>
            <div
              dangerouslySetInnerHTML={{
                __html: e?.note || '',
              }}
            ></div>
          </Timeline.Item>
        ))}
      </Timeline>
    </Modal>
  );
}

export default SubmissionHistory;
