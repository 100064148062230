import { MinusCircleOutlined, PlusSquareFilled } from "@ant-design/icons";
import { Button, Col, Divider, Row, Space } from "antd";
import { FieldArray } from "formik";
import { DatePicker, Form, Input, InputNumber, Select } from "formik-antd";
import ContentWithErrorHandler from "../../../app/ContentWithErrorHandler";
import { Admin } from "../../admin/types";
import { IncomeType, SimpleIncomeData, SubmissionForm } from "./types";

const DATE_FORMAT = "DD/MM/YYYY";

const { Option } = Select;

type Props = {
  formValue: SubmissionForm;
  admins: Admin[] | undefined;
};

function InterviewForm(props: Props) {
  return (
    <ContentWithErrorHandler isError={false} isLoading={false} withBox={false}>
      <Row gutter={[8, 0]}>
        <Col span={24}>
          <Divider plain>Pihak Yang Terlibat</Divider>
          <Form.Item name="interviewAgreement.peopleInvolved" label="Pihak yang terlibat">
            <Select
              fast
              mode="multiple"
              showSearch
              name="interviewAgreement.peopleInvolved"
              style={{ width: '100%' }}
              placeholder="Masukkan pihak yang terlibat"
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {props.admins?.map((admin, index) => {
                return (
                  <Option key={index} value={admin.id}>
                    {admin.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Divider plain>Kesepakatan Wawancara</Divider>
          <FieldArray name="interviewAgreement.items">
            {({ push, remove }) => (
              <div>
                {props.formValue.interviewAgreement.items.map((item, index) => {
                  const agreement = `interviewAgreement.items[${index}].agreement`;
                  const description = `interviewAgreement.items[${index}].description`;
                  const id = `interviewAgreement.items[${index}].id`;
                  return (
                    <Row gutter={6} key={item.id}>
                      <Col span={3}>
                        <Form.Item name={id} label="No.">
                          <InputNumber name={id} value={item.id} readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item name={agreement} label="Kesepakatan">
                          <Input.TextArea
                            name={agreement}
                            value={item.agreement}
                            placeholder="Masukkan kesepakatan"
                            autoSize={{ minRows: 3, maxRows: 6 }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={9}>
                        <Form.Item name={description} label="Keterangan">
                          <Input.TextArea
                            name={description}
                            value={item.description}
                            placeholder="Masukkan keterangan"
                            autoSize={{ minRows: 3, maxRows: 6 }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <MinusCircleOutlined color="red" onClick={() => remove(index)} />
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  block
                  icon={<PlusSquareFilled />}
                  onClick={() => push({ id: props.formValue.interviewAgreement.items.length + 1, agreement: null, description: null })}
                >
                  Tambah Item Wawancara
                </Button>
              </div>
            )}
          </FieldArray>
        </Col>
      </Row>
    </ContentWithErrorHandler>
  );
}

export default InterviewForm;
