import { Divider, message, Modal } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton, InputNumber } from 'formik-antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { useCreateKpiMutation, useGetAllAdminQuery, useGetKpiQuery, useUpdateKpiMutation } from './adminAPI';

const { Option } = Select;

function ManagePerformanceDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const { data: adminList, error: adminListError, isLoading: adminListLoading } = useGetAllAdminQuery();
  const { data, isLoading, isError } = useGetKpiQuery(id || '', {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const [initialData, setInitialData] = useState({
    admin: {
      id: '',
    },
    indicatorType: '',
    indicator: '',
    weight: 0,
    base: {
      operator: '',
      value: 0,
      value2: 0,
      type: '',
      text: '',
    },
    stretch: {
      value: 0,
      type: '',
      text: '',
    },
    description: '',
  });

  const [updateKpi] = useUpdateKpiMutation();
  const [createKpi] = useCreateKpiMutation();

  useEffect(() => {
    if (data) {
      setInitialData({
        admin: {
          id: location.pathname.includes('create') ? '' : data.admin?.id.toString(),
        },
        indicatorType: data.indicatorType,
        indicator: data.indicator,
        weight: data.weight,
        base: {
          operator: data.base?.operator,
          value: data.base?.value,
          value2: data.base?.value2,
          type: data.base?.type,
          text: data.base?.text,
        },
        stretch: {
          value: data.stretch?.value,
          type: data.stretch?.type,
          text: data.stretch?.text,
        },
        description: data.description,
      });
    }
  }, [data]);

  const validate = (value) => {
    return;
  };

  const save = async (value: any, closeLoading: any) => {
    let data: any = {
      ...value,
      admin: { id: Number(value.admin.id) },
    };
    let promise;
    if (id && !location.pathname.includes('create')) {
      data.id = id;
      promise = updateKpi(data);
    } else {
      promise = createKpi(data);
    }

    promise
      .unwrap()
      .then((data) => {
        closeLoading();
        message.success('Berhasil!');
        if (location.pathname.includes('create')) {
          navigate('/admin/management');
        }
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  const renderTarget = (values, targetType) => {
    const type = values[targetType].type;
    const nameValue = `${targetType}.value`;
    const nameValue2 = `${targetType}.value2`;
    const nameOperator = `${targetType}.operator`;
    const nameText = `${targetType}.text`;

    if (type == 'NUMBER') {
      return (
        <>
          {targetType == 'base' ? (
            <Form.Item name={nameValue} label={`Operator untuk ${targetType}`} validate={validate}>
              <Select name={nameOperator} style={{ width: '100%' }} placeholder="Pilih Tipe Indikator">
                <Option value={'EQUAL'}>Sama Dengan</Option>
                <Option value={'LESS_THAN'}>Kurang Dari</Option>
                <Option value={'GREATER_THAN'}>Lebih Dari</Option>
                <Option value={'BETWEEN'}>Diantara</Option>
              </Select>
            </Form.Item>
          ) : (
            <></>
          )}
          <Form.Item name={nameValue} label={`Target untuk ${targetType}`} validate={validate}>
            <InputNumber
              style={{ width: '100%' }}
              name={nameValue}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              placeholder="Masukkan Angka"
            />
          </Form.Item>
          {values[targetType].operator == 'BETWEEN' && targetType == 'base' ? (
            <Form.Item name={nameValue2} label={`Target 2 untuk ${targetType}`} validate={validate}>
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                name={nameValue2}
                placeholder="Masukkan Angka"
              />
            </Form.Item>
          ) : (
            <></>
          )}
        </>
      );
    } else if (type == 'PERCENTAGE') {
      return (
        <>
          {targetType == 'base' ? (
            <Form.Item name={nameOperator} label={`Operator untuk ${targetType}`} validate={validate}>
              <Select name={nameOperator} style={{ width: '100%' }} placeholder="Pilih Tipe Indikator">
                <Option value={'EQUAL'}>Sama Dengan</Option>
                <Option value={'LESS_THAN'}>Kurang Dari</Option>
                <Option value={'GREATER_THAN'}>Lebih Dari</Option>
                <Option value={'BETWEEN'}>Diantara</Option>
              </Select>
            </Form.Item>
          ) : (
            <></>
          )}
          <Form.Item name={nameValue} label={`Persentase untuk ${targetType}`} validate={validate}>
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              addonAfter="%"
              name={nameValue}
              placeholder="Masukkan Persentase"
            />
          </Form.Item>
          {values[targetType].operator == 'BETWEEN' ? (
            <Form.Item name={nameValue2} label={`Target 2 untuk ${targetType}`} validate={validate}>
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                addonAfter="%"
                name={nameValue2}
                placeholder="Masukkan Persentase"
              />
            </Form.Item>
          ) : (
            <></>
          )}
        </>
      );
    } else if (type == 'TEXT') {
      return (
        <Form.Item name={nameText} label={`Teks untuk ${targetType}`} validate={validate}>
          <Input name={nameText} placeholder="Masukkan Teks" />
        </Form.Item>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Modal
      title="Detail KPI"
      width={800}
      open={true}
      destroyOnClose={true}
      onCancel={() => navigate('/admin/management')}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
    >
      <ContentWithErrorHandler isError={isError || adminListError} isLoading={isLoading || adminListLoading}>
        <Formik
          initialValues={initialData}
          onSubmit={async (values, actions) => {
            const hide = message.loading('Loading', 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
        >
          {({ values, touched, errors, handleChange, handleBlur, isValid, submitForm }) => (
            <Form layout="vertical">
              <Form.Item name="admin.id" label="Nama" validate={validate}>
                <Select fast showSearch name="admin.id" style={{ width: '100%' }} placeholder="Pilih Manajemen" optionFilterProp="children">
                  {adminList?.map((admin, index) => {
                    return (
                      <Option key={index} value={admin.id.toString()}>
                        {admin.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item name="indicatorType" label="Tipe Indikator" validate={validate}>
                <Select name="indicatorType" style={{ width: '100%' }} placeholder="Pilih Tipe Indikator">
                  <Option value={'SkillAndTechnical'}>Skill-Teknikal</Option>
                  <Option value={'SOP'}>SOP</Option>
                  <Option value={'Teamwork'}>Teamwork</Option>
                </Select>
              </Form.Item>
              <Form.Item name="indicator" label="Indikator" validate={validate}>
                <Input name="indicator" placeholder="Masukkan Indikator" />
              </Form.Item>
              <Form.Item name="weight" label="Bobot" validate={validate}>
                <InputNumber style={{ width: '100%' }} addonAfter="%" name="weight" placeholder="Masukkan Bobot" />
              </Form.Item>
              <Form.Item name="description" label="Deskripsi" validate={validate}>
                <Input name="description" placeholder="Masukkan Deskripsi" />
              </Form.Item>
              <Divider plain>Base</Divider>
              <Form.Item name="base.type" label="Tipe Base" validate={validate}>
                <Select name="base.type" style={{ width: '100%' }} placeholder="Pilih Tipe Base">
                  <Option value={'NUMBER'}>Angka</Option>
                  <Option value={'PERCENTAGE'}>Persentase</Option>
                  <Option value={'DISABLED'}>Nonaktif</Option>
                  <Option value={'TEXT'}>Teks</Option>
                </Select>
              </Form.Item>
              {renderTarget(values, 'base')}
              <Divider plain>Stretch</Divider>
              <Form.Item name="stretch.type" label="Tipe Stretch" validate={validate}>
                <Select name="stretch.type" style={{ width: '100%' }} placeholder="Pilih Tipe Stretch">
                  <Option value={'NUMBER'}>Angka</Option>
                  <Option value={'PERCENTAGE'}>Persentase</Option>
                  <Option value={'DISABLED'}>Nonaktif</Option>
                  <Option value={'TEXT'}>Teks</Option>
                </Select>
              </Form.Item>
              {renderTarget(values, 'stretch')}
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        </Formik>
      </ContentWithErrorHandler>
    </Modal>
  );
}

export default ManagePerformanceDetail;
