import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, message, Popconfirm, Table } from "antd";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { useDeleteGeneralInfoMutation, useGetGeneralInfoByServiceTypeQuery } from "./generalInfoAPI";

function GeneralInfoServiceInfo() {
  const navigate = useNavigate();
  const { serviceType } = useParams();

  const { data, error, isLoading } = useGetGeneralInfoByServiceTypeQuery(serviceType as string, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const [deleteGeneralInfo] = useDeleteGeneralInfoMutation();

  const intersetContent = serviceType == "loan" ? "perbulan" : "pertahun";
  const serviceTypeContent = serviceType == "loan" ? "Pinjaman" : "Simpanan";

  const renderByType = () => {
    if (serviceType == "loan" || serviceType == "saving") {
      return (
        <>
          <Card
            title={`Jenis ${serviceTypeContent}`}
            size="small"
            style={{ marginBottom: 15 }}
            extra={
              <div>
                <Button style={{ borderRadius: 5 }}>
                  <Link to="type-create">+ Tambah Jenis</Link>
                </Button>
              </div>
            }
          >
            <Table
              loading={isLoading}
              dataSource={data?.itemMap.type}
              bordered
              size="small"
              pagination={{ pageSize: 10 }}
              columns={[
                {
                  title: "Urutan",
                  dataIndex: "viewOrder",
                  key: "viewOrder",
                  width: "5%",
                },
                {
                  title: "Nama " + serviceTypeContent,
                  dataIndex: "title",
                  key: "title",
                  width: "15%",
                },
                {
                  title: "Ikon",
                  dataIndex: "imageUrl",
                  key: "imageUrl",
                  width: "20%",
                  render: (text, record) => <img src={record.imageUrl} width="60px" />,
                },
                {
                  title: "Deskripsi",
                  dataIndex: "content",
                  key: "content",
                  width: "40%",
                  render: (text, record) => <div dangerouslySetInnerHTML={{ __html: record.content }} />,
                },
                {
                  title: "Bunga " + intersetContent,
                  dataIndex: "interest",
                  key: "interest",
                  width: "10%",
                  render: (text, record) => record.interest + " %",
                },
                {
                  title: "Aksi",
                  key: "x",
                  width: "10%",
                  render: (text, record) => (
                    <div>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        style={{ borderRadius: 5 }}
                        onClick={() => navigate(`type-detail/${record.id[0]}`)}
                      />
                      <Popconfirm
                        title="Apakah anda yakin?"
                        onConfirm={() => {
                          const hideLoading = message.loading("Loading", 0);
                          deleteGeneralInfo(record.id[0])
                            .unwrap()
                            .then(() => {
                              hideLoading();
                              message.success("Berhasil!");
                            })
                            .catch(() => {
                              hideLoading();
                              message.error("Gagal!");
                            });
                        }}
                        okText="Hapus"
                        cancelText="Batal"
                      >
                        <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                      </Popconfirm>
                    </div>
                  ),
                },
              ]}
            />
          </Card>

          <Card
            title={`Tahapan ${serviceTypeContent}`}
            size="small"
            extra={
              <div>
                <Button style={{ borderRadius: 5 }}>
                  <Link to="step-create">+ Tambah Tahapan</Link>
                </Button>
              </div>
            }
          >
            <Table
              loading={isLoading}
              dataSource={data?.itemMap.step}
              bordered
              size="small"
              pagination={{ pageSize: 10 }}
              columns={[
                {
                  title: "Urutan",
                  dataIndex: "viewOrder",
                  key: "viewOrder",
                  width: "10%",
                },
                {
                  title: "Nama Tahapan",
                  dataIndex: "title",
                  key: "title",
                  width: "20%",
                },
                {
                  title: "Deskripsi",
                  dataIndex: "content",
                  key: "content",
                  width: "60%",
                  render: (text, record) => <div dangerouslySetInnerHTML={{ __html: record.content }} />,
                },
                {
                  title: "Aksi",
                  key: "x",
                  width: "10%",
                  render: (text, record) => (
                    <div>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        style={{ borderRadius: 5 }}
                        onClick={() => navigate(`step-detail/${record.id}`)}
                      />
                      <Popconfirm
                        title="Apakah anda yakin?"
                        onConfirm={() => {
                          const hideLoading = message.loading("Loading", 0);
                          deleteGeneralInfo(record.id[0])
                            .unwrap()
                            .then(() => {
                              hideLoading();
                              message.success("Berhasil!");
                            })
                            .catch(() => {
                              hideLoading();
                              message.error("Gagal!");
                            });
                        }}
                        okText="Hapus"
                        cancelText="Batal"
                      >
                        <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                      </Popconfirm>
                    </div>
                  ),
                },
              ]}
            />
          </Card>
        </>
      );
    } else {
      return (
        <Card
          title={`Jenis ${serviceTypeContent}`}
          size="small"
          style={{ marginBottom: 15 }}
          extra={
            <div>
              <Button style={{ borderRadius: 5 }}>
                <Link to="item-create">+ Tambah Jenis</Link>
              </Button>
            </div>
          }
        >
          <Table
            loading={isLoading}
            dataSource={data?.itemMap.item}
            bordered
            size="small"
            pagination={{ pageSize: 10 }}
            columns={[
              {
                title: "Urutan",
                dataIndex: "viewOrder",
                key: "viewOrder",
                width: "5%",
              },
              {
                title: "Jenis Layanan",
                dataIndex: "title",
                key: "title",
                width: "15%",
              },
              {
                title: "Syarat & Ketentuan",
                dataIndex: "prerequisite",
                key: "prerequisite",
                width: "40%",
                render: (text, record) => <div dangerouslySetInnerHTML={{ __html: record.prerequisite }} />,
              },
              {
                title: "Bentuk Santunan",
                dataIndex: "content",
                key: "content",
                width: "40%",
                render: (text, record) => <div dangerouslySetInnerHTML={{ __html: record.content }} />,
              },
              {
                title: "Aksi",
                key: "x",
                width: "5%",
                render: (text, record) => (
                  <div>
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      style={{ borderRadius: 5 }}
                      onClick={() => navigate(`item-detail/${record.id.join(",")}`)}
                    />
                    <Popconfirm
                      title="Apakah anda yakin?"
                      onConfirm={() => {
                        const hideLoading = message.loading("Loading", 0);
                        let successState = true;
                        for (let id of record.id) {
                          deleteGeneralInfo(id)
                            .unwrap()
                            .then(() => {
                              successState = successState && true;
                            })
                            .catch(() => {
                              successState = successState && false;
                            });
                        }

                        hideLoading();
                        if (successState) {
                          message.success("Berhasil!");
                        } else {
                          message.error("Gagal!");
                        }
                      }}
                      okText="Hapus"
                      cancelText="Batal"
                    >
                      <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                    </Popconfirm>
                  </div>
                ),
              },
            ]}
          />
        </Card>
      );
    }
  };

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Card
        title="Tajuk"
        size="small"
        style={{ marginBottom: 15 }}
        extra={
          <div>
            <Button style={{ borderRadius: 5, marginTop: 5 }}>
              <Link to={`/service/${serviceType}/master`}>Sunting</Link>
            </Button>
          </div>
        }
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data?.itemMap.master[0].content || "",
          }}
        ></div>
      </Card>

      {renderByType()}
    </ContentWithErrorHandler>
  );
}

export default GeneralInfoServiceInfo;
