import { message } from "antd";
import { Formik } from "formik";
import { Checkbox, Form, Input, InputNumber, Select, SubmitButton } from "formik-antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { useCreateAdminMutation, useGetAdminQuery, useGetAllAdminPositionQuery, useUpdateAdminMutation } from "./adminAPI";

const { Option } = Select;

interface InitialData {
  username: string;
  name: string;
  email: string;
  fullKpiBonus: number;
  sicundoUsername: string;
  positionId: number;
  accessList: any;
}

const CRUD = ["create", "read", "update", "delete"];
const ACCESS_MAPPING = {
  superadmin: "Super Administrator",

  //operational section
  op_config: "Menu Konfigurasi Umum",
  op_memo: "Menu Slip Memo",
  op_user: "Menu Pengaturan Pengguna",
  op_member: "Menu Pengaturan member koperasi",
  op_loan: "Menu Pinjaman",
  op_kpi_user: "Menu KPI Pengguna",
  op_kpi_manager: "Menu Pengaturan KPI",

  //web section
  web_general_info: "Menu Layanan Web (Visi, Misi, Sejarah, Layanan, Struktur)",
  web_publication: "Menu Berita, Galeri, Dokumen",
  web_contact: "Menu Kontak",

  //bdc section
  bdc_fl_questionaire: "Menu Kuisioner Financial Literacy",
};

function AdminDetail() {
  const { id } = useParams();

  const [accessListOption, setAccessListOption] = useState<any>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [initialData, setInitialData] = useState<InitialData>({
    username: "",
    name: "",
    email: "",
    fullKpiBonus: 0,
    sicundoUsername: "",
    positionId: 1,
    accessList: {},
  });

  const { data: adminPositions } = useGetAllAdminPositionQuery();
  const { data: adminData, isLoading: adminDataIsLoading, isError: adminDataIsError } = useGetAdminQuery(id || "", {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const [updateAdmin] = useUpdateAdminMutation();
  const [createAdmin] = useCreateAdminMutation();

  useEffect(() => {
    let accessObject: any = Object.keys(ACCESS_MAPPING).map((e) => {
      return {
        key: e,
        option:
          e == "superadmin"
            ? [
                {
                  value: "superadmin",
                  label: "SUPERADMIN",
                },
              ]
            : CRUD.map((x) => {
                return { value: `${e}-${x}`, label: x.toUpperCase() };
              }),
      };
    });
    setAccessListOption(accessObject);
  }, []);

  useEffect(() => {
    if (adminData) {
      let accessGroup = [...new Set(adminData.accessList.map((e) => e.split("-")[0]))];
      let accessObject = {};
      for (let group of accessGroup) {
        accessObject[group] = adminData.accessList.filter((x) => x.includes(group));
      }
      setInitialData({
        username: adminData.username,
        name: adminData.name,
        email: adminData.email,
        fullKpiBonus: adminData.fullKpiBonus,
        sicundoUsername: adminData.sicundoUsername,
        positionId: adminData.positionId,
        accessList: accessObject,
      });
      // setFileList(
      //   signature.coverPhotoUrl != null
      //     ? [
      //         {
      //           uid: "-1",
      //           name: galleryData.title,
      //           status: "done",
      //           url: galleryData.coverPhotoUrl,
      //         },
      //       ]
      //     : []
      // );
    }
  }, [adminData]);

  const validate = (value) => {
    let error;
    if (!value) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  const save = async (value: InitialData, closeLoading: any) => {
    let accessList = [];
    for (let key of Object.keys(value.accessList)) {
      accessList = accessList.concat(value.accessList[key]);
    }

    let data: any = { ...value };
    data.accessList = accessList;

    let promise;
    if (id) {
      data.id = id;
      promise = updateAdmin(data);
    } else {
      promise = createAdmin(data);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success("Berhasil!");
      })
      .catch(() => {
        closeLoading();
        message.error("Gagal!");
      });
  };

  return (
    <div>
      <ContentWithErrorHandler isError={adminDataIsError} isLoading={adminDataIsLoading} withBox={true}>
        <Formik
          initialValues={initialData}
          onSubmit={async (values, actions) => {
            const hide = message.loading("Loading", 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              <Form.Item name="username" label="Username" validate={validate}>
                <Input name="username" placeholder="Masukkan username" />
              </Form.Item>
              <Form.Item name="name" label="Nama" validate={validate}>
                <Input name="name" placeholder="Masukkan Nama" />
              </Form.Item>
              <Form.Item name="positionId" label="Posisi">
                <Select name="positionId" placeholder="Masukkan kategori">
                  {adminPositions?.map((position) => (
                    <Option key={position.id} value={position.id}>
                      {position.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="email" label="Email" validate={validate}>
                <Input name="email" placeholder="Masukkan email" />
              </Form.Item>
              <Form.Item name="sicundoUsername" label="Username Sicundo" validate={validate}>
                <Input name="sicundoUsername" placeholder="Masukkan username sicundo" />
              </Form.Item>
              <Form.Item name="fullKpiBonus" label="Nominal Full KPI" validate={validate}>
                <InputNumber name="fullKpiBonus" placeholder="Masukkan nominal" style={{ width: "100%" }} />
              </Form.Item>
              {accessListOption.map((group, index) => {
                return (
                  <Form.Item name={`accessList[${group.key}]`} label={ACCESS_MAPPING[group.key]}>
                    <Checkbox.Group name={`accessList[${group.key}]`} options={[...group.option]} />
                  </Form.Item>
                );
              })}
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default AdminDetail;
