import { Space, Statistic, Table } from "antd";
import moment from "moment";
import React from "react";
import { TransactionRecordPerCategory } from "./types";

type Props = {
  data: TransactionRecordPerCategory | null;
  isPerCategory: boolean;
};

function TransactionReportCategoryItem(props: Props) {
  const columns = [
    {
      title: "Nomor Memo",
      dataIndex: "memoNumber",
      key: "memoNumber",
      width: "20%",
    },
    {
      title: "Tanggal",
      dataIndex: "memoDate",
      key: "memoDate",
      width: "10%",
      sorter: (a, b) => moment(a.memoDate, "YYYY-MM-DD").diff(moment(b.memoDate, "YYYY-MM-DD")),
      render: (text, record) => {
        return <div>{moment(record.memoDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</div>;
      },
    },
    {
      title: "Item",
      key: "memoItem",
      width: "40%",
      render: (text, record) => {
        let copyArray = [...record.memoItem];
        let sorted = copyArray.sort((a, b) => {
          let debitA = parseInt(a.debitAmount.replace(/,/g, "")) ? parseInt(a.debitAmount.replace(/,/g, "")) : 0;
          let debitB = parseInt(b.debitAmount.replace(/,/g, "")) ? parseInt(b.debitAmount.replace(/,/g, "")) : 0;

          let creditA = parseInt(a.creditAmount.replace(/,/g, "")) ? parseInt(a.creditAmount.replace(/,/g, "")) : 0;
          let creditB = parseInt(b.creditAmount.replace(/,/g, "")) ? parseInt(b.creditAmount.replace(/,/g, "")) : 0;

          return creditA - creditB || debitA - debitB;
        });

        return sorted.map((elem) => {
          if (parseInt(elem.debitAmount) > 0) {
            return (
              <div style={{ textAlign: "left", color: "darkgreen" }}>
                <div>{elem.debitAccount}</div>
                <div style={{ fontStyle: "italic", fontWeight: "bold" }}>Rp {elem.debitAmount}</div>
              </div>
            );
          } else {
            return (
              <div style={{ textAlign: "right", color: "darkred" }}>
                <div>{elem.creditAccount}</div>
                <div style={{ fontStyle: "italic", fontWeight: "bold" }}>Rp {elem.creditAmount}</div>
              </div>
            );
          }
        });
      },
    },
    {
      title: "Penerima",
      dataIndex: "recipientName",
      key: "recipientName",
      width: "20%",
    },
    {
      title: "Operator",
      dataIndex: "admin",
      key: "admin",
      width: "10%",
      onFilter: (value, record) => record.user.includes(value),
    },
  ];

  const getColumn = () => {
    var resultColumn: any = [];
    for (var col of columns) {
      if (!props.isPerCategory && col.title != "Operator") {
        resultColumn.push(col);
      } else if (props.isPerCategory) {
        resultColumn.push(col);
      }
    }
    return resultColumn;
  };

  return (
    <div>
      <Space style={{ marginBottom: 15 }} wrap size={"large"}>
        <Statistic
          title="Total Transaksi"
          loading={!props.data}
          value={props.data?.numOfTransaction || "-"}
          suffix={<div style={{ fontSize: 16, color: "#6c757d" }}>transaksi</div>}
        />
        <Statistic title="Total Debit" loading={!props.data} value={props.data?.debit || "-"} prefix="Rp" />
        <Statistic title="Total Kredit" loading={!props.data} value={props.data?.credit || "-"} prefix="Rp" />
      </Space>
      <Table dataSource={props.data?.dataList} columns={getColumn()} size="small"></Table>
    </div>
  );
}

export default TransactionReportCategoryItem;
