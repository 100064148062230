import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Collapse, List, Popconfirm, Table, Tag, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { TableColumnTextFilterConfig, basicSortDateString } from '../../common/TableHelper';
import { useDeleteQuizRecordMutation, useGetAllQuizRecordQuery, useGetQuizMonthlyReportQuery } from './quizAPI';
import { QuizRecord } from './types';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

function QuizRecordList() {
  const navigate = useNavigate();
  const { data, error, isFetching } = useGetAllQuizRecordQuery();
  const { data: monthlyData, error: monthlyDataError, isFetching: monthlyDataFetching } = useGetQuizMonthlyReportQuery();

  const [deleteQuizRecord] = useDeleteQuizRecordMutation();
  const [adminNameList, setAdminNameList] = useState<ColumnFilterItem[]>([]);

  useEffect(() => {
    if (data) {
      setAdminNameList(
        [...new Set(data.map((e) => e.admin.name))].map((v) => {
          return {
            text: v,
            value: v,
          };
        }),
      );
    }
  }, [data]);

  const getDate = (stringDate) => {
    return new Date(stringDate).toLocaleDateString('id-ID');
  };

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Collapse>
        <CollapsePanel key="1" header="Data Bulanan" style={{ marginBottom: 12 }}>
          <Table
            loading={monthlyDataFetching}
            dataSource={monthlyData}
            bordered
            size="small"
            pagination={{ pageSize: 1 }}
            columns={[
              {
                title: 'Bulan',
                dataIndex: 'month',
                key: 'month',
                width: '15%',
              },
              {
                title: 'Total Hari',
                dataIndex: 'numberOfDay',
                key: 'numberOfDay',
                width: '10%',
              },
              {
                title: 'Skor',
                width: '90%',
                render: (record) => {
                  let perAdmin = record.admins.map((e) => {
                    return (
                      <>
                        {e.name}: <b>{e.numCorrect}</b>
                      </>
                    );
                  });

                  return (
                    <ul style={{ margin: 0, paddingLeft: 12 }}>
                      {perAdmin.map((e) => (
                        <li>{e}</li>
                      ))}
                    </ul>
                  );
                },
              },
            ]}
          />
        </CollapsePanel>
      </Collapse>
      <Card title="Data Jawaban Quiz" size="small">
        <Table
          loading={isFetching}
          dataSource={data}
          bordered
          size="small"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              title: 'Tanggal',
              dataIndex: 'createdAt',
              key: 'createdAt',
              width: '10%',
              sorter: (a, b) => basicSortDateString(a.createdAt, b.createdAt),
              render: (createdAt) => {
                return <> {getDate(createdAt)} </>;
              },
            },
            {
              title: 'Nama',
              dataIndex: ['admin', 'name'],
              key: 'admin.name',
              width: '20%',
              filters: adminNameList,
              onFilter: (value, record) => record.admin.name == value,
            },
            {
              title: 'Hasil',
              dataIndex: 'isCorrect',
              key: 'isCorrect',
              width: '10%',
              filters: [
                { text: 'Benar', value: true },
                { text: 'Salah', value: false },
              ],
              onFilter: (value, record) => record.isCorrect == value,
              render: (item) => {
                if (!item) {
                  return <Tag color="red">Salah</Tag>;
                } else {
                  return <Tag color="forestgreen">Benar</Tag>;
                }
              },
            },
            {
              title: 'Pertanyaan',
              dataIndex: ['questionSnapshot', 'question'],
              key: 'questionSnapshot.question',
              width: '25%',
              ...TableColumnTextFilterConfig<QuizRecord>(['questionSnapshot', 'question']),
            },
            {
              title: 'Jawaban',
              dataIndex: ['questionSnapshot', 'answers'],
              key: 'questionSnapshot.answers',
              width: '30%',
              render: (answers) => {
                return (
                  <>
                    {answers?.map((e, i) => (
                      <List.Item
                        key={e.text}
                        style={{
                          border: `${e.isCorrectAnswer ? '3px forestgreen' : '1px lightgray'} solid`,
                          paddingLeft: '6px',
                          paddingRight: '6px',
                          marginBottom: '6px',
                          background: e.isSelected ? 'paleturquoise' : 'transparent',
                        }}
                      >
                        <span style={{ fontWeight: 'bold' }}>{i + 1}.</span>
                        {' ' + e.text}
                      </List.Item>
                    ))}
                  </>
                );
              },
            },
            {
              title: 'Aksi',
              key: 'x',
              width: '10%',
              render: (text, record) => (
                <div>
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading('Loading', 0);
                      deleteQuizRecord(record.id)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success('Berhasil!');
                        })
                        .catch(() => {
                          hideLoading();
                          message.error('Gagal!');
                        });
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
    </ContentWithErrorHandler>
  );
}

export default QuizRecordList;
