import { Form, Input, List, Modal, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { useGetQuestionaireResultQuery } from './financialLiteracyAPI';
import { QuestionaireImpact, QuestionaireSnapshot } from './types';

const DATE_FORMAT = 'DD/MM/YYYY';

function FinancialLiteracyQuestionResultDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [totalImpact, setTotalImpact] = useState<QuestionaireImpact>({
    saving: 0,
    debt: 0,
    expense: 0,
  });

  const { data, isLoading, isError } = useGetQuestionaireResultQuery(id || '', {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (data) {
      let totalSaving = 0;
      let totalExpense = 0;
      let totalDebt = 0;

      for (let datum of data?.data) {
        for (let index = 0; index < datum.answers.length; index++) {
          if (index == datum.choosedAnswer) {
            totalSaving += isNaN(datum.answers[index].impact.saving) ? 0 : datum.answers[index].impact.saving;
            totalExpense += isNaN(datum.answers[index].impact.expense) ? 0 : datum.answers[index].impact.expense;
            totalDebt += isNaN(datum.answers[index].impact.debt) ? 0 : datum.answers[index].impact.debt;
          }
        }
      }
      setTotalImpact({
        saving: totalSaving,
        debt: totalDebt,
        expense: totalExpense,
      });
    }
  }, [data]);

  return (
    <Modal
      title={`Detail Hasil Kuisioner`}
      width={1000}
      visible={true}
      destroyOnClose={true}
      onCancel={() => navigate('/fl/questionaire/result')}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
    >
      <ContentWithErrorHandler isError={isError} isLoading={isLoading} withBox={false}>
        <List style={{ fontSize: '14px' }}>
          <List.Item style={{ padding: 3 }}>Nama Anggota : {data?.member?.name}</List.Item>
          <List.Item style={{ padding: 3 }}>Nomor Anggota : {data?.member?.memberId}</List.Item>
          <List.Item style={{ padding: 3 }}>Penghasilan Anggota : {data?.member?.income}</List.Item>
          <List.Item style={{ padding: 3 }}>Tanggal : {data ? moment.utc(data?.updatedAt).format('DD-MM-YYYY HH:mm') : '-'}</List.Item>
          <List.Item style={{ padding: 3 }}>
            Total Nilai : Simpanan ({totalImpact.saving}), Pengeluaran ({totalImpact.expense}), Hutang ({totalImpact.debt})
          </List.Item>
          <List.Item style={{ padding: 3 }}>
            Saran:
            <ul>
              {data?.suggestionMessage.map((e) => (
                <li>{e}</li>
              ))}
            </ul>
          </List.Item>
        </List>

        <Table
          loading={isLoading}
          dataSource={data?.data || []}
          bordered
          size="small"
          pagination={{ pageSize: 100 }}
          columns={[
            {
              title: 'Pertanyaan',
              dataIndex: 'question',
              key: 'question',
              width: '40%',
            },
            {
              title: 'Jawaban',
              key: 'answers',
              width: '40%',
              render: (text, record: QuestionaireSnapshot) => {
                return (
                  <>
                    {record.answers.map((e, i) => (
                      <List.Item
                        key={e.text}
                        style={{
                          border: i == record.choosedAnswer ? 'black 1px solid' : 'lightgray 0.2px solid',
                          background: i == record.choosedAnswer ? 'lightgreen' : 'none',
                          paddingLeft: '6px',
                          paddingRight: '6px',
                          marginBottom: '6px',
                        }}
                      >
                        <span style={{ fontWeight: i == record.choosedAnswer ? 'bold' : 'normal' }}>{i + 1 + '. ' + e.text}</span>
                      </List.Item>
                    ))}
                  </>
                );
              },
            },
            {
              title: 'Nilai',
              children: [
                {
                  title: 'Simpanan',
                  key: 'impact.saving',
                  width: '5%',
                  render: (text, record: QuestionaireSnapshot) => record.answers[record.choosedAnswer]?.impact?.saving,
                },
                {
                  title: 'Pengeluaran',
                  key: 'impact.expense',
                  width: '5%',
                  render: (text, record: QuestionaireSnapshot) => record.answers[record.choosedAnswer]?.impact?.expense,
                },
                {
                  title: 'Hutang',
                  key: 'impact.debt',
                  width: '5%',
                  render: (text, record: QuestionaireSnapshot) => record.answers[record.choosedAnswer]?.impact?.debt,
                },
              ],
            },
          ]}
        />
      </ContentWithErrorHandler>
    </Modal>
  );
}

export default FinancialLiteracyQuestionResultDetail;
