import firebase from "firebase/compat/app";
import "firebase/compat/database";

export const user = {
  username: "m-care@creditunionmadani.org",
  password: "XXxSS6KLyt8Sv9br",
};

export const config = {
  apiKey: "AIzaSyD_x6HquCi0jdPPUhvWDdU9nE9Pqhhp_G0",
  authDomain: "web-madani.firebaseapp.com",
  databaseURL: "https://web-madani.firebaseio.com",
  projectId: "web-madani",
  storageBucket: "web-madani.appspot.com",
  messagingSenderId: "35031621090",
  appId: "1:35031621090:web:79dc72e9293bb56fa0edbb",
  measurementId: "G-S6NQSNYRYX",
};

const app = firebase.initializeApp(config);
export const db = firebase.database(app);

export const todayCountRef = db.ref(process.env.NODE_ENV + "/queue/today");
export const queueListRef = db.ref(process.env.NODE_ENV + "/queue/list");
