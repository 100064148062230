import { FirebaseAuthConsumer, FirebaseAuthProvider, IfFirebaseAuthed } from "@react-firebase/auth";
import { Button, Col, Divider, Layout, message, Row, Spin } from "antd";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { config, queueListRef, todayCountRef, user } from "../../common/firebase";

const { Content } = Layout;

const queueType = [
  { type: "teller", name: "Tarik/Setor", code: "T" },
  { type: "loan", name: "Pengajuan Pinjaman", code: "P" },
  { type: "administration", name: "Administrasi Anggota", code: "A" },
  { type: "other", name: "Lainnya", code: "L" },
];
function QueueProducer(props) {
  const [currentState, setCurrentState] = useState<any>([]);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isPushQueueLoading, setIsPushQueueLoading] = useState(false);

  useEffect(() => {
    if (!isSignedIn)
      firebase
        .auth()
        .signInWithEmailAndPassword(user.username, user.password)
        .then(() => {
          setIsSignedIn(true);
        })
        .catch((error) => {
          message.error("Autentikasi gagal");
        });
  }, [isSignedIn]);

  useEffect(() => {
    todayCountRef.on(
      "value",
      (snapshot) => {
        if (snapshot.val() != null) {
          var current: any[] = [];
          for (var key in snapshot.val()) {
            var queuePerType = snapshot.val()[key];
            if (queuePerType.hasOwnProperty("counters")) {
              for (var elementKey in queuePerType.counters) {
                if (queuePerType.counters[elementKey]) {
                  current.push({
                    name: queuePerType.counters[elementKey].name,
                    operator: queuePerType.counters[elementKey].operator,
                    lastQueue: queuePerType.counters[elementKey].lastQueue,
                    isOpen: queuePerType.counters[elementKey].isOpen,
                  });
                }
              }
            }
          }
          setCurrentState(current);
        }
      },
      (errorObject) => {
        console.log("The read failed: " + errorObject.name);
      }
    );
  }, []);

  const pushToQueue = (e, key) => {
    e.preventDefault();
    setIsPushQueueLoading(true);
    todayCountRef.child(key).transaction(
      (currentData) => {
        var total = 0;
        if (currentData != null && currentData.total != null) {
          total = currentData.total;
        }
        return { ...currentData, total: total + 1 };
      },
      function (error, committed, snapshot) {
        if (error || !committed) {
          message.error("Terjadi kesalahan");
          console.log("Something went wrong: " + error);
        } else {
          queueListRef.child(key).push({
            queueTimestamp: moment().unix(),
            queueNumber: snapshot?.val().total,
          });
        }
        setIsPushQueueLoading(false);
      }
    );
  };

  return (
    <FirebaseAuthProvider {...config} firebase={firebase}>
      <FirebaseAuthConsumer>
        {({ isSignedIn, user, providerId }) => {
          if (!isSignedIn) {
            return <Spin size="large" />;
          }
          return;
        }}
      </FirebaseAuthConsumer>
      <IfFirebaseAuthed>
        {() => (
          <Content style={{ padding: "10px 10px" }} className="queue">
            <Row gutter={[12, 36]} align="middle" style={{ display: "flex" }}>
              {queueType.map((elem) => {
                return (
                  <Col span={12} style={{ display: "inline-flex", alignSelf: "stretch" }}>
                    <Button
                      size="large"
                      block
                      loading={isPushQueueLoading}
                      style={{ height: "150%", fontSize: "1.5em", whiteSpace: "normal" }}
                      onClick={(e) => pushToQueue(e, elem.type)}
                    >
                      {elem.name}
                    </Button>
                  </Col>
                );
              })}
            </Row>
            <Divider style={{ marginTop: "36px" }}>ANTRIAN SAAT INI</Divider>
            <Row gutter={[12, 24]} align="middle" style={{ display: "flex" }}>
              {currentState.map((elem) => {
                return (
                  <Col span={8} style={{ display: "inline-flex", alignSelf: "stretch" }}>
                    <div style={{ background: "white", textAlign: "center", width: "100%", padding: "10px" }}>
                      <div style={{ fontSize: "1.3em" }}>{elem.name}</div>
                      <div>{elem.operator}</div>
                      <Divider style={{ marginTop: 0, marginBottom: 6 }} />
                      <div style={{ fontSize: "2em" }}>{elem.isOpen ? (elem.lastQueue ? elem.lastQueue : "-") : "Tutup"}</div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Content>
        )}
      </IfFirebaseAuthed>
    </FirebaseAuthProvider>
  );
}

export default QueueProducer;
