import { useNavigate, useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../../app/ContentWithErrorHandler";

import { Button, Col, message, Modal, Row, Tabs } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Formik } from "formik";
import { Form } from "formik-antd";
import { useEffect, useState } from "react";
import { useGetAllAdminQuery } from "../../admin/adminAPI";
import { useGetAllMemberQuery, useGetMemberQuery } from "../../member/membersAPI";
import {
  useGetFiveCBusinessAssessmentQuery,
  useGetFiveCWorkAssessmentQuery,
  useGetPersonalAssessmentQuery,
} from '../submissionConfiguration/submissionConfigurationAPI';
import AssessmentForm from './AssessmentForm';
import BiodataForm from './BiodataForm';
import CollateralDataForm from './CollateralForm';
import DebtDataForm from './DebtDataForm';
import IncomeDataForm from './IncomeDataForm';
import InterviewForm from './InterviewForm';
import { useCreateLoanSubmissionMutation, useGetLoanSubmissionQuery, useUpdateLoanSubmissionMutation } from './loanSubmissionAPI';
import SubmissionDataForm from './SubmissionDataForm';
import SummarySection from './SummarySection';
import { FiveCType, HomeOwnershipStatus, SubmissionForm } from './types';
import CfoAnalysisForm from './CfoAnalysisForm';
import moment from 'moment';
import CeoAnalysisForm from './CeoAnalysisForm';

const { TabPane } = Tabs;

function LoanSubmissionDetail() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [selectedMemberId, setSelectedMemberId] = useState<string>();
  const [applicantSignature, setApplicantSignature] = useState<any>([]);
  const [relativeSignature, setRelativeSignature] = useState<any>([]);
  const [referenceSignature, setReferenceSignature] = useState<any>([]);
  const [ceoUpload, setCeoUpload] = useState<any>([]);
  const [updateNotes, setUpdateNotes] = useState('');
  const [updateDataModal, setUpdateDataModal] = useState(false);

  const { data: adminListData, isFetching: adminListDataLoading, isError: adminListDataError } = useGetAllAdminQuery();
  const { data: memberListData, isFetching: memberListDataIsLoading, isError: memberListDataIsError } = useGetAllMemberQuery(true);
  const {
    data: selectedMember,
    isFetching: memberDataIsLoading,
    isError: memberDataIsError,
  } = useGetMemberQuery(selectedMemberId || '', {
    skip: selectedMemberId ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });
  const { data: personalConfigData, error: personalConfigError, isFetching: personalConfigFetch } = useGetPersonalAssessmentQuery();
  const { data: fiveCWorkConfigData, error: fiveCWorkConfigError, isFetching: fiveCWorkConfigFetch } = useGetFiveCWorkAssessmentQuery();
  const {
    data: fiveCBusinessConfigData,
    error: fiveCBusinessConfigError,
    isFetching: fiveCBusinessConfigFetch,
  } = useGetFiveCBusinessAssessmentQuery();
  const {
    data: fetchedSubmissionData,
    isLoading: submissionDataLoading,
    isError: submissionDataError,
  } = useGetLoanSubmissionQuery(id || '', {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const [create] = useCreateLoanSubmissionMutation();
  const [update] = useUpdateLoanSubmissionMutation();

  const [submissionData, setSubmissionData] = useState<SubmissionForm>({
    biodata: {
      memberId: '',
      memberName: '',
      phones: [],
      address: '',
      nickname: '',
      homeOwnershipStatus: HomeOwnershipStatus.self_owned,
      dependents: 0,
      relatives: [],
      groups: [],
      signature: null,
      reference: {
        type: '',
        name: '',
        memberId: '',
        signature: null,
      },
      family: {
        type: '',
        name: '',
        memberId: '',
        signature: null,
      },
    },
    submissionData: {
      installment: 0,
      installmentType: '',
      amount: 0,
      purpose: '',
      dueDate: 0,
      notes: '',
    },
    collateralData: {
      savings: [],
      totalParentsSaving: 0,
      totalRelativesSaving: 0,
      totalChildrenSaving: 0,
      totalSpouseSaving: 0,
      totalGroupSaving: 0,
      assets: [],
    },
    debtData: {
      bankLoan: 0,
      otherCoopLoan: 0,
      friendLoan: 0,
      dealerLoan: 0,
      arisan: 0,
    },
    incomeData: {
      estimation: {
        incomePerMonth: 0,
        otherIncomePerMonth: 0,
        expensesPerMonth: 0,
      },
      business: {
        startAt: '',
        name: '',
        type: '',
        item: [],
      },
      job: {
        startAt: '',
        name: '',
        position: '',
        item: [],
      },
    },
    assessment: {
      personal: [],
      fiveCWork: [],
      fiveCBusiness: [],
    },
    cfoAnalysisForm: {
      analysisDate: moment().format('DD/MM/YYYY'),
      consumption: {
        goalFl: 60,
        notes: '',
      },
      loanRepayment: {
        goalFl: 25,
        notes: '',
        repaymentSource: 'family',
        isCapable: false,
      },
      savingAbility: {
        goalFl: 15,
        lastThreeMonth: 0,
        notes: '',
        savingType: 'personal',
      },
      others: {
        badRecord: false,
        badRecordNotes: '',
        notes: '',
        socialRelationCollateral: false,
        familySignature: false,
        addressOnGmaps: false,
        validation: false,
        referenceSignature: false,
        basicCoopEducationScore: 0,
        financialLiteracy: false,
        praRat: false,
      },
      twtjLastLoan: {
        count: 0,
        notes: '',
      },
    },
    ceoAnalysisForm: {
      notes: '',
      upload: null,
    },
    interviewAgreement: {
      items: [],
      peopleInvolved: [],
    },
    verificationData: {
      checkedAt: null,
      checkedBy: null,
      verifiedAt: null,
      verifiedBy: null,
    },
    loanAgreementDocument: null,
  });

  useEffect(() => {
    if (selectedMember != null && selectedMember.id == Number(selectedMemberId)) {
      setSubmissionData({
        ...submissionData,
        biodata: {
          ...submissionData.biodata,
          memberId: selectedMember.memberId,
          memberName: selectedMember.name,
          phones: selectedMember.phone,
        },
        collateralData: {
          ...submissionData.collateralData,
          savings: selectedMember.savings,
        },
      });
    }
  }, [selectedMember]);

  useEffect(() => {
    if (fetchedSubmissionData) {
      setSubmissionData({ ...fetchedSubmissionData.data });
      setSelectedMemberId(fetchedSubmissionData.member.id);
      setApplicantSignature(
        fetchedSubmissionData.data.biodata.signature != null
          ? [
              {
                uid: '-1',
                name: 'Tanda tangan pengaju pinjaman',
                status: 'done',
                url: fetchedSubmissionData.data.biodata.signature,
              },
            ]
          : [],
      );
      setReferenceSignature(
        fetchedSubmissionData.data.biodata.reference.signature != null
          ? [
              {
                uid: '-1',
                name: 'Tanda tangan referensi',
                status: 'done',
                url: fetchedSubmissionData.data.biodata.reference.signature,
              },
            ]
          : [],
      );
      setRelativeSignature(
        fetchedSubmissionData.data.biodata.family.signature != null
          ? [
              {
                uid: '-1',
                name: 'Tanda tangan suami/istri/keluarga',
                status: 'done',
                url: fetchedSubmissionData.data.biodata.family.signature,
              },
            ]
          : [],
      );
      setCeoUpload(
        fetchedSubmissionData?.data?.ceoAnalysisForm?.upload != null
          ? [
              {
                uid: '-1',
                name: 'Berkas Upload CEO',
                status: 'done',
                url: fetchedSubmissionData.data.ceoAnalysisForm.upload,
              },
            ]
          : [],
      );
    }
  }, [fetchedSubmissionData]);

  useEffect(() => {
    if (fiveCWorkConfigData != null && personalConfigData != null && fiveCBusinessConfigData != null && id == null) {
      setSubmissionData({
        ...submissionData,
        assessment: {
          personal: personalConfigData.map((e) => {
            return {
              id: e.id,
              indicator: e.indicator,
              eligible: false,
            };
          }),
          fiveCWork: fiveCWorkConfigData.map((e) => {
            return {
              id: e.id,
              indicator: e.indicator,
              description: e.descriptions,
              score: 0,
              type: FiveCType[e.type],
              allowedUser: e.allowedUser,
            };
          }),
          fiveCBusiness: fiveCBusinessConfigData.map((e) => {
            return {
              id: e.id,
              indicator: e.indicator,
              description: e.descriptions,
              score: 0,
              type: FiveCType[e.type],
              allowedUser: e.allowedUser,
            };
          }),
        },
      });
    }
  }, [fiveCWorkConfigData, personalConfigData, fiveCBusinessConfigData]);

  const handleUploadApplicantSignature = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setApplicantSignature(fileList);
  };

  const handleUploadRelativeSignature = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setRelativeSignature(fileList);
  };

  const handleUploadReferenceSignature = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setReferenceSignature(fileList);
  };

  const handleCeoUpload = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setCeoUpload(fileList);
  };

  const save = async (value: SubmissionForm, closeLoading: any) => {
    const formData = new FormData();
    await applicantSignature.map((item) => {
      if (item.originFileObj) {
        formData.append('applicantSignatureFile', item.originFileObj);
      }
    });
    await relativeSignature.map((item) => {
      if (item.originFileObj) {
        formData.append('relativeSignatureFile', item.originFileObj);
      }
    });
    await referenceSignature.map((item) => {
      if (item.originFileObj) {
        formData.append('referenceSignatureFile', item.originFileObj);
      }
    });
    await ceoUpload.map((item) => {
      if (item.originFileObj) {
        formData.append('ceoUploadFile', item.originFileObj);
      }
    });

    if (selectedMemberId == null || selectedMemberId == undefined) {
      closeLoading();
      message.error('Pilih anggota terlebih dahulu');
      return;
    }

    formData.append('memberId', selectedMemberId);
    formData.append('data', JSON.stringify(value));

    let promise;
    let isCreate = false;
    if (id) {
      formData.append('id', id);
      formData.append('updateNote', updateNotes);
      promise = update(formData);
    } else {
      promise = create(formData);
      isCreate = true;
    }

    promise
      .unwrap()
      .then((data) => {
        closeLoading();
        message.success('Berhasil!');
        setUpdateDataModal(false);
        if (isCreate) {
          navigate('/loan-submission/data/detail/' + data.id);
        }
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  return (
    <Formik
      initialValues={submissionData}
      onSubmit={async (values, actions) => {
        const hide = message.loading('Loading', 0);
        save(values, hide);
        actions.setSubmitting(false);
      }}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, touched, errors, handleChange, handleBlur, isValid, submitForm, setFieldValue }) => (
        <Form layout="horizontal" labelWrap={true}>
          <Modal title="Ubah data" visible={updateDataModal} onOk={submitForm} onCancel={() => setUpdateDataModal(false)}>
            <TextArea rows={4} value={updateNotes} onChange={(event) => setUpdateNotes(event.target.value)} />
          </Modal>
          <ContentWithErrorHandler show={id != null} isError={false} isLoading={false} withBox={false}>
            <SummarySection wrapper={fetchedSubmissionData} selectedMember={selectedMember} />
          </ContentWithErrorHandler>
          <ContentWithErrorHandler
            isError={memberListDataIsError || memberDataIsError || fiveCWorkConfigError || personalConfigError || adminListDataError}
            isLoading={memberListDataIsLoading || memberDataIsLoading || fiveCWorkConfigFetch || personalConfigFetch || adminListDataLoading}
            withBox={true}
            customErrorMessage="Halaman ini belum selesai. Sedang dalam perbaikan"
          >
            <Tabs defaultActiveKey="1" style={{ marginTop: '-10px', padding: 0 }}>
              <TabPane tab={<span>Data Diri</span>} key="1">
                <BiodataForm
                  memberListData={memberListData}
                  selectedMember={selectedMember}
                  onMemberIdChange={setSelectedMemberId}
                  formValues={values}
                  setFieldValue={setFieldValue}
                  signature={{
                    applicantSignature,
                    setApplicantSignature,
                    handleUploadApplicantSignature,
                    relativeSignature,
                    setRelativeSignature,
                    handleUploadRelativeSignature,
                    referenceSignature,
                    setReferenceSignature,
                    handleUploadReferenceSignature,
                  }}
                />
              </TabPane>
              <TabPane tab={<span>Pengajuan</span>} key="2">
                <SubmissionDataForm />
              </TabPane>
              <TabPane tab={<span>Jaminan</span>} key="3">
                <CollateralDataForm formValue={values} />
              </TabPane>
              <TabPane tab={<span>Hutang</span>} key="4">
                <DebtDataForm selectedMember={selectedMember} />
              </TabPane>
              <TabPane tab={<span>Pendapatan</span>} key="5">
                <IncomeDataForm formValue={values} setFieldValue={setFieldValue} />
              </TabPane>
              <TabPane tab={<span>Asesmen</span>} key="6">
                <AssessmentForm formValue={values} />
              </TabPane>
              <TabPane tab={<span>CFO</span>} key="7">
                <CfoAnalysisForm formValue={values} selectedMember={selectedMember} />
              </TabPane>
              <TabPane tab={<span>CEO</span>} key="8">
                <CeoAnalysisForm
                  formValue={values}
                  setFieldValue={setFieldValue}
                  upload={{
                    ceoUpload,
                    setCeoUpload,
                    handleCeoUpload,
                  }}
                />
              </TabPane>
              <TabPane tab={<span>Wawancara</span>} key="9">
                <InterviewForm formValue={values} admins={adminListData} />
              </TabPane>
            </Tabs>
          </ContentWithErrorHandler>
          <Col>
            <Row justify="end" style={{ marginTop: 20 }}>
              <Button
                type="primary"
                onClick={() => {
                  setUpdateDataModal(true);
                  setUpdateNotes('');
                }}
              >
                Simpan
              </Button>
            </Row>
          </Col>
        </Form>
      )}
    </Formik>
  );
}

export default LoanSubmissionDetail;
