import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, message, Popconfirm, Row, Select, Space, Statistic, Tabs, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import TabPane from 'antd/lib/tabs/TabPane';
import { useEffect, useState } from 'react';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import {
  useApproveExecutionManagerMutation,
  useDeleteExecutionManagerMutation,
  useGetAllAdminQuery,
  useGetKpiExecutionManagerQuery,
  useSaveKpiExecutionItemManagerMutation,
} from './adminAPI';
import PerformanceIndicatorExecutionsDetail from './PerformanceIndicatorExecutionDetail';
import { KPIExecutionEntity, MONTHS } from './types';
import { calculateRealization } from './Util';

const { Option } = Select;
const { Text } = Typography;

function ManagePerformanceView() {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [admin, setAdmin] = useState<number>();
  const [data, setData] = useState<KPIExecutionEntity[]>([]);
  const [notes, setNotes] = useState('');

  const {
    data: apiData,
    error,
    isFetching,
    refetch,
  } = useGetKpiExecutionManagerQuery(
    { admin, month, year },
    {
      skip: admin == undefined ? true : false,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: adminData, error: adminError, isLoading: adminLoading } = useGetAllAdminQuery();

  const [saveKpiExecutionItem] = useSaveKpiExecutionItemManagerMutation();
  const [deleteExecution] = useDeleteExecutionManagerMutation();
  const [approveExecutionManager] = useApproveExecutionManagerMutation();

  useEffect(() => {
    if (apiData) {
      setData(apiData);
      setNotes(apiData.at(0)?.note ?? '');
    }
  }, [apiData]);

  const handleValueChange = (id: number, itemKey: string, rawValue: number | null | boolean) => {
    let value = rawValue == null ? 0 : Number(rawValue);
    setData((prev) => {
      let result = prev.map((e) => {
        if (e.id == id) {
          return {
            ...e,
            items: e.items.map((i) => {
              if (i.key == itemKey) {
                return {
                  key: i.key,
                  value: value,
                };
              }
              return i;
            }),
          };
        }
        return e;
      });
      return result;
    });
  };

  const calculateTotalAchievement = () => {
    let total = 0;
    for (let datum of data) {
      total += calculateRealization(datum).weightPercentage;
    }
    return total;
  };

  const handleSaveExecutions = async () => {
    const hide = message.loading('Loading', 0);
    let dataToSave = await data.map((e) => {
      return { ...e, note: notes };
    });

    saveKpiExecutionItem({ body: dataToSave, params: admin })
      .unwrap()
      .then(() => {
        hide();
        refetch();
        message.success('Berhasil!');
      })
      .catch((e) => {
        hide();
        message.error(e.data.message);
      });
  };

  return (
    <ContentWithErrorHandler isError={false} isLoading={adminLoading} customErrorMessage="Halaman ini belum selesai. Sedang dalam perbaikan">
      <Tabs
        destroyInactiveTabPane={true}
        activeKey={month.toString()}
        onChange={(month) => setMonth(Number(month))}
        tabBarExtraContent={{
          left: (
            <span style={{ marginRight: 10, fontSize: 16 }}>
              <Select value={admin} style={{ width: 180, marginRight: 12 }} placeholder="Pilih Officer" onChange={setAdmin}>
                {adminData?.map((e) => {
                  return <Option value={e.id}>{e.name}</Option>;
                })}
              </Select>
              <Select value={year} style={{ width: 80 }} onChange={setYear}>
                <Option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</Option>
                <Option value={new Date().getFullYear()}>{new Date().getFullYear()}</Option>
                <Option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</Option>
                <Option value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</Option>
              </Select>
              <Divider type="vertical" style={{ height: 20, borderWidth: 2, borderColor: 'gray' }} />
            </span>
          ),
        }}
      >
        {MONTHS.map((monthElement) => {
          return (
            <TabPane tab={monthElement.value} key={monthElement.key} destroyInactiveTabPane>
              <Card
                title="Ringkasan"
                size="small"
                style={{ marginBottom: 10 }}
                extra={
                  <Space>
                    <Popconfirm
                      title="Apakah anda yakin?"
                      onConfirm={() => {
                        const hideLoading = message.loading('Loading', 0);
                        deleteExecution({ admin, month, year })
                          .unwrap()
                          .then(() => {
                            hideLoading();
                            message.success('Berhasil!');
                          })
                          .catch(() => {
                            hideLoading();
                            message.error('Gagal!');
                          });
                      }}
                      okText="Hapus"
                      cancelText="Batal"
                    >
                      <Button danger icon={<DeleteOutlined />} size="small">
                        Hapus KPI
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      title="Apakah anda yakin?"
                      onConfirm={() => {
                        const hideLoading = message.loading('Loading', 0);
                        approveExecutionManager({ admin, month, year })
                          .unwrap()
                          .then(() => {
                            hideLoading();
                            message.success('Berhasil!');
                          })
                          .catch(() => {
                            hideLoading();
                            message.error('Gagal!');
                          });
                      }}
                      okText="Approve"
                      cancelText="Batal"
                    >
                      <Button type="dashed" icon={<DeleteOutlined />} size="small">
                        Approve KPI
                      </Button>
                    </Popconfirm>
                  </Space>
                }
              >
                <Statistic
                  title="Total Pencapaian KPI"
                  value={calculateTotalAchievement()}
                  precision={2}
                  valueStyle={{ color: calculateTotalAchievement() >= 80 ? '#3f8600' : '#cf1322' }}
                  suffix="%"
                />
                <div>
                  {data.at(0)?.approvedAt ? `KPI disetujui pada ${new Date(data.at(0)?.approvedAt ?? 0).toDateString()}` : 'KPI belum disetujui'}{' '}
                </div>
              </Card>
              <Card title="Detail" size="small" style={{ marginBottom: 10 }}>
                <PerformanceIndicatorExecutionsDetail
                  data={data}
                  isFetching={isFetching}
                  month={month}
                  refetch={refetch}
                  setData={setData}
                  year={year}
                  adminId={admin}
                />
                <Divider />
                <p>Catatan Lain</p>
                <TextArea rows={4} value={notes} onChange={(event) => setNotes(event.target.value)} />
              </Card>
              <Col>
                <Row justify="end" style={{ marginTop: 10 }}>
                  <Button type="primary" disabled={data.length == 0} onClick={() => handleSaveExecutions()}>
                    Simpan
                  </Button>
                </Row>
              </Col>
            </TabPane>
          );
        })}
      </Tabs>
    </ContentWithErrorHandler>
  );
}

export default ManagePerformanceView;
