import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, message, Popconfirm, Table, Tag } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { basicSortDateString } from "../../common/TableHelper";
import { useDeleteOfficeLocationMutation, useGetAllOfficeLocationQuery } from "./officeLocationAPI";

interface TagItem {
  text: string;
  value: string;
}

function OfficeLocationList() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetAllOfficeLocationQuery();
  const [deleteOfficeLocation] = useDeleteOfficeLocationMutation();

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Card
        title="Data Kantor Layanan"
        size="small"
        extra={
          <div>
            <Button style={{ borderRadius: 5 }}>
              <Link to="create">+ Tambah Baris</Link>
            </Button>
          </div>
        }
      >
        <Table
          loading={isLoading}
          dataSource={data}
          bordered
          size="small"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              title: "Tipe",
              dataIndex: "type",
              key: "type",
              render: (text, record) => {
                if (record.type == "main") {
                  return <Tag color="red">PUSAT</Tag>;
                } else {
                  return <Tag color="geekblue">CABANG</Tag>;
                }
              },
              width: "10%",
            },
            {
              title: "Nama Kantor Layanan",
              dataIndex: "name",
              key: "name",
              width: "20%",
              sorter: (a, b) => basicSortDateString(a.name, b.name),
            },
            {
              title: "Alamat",
              dataIndex: "address",
              key: "address",
              width: "40%",
              render: (text, record) => <div dangerouslySetInnerHTML={{ __html: record.address }} />,
            },
            {
              title: "Telepon",
              dataIndex: "phone",
              key: "phone",
              width: "40%",
              render: (phone) => (
                <>
                  {phone.map((element) => {
                    return (
                      <Tag color="blue" key={element}>
                        {element}
                      </Tag>
                    );
                  })}
                </>
              ),
            },
            {
              title: "Aksi",
              key: "x",
              width: "10%",
              render: (text, record) => (
                <div>
                  <Button type="link" icon={<EditOutlined />} style={{ borderRadius: 5 }} onClick={() => navigate(`detail/${record.id}`)} />
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading("Loading", 0);
                      deleteOfficeLocation(record.id)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success("Berhasil!");
                        })
                        .catch(() => {
                          hideLoading();
                          message.error("Gagal!");
                        });
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
    </ContentWithErrorHandler>
  );
}

export default OfficeLocationList;
