import {
  AppstoreOutlined,
  BookOutlined,
  CalendarOutlined,
  ContainerOutlined,
  FundViewOutlined,
  OrderedListOutlined,
  PhoneOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  SolutionOutlined,
  TableOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';

export interface MenuGroupObject {
  groupName: string;
  menu: MenuObject[];
}

export interface SubMenuObject {
  title: string;
  path: string;
}

export interface MenuObject {
  title: string;
  subMenu?: SubMenuObject[];
  icon?: React.ReactNode;
  path?: string;
}

export const navbarMenu: MenuGroupObject[] = [
  {
    groupName: 'Setting Website',
    menu: [
      {
        title: 'Profil',
        icon: <UserOutlined />,
        subMenu: [
          { title: 'Visi Misi Nilai', path: '/profile/org.profile' },
          { title: 'Sejarah', path: '/profile/history' },
          { title: 'Struktur Organisasi', path: '/profile/org-structure' },
        ],
      },
      {
        title: 'Layanan',
        icon: <AppstoreOutlined />,
        subMenu: [
          { title: 'Layanan Simpanan', path: '/service/saving' },
          { title: 'Layanan Pinjaman', path: '/service/loan' },
          { title: 'Layanan Anggota', path: '/service/memberService' },
        ],
      },
      {
        title: 'Publikasi',
        icon: <ContainerOutlined />,
        subMenu: [
          { title: 'Berita', path: '/news' },
          { title: 'Galeri', path: '/gallery' },
          { title: 'Dokumen', path: '/document' },
        ],
      },
      {
        title: 'Kontak',
        icon: <PhoneOutlined />,
        subMenu: [
          { title: 'Kantor Pelayanan', path: '/office-location' },
          { title: 'Pesan Masuk', path: '/contact-us' },
        ],
      },
      {
        title: 'Acara',
        icon: <CalendarOutlined />,
        subMenu: [
          { title: 'Pengaturan', path: '/event' },
          { title: 'Jadwal', path: '/event-schedule' },
        ],
      },
    ],
  },
  {
    groupName: 'Operasional',
    menu: [
      {
        title: 'Pengaturan Umum',
        path: '/config',
        icon: <SettingOutlined />,
      },
      {
        title: 'KPI',
        path: '/kpi',
        icon: <FundViewOutlined />,
      },
      {
        title: 'Daftar Anggota',
        path: '/member',
        icon: <UsergroupAddOutlined />,
      },
      {
        title: 'Pengaturan Officer',
        icon: <SolutionOutlined />,
        subMenu: [
          { title: 'Daftar Officer', path: '/admin/staff' },
          { title: 'Daftar Jabatan', path: '/admin/position' },
          { title: 'Pengaturan KPI', path: '/admin/management' },
        ],
      },
      {
        title: 'Pengaturan Pinjaman',
        icon: <CalendarOutlined />,
        subMenu: [
          { title: 'Jatuh Tempo', path: '/loan' },
          { title: 'Laporan Pinjaman', path: '/loan/report' },
        ],
      },
      {
        title: 'Pengajuan Pinjaman',
        icon: <CalendarOutlined />,
        subMenu: [
          { title: 'Data', path: '/loan-submission/data' },
          { title: 'Pengaturan', path: '/loan-submission/config' },
        ],
      },
      {
        title: 'Transaksi',
        icon: <ReconciliationOutlined />,
        subMenu: [
          { title: 'Slip Memo', path: '/transaction' },
          { title: 'Laporan Memo', path: '/transaction/report' },
          { title: 'Akun Transaksi', path: '/transaction-account' },
        ],
      },
      {
        title: 'Antrian',
        icon: <OrderedListOutlined />,
        subMenu: [
          { title: 'Admin', path: '/queue-admin' },
          { title: 'Klien', path: '/queue-producer' },
        ],
      },
      {
        title: 'Financial Literacy',
        icon: <BookOutlined />,
        subMenu: [
          { title: 'Pertanyaan Kuisioner', path: '/fl/questionaire' },
          { title: 'Hasil Kuisioner', path: '/fl/questionaire/result' },
          { title: 'Pengaturan Hasil', path: '/fl/questionaire/result-config' },
        ],
      },
      {
        title: 'Quiz',
        icon: <TableOutlined />,
        subMenu: [
          { title: 'Pertanyaan', path: '/quiz/question' },
          { title: 'Hasil', path: '/quiz/record' },
        ],
      },
    ],
  },
];
