import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, message, Popconfirm, Table } from "antd";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { useDeleteGeneralInfoMutation, useGetGeneralInfoByServiceTypeQuery } from "./generalInfoAPI";

function GeneralInfoProfileInfo() {
  const navigate = useNavigate();
  const { profileType } = useParams();

  const { data, error, isLoading, isFetching } = useGetGeneralInfoByServiceTypeQuery(profileType as string, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const [deleteGeneralInfo] = useDeleteGeneralInfoMutation();

  const renderByType = () => {
    if (profileType == "org.profile") {
      return renderVisionMissionValue();
    } else {
      return renderHistory();
    }
  };

  const renderVisionMissionValue = () => {
    return (
      <>
        <Card
          title="Visi"
          size="small"
          style={{ marginBottom: 15 }}
          extra={
            <div>
              <Button style={{ borderRadius: 5, marginTop: 5 }}>
                <Link to={`master/vision`}>Sunting</Link>
              </Button>
            </div>
          }
        >
          <div
            dangerouslySetInnerHTML={{
              __html: data?.itemMap.vision?.[0].content || "",
            }}
          ></div>
        </Card>

        <Card
          title="Misi"
          size="small"
          style={{ marginBottom: 15 }}
          extra={
            <div>
              <Button style={{ borderRadius: 5, marginTop: 5 }}>
                <Link to={`master/mission`}>Sunting</Link>
              </Button>
            </div>
          }
        >
          <div
            dangerouslySetInnerHTML={{
              __html: data?.itemMap.mission?.[0].content || "",
            }}
          ></div>
        </Card>

        <Card
          title={`Nilai Nilai CU Madani`}
          size="small"
          style={{ marginBottom: 15 }}
          extra={
            <div>
              <Button style={{ borderRadius: 5 }}>
                <Link to="value-create">+ Tambah Jenis</Link>
              </Button>
            </div>
          }
        >
          <Table
            loading={isLoading}
            dataSource={data?.itemMap.value}
            bordered
            size="small"
            pagination={{ pageSize: 10 }}
            columns={[
              {
                title: "Urutan",
                dataIndex: "viewOrder",
                key: "viewOrder",
                width: "5%",
              },
              {
                title: "Nilai",
                dataIndex: "title",
                key: "title",
                width: "15%",
              },
              {
                title: "Ikon",
                dataIndex: "imageUrl",
                key: "imageUrl",
                width: "20%",
                render: (text, record) => <img src={record.imageUrl} width="60px" />,
              },
              {
                title: "Deskripsi",
                dataIndex: "content",
                key: "content",
                width: "40%",
                render: (text, record) => <div dangerouslySetInnerHTML={{ __html: record.content }} />,
              },
              {
                title: "Aksi",
                key: "x",
                width: "10%",
                render: (text, record) => (
                  <div>
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      style={{ borderRadius: 5 }}
                      onClick={() => navigate(`value-detail/${record.id[0]}`)}
                    />
                    <Popconfirm
                      title="Apakah anda yakin?"
                      onConfirm={() => {
                        const hideLoading = message.loading("Loading", 0);
                        deleteGeneralInfo(record.id[0])
                          .unwrap()
                          .then(() => {
                            hideLoading();
                            message.success("Berhasil!");
                          })
                          .catch(() => {
                            hideLoading();
                            message.error("Gagal!");
                          });
                      }}
                      okText="Hapus"
                      cancelText="Batal"
                    >
                      <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                    </Popconfirm>
                  </div>
                ),
              },
            ]}
          />
        </Card>
      </>
    );
  };

  const renderHistory = () => {
    return (
      <>
        <Card
          title="Tajuk"
          size="small"
          style={{ marginBottom: 15 }}
          extra={
            <div>
              <Button style={{ borderRadius: 5, marginTop: 5 }}>
                <Link to={`master`}>Sunting</Link>
              </Button>
            </div>
          }
        >
          <div
            dangerouslySetInnerHTML={{
              __html: data?.itemMap.master?.[0].content || "",
            }}
          ></div>
        </Card>

        <Card
          title={`Perjalanan CU Madani`}
          size="small"
          style={{ marginBottom: 15 }}
          extra={
            <div>
              <Button style={{ borderRadius: 5 }}>
                <Link to="milestone-create">+ Tambah</Link>
              </Button>
            </div>
          }
        >
          <Table
            loading={isLoading}
            dataSource={data?.itemMap.milestone}
            bordered
            size="small"
            pagination={{ pageSize: 10 }}
            columns={[
              {
                title: "Tahun",
                dataIndex: "viewOrder",
                key: "viewOrder",
                width: "10%",
              },
              {
                title: "Deskripsi",
                dataIndex: "content",
                key: "content",
                width: "80%",
                render: (text, record) => <div dangerouslySetInnerHTML={{ __html: record.content }} />,
              },
              {
                title: "Aksi",
                key: "x",
                width: "10%",
                render: (text, record) => (
                  <div>
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      style={{ borderRadius: 5 }}
                      onClick={() => navigate(`milestone-detail/${record.id[0]}`)}
                    />
                    <Popconfirm
                      title="Apakah anda yakin?"
                      onConfirm={() => {
                        const hideLoading = message.loading("Loading", 0);
                        deleteGeneralInfo(record.id[0])
                          .unwrap()
                          .then(() => {
                            hideLoading();
                            message.success("Berhasil!");
                          })
                          .catch(() => {
                            hideLoading();
                            message.error("Gagal!");
                          });
                      }}
                      okText="Hapus"
                      cancelText="Batal"
                    >
                      <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                    </Popconfirm>
                  </div>
                ),
              },
            ]}
          />
        </Card>
      </>
    );
  };

  return (
    <ContentWithErrorHandler isError={error} isLoading={isLoading || isFetching}>
      {renderByType()}
    </ContentWithErrorHandler>
  );
}

export default GeneralInfoProfileInfo;
