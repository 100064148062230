import React, { useEffect, useState } from "react";
import { Table, Card, DatePicker, Space, Button } from "antd";
import { RangeValue } from "rc-picker/lib/interface.d";
import moment, { Moment } from "moment";
import { useGetLoanReportForecastQuery } from "./loanAPI";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { RangePicker } = DatePicker;

function numberToRupiah(num) {
  return "Rp " + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ",-";
}

function LoanPaymentForecast() {
  const [param, setParam] = useState<any>(null);
  const { data, error, isFetching } = useGetLoanReportForecastQuery(param);

  const [date, setDate] = useState<RangeValue<Moment>>([moment(), moment().add(1, "months")]);
  const [params, setParams] = useState<any>(null);

  const columns = [
    {
      title: "Tanggal",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Perkiraan",
      dataIndex: "forecastAmount",
      key: "forecastAmount",
      render: (text, record) => {
        return numberToRupiah(record.forecastAmount);
      },
    },
  ];

  const filter = async () => {
    setParam({ start: date?.[0]?.format("DD-MM-YYYY"), end: date?.[1]?.format("DD-MM-YYYY") });
  };

  return (
    <Card title="Perkiraan Penerimaan Cicilan" size="small" bordered={false}>
      <Space style={{ marginBottom: 15 }}>
        <RangePicker value={date} onChange={setDate} />
        <Button type="primary" onClick={filter}>
          Filter
        </Button>
        <ExcelFile
          filename={`Perkiraan Penerimaan ${date?.[0]?.format("DD-MM-YYYY")} sampai ${date?.[1]?.format("DD-MM-YYYY")}`}
          element={<Button>Ekspor</Button>}
        >
          <ExcelSheet data={data} name="Data Perkiraan Penerimaan Cicilan">
            <ExcelColumn label="Tanggal" value="date" />
            <ExcelColumn label="Perkiraan Penerimaan" value="forecastAmount" />
          </ExcelSheet>
        </ExcelFile>
      </Space>
      <Table pagination={{ pageSize: 5 }} loading={isFetching} dataSource={data} columns={columns} size="small"></Table>
    </Card>
  );
}

export default LoanPaymentForecast;
