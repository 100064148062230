import Auth from '../features/auth/Auth';
import DocumentDetail from '../features/documents/DocumentDetail';
import DocumentList from '../features/documents/DocumentList';
import GeneralInfoItemDetail from '../features/info/GeneralInfoItemDetail';
import GeneralInfoMasterDetail from '../features/info/GeneralInfoMasterDetail';
import GeneralInfoMilestoneDetail from '../features/info/GeneralInfoMilestoneDetail';
import GeneralInfoProfileInfo from '../features/info/GeneralInfoProfileInfo';
import GeneralInfoServiceInfo from '../features/info/GeneralInfoServiceInfo';
import GeneralInfoStepDetail from '../features/info/GeneralInfoStepDetail';
import GeneralInfoTypeDetail from '../features/info/GeneralInfoTypeDetail';
import GeneralInfoValueDetail from '../features/info/GeneralInfoValueDetail';
import OrganizationStructureList from '../features/info/OrganizationStructureList';
import NewsDetail from '../features/news/NewsDetail';
import NewsList from '../features/news/NewsList';
import BaseLayout from '../app/BaseLayout';
import { PrivateRoute as Page } from '../app/PrivateRoute';
import GalleryList from '../features/gallery/GalleryList';
import GalleryDetail from '../features/gallery/GalleryDetail';
import OfficeLocationList from '../features/contact/OfficeLocationList';
import OfficeLocationDetail from '../features/contact/OfficeLocationDetail';
import ContactUsDetail from '../features/contact/ContactUsDetail';
import ContactUsList from '../features/contact/ContactUsList';
import AdminList from '../features/admin/AdminList';
import AdminDetail from '../features/admin/AdminDetail';
import AdminPositionList from '../features/admin/AdminPositionList';
import AdminPositionDetail from '../features/admin/AdminPositionDetail';
import ManagePerformance from '../features/admin/ManagePerformance';
import MemberList from '../features/member/MemberList';
import MemberDetail from '../features/member/MemberDetail';
import LoanList from '../features/loan/LoanList';
import LoanDetail from '../features/loan/LoanDetail';
import LoanReport from '../features/loan/LoanReport';
import CreateLoan from '../features/loan/CreateLoan';
import TransactionList from '../features/transaction/TransactionList';
import TransactionDetail from '../features/transaction/TransactionDetail';
import TransactionAccountList from '../features/transaction/TransactionAccountList';
import TransactionAccountDetail from '../features/transaction/TransactionAccountDetail';
import TransactionReport from '../features/transaction/TransactionReport';
import QueueAdmin from '../features/queue/QueueAdmin';
import QueueProducer from '../features/queue/QueueProducer';
import OrganizationStructureDetail from '../features/info/OrganizationStructureDetail';
import LoanSubmissionDetail from '../features/loan/submission/LoanSubmissionDetail';
import FinancialLiteracyQuestionList from '../features/financialLiteracy/FinancialLiteracyQuestionList';
import FinancialLiteracyQuestionDetail from '../features/financialLiteracy/FinancialLiteracyQuestionDetail';
import FinancialLiteracyQuestionResultList from '../features/financialLiteracy/FinancialLiteracyQuestionResultList';
import FinancialLiteracyQuestionResultDetail from '../features/financialLiteracy/FinancialLiteracyResultDetail';
import FinancialLiteracyQuestionResultConfigList from '../features/financialLiteracy/FinancialLiteracyQuestionResultConfigList';
import FinancialLiteracyQuestionResultConfigDetail from '../features/financialLiteracy/FinancialLiteracyResultConfigDetail';
import SubmissionConfigList from '../features/loan/submissionConfiguration/SubmissionConfigList';
import SubmissionConfigDetail from '../features/loan/submissionConfiguration/SubmissionConfigDetail';
import LoanSubmissionList from '../features/loan/submission/LoanSubmissionList';
import Profile from '../features/auth/Profile';
import PerformanceIndicatorDetail from '../features/admin/PerformanceIndicatorDetail';
import PerformanceIndicatorExecutions from '../features/admin/PerformanceIndicatorExecution';
import UnderConstruction from '../features/admin/UnderConstruction';
import Home from '../features/home/Home';
import GeneralInfoConfig from '../features/info/GeneralInfoConfig';
import EventList from '../features/event/EventList';
import EventDetail from '../features/event/EventDetail';
import EventScheduleList from '../features/event/EventScheduleList';
import EventScheduleDetail from '../features/event/EventScheduleDetail';
import QuizQuestionList from '../features/quiz/QuizQuestionList';
import QuizQuestionDetail from '../features/quiz/QuizQuestionDetail';
import QuizRecordList from '../features/quiz/QuizRecordList';

interface RouteObjectBreadcrumb {
  caseSensitive?: boolean;
  children?: RouteObjectBreadcrumb[];
  element?: React.ReactNode;
  index?: boolean;
  path?: string;
  breadcrumb?: string;
}

export const routes: RouteObjectBreadcrumb[] = [
  { path: '/login', element: <Auth /> },
  {
    path: '/',
    element: <Page component={BaseLayout} />,
    children: [
      { path: '', element: <Page component={Home} /> },
      // Berita
      { path: 'news', element: <Page component={NewsList} /> },
      { path: 'news/create', element: <Page component={NewsDetail} /> },
      { path: 'news/detail/:id', element: <Page component={NewsDetail} /> },

      // Dokumen Publik
      { path: 'document', element: <Page component={DocumentList} /> },
      { path: 'document/detail/:id', element: <Page component={DocumentDetail} /> },
      { path: 'document/create', element: <Page component={DocumentDetail} /> },

      // Galleri
      { path: 'gallery', element: <Page component={GalleryList} /> },
      { path: 'gallery/create', element: <Page component={GalleryDetail} /> },
      { path: 'gallery/detail/:id', element: <Page component={GalleryDetail} /> },

      // Layanan
      { path: 'service/:serviceType', element: <Page component={GeneralInfoServiceInfo} /> },
      { path: 'service/:serviceType/master', element: <Page component={GeneralInfoMasterDetail} /> },
      { path: 'service/:serviceType/type-create', element: <Page component={GeneralInfoTypeDetail} /> },
      { path: 'service/:serviceType/type-detail/:id', element: <Page component={GeneralInfoTypeDetail} /> },
      { path: 'service/:serviceType/step-create', element: <Page component={GeneralInfoStepDetail} /> },
      { path: 'service/:serviceType/step-detail/:id', element: <Page component={GeneralInfoStepDetail} /> },
      { path: 'service/:serviceType/item-create', element: <Page component={GeneralInfoItemDetail} /> },
      { path: 'service/:serviceType/item-detail/:id', element: <Page component={GeneralInfoItemDetail} /> },

      // Struktur Organisasi
      { path: 'profile/org-structure', element: <Page component={OrganizationStructureList} /> },
      { path: 'profile/org-structure/create', element: <Page component={OrganizationStructureDetail} /> },
      { path: 'profile/org-structure/detail/:id', element: <Page component={OrganizationStructureDetail} /> },

      // Profile
      { path: 'profile/:profileType', element: <Page component={GeneralInfoProfileInfo} /> },
      { path: 'profile/:profileType/master', element: <Page component={GeneralInfoMasterDetail} /> },
      { path: 'profile/:profileType/master/:subType', element: <Page component={GeneralInfoMasterDetail} /> },
      { path: 'profile/:profileType/value-create', element: <Page component={GeneralInfoValueDetail} /> },
      { path: 'profile/:profileType/value-detail/:id', element: <Page component={GeneralInfoValueDetail} /> },
      { path: 'profile/:profileType/milestone-create', element: <Page component={GeneralInfoMilestoneDetail} /> },
      { path: 'profile/:profileType/milestone-detail/:id', element: <Page component={GeneralInfoMilestoneDetail} /> },

      // Lokasi Kantor
      { path: 'office-location', element: <Page component={OfficeLocationList} /> },
      { path: 'office-location/detail/:id', element: <Page component={OfficeLocationDetail} /> },
      { path: 'office-location/create', element: <Page component={OfficeLocationDetail} /> },

      // Kontak Kami
      { path: 'contact-us', element: <Page component={ContactUsList} /> },
      { path: 'contact-us/detail/:id', element: <Page component={ContactUsDetail} /> },

      // Event
      { path: 'event', element: <Page component={EventList} /> },
      { path: 'event/detail/:id', element: <Page component={EventDetail} /> },
      { path: 'event/create', element: <Page component={EventDetail} /> },
      { path: 'event-schedule', element: <Page component={EventScheduleList} /> },
      { path: 'event-schedule/detail/:id', element: <Page component={EventScheduleDetail} /> },

      // -----------------------------
      //  MENU OPERASIONAL
      // -----------------------------

      // Pengaturan Umum
      { path: 'myprofile', element: <Page component={Profile} /> },

      // Pengaturan Umum
      { path: 'config', element: <Page component={GeneralInfoConfig} /> },

      // Pengaturan Officer
      { path: 'admin/staff', element: <Page component={AdminList} /> },
      { path: 'admin/staff/detail/:id', element: <Page component={AdminDetail} /> },
      { path: 'admin/staff/create', element: <Page component={AdminDetail} /> },
      { path: 'admin/position', element: <Page component={AdminPositionList} /> },
      { path: 'admin/position/detail/:id', element: <Page component={AdminPositionDetail} /> },
      { path: 'admin/position/create', element: <Page component={AdminPositionDetail} /> },

      // KPI
      {
        path: 'admin/management',
        element: <Page component={ManagePerformance} />,
        children: [
          { path: 'detail/:id', element: <Page component={PerformanceIndicatorDetail} /> },
          { path: 'create/:id', element: <Page component={PerformanceIndicatorDetail} /> },
          { path: 'create', element: <Page component={PerformanceIndicatorDetail} /> },
        ],
      },

      { path: 'kpi', element: <Page component={PerformanceIndicatorExecutions} /> },

      // Member
      { path: 'member', element: <Page component={MemberList} /> },
      { path: 'member/create', element: <Page component={MemberDetail} /> },
      { path: 'member/detail/:id', element: <Page component={MemberDetail} /> },

      // Pinjaman Jatuh Tempo
      {
        path: 'loan',
        element: <Page component={LoanList} />,
        children: [
          { path: 'create', element: <Page component={CreateLoan} /> },
          {
            path: 'detail/:id',
            element: <Page component={LoanDetail} />,
          },
        ],
      },
      { path: 'loan/report', element: <Page component={LoanReport} /> },
      { path: 'loan-submission/data', element: <Page component={LoanSubmissionList} /> },
      { path: 'loan-submission/data/create', element: <Page component={LoanSubmissionDetail} /> },
      { path: 'loan-submission/data/detail/:id', element: <Page component={LoanSubmissionDetail} /> },
      {
        path: 'loan-submission/config',
        element: <Page component={SubmissionConfigList} />,
        children: [
          {
            path: 'assessment-create/:type',
            element: <Page component={SubmissionConfigDetail} />,
          },
          {
            path: 'assessment-detail/:type/:id',
            element: <Page component={SubmissionConfigDetail} />,
          },
        ],
      },

      // Memo Transaction
      {
        path: 'transaction',
        element: <Page component={TransactionList} />,
        children: [
          {
            path: 'create',
            element: <Page component={TransactionDetail} />,
          },
          {
            path: 'detail/:id',
            element: <Page component={TransactionDetail} />,
          },
        ],
      },
      { path: 'transaction/report', element: <Page component={TransactionReport} /> },
      {
        path: 'transaction-account',
        element: <Page component={TransactionAccountList} />,
        children: [
          {
            path: 'create',
            element: <Page component={TransactionAccountDetail} />,
          },
          {
            path: 'detail/:id',
            element: <Page component={TransactionAccountDetail} />,
          },
        ],
      },

      // Queue
      { path: 'queue-admin', element: <Page component={QueueAdmin} /> },
      { path: 'queue-producer', element: <Page component={QueueProducer} /> },

      // Financial Literacy
      { path: 'fl/questionaire', element: <Page component={FinancialLiteracyQuestionList} /> },
      { path: 'fl/questionaire/create', element: <Page component={FinancialLiteracyQuestionDetail} /> },
      { path: 'fl/questionaire/detail/:id', element: <Page component={FinancialLiteracyQuestionDetail} /> },
      {
        path: 'fl/questionaire/result',
        element: <Page component={FinancialLiteracyQuestionResultList} />,
        children: [
          {
            path: 'detail/:id',
            element: <Page component={FinancialLiteracyQuestionResultDetail} />,
          },
        ],
      },
      {
        path: 'fl/questionaire/result-config',
        element: <Page component={FinancialLiteracyQuestionResultConfigList} />,
        children: [
          {
            path: 'create',
            element: <Page component={FinancialLiteracyQuestionResultConfigDetail} />,
          },
          {
            path: 'detail/:id',
            element: <Page component={FinancialLiteracyQuestionResultConfigDetail} />,
          },
        ],
      },
      { path: 'quiz/question', element: <Page component={QuizQuestionList} /> },
      { path: 'quiz/question/create', element: <Page component={QuizQuestionDetail} /> },
      { path: 'quiz/question/detail/:id', element: <Page component={QuizQuestionDetail} /> },
      { path: 'quiz/record', element: <Page component={QuizRecordList} /> },
    ],
  },
];
