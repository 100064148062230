import {
  CheckCircleOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  DislikeTwoTone,
  DownloadOutlined,
  FallOutlined,
  LikeTwoTone,
  SmileOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Collapse, Radio, Result, Row, Space, Statistic, message } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Paragraph from 'antd/lib/typography/Paragraph';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import ReactExport from 'react-export-excel';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { useAnswerTodayQuizMutation, useGetHistoryLksbQuery, useGetTodayLksbQuery, useGetTodayQuizQuery } from './homeAPI';
import { AnswerTodayQuiz } from './types';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const today = moment();
const prevDate = moment().subtract(12, 'months');

function Home() {
  const [tabActiveKey, setTabActiveKey] = useState<string | string[]>('0');
  const [quizAnswer, setQuizAnswer] = useState(-1);
  const [answerTodayQuizResponse, setAnswerTodayQuizResponse] = useState<AnswerTodayQuiz | undefined>();
  const { data, error, isFetching } = useGetTodayLksbQuery();
  const { data: historyData, error: historyError, isFetching: historyFetching } = useGetHistoryLksbQuery();
  const { data: quizData, error: quizError, isFetching: quizFetching } = useGetTodayQuizQuery();
  const [answerTodayQuiz] = useAnswerTodayQuizMutation();

  const [downloadData, setDownloadData] = useState<any>([]);
  const [downloadDataStructure, setDownloadDataStructure] = useState<any>([]);

  useEffect(() => {
    if (data != null && data != undefined) {
      download();
    }
  }, [data]);

  useEffect(() => {
    if (quizData != null && quizData != undefined && !quizData.answered) {
      setTabActiveKey('1');
    }
  }, [quizData]);

  useEffect(() => {
    return () => {
      setAnswerTodayQuizResponse(undefined);
    };
  }, []);

  const download = () => {
    let result: any[] = [];
    let structure: any[] = ['Tanggal'];
    if (historyData == undefined || historyData == null) return;

    for (let idx in historyData.labels) {
      let item: any = {};
      for (let dataset of historyData.datasets) {
        item[dataset.label] = dataset.data[idx];

        if (idx == '0') {
          structure.push(dataset.label);
        }
      }

      result.push({
        Tanggal: historyData.labels[idx],
        ...item,
      });
    }
    setDownloadDataStructure(structure);
    setDownloadData(result);
  };

  const options: any = {
    scales: {
      yAxes: [{ ticks: { beginAtZero: true } }],
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      title: {
        display: false,
      },
    },
    datasets: {
      line: {
        pointRadius: 0, // disable for all `'line'` datasets
      },
    },
  };

  const renderGoodOrBadIcon = (isGood) => {
    if (isGood) {
      return <LikeTwoTone twoToneColor="#3f8600" />;
    }
    return <DislikeTwoTone twoToneColor="#cf1322" />;
  };

  const renderGoodOrBadStyle = (isGood) => {
    if (isGood) {
      return { color: '#3f8600' };
    }
    return { color: '#cf1322' };
  };

  const getQuizCompleteSubtitle = () => {
    if (quizData == undefined || quizData == null) {
      return '';
    }
    return `Nilai anda per-bulan ini adalah ${quizData?.monthlyScore} dari ${quizData?.monthlyScoreBase}`;
  };

  const answerQuiz = async () => {
    const hide = message.loading('Loading', 0);
    if (quizAnswer == null || quizAnswer == undefined || quizAnswer < 0) {
      message.error('Anda belum menjawab!');
    }
    answerTodayQuiz(quizAnswer)
      .unwrap()
      .then((e) => {
        hide();
        setAnswerTodayQuizResponse(e);
        message.success('Berhasil menjawab quiz!');
      })
      .catch(() => {
        hide();
        message.error('Gagal menjawab quiz!');
      });
  };

  const renderAnswerResult = () => {
    if (answerTodayQuizResponse) {
      if (answerTodayQuizResponse.isCorrect) {
        return <Result status="success" title="Jawaban Anda Benar!" subTitle={getQuizCompleteSubtitle()} />;
      }
      let correctAnswer = answerTodayQuizResponse.correctAnswer
        .map((e) => {
          return quizData?.answers[e];
        })
        .map((e, idx) => `(${idx + 1}) ${e?.text}.` || '')
        .join(' ');
      return <Result status="error" title="Jawaban Anda Salah!" subTitle={`Jawaban benar: ${correctAnswer}`} />;
    }
    return <></>;
  };

  const shouldShowNoQuestion = () => {
    return quizData && !answerTodayQuizResponse ? quizData.answered : false;
  };

  return (
    <>
      <Collapse bordered={false} activeKey={tabActiveKey} onChange={(e) => setTabActiveKey(e)}>
        <CollapsePanel header="Quiz" key={1}>
          <ContentWithErrorHandler isError={quizError} isLoading={quizFetching} withBox={false} fullScreen={false} show={!quizData?.answered}>
            <Paragraph>{quizData?.question}</Paragraph>
            <div style={{ marginBottom: 16 }}>
              <Radio.Group
                value={quizAnswer}
                onChange={(e) => {
                  setQuizAnswer(e.target.value);
                  console.log(e);
                }}
              >
                <Space direction="vertical">
                  {quizData?.answers.map((e, idx) => (
                    <Radio value={idx}>{e.text}</Radio>
                  ))}
                </Space>
              </Radio.Group>
            </div>
            <Button type="primary" onClick={answerQuiz}>
              Jawab
            </Button>
          </ContentWithErrorHandler>
          <ContentWithErrorHandler isError={false} isLoading={false} withBox={false} fullScreen={false} show={answerTodayQuizResponse ? true : false}>
            {renderAnswerResult()}
          </ContentWithErrorHandler>
          <ContentWithErrorHandler isError={false} isLoading={false} withBox={false} fullScreen={false} show={shouldShowNoQuestion()}>
            <Result icon={<SmileOutlined />} title="Quiz hari ini sudah terjawab" subTitle={getQuizCompleteSubtitle()} />
          </ContentWithErrorHandler>
        </CollapsePanel>
      </Collapse>
      <Card size="small" title="Ringkasan hari ini (semua nilai dalam persentase)" bordered={false} style={{ marginTop: 12 }}>
        <ContentWithErrorHandler isError={error} isLoading={isFetching} withBox={false} fullScreen={false}>
          <Row gutter={[16, 16]}>
            <Col span={7}>
              <Statistic
                title={
                  <>
                    Pinjaman Beredar
                    <br />
                    Terhadap Total Aset (E1)
                  </>
                }
                value={data?.e1}
                valueStyle={renderGoodOrBadStyle((data?.e1 || 0) >= 70 && (data?.e1 || 0) <= 80)}
                prefix={renderGoodOrBadIcon((data?.e1 || 0) >= 70 && (data?.e1 || 0) <= 80)}
                suffix={<div style={{ fontSize: 16, color: '#6c757d' }}>(nilai ideal 70-80)</div>}
              />
            </Col>
            <Col span={7}>
              <Statistic
                title={
                  <>
                    Simpanan Non-Saham Anggota
                    <br />
                    terhadap Total Aset (E5)
                  </>
                }
                value={data?.e5}
                valueStyle={renderGoodOrBadStyle((data?.e5 || 0) >= 70 && (data?.e5 || 0) <= 80)}
                prefix={renderGoodOrBadIcon((data?.e5 || 0) >= 70 && (data?.e5 || 0) <= 80)}
                suffix={<div style={{ fontSize: 16, color: '#6c757d' }}>(nilai ideal 70-80)</div>}
              />
            </Col>
            <Col span={7}>
              <Statistic
                title={
                  <>
                    Simpanan Saham Anggota
                    <br />
                    terhadap Total Aset (E7)
                  </>
                }
                value={data?.e7}
                valueStyle={renderGoodOrBadStyle((data?.e7 || 0) >= 10 && (data?.e7 || 0) <= 20)}
                prefix={renderGoodOrBadIcon((data?.e7 || 0) >= 10 && (data?.e7 || 0) <= 20)}
                suffix={<div style={{ fontSize: 16, color: '#6c757d' }}>(nilai ideal 10-20)</div>}
              />
            </Col>
            <Col span={7}>
              <Statistic
                title="Modal Lembaga (E8)"
                value={data?.e8}
                valueStyle={renderGoodOrBadStyle((data?.e8 || 0) >= 10)}
                prefix={renderGoodOrBadIcon((data?.e8 || 0) >= 10)}
                suffix={<div style={{ fontSize: 16, color: '#6c757d' }}>(nilai ideal lebih dari 10)</div>}
              />
            </Col>
            <Col span={7}>
              <Statistic
                title="Kemampuan Pendapatan Bunga (R1)"
                value={data?.r1}
                valueStyle={renderGoodOrBadStyle((data?.r1 || 0) >= 15 && (data?.r1 || 0) <= 30)}
                prefix={renderGoodOrBadIcon((data?.r1 || 0) >= 15 && (data?.r1 || 0) <= 30)}
                suffix={<div style={{ fontSize: 16, color: '#6c757d' }}>(nilai ideal 15-30)</div>}
              />
            </Col>
            <Col span={7}>
              <Statistic
                title="NPL (A1)"
                value={data?.a1}
                valueStyle={renderGoodOrBadStyle((data?.a1 || 0) <= 5)}
                prefix={renderGoodOrBadIcon((data?.a1 || 0) <= 5)}
                suffix={<div style={{ fontSize: 16, color: '#6c757d' }}>(nilai ideal kurang dari 5)</div>}
              />
            </Col>
            <Col span={7}>
              <Statistic
                title="Likuiditas (L1)"
                value={data?.l1}
                valueStyle={renderGoodOrBadStyle((data?.l1 || 0) >= 10 && (data?.l1 || 0) <= 20)}
                prefix={renderGoodOrBadIcon((data?.l1 || 0) >= 10 && (data?.l1 || 0) <= 20)}
                suffix={<div style={{ fontSize: 16, color: '#6c757d' }}>(nilai ideal 10-20)</div>}
              />
            </Col>
          </Row>
        </ContentWithErrorHandler>
      </Card>
      <Card
        style={{ marginTop: 12 }}
        size="small"
        title={'PEARLS 12 Bulan Terakhir'}
        bodyStyle={{ padding: 15, paddingTop: 0, height: '45vh' }}
        bordered={false}
        extra={
          <Space>
            <ExcelFile
              filename={`PEARLS ${prevDate.format('DD-MM-YYYY')} sampai ${today.format('DD-MM-YYYY')}`}
              element={
                <Button size="small" icon={<DownloadOutlined size={16} />}>
                  Ekspor
                </Button>
              }
            >
              <ExcelSheet data={downloadData} name="map Perkiraan Penerimaan Cicilan">
                {downloadDataStructure.map((e) => (
                  <ExcelColumn label={e} value={e} />
                ))}
              </ExcelSheet>
            </ExcelFile>
          </Space>
        }
      >
        <ContentWithErrorHandler isError={historyError} isLoading={historyFetching} withBox={false} fullScreen={false}>
          {data ? <Line data={historyData} options={options} /> : <></>}
        </ContentWithErrorHandler>
      </Card>
    </>
  );
}

export default Home;
