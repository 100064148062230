import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Space, Typography } from 'antd';
import { FieldArray, Formik } from 'formik';
import { Checkbox, Form, Input, InputNumber, Select, SubmitButton } from 'formik-antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { useCreateQuizQuestionMutation, useGetQuizQuestionQuery, useUpdateQuizQuestionMutation } from './quizAPI';
import { isDataURI } from 'class-validator';

const { Option } = Select;
const { Text } = Typography;

interface InitialAnswer {
  text: string;
  isCorrectAnswer: boolean;
}

interface InitialData {
  question: string;
  isActive: boolean;
  answers: InitialAnswer[];
}

function QuizQuestionDetail() {
  const { id } = useParams();
  const [initialData, setInitialData] = useState<InitialData>({
    question: '',
    isActive: false,
    answers: [],
  });

  const { data, isFetching, isError } = useGetQuizQuestionQuery(id || '', {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });
  const [updateQuiz] = useUpdateQuizQuestionMutation();
  const [createQuiz] = useCreateQuizQuestionMutation();

  useEffect(() => {
    if (data) {
      setInitialData({
        question: data.question,
        isActive: data.isActive,
        answers: data.answers || [],
      });
    }
  }, [data]);

  const validate = (value) => {
    let error;
    if (!value) {
      error = 'Tidak boleh kosong';
    }
    return error;
  };

  const save = async (value: InitialData, closeLoading: any) => {
    let promise;
    if (id) {
      promise = updateQuiz({ id, ...value });
    } else {
      promise = createQuiz({ ...value });
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success('Berhasil!');
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  return (
    <div>
      <ContentWithErrorHandler isError={isError} isLoading={isFetching} withBox={true}>
        <Formik
          initialValues={initialData}
          onSubmit={async (values, actions) => {
            const hide = message.loading('Loading', 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={({ values }) => (
            <Form layout="vertical">
              <Form.Item name="question" label="Pertanyaan" validate={validate}>
                <Input name="question" placeholder="Masukkan pertanyaan" />
              </Form.Item>

              <Form.Item name="isActive" label="Aktif?">
                <Checkbox name="isActive" />
              </Form.Item>

              <Form.Item name="answers" label="Jawaban">
                <FieldArray
                  name="answers"
                  render={(arrayHelpers) => (
                    <div style={{ width: '100%' }}>
                      {values.answers.map((answer, index) => (
                        <div key={index} style={{ width: '100%', display: 'flex', marginBottom: '12px' }}>
                          <div style={{ border: 'lightgray 1px solid', padding: 12 }}>
                            <Text>Jawaban {index + 1}</Text>
                            <Input.TextArea name={`answers.${index}.text`} />

                            <Text>Jawaban Benar? </Text>
                            <Checkbox name={`answers.${index}.isCorrectAnswer`} />
                          </div>
                          <Button
                            icon={<MinusCircleOutlined />}
                            type="text"
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          />
                        </div>
                      ))}

                      <Button
                        icon={<PlusOutlined />}
                        onClick={() =>
                          arrayHelpers.push({
                            text: '',
                            isCorrectAnswer: false,
                          })
                        }
                      >
                        Tambah
                      </Button>
                    </div>
                  )}
                />
              </Form.Item>

              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default QuizQuestionDetail;
