export enum SubmissionAssessmentConfigType {
  PersonalAssessment = 'PersonalAssessment',
  Character = 'Character',
  Capacity = 'Capacity',
  Capital = 'Capital',
  Collateral = 'Collateral',
  Condition = 'Condition',
  Character_Business = 'Character_Business',
  Capacity_Business = 'Capacity_Business',
  Capital_Business = 'Capital_Business',
  Collateral_Business = 'Collateral_Business',
  Condition_Business = 'Condition_Business',
}

export const SubmissionAssessmentConfigTypeList = Object.values(SubmissionAssessmentConfigType).filter((v) => isNaN(Number(v)));

export const SubmissionAssessmentConfigTypeText = {
  PersonalAssessment: 'Asesmen Pribadi',
  Character: 'Character (Tidak Memiliki Usaha)',
  Capacity: 'Capacity (Tidak Memiliki Usaha)',
  Capital: 'Capital (Tidak Memiliki Usaha)',
  Collateral: 'Collateral (Tidak Memiliki Usaha)',
  Condition: 'Condition (Tidak Memiliki Usaha)',
  Character_Business: 'Character (Memiliki Usaha)',
  Capacity_Business: 'Capacity (Memiliki Usaha)',
  Capital_Business: 'Capital (Memiliki Usaha)',
  Collateral_Business: 'Collateral (Memiliki Usaha)',
  Condition_Business: 'Condition (Memiliki Usaha)',
};

export const SubmissionAssessmentConfigTypeColor = {
  PersonalAssessment: 'Asesmen Pribadi',
  Character: '#ffccc7',
  Capacity: '#ffe7ba',
  Capital: '#bae7ff',
  Collateral: '#d9f7be',
  Condition: '#d9d9d9',
  Character_Business: '#ffccc7',
  Capacity_Business: '#ffe7ba',
  Capital_Business: '#bae7ff',
  Collateral_Business: '#d9f7be',
  Condition_Business: '#d9d9d9',
};

export type SubmissionAssessmentConfig = {
  id: number;
  descriptions: string[];
  indicator: string;
  type: string;
  allowedUser: number[];
};

export type ApprovalSignatureUpsert = {
  key: string;
  value: string;
};