import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, message, Popconfirm, Table, Tag } from 'antd';
import { isError } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { basicSortDateString } from '../../common/TableHelper';
import { useDeleteEventMutation, useGetAllEventQuery } from './eventAPI';

function EventList() {
  const navigate = useNavigate();
  const { data, error, isFetching } = useGetAllEventQuery();
  const [deleteGallery] = useDeleteEventMutation();

  return (
    <ContentWithErrorHandler isError={false} isLoading={false}>
      <Card
        title="Data Acara"
        size="small"
        extra={
          <div>
            <Button style={{ borderRadius: 5 }}>
              <Link to="create">+ Tambah Baris</Link>
            </Button>
          </div>
        }
      >
        <Table
          loading={isFetching}
          dataSource={data}
          bordered
          size="small"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              title: 'Status',
              dataIndex: 'isActive',
              key: 'isActive',
              render: (text, record) => {
                if (!record.isActive) {
                  return <Tag color="red">NON-AKTIF</Tag>;
                } else {
                  return <Tag color="geekblue">AKTIF</Tag>;
                }
              },
              width: '10%',
            },
            {
              title: 'Nama',
              dataIndex: 'name',
              key: 'name',
              width: '20%',
              sorter: (a, b) => basicSortDateString(a.name, b.name),
            },
            {
              title: 'Deskripsi Singkat',
              dataIndex: 'shortDescription',
              key: 'shortDescription',
              width: '30%',
              render: (item) => {
                return <div dangerouslySetInnerHTML={{ __html: item }}></div>;
              },
            },
            {
              title: 'Aksi',
              key: 'x',
              width: '10%',
              render: (text, record) => (
                <div>
                  <Button type="link" icon={<EditOutlined />} style={{ borderRadius: 5 }} onClick={() => navigate(`detail/${record.id}`)} />
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading('Loading', 0);
                      deleteGallery(record.id)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success('Berhasil!');
                        })
                        .catch(() => {
                          hideLoading();
                          message.error('Gagal!');
                        });
                    }}
                    okText="Non aktifkan"
                    cancelText="Batal"
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
    </ContentWithErrorHandler>
  );
}

export default EventList;
