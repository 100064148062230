import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, message, Popconfirm, Table } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { useDeleteOrganizationStructureMutation, useGetAllOrganizationStructureQuery } from "./organizationStructureAPI";
import { basicSort, TableColumnTextFilterConfig } from "../../common/TableHelper";
import { OrganizationStructure } from "./types";

function OrganizationStructureList() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetAllOrganizationStructureQuery(undefined, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const [deleteOne] = useDeleteOrganizationStructureMutation();

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Card
        title="Data Struktur Organisasi"
        size="small"
        extra={
          <div>
            <Button style={{ borderRadius: 5 }}>
              <Link to="create">+ Tambah Baris</Link>
            </Button>
          </div>
        }
      >
        <Table
          loading={isLoading}
          dataSource={data}
          bordered
          size="small"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              title: "Nama",
              dataIndex: "name",
              key: "title",
              width: "50%",
              sorter: (a, b) => basicSort(a, b),
              ...TableColumnTextFilterConfig<OrganizationStructure>("name"),
            },
            {
              title: "Jabatan",
              dataIndex: "position",
              key: "position",
              width: "20%",
              sorter: (a, b) => basicSort(a, b),
            },
            {
              title: "Peran",
              dataIndex: "role",
              key: "role",
              width: "20%",
              sorter: (a, b) => basicSort(a, b),
            },
            {
              title: "Aksi",
              key: "x",
              width: "10%",
              render: (text, record) => (
                <div>
                  <Button type="link" icon={<EditOutlined />} style={{ borderRadius: 5 }} onClick={() => navigate(`detail/${record.id}`)} />
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading("Loading", 0);
                      deleteOne(record.id)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success("Berhasil!");
                        })
                        .catch(() => {
                          hideLoading();
                          message.error("Gagal!");
                        });
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
    </ContentWithErrorHandler>
  );
}

export default OrganizationStructureList;
