export type Admin = {
  id: number;
  username: string;
  name: string;
  email: string;
  directReport: string;
  fullKpiBonus: number;
  sicundoUsername: string;
  positionId: number;
  position: AdminPosition;
  accessList: string[];
  signature: string;
};

export type AdminPosition = {
  id: number;
  name: string;
  code: string;
  type: string;
};

export type KeyValue = {
  key: string;
  value: string;
};

export type AdminPositionCode = {
  ManagementBoard: KeyValue[];
  ExecutiveBoard: KeyValue[];
  SupervisoryBoard: KeyValue[];
};

export const IndicatorTypeText = {
  SkillAndTechnical: 'Skill-Teknikal',
  SOP: 'SOP',
  Teamwork: 'Teamwork',
};

export const OperatorTypeText = {
  EQUAL: 'Sama Dengan',
  LESS_THAN: 'Kurang Dari',
  GREATER_THAN: 'Lebih Dari',
  BETWEEN: 'Diantara',
};

export const TargetType = {
  NUMBER: 'NUMBER',
  PERCENTAGE: 'PERCENTAGE',
  DISABLED: 'DISABLED',
  TEXT: 'TEXT',
};

export type KPITarget = {
  value: number;
  value2: number;
  operator: string;
  type: string;
  text: string;
};

export type KPIBaseEntity = {
  id: number;
  admin: Admin;
  indicatorType: string;
  indicator: string;
  weight: number;
  base: KPITarget;
  stretch: KPITarget;
  description: string;
};

export type KPIExecutionItem = {
  key: string;
  value: number;
};

export type KPIExecutionEntity = {
  id: number;
  kpi: KPIBaseEntity;
  month: number;
  year: number;
  items: KPIExecutionItem[];
  note: string;
  approvedAt: number;
};

export const MONTHS = [
  { value: 'Jan', longString: 'Januari', key: 0 },
  { value: 'Feb', longString: 'Februari', key: 1 },
  { value: 'Mar', longString: 'Maret', key: 2 },
  { value: 'Apr', longString: 'April', key: 3 },
  { value: 'Mei', longString: 'Mei', key: 4 },
  { value: 'Jun', longString: 'Juni', key: 5 },
  { value: 'Jul', longString: 'Juli', key: 6 },
  { value: 'Agu', longString: 'Agustus', key: 7 },
  { value: 'Sep', longString: 'September', key: 8 },
  { value: 'Okt', longString: 'Oktober', key: 9 },
  { value: 'Nov', longString: 'November', key: 10 },
  { value: 'Des', longString: 'Desember', key: 11 },
];