import { KPIExecutionEntity, TargetType } from './types';

export const calculateRealization = (record: KPIExecutionEntity) => {
  let total = record.items.reduce((n, item) => n + item.value, 0);
  let divider = record.kpi.base.type == 'TEXT' || record.kpi.base.type == 'DISABLED' ? 1 : record.kpi.base.value;
  let result: any = { total: total, percentage: Math.min(Math.round((total / divider) * 100) / 100, 1) };

  if (record.kpi.base.type == TargetType.NUMBER.toString() || record.kpi.base.type == TargetType.PERCENTAGE.toString()) {
    let baseTarget = record.kpi.base.value;
    let baseTarget2 = record.kpi.base.value2;

    let isStretchEnabled = record.kpi.stretch.type != 'DISABLED';
    let stretchTarget = record.kpi.stretch.value;

    if (record.kpi.base.operator == 'EQUAL') {
      result = {
        total: total,
        percentage: isStretchEnabled && total == stretchTarget ? 2 : total == baseTarget ? 1 : 0,
      };
    } else if (record.kpi.base.operator == 'BETWEEN') {
      result = {
        total: total,
        percentage: total >= baseTarget && total <= baseTarget2 ? 1 : 0,
      };
    } else if (record.kpi.base.operator == 'GREATER_THAN') {
      let multiplier = total >= stretchTarget ? 1 : 0;
      let normalPercentage = Math.round((total / baseTarget) * 100) / 100;
      let stretchPercentage = isStretchEnabled ? Math.round(((total - stretchTarget) / baseTarget) * 100) / 100 : baseTarget;
      result = {
        total: total,
        percentage: Math.min(normalPercentage, 1) + multiplier * (stretchPercentage + 1),
      };
    } else if (record.kpi.base.operator == 'LESS_THAN') {
      let tempStretchTarget = isStretchEnabled ? stretchTarget : Number.MIN_SAFE_INTEGER;
      let multiplier = total <= tempStretchTarget ? 1 : 0;
      let normalPercentage = 1 - Math.round(((total - baseTarget) / baseTarget) * 100) / 100;
      if (record.kpi.id == 5) {
        console.log(tempStretchTarget, multiplier, normalPercentage, (total - baseTarget) / baseTarget);
      }
      result = {
        total: total,
        percentage: Math.min(normalPercentage, 1) + multiplier * 1,
      };
    }
  }

  result.weightPercentage = Math.round(result.percentage * record.kpi.weight * 100) / 100;
  return result;
};
