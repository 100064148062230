import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { Formik } from "formik";
import { Form, Select, SubmitButton } from "formik-antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import quillConfig from "../../common/quillConfig";
import { useCreateGeneralInfoMutation, useGetGeneralInfoByTypeQuery } from "./generalInfoAPI";

function GeneralInfoMasterDetail() {
  const { serviceType, profileType, subType } = useParams();

  const [fileList, setFileList] = useState<any>([]);
  const [content, setContent] = useState("");

  const typeToBeQueried = () => {
    if (serviceType) {
      return `${serviceType}.master`;
    }

    if (profileType && subType) {
      return `${profileType}.${subType}`;
    } else if (profileType) {
      return `${profileType}.master`;
    }

    return false;
  };

  const { data: generalInfo, isLoading: generalInfoIsLoading, isError: generalInfoIsError } = useGetGeneralInfoByTypeQuery(
    typeToBeQueried() || "",
    {
      skip: typeToBeQueried() ? false : true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  const [createGeneralInfo] = useCreateGeneralInfoMutation();

  useEffect(() => {
    if (generalInfo) {
      setContent(generalInfo[0].content);
      setFileList(
        generalInfo[0].imageUrl != null
          ? [
              {
                uid: "-1",
                name: generalInfo[0].title,
                status: "done",
                url: generalInfo[0].imageUrl,
              },
            ]
          : []
      );
    }
  }, [generalInfo]);

  const handleUploadChange = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(fileList);
  };

  const save = async (closeLoading: any) => {
    if (content.length == 0) {
      closeLoading();
      message.error("Konten tidak boleh kosong");
      return;
    }

    const formData = new FormData();
    await fileList.map((item) => {
      if (item.originFileObj) {
        formData.append("file", item.originFileObj);
      }
    });

    if (!typeToBeQueried()) {
      message.error("Gagal menyimpan data");
      return;
    }

    formData.append("title", "Tajuk");
    formData.append("content", content);
    formData.append("type", typeToBeQueried() || "");
    formData.append("isHeader", String(true));

    createGeneralInfo(formData)
      .unwrap()
      .then(() => {
        closeLoading();
        message.success("Berhasil!");
      })
      .catch(() => {
        closeLoading();
        message.error("Gagal!");
      });
  };

  const renderPhotoField = () => {
    if (serviceType || !subType) {
      return (
        <Form.Item name="photo" label="Foto">
          <Upload
            beforeUpload={(file) => {
              setFileList((oldState) => [...oldState, file]);
              return false;
            }}
            onChange={handleUploadChange}
            multiple={false}
            listType="picture"
            accept=".png, .jpg, .jpeg"
            fileList={fileList}
          >
            <Button>
              <UploadOutlined /> Unggah
            </Button>
          </Upload>
        </Form.Item>
      );
    }
    return;
  };

  return (
    <div>
      <ContentWithErrorHandler isError={generalInfoIsError} isLoading={generalInfoIsLoading} withBox={true}>
        <Formik
          initialValues={{}}
          onSubmit={async (values, actions) => {
            const hide = message.loading("Loading", 0);
            await save(hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              {renderPhotoField()}
              <Form.Item name="content" label="Konten">
                <ReactQuill
                  modules={quillConfig}
                  style={{
                    background: "white",
                  }}
                  onChange={(content) => setContent(content)}
                  value={content}
                />
              </Form.Item>
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default GeneralInfoMasterDetail;
