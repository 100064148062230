import { Member } from '../member/types';

export const REGISTRATION_METHOD = {
  WITHOUT_REGISTRATION: 'WITHOUT_REGISTRATION',
  EXTERNAL_LINK: 'EXTERNAL_LINK',
  INTERNAL: 'INTERNAL',
};

export const REGISTRATION_METHOD_TEXT = {
  WITHOUT_REGISTRATION: 'Tanpa Registrasi',
  EXTERNAL_LINK: 'Link Eksternal',
  INTERNAL: 'Melalui BDC',
};

export type Event = {
  id: number;
  name: string;
  outline: string;
  shortDescription: string;
  image: string;
  isActive: boolean;
  eventSchedules: EventSchedule[];
};

export type EventSchedule = {
  id?: number;
  event: {
    id: number | null;
    name?: string;
  };
  date: number;
  quota: number;
  startTime: string;
  endTime: string;
  location: string;
  registrationMethod: string;
  registrationLink: string;
  openToPublic: boolean;
  participants?: EventParticipant[] | null;
};

export type EventParticipant = {
  id?: number;
  eventSchedule: {
    id: number;
  };
  member: Member;
  createdAt?: string;
};
