import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, message, Popconfirm, Table, Tag } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { basicSort, TableColumnTextFilterConfig } from "../../common/TableHelper";
import { useDeleteMemberMutation, useGetAllMemberQuery } from "./membersAPI";
import { Member } from "./types";

interface TagItem {
  text: string;
  value: boolean;
}

function MemberList() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetAllMemberQuery(true);
  const [deleteMember] = useDeleteMemberMutation();
  const [tags, setTags] = useState<TagItem[]>([
    { text: "Aktif", value: true },
    { text: "Non-aktif", value: false },
  ]);

  const getDate = (stringDate) => {
    return new Date(stringDate).toLocaleDateString("id-ID");
  };

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Card
        title="Data Anggota Koperasi"
        size="small"
        extra={
          <div>
            <Button style={{ borderRadius: 5 }}>
              <Link to="create">+ Tambah Anggota</Link>
            </Button>
          </div>
        }
      >
        <Table
          loading={isLoading}
          dataSource={data}
          bordered
          size="small"
          pagination={{}}
          scroll={{ x: "max-content", y: "60vh" }}
          tableLayout="fixed"
          columns={[
            {
              title: "Status",
              dataIndex: "isActive",
              key: "isActive",
              width: "8%",
              fixed: "left",
              filters: tags,
              defaultFilteredValue: [true],
              onFilter: (value: any, record) => record.isActive == value,
              render: (text, record) => {
                if (record.isActive) {
                  return <Tag color="green">Aktif</Tag>;
                } else {
                  return <Tag color="red">Non-aktif</Tag>;
                }
              },
            },
            {
              title: "Nomor",
              dataIndex: "memberId",
              key: "memberId",
              width: "10%",
              defaultSortOrder: "ascend",
              fixed: "left",
              sorter: (a, b) => basicSort(a.memberId, b.memberId),
              ...TableColumnTextFilterConfig<Member>("memberId"),
            },
            {
              title: "Nama",
              dataIndex: "name",
              key: "name",
              width: "20%",
              fixed: "left",
              sorter: (a, b) => basicSort(a.name, b.name),
              ...TableColumnTextFilterConfig<Member>("name"),
            },

            {
              title: "Email",
              dataIndex: "email",
              key: "email",
              width: "15%",
              sorter: (a, b) => basicSort(a.email, b.email),
            },
            {
              title: "Tgl Lahir",
              dataIndex: "dob",
              key: "dob",
              width: "10%",
              sorter: (a, b) => basicSort(a.dob, b.dob),
            },
            {
              title: "Telepon",
              dataIndex: "phone",
              key: "phone",
              width: "10%",
              render: (text, record) => record.phone.join(", "),
            },
            {
              title: "Kelompok",
              dataIndex: "group",
              key: "group",
              width: "15%",
              sorter: (a, b) => basicSort(a.name, b.name),
              ...TableColumnTextFilterConfig<Member>("group"),
            },
            {
              title: "Catatan",
              dataIndex: "notes",
              key: "notes",
              width: "20%",
              render: (text, record) => <div dangerouslySetInnerHTML={{ __html: record.notes }}></div>,
            },
            {
              title: "Aksi",
              key: "x",
              width: "10%",
              render: (text, record) => (
                <div>
                  <Button type="link" icon={<EditOutlined />} style={{ borderRadius: 5 }} onClick={() => navigate(`detail/${record.id}`)} />
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading("Loading", 0);
                      deleteMember(record.id)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success("Berhasil!");
                        })
                        .catch(() => {
                          hideLoading();
                          message.error("Gagal!");
                        });
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
    </ContentWithErrorHandler>
  );
}

export default MemberList;
