import { DeleteOutlined, EditOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload, Table, Popconfirm, Modal } from "antd";
import { Formik } from "formik";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import quillConfig from "../../common/quillConfig";
import { useCreateNewTagMutation, useGetTagByTypeQuery } from "../tags/tagsAPI";
import {
  useCreateGalleryItemMutation,
  useCreateGalleryMutation,
  useDeleteGalleryItemMutation,
  useGetGalleryQuery,
  useUpdateGalleryItemMutation,
  useUpdateGalleryMutation,
} from "./galleryAPI";
import { GalleryItem } from "./types";

const { Option } = Select;

interface InitialData {
  title: string;
  isPublished: boolean;
  tags: any[];
}

function GalleryDetail() {
  const { id } = useParams();

  const [fileList, setFileList] = useState<any>([]);
  const [content, setContent] = useState("");
  const [initialData, setInitialData] = useState<InitialData>({
    title: "",
    isPublished: false,
    tags: [],
  });

  const [showItemModal, setShowItemModal] = useState(false);
  const [itemTitle, setItemTitle] = useState("");
  const [itemType, setItemType] = useState("");
  const [itemUrl, setItemUrl] = useState("");
  const [itemId, setItemId] = useState<any>(null);
  const [itemFileList, setItemFileList] = useState<any>([]);

  const { data: galleryTags, isLoading: galleryTagsIsLoading } = useGetTagByTypeQuery("galleryTag");
  const { data: galleryData, isLoading: galleryDataIsLoading, isError: galleryDataIsError, refetch } = useGetGalleryQuery(id || "", {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const [createTag] = useCreateNewTagMutation();
  const [updateGallery] = useUpdateGalleryMutation();
  const [createGallery] = useCreateGalleryMutation();

  const [createGalleryItem] = useCreateGalleryItemMutation();
  const [updateGalleryItem] = useUpdateGalleryItemMutation();
  const [deleteGaleryItem] = useDeleteGalleryItemMutation();

  useEffect(() => {
    if (galleryData) {
      setInitialData({
        title: galleryData.title,
        isPublished: galleryData.isPublished,
        tags: galleryData.tags.map((e) => e.name),
      });
      setContent(galleryData.content);
      setFileList(
        galleryData.coverPhotoUrl != null
          ? [
              {
                uid: "-1",
                name: galleryData.title,
                status: "done",
                url: galleryData.coverPhotoUrl,
              },
            ]
          : []
      );
    }
  }, [galleryData]);

  const handleUploadChange = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(fileList);
  };

  const handleItemUploadChange = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setItemFileList(fileList);
  };

  const validate = (value) => {
    let error;
    if (!value) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  const save = async (value: InitialData, closeLoading: any) => {
    var tagsNotExist: string[] = [];
    var existingTags = galleryTags?.map((e) => e.name) || [];

    await value.tags.forEach((userInputTag: any) => {
      if (!existingTags.includes(userInputTag)) {
        tagsNotExist.push(userInputTag);
      }
    });

    await tagsNotExist.map(async (tagToAdd) => {
      createTag({ name: tagToAdd, type: "galleryTag" })
        .unwrap()
        .catch((e) => {
          message.error("Gagal membuat tag!");
        });
    });

    if (id == undefined && fileList.length == 0) {
      closeLoading();
      message.error("Foto sampul tidak boleh kosong");
      return;
    }
    if (content.length == 0) {
      closeLoading();
      message.error("Konten tidak boleh kosong");
      return;
    }

    const formData = new FormData();
    await fileList.map((item) => {
      if (item.originFileObj) {
        formData.append("file", item.originFileObj);
      }
    });

    formData.append("title", value.title);
    formData.append("content", content);
    formData.append("isPublished", String(value.isPublished));

    let tagIds = await value.tags.map((tagName) => {
      let tagObject = galleryTags?.find((e) => e.name == tagName);
      if (tagObject) {
        let id = tagObject.id;
        return id;
      }
    });
    formData.append("tagIds", JSON.stringify(tagIds.map((e) => e)));

    let promise;
    if (id) {
      formData.append("id", id);
      promise = updateGallery(formData);
    } else {
      promise = createGallery(formData);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success("Berhasil!");
      })
      .catch(() => {
        closeLoading();
        message.error("Gagal!");
      });
  };

  const saveItem = async () => {
    const closeLoading = message.loading("Loading", 0);

    if (itemTitle.length == 0) {
      closeLoading();
      message.error("Judul tidak boleh kosong");
      return;
    }
    if (itemType.length == 0) {
      closeLoading();
      message.error("Tipe tidak boleh kosong");
      return;
    }

    if (itemId == null && itemType == "image" && itemFileList.length == 0) {
      closeLoading();
      message.error("Foto tidak boleh kosong");
      return;
    }
    if (itemId != null && itemUrl.length == 0) {
      closeLoading();
      message.error("Link tidak boleh kosong");
      return;
    }

    const formData = new FormData();
    await itemFileList.map((item) => {
      if (item.originFileObj) {
        formData.append("file", item.originFileObj);
      }
    });

    formData.append("title", itemTitle);
    formData.append("type", itemType.toUpperCase());

    if (itemType.toUpperCase() == "VIDEO") {
      formData.append("link", itemUrl);
    }
    formData.append("gallery[id]", id || "");

    let promise;
    if (itemId) {
      formData.append("id", itemId);
      promise = updateGalleryItem(formData);
    } else {
      promise = createGalleryItem(formData);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success("Berhasil!");
        refetch();
      })
      .catch(() => {
        closeLoading();
        message.error("Gagal!");
      });
  };

  const handleCancelItemModal = () => {
    setShowItemModal(false);
    setItemId(null);
    setItemTitle("");
    setItemType("");
    setItemFileList([]);
    setItemUrl("");
  };

  const handleOpenItemModal = (data: GalleryItem) => {
    setItemTitle(data.title);
    setItemType(data.type.toLocaleLowerCase());
    setItemUrl(data.link);
    setItemId(data.id);

    if (data.type.toLocaleLowerCase() == "foto" || data.type.toLocaleLowerCase() == "image") {
      setItemFileList([
        {
          uid: "-1",
          name: data.title,
          status: "done",
          url: data.link,
        },
      ]);
    }

    setShowItemModal(true);
  };

  const renderGalleryItemModal = () => {
    return (
      <Modal
        title="Detail Item"
        visible={showItemModal}
        onCancel={handleCancelItemModal}
        onOk={saveItem}
        okText="Simpan"
        cancelText="Batal"
      >
        <Form.Item name="itemTitle" label="Judul Foto/Video">
          <Input
            name="itemTitle"
            defaultValue="Tuliskan Judul"
            value={itemTitle}
            style={{ width: "100%" }}
            onChange={(event) => setItemTitle(event.target.value)}
          ></Input>
        </Form.Item>
        <Form.Item name="itemType" label="Tipe">
          <Select name="itemType" defaultValue="Pilih Tipe" value={itemType} onChange={setItemType} style={{ width: "100%" }}>
            <Option value="video">Video</Option>
            <Option value="image">Foto</Option>
          </Select>
        </Form.Item>
        {itemType === "video" ? (
          <Form.Item name="itemUrl" label="Link Video">
            <Input name="itemUrl" placeholder="Tempel Alamat Video" value={itemUrl} onChange={(event) => setItemUrl(event.target.value)} />
          </Form.Item>
        ) : itemType === "foto" || itemType === "image" ? (
          <Form.Item name="itemFile" label="Upload Foto">
            <Upload
              beforeUpload={(file) => {
                setItemFileList((oldState) => [...oldState, file]);
                return false;
              }}
              onChange={handleItemUploadChange}
              multiple={false}
              listType="picture"
              accept=".png, .jpg, .jpeg"
              fileList={itemFileList}
            >
              <Button>
                <UploadOutlined /> Unggah
              </Button>
            </Upload>
          </Form.Item>
        ) : null}
      </Modal>
    );
  };

  return (
    <div>
      <ContentWithErrorHandler isError={galleryDataIsError} isLoading={galleryDataIsLoading} withBox={true}>
        <Formik
          initialValues={initialData}
          onSubmit={async (values, actions) => {
            const hide = message.loading("Loading", 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              {renderGalleryItemModal()}
              <Form.Item name="photo" label="Foto Sampul">
                <Upload
                  beforeUpload={(file) => {
                    setFileList((oldState) => [...oldState, file]);
                    return false;
                  }}
                  onChange={handleUploadChange}
                  multiple={false}
                  listType="picture"
                  accept=".png, .jpg, .jpeg"
                  fileList={fileList}
                >
                  <Button>
                    <UploadOutlined /> Unggah
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item name="title" label="Judul Galeri" validate={validate}>
                <Input name="title" placeholder="Masukkan judul" />
              </Form.Item>
              <Form.Item name="tags" label="Kategori">
                <Select name="tags" mode="tags" placeholder="Masukkan kategori">
                  {galleryTags?.map((tag) => (
                    <Option key={tag.id} value={tag.name}>
                      {tag.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="isPublished" label="Status">
                <Select name="isPublished">
                  <Option value={true}>Terbit</Option>
                  <Option value={false}>Draf</Option>
                </Select>
              </Form.Item>
              <Form.Item name="content" label="Isi Galeri">
                <ReactQuill
                  modules={quillConfig}
                  style={{
                    background: "white",
                  }}
                  onChange={(content) => setContent(content)}
                  value={content}
                />
              </Form.Item>
              <Form.Item name="media" label="Item Foto/Video">
                <Button key="addMedia" style={{ marginBottom: 10 }} onClick={() => setShowItemModal(!showItemModal)}>
                  + Tambah Foto/Video
                </Button>
                <Table
                  loading={galleryData?.galleryItems == undefined}
                  dataSource={galleryData?.galleryItems}
                  bordered
                  size="small"
                  pagination={{ pageSize: 10 }}
                  columns={[
                    {
                      title: "Judul",
                      dataIndex: "title",
                      key: "title",
                      width: "30%",
                    },
                    {
                      title: "Pratinjau",
                      dataIndex: "link",
                      key: "link",
                      width: "60%",
                      render: (text, record) => {
                        if (record.type.toLocaleLowerCase() == "video") {
                          return (
                            <div style={{ height: 250 }}>
                              <ReactPlayer url={record.link} playing={false} controls={true} width="80%" height="inherit" />
                            </div>
                          );
                        } else {
                          return (
                            <div style={{ height: 250 }}>
                              <img src={record.link} style={{ height: "inherit" }} />
                            </div>
                          );
                        }
                      },
                    },
                    {
                      title: "Aksi",
                      key: "x",
                      width: "10%",
                      render: (text, record) => (
                        <div>
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            style={{ borderRadius: 5 }}
                            onClick={() => handleOpenItemModal(record)}
                          />
                          <Popconfirm
                            title="Apakah anda yakin?"
                            onConfirm={() => {
                              const hideLoading = message.loading("Loading", 0);
                              deleteGaleryItem(record.id)
                                .unwrap()
                                .then(() => {
                                  hideLoading();
                                  message.success("Berhasil!");
                                })
                                .catch(() => {
                                  hideLoading();
                                  message.error("Gagal!");
                                });
                            }}
                            okText="Hapus"
                            cancelText="Batal"
                          >
                            <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                          </Popconfirm>
                        </div>
                      ),
                    },
                  ]}
                />
              </Form.Item>
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default GalleryDetail;
