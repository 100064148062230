import { Modal } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";

function CreateLoan() {
  const navigate = useNavigate();

  return (
    <Modal
      title={`Input Data Pinjaman`}
      width={800}
      visible={true}
      destroyOnClose={true}
      onCancel={() => navigate("/loan")}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
    >
      <ContentWithErrorHandler isError={true} isLoading={false} customErrorMessage="Halaman ini belum selesai. Sedang dalam perbaikan">
        <div>Placeholder</div>
      </ContentWithErrorHandler>
    </Modal>
  );
}

export default CreateLoan;
