import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, message, Modal, Row, Space, Tag } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { Select } from 'formik-antd';
import { floor } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import ContentWithErrorHandler from '../../../app/ContentWithErrorHandler';
import { isEmpty, toCurrencyString } from '../../../common/tokenizer';
import { Member } from '../../member/types';
import { useUploadAgreementDocumentMutation, useVerifyLoanSubmissionMutation } from './loanSubmissionAPI';
import SubmissionAprroveForm from './SubmissionApproveForm';
import SubmissionHistory from './SubmissionHistory';
import SubmissionStatusChangeForm from './SubmissionStatusChangeForm';
import {
  AssetText,
  History,
  InterestTypeText,
  LoanSubmissionStatus,
  LoanSubmissionStatusColor,
  LoanSubmissionStatusText,
  SubmissionAssetItem,
  SubmissionFormWrapper,
} from './types';
import { getId } from './util';

const DATE_FORMAT = 'DD/MM/YYYY';

const { Option } = Select;

type Props = {
  selectedMember: Member | undefined;
  wrapper: SubmissionFormWrapper | undefined;
};

function SummarySection(props: Props) {
  const [loanAgreementDocument, setLoanAgreementDocument] = useState<any>([]);

  const [statusChangeModalOpen, setStatusChangeModalOpen] = useState(false);
  const [approveSubmissionModalOpen, setApproveSubmissionModalOpen] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [loanAgreementModalOpen, setLoanAgreementModalOpen] = useState(false);
  const [statusHistoryModalOpen, setStatusHistoryModalOpen] = useState(false);
  const [dataHistoryModalOpen, setDataHistoryModalOpen] = useState(false);
  const [submissionHistory, setSubmissionHistory] = useState<History[]>([]);

  const [verify] = useVerifyLoanSubmissionMutation();
  const [doUploadLoanAgreement] = useUploadAgreementDocumentMutation();

  const getApproveOrRejectDate = () => {
    for (let history of submissionHistory) {
      if (
        history.label == LoanSubmissionStatus.approved ||
        history.label == LoanSubmissionStatus.approved_with_changes ||
        history.label == LoanSubmissionStatus.rejected
      ) {
        return moment(history.updatedAt).format('DD/MM/YYYY HH:mm');
      }
    }

    return '-';
  };

  const getInterviewDate = () => {
    for (let history of submissionHistory) {
      if (history.label == LoanSubmissionStatus.waiting_for_interview) {
        return history.note.match(/\d{1,2}\D\d{1,2}\D\d{4}/);
      }
    }

    return '-';
  };

  const getDisbursementScheduleDate = () => {
    for (let history of submissionHistory) {
      if (history.label == LoanSubmissionStatus.waiting_disbursement) {
        return history.note.match(/\d{1,2}\D\d{1,2}\D\d{4}/);
      }
    }

    return '-';
  };

  const getDisbursementDate = () => {
    for (let history of submissionHistory) {
      if (history.label == LoanSubmissionStatus.disbursed) {
        return moment(history.updatedAt).format('DD/MM/YYYY HH:mm');
      }
    }

    return '-';
  };

  const verifyLoanSubmission = (action: string) => {
    const closeLoading = message.loading('Loading', 0);
    verify({ id: props.wrapper?.id, action: action })
      .unwrap()
      .then(() => {
        closeLoading();
        message.success('Berhasil!');
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  const getVerificationData = (type: string) => {
    if (type == 'check') {
      if (props.wrapper?.data.verificationData?.checkedAt == null) {
        return '-';
      }
      return `${props.wrapper?.data.verificationData.checkedBy?.name} pada ${props.wrapper?.data.verificationData.checkedAt}`;
    } else if (type == 'verification') {
      if (props.wrapper?.data.verificationData?.verifiedAt == null) {
        return '-';
      }
      return `${props.wrapper?.data.verificationData.verifiedBy?.name} pada ${props.wrapper?.data.verificationData.verifiedAt}`;
    }
  };

  const handleDownloadDocument = async () => {
    const hide = message.loading('Loading', 0);
    if (!props.wrapper) {
      hide();
      message.error('Gagal!');
    }

    var path = 'loans-submission/data/download/' + props.wrapper?.id;
    const url = process.env.REACT_APP_API_URL + path;

    var downloadBlob = await fetch(url, { credentials: 'include' });
    downloadBlob
      .blob()
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = getId(props.wrapper).replace(/[^\w\d\.-]/g, '-') + '.pdf';
        a.click();
        hide();
      })
      .catch((e) => {
        hide();
        message.error('Gagal!');
      });
  };

  const getSaving = () => {
    console.log(props.selectedMember);
    let totalSaving = 0;
    let savingLength = props.selectedMember?.savings?.length ?? 0;
    if (savingLength > 0) {
      totalSaving = props.selectedMember?.savings?.map((e) => e.amount)?.reduce((prev, next) => prev + next);
    }
    return <span>- Simpanan senilai {toCurrencyString(totalSaving)}</span>;
  };

  const getAsset = () => {
    return props.wrapper?.data?.collateralData?.assets?.map((e: SubmissionAssetItem) => {
      if (e.type == 'sertifikat_hak_milik' || e.type == 'sertifikat_hak_guna' || e.type == 'hak_guna_usaha') {
        let buildingAreaInMeter = e.houseOrLand?.value.building.areaInMeter ?? 0;
        let buildingValuePerMeter = e.houseOrLand?.value.building.valuePerMeter ?? 0;

        let landAreaInMeter = e.houseOrLand?.value.land.areaInMeter ?? 0;
        let landValuePerMeter = e.houseOrLand?.value.land.valuePerMeter ?? 0;
        let totalValue = toCurrencyString(
          floor(buildingAreaInMeter * buildingValuePerMeter * 1.4) + floor(landAreaInMeter * landValuePerMeter * 1.4),
        );
        return (
          <span>
            - {AssetText[e.type]} senilai {totalValue}
          </span>
        );
      } else if (e.type == 'emas') {
        return (
          <span>
            - {AssetText[e.type]} senilai {toCurrencyString(e.gold?.estimatedValue)}
          </span>
        );
      } else {
        let estimatedValue = e.bpkb?.estimatedValue ?? 0;
        let monthlyServiceCost = e.bpkb?.monthlyServiceCost ?? 0;
        let vehicleAgeInMonth = e.bpkb?.vehicleAgeInMonth ?? 0;
        let totalValue = floor(0.5 * estimatedValue - monthlyServiceCost * vehicleAgeInMonth);

        return (
          <span>
            - {AssetText[e.type]} senilai {toCurrencyString(totalValue)}
          </span>
        );
      }
    });
  };

  const getLastLoan = () => {
    let loan = props.selectedMember?.loans.at(props.selectedMember.loans.length - 1);
    let remainingPercent = Math.round(((loan?.remainingAmount ?? 0) / (loan?.borrowAmount ?? 0)) * 10000) / 100;
    return (
      <span>
        {loan?.accountId} nominal pinjaman {toCurrencyString(loan?.borrowAmount)} <br />
        tersisa {toCurrencyString(loan?.remainingAmount)} ({remainingPercent}%)
      </span>
    );
  };

  const getKetkel = () => {
    if (!isEmpty(props.wrapper?.data?.biodata?.reference?.name)) {
      let nba = isEmpty(props.wrapper?.data?.biodata?.reference.memberId) ? '' : `(${props.wrapper?.data?.biodata?.reference.memberId})`;
      return (
        <span>
          - KetKel {props.wrapper?.data?.biodata?.reference.name} {nba}
        </span>
      );
    }
  };

  const getRelative = () => {
    return props.wrapper?.data?.biodata?.relatives.map((e) => {
      let nba = isEmpty(e.memberId) ? '' : `(${e.memberId})`;
      return (
        <span>
          - Saudara: {e.name} {nba}
        </span>
      );
    });
  };

  const getFamily = () => {
    if (!isEmpty(props.wrapper?.data?.biodata?.family.name)) {
      let nba = isEmpty(props.wrapper?.data?.biodata?.family.memberId) ? '' : `(${props.wrapper?.data?.biodata?.family.memberId})`;
      return (
        <span>
          - Keluarga {props.wrapper?.data?.biodata?.family.name} {nba}
        </span>
      );
    }
  };

  const handleUploadLoanAgreementDocument = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setLoanAgreementDocument(fileList);
  };

  const uploadLoanAgreement = async () => {
    console.log('masuk');
    const closeLoading = message.loading('Loading', 0);
    const formData = new FormData();
    if (props?.wrapper?.id == null || props?.wrapper?.id == undefined) {
      closeLoading();
      message.error('Tidak bisa upload Surat Perjanjian Pinjaman');
      return;
    }
    await loanAgreementDocument.map((item) => {
      if (item.originFileObj) {
        formData.append('loanAgreementUploadFile', item.originFileObj);
      }
    });
    formData.append('id', props.wrapper.id);

    doUploadLoanAgreement(formData)
      .unwrap()
      .then(() => {
        closeLoading();
        message.success('Berhasil!');
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  return (
    <>
      <Modal
        title="Verifikasi"
        visible={verificationModalOpen}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        onCancel={() => setVerificationModalOpen(false)}
      >
        <Row gutter={8}>
          <Col span="12">
            <Button type="default" block onClick={() => verifyLoanSubmission('check')}>
              Tandai Diperiksa
            </Button>
          </Col>
          <Col span="12">
            <Button type="primary" block onClick={() => verifyLoanSubmission('verification')}>
              Tandai Disahkan
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Upload Surat Perjanjian Pinjaman"
        visible={loanAgreementModalOpen}
        okText="Simpan"
        cancelText="Batal"
        onOk={() => uploadLoanAgreement()}
        onCancel={() => setLoanAgreementModalOpen(false)}
      >
        <Dragger
          beforeUpload={(file) => {
            setLoanAgreementDocument((oldState) => [...oldState, file]);
            return false;
          }}
          onChange={handleUploadLoanAgreementDocument}
          multiple={false}
          listType="picture"
          accept=".pdf"
          fileList={loanAgreementDocument}
        >
          <Button>
            <UploadOutlined /> Pilih / Drag & Drop File
          </Button>
        </Dragger>
      </Modal>
      <SubmissionStatusChangeForm
        id={props?.wrapper?.id}
        status={props?.wrapper?.status}
        isModalVisible={statusChangeModalOpen}
        setModalVisible={setStatusChangeModalOpen}
        allowStatusChange={true}
      />
      <SubmissionAprroveForm
        id={props?.wrapper?.id}
        status={props?.wrapper?.status}
        isModalVisible={approveSubmissionModalOpen}
        setModalVisible={setApproveSubmissionModalOpen}
        dueDate={props?.wrapper?.data.submissionData.dueDate}
      />
      <SubmissionHistory
        type="Status"
        id={props?.wrapper?.id}
        isModalVisible={statusHistoryModalOpen}
        setModalVisible={setStatusHistoryModalOpen}
        setSubmissionHistory={setSubmissionHistory}
      />
      <SubmissionHistory
        type="Data"
        id={props?.wrapper?.id}
        isModalVisible={dataHistoryModalOpen}
        setModalVisible={setDataHistoryModalOpen}
        setSubmissionHistory={null}
      />

      <Collapse defaultActiveKey={['1']}>
        <Collapse.Panel
          collapsible="icon"
          key="1"
          header="Ringkasan"
          style={{ marginBottom: 15 }}
          extra={
            <Space>
              <Button disabled={props.wrapper ? false : true} type="primary" size="small" onClick={() => setLoanAgreementModalOpen(true)}>
                Upload SPP
              </Button>
              <Button disabled={props.wrapper ? false : true} type="primary" size="small" onClick={() => setApproveSubmissionModalOpen(true)}>
                Approve Pinjaman
              </Button>
              <Button disabled={props.wrapper ? false : true} type="primary" size="small" onClick={() => setVerificationModalOpen(true)}>
                Verifikasi
              </Button>
              <Button disabled={props.wrapper ? false : true} type="primary" size="small" onClick={() => setStatusChangeModalOpen(true)}>
                Update Status
              </Button>
              <Button disabled={props.wrapper ? false : true} size="small" onClick={() => setStatusHistoryModalOpen(true)}>
                History Status
              </Button>
              <Button disabled={props.wrapper ? false : true} size="small" onClick={() => setDataHistoryModalOpen(true)}>
                History Perubahan
              </Button>
              <Button size="small" type="dashed" onClick={handleDownloadDocument}>
                Generate PDF
              </Button>
            </Space>
          }
        >
          <ContentWithErrorHandler isError={false} withBox={false} isLoading={props.wrapper ? false : true} fullScreen={false}>
            <Row>
              <Col span={8}>
                <Space direction="vertical" style={{ rowGap: '2px', display: 'flex' }}>
                  <span>ID/Nomor Pengajuan: {getId(props.wrapper)}</span>
                  <span>
                    Status:{' '}
                    <Tag color={LoanSubmissionStatusColor[props?.wrapper?.status || 'submitted']}>
                      {LoanSubmissionStatusText[props?.wrapper?.status || 'submitted']}
                    </Tag>
                  </span>
                  <span>Nama: {props.wrapper?.data.biodata.memberName}</span>
                  <span>NBA: {props.wrapper?.data.biodata.memberId}</span>
                  <span>Nominal Pengajuan: {toCurrencyString(props.wrapper?.data.submissionData?.amount)}</span>
                  <span>Cicilan: {props.wrapper?.data.submissionData?.installment}x</span>
                  <br />

                  <span>Jaminan</span>
                  {getSaving()}
                  {getAsset()}
                  <br />

                  <span>Pinjaman Terakhir</span>
                  {getLastLoan()}
                  <br />

                  <span>Relasi</span>
                  {getFamily()}
                  {getKetkel()}
                  {getRelative()}
                </Space>
              </Col>
              <Col span={8}>
                <div style={{ background: '#F6F6F6', padding: 10, marginRight: 15 }}>
                  <Space direction="vertical" style={{ rowGap: '2px', display: 'flex' }}>
                    <span>Tgl Pengajuan: {moment(props?.wrapper?.createdAt).format('DD/MM/YYYY HH:mm') || '-'}</span>
                    <span>Tgl Wawancara: {getInterviewDate()} </span>
                    <span>Tgl Persetujuan/Penolakan: {getApproveOrRejectDate()} </span>
                    <span>Tgl Jadwal Pencairan: {getDisbursementScheduleDate()} </span>
                    <span>Tgl Pencairan: {getDisbursementDate()} </span>
                    <br />

                    <span>Update terakhir: </span>
                    <span>Tanggal update : {moment(props?.wrapper?.updatedAt).format('DD/MM/YYYY HH:mm') || '-'}</span>
                    <span>Diupdate oleh : {props?.wrapper?.updatedBy || '-'}</span>
                    <span>
                      Alasan update : <div dangerouslySetInnerHTML={{ __html: props?.wrapper?.updateNote || '-' }}></div>
                    </span>
                  </Space>
                </div>
              </Col>
              <Col span={8}>
                <Space direction="vertical" style={{ rowGap: '2px', display: 'flex' }}>
                  <span>Diperiksa: {getVerificationData('check')}</span>
                  <span>Disahkan: {getVerificationData('verification')}</span>
                  <span>
                    Upload SPP: {props.wrapper?.data.loanAgreementDocument != null ? <Tag color="green">Sudah</Tag> : <Tag color="red">Belum</Tag>}
                  </span>
                  <br />
                </Space>
                <Space
                  direction="vertical"
                  style={{
                    display: props?.wrapper?.approvedData != null ? 'flex' : 'none',
                    borderColor: 'black',
                    borderWidth: '1px',
                    background: '#f6ffed',
                    borderStyle: 'solid',
                    width: '70%',
                    padding: '5px',
                    rowGap: '2px',
                  }}
                >
                  <span>Persetujuan Pengajuan Pinjaman: </span>
                  <span></span>
                  <span>Pinjaman Sebesar: {toCurrencyString(props?.wrapper?.approvedData?.approvedAmount)}</span>
                  <span>Biaya Provisi: {toCurrencyString(props?.wrapper?.approvedData?.provisionFee)}</span>
                  <span>Biaya Administrasi: {toCurrencyString(props?.wrapper?.approvedData?.adminFee)}</span>
                  <span>Jangka waktu: {props?.wrapper?.approvedData?.installment || '-'} bulan </span>
                  <span>
                    Bunga: {props?.wrapper?.approvedData?.insterest || '-'}%{' '}
                    {props?.wrapper?.approvedData?.interestIsPerYear ? 'pertahun' : 'perbulan'}{' '}
                    {props?.wrapper?.approvedData?.interestType ? InterestTypeText[props?.wrapper?.approvedData?.interestType] : '-'}
                  </span>
                </Space>
              </Col>
            </Row>
          </ContentWithErrorHandler>
        </Collapse.Panel>
      </Collapse>
    </>
  );
}

export default SummarySection;
