import { message } from 'antd';
import { Formik } from 'formik';
import { Form, Select, SubmitButton } from 'formik-antd';

import { useGetAllAdminQuery } from '../../admin/adminAPI';
import { useGetApprovalSignatureConfigQuery, useUpdateApprovalSignatureConfigMutation } from './submissionConfigurationAPI';

const CONFIG_KEY = 'config_loan_submission-approve_signature';

const { Option } = Select;

type InitialData = {
  value: number[];
};

function ApprovalSignatureConfig() {
  const { data: adminData, error: adminError, isLoading: adminLoading } = useGetAllAdminQuery();
  const { data, error, isLoading } = useGetApprovalSignatureConfigQuery(CONFIG_KEY);
  const [upsert] = useUpdateApprovalSignatureConfigMutation();

  const validate = (value) => {
    let error;
    if (!value || value.length == 0) {
      error = 'Tidak boleh kosong';
    }
    return error;
  };

  const save = async (value: InitialData, closeLoading: any) => {
    upsert({
      key: CONFIG_KEY,
      value: JSON.stringify(value.value),
    })
      .unwrap()
      .then(() => {
        closeLoading();
        message.success('Berhasil!');
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  return (
    <Formik
      initialValues={{
        value: data || [],
      }}
      onSubmit={async (values, actions) => {
        const hide = message.loading('Loading', 0);
        await save(values, hide);
        actions.setSubmitting(false);
      }}
      enableReinitialize={true}
    >
      {({ values, touched, errors, handleChange, handleBlur, isValid }) => (
        <Form layout="vertical">
          <Form.Item name="value" label="Pengurus/pengawas/manajemen yang bertanda tangan pada pdf approval" labelCol={{ span: 24 }}>
            <Select
              mode="multiple"
              placeholder="Pengurus/pengawas/manajemen yang bertanda tangan pada pdf approval"
              style={{ width: '100%' }}
              loading={isLoading}
              name="value"
            >
              {adminData?.map((element) => {
                return <Option value={element.id}>{element.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <SubmitButton>Simpan</SubmitButton>
        </Form>
      )}
    </Formik>
  );
}

export default ApprovalSignatureConfig;
