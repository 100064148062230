import { CopyOutlined, DeleteOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, InputNumber, message, Modal, Popconfirm, Switch, Table, TimePicker, Upload } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useNavigate, useParams } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import quillConfig from '../../common/quillConfig';
import {
  useCreateEventMutation,
  useCreateEventScheduleMutation,
  useDeleteEventScheduleMutation,
  useGetEventQuery,
  useUpdateEventMutation,
  useUpdateEventScheduleMutation,
} from './eventAPI';
import { EventSchedule, REGISTRATION_METHOD, REGISTRATION_METHOD_TEXT } from './types';

const { Option } = Select;

interface InitialData {
  name: string;
  isActive: boolean;
}

function EventDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [fileList, setFileList] = useState<any>([]);
  const [shortDescription, setShortDescription] = useState('');
  const [outline, setOutline] = useState('');
  const [initialData, setInitialData] = useState<InitialData>({
    name: '',
    isActive: false,
  });

  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(moment().add(1, 'day'));
  const [scheduleStartTime, setScheduleStartTime] = useState(moment('09:00', 'HH:mm'));
  const [scheduleEndTime, setScheduleEndTime] = useState(moment('10:00', 'HH:mm'));
  const [scheduleId, setScheduleId] = useState<any>(null);
  const [scheduleLocation, setScheduleLocation] = useState('');
  const [quota, setQuota] = useState<any>(0);
  const [registrationMethod, setRegistrationMethod] = useState(REGISTRATION_METHOD.WITHOUT_REGISTRATION);
  const [registrationLink, setRegistrationLink] = useState('');
  const [openToPublic, setOpenToPublic] = useState(false);

  const { data, isFetching, isError, refetch } = useGetEventQuery(id || '', {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const [createEvent] = useCreateEventMutation();
  const [updateEvent] = useUpdateEventMutation();
  const [createSchedule] = useCreateEventScheduleMutation();
  const [updateSchedule] = useUpdateEventScheduleMutation();
  const [deleteSchedule] = useDeleteEventScheduleMutation();

  useEffect(() => {
    if (data) {
      setInitialData({
        name: data.name,
        isActive: data.isActive,
      });
      setShortDescription(data.shortDescription);
      setOutline(data.outline);
      setFileList(
        data.image != null
          ? [
              {
                uid: '-1',
                name: data.name,
                status: 'done',
                url: data.image,
              },
            ]
          : [],
      );
    }
  }, [data]);

  const handleUploadChange = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(fileList);
  };

  const save = async (value: InitialData, closeLoading: any) => {
    if (id == undefined && fileList.length == 0) {
      closeLoading();
      message.error('Foto sampul tidak boleh kosong');
      return;
    }

    if (outline.length == 0) {
      closeLoading();
      message.error('Deskripsi tidak boleh kosong');
      return;
    }

    if (shortDescription.length == 0) {
      closeLoading();
      message.error('Deskripsi singkat tidak boleh kosong');
      return;
    }

    const formData = new FormData();
    await fileList.map((item) => {
      if (item.originFileObj) {
        formData.append('file', item.originFileObj);
      }
    });

    formData.append('name', value.name);
    formData.append('shortDescription', shortDescription);
    formData.append('outline', outline);
    formData.append('isActive', String(value.isActive));

    let promise;
    if (id) {
      formData.append('id', id);
      promise = updateEvent(formData);
    } else {
      promise = createEvent(formData);
    }

    promise
      .unwrap()
      .then((e) => {
        closeLoading();
        message.success('Berhasil!');
        if (id == null) {
          navigate(`/event/detail/${e.id}`);
        }
      })
      .catch((e) => {
        closeLoading();
        if (e != null && e.data != null) {
          message.error(e.data.message[0], 5);
        } else {
          message.error('Gagal');
        }
      });
  };

  const saveSchedule = async () => {
    const closeLoading = message.loading('Loading', 0);

    if (scheduleStartTime == null || scheduleEndTime == null) {
      closeLoading();
      message.error('Jam tidak boleh kosong');
      return;
    }
    if (scheduleDate == null) {
      closeLoading();
      message.error('Tipe tidak boleh kosong');
      return;
    }

    if (registrationMethod == REGISTRATION_METHOD.EXTERNAL_LINK && registrationLink.length == 0) {
      closeLoading();
      message.error('Link registrasi tidak boleh kosong');
      return;
    }

    if (id == null) {
      closeLoading();
      message.error('Klik simpan event terlebih dahulu sebelum menambah jadwal');
      return;
    }

    let data: EventSchedule = {
      date: scheduleDate.unix(),
      event: {
        id: Number(id),
      },
      quota: quota,
      registrationLink: registrationLink,
      registrationMethod: registrationMethod,
      startTime: `${scheduleStartTime.hour()}:${scheduleStartTime.minute()}`,
      endTime: `${scheduleEndTime.hour()}:${scheduleEndTime.minute()}`,
      location: scheduleLocation,
      openToPublic: openToPublic,
    };
    let promise;
    if (scheduleId) {
      data.id = scheduleId;
      promise = updateSchedule(data);
    } else {
      promise = createSchedule(data);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success('Berhasil!');
        refetch();
      })
      .catch((e) => {
        closeLoading();
        if (e != null && e.data != null) {
          message.error(e.data.message[0], 5);
        } else {
          message.error('Gagal');
        }
      });
  };

  const handleCancelItemModal = () => {
    setShowScheduleModal(false);
    setScheduleDate(moment().add(1, 'days'));
    setScheduleStartTime(moment('09:00', 'HH:mm'));
    setScheduleEndTime(moment('10:00', 'HH:mm'));
    setQuota(0);
    setRegistrationMethod(REGISTRATION_METHOD.WITHOUT_REGISTRATION);
    setOpenToPublic(false);
    setRegistrationLink('');
    setScheduleLocation('');
  };

  console.log(openToPublic);

  const handleOpenItemModal = (data: EventSchedule, isClone: boolean = false) => {
    setShowScheduleModal(true);
    setScheduleDate(moment.unix(data.date));
    setScheduleStartTime(moment(data.startTime, 'HH:mm'));
    setScheduleEndTime(moment(data.endTime, 'HH:mm'));
    setQuota(data.quota);
    setRegistrationMethod(data.registrationMethod);
    setRegistrationLink(data.registrationLink);
    setScheduleLocation(data.location);
    setOpenToPublic(data.openToPublic);

    if (!isClone) {
      setScheduleId(data.id);
    }
  };

  const renderScheduleModal = () => {
    return (
      <Modal title="Detail Item" visible={showScheduleModal} onCancel={handleCancelItemModal} onOk={saveSchedule} okText="Simpan" cancelText="Batal">
        <Form.Item name="date" label="Tanggal">
          <DatePicker value={scheduleDate} onChange={(date) => setScheduleDate(date || moment())} />
        </Form.Item>
        <Form.Item name="startTime" label="Waktu Mulai">
          <TimePicker value={scheduleStartTime} onChange={(date) => setScheduleStartTime(date || moment())} />
        </Form.Item>
        <Form.Item name="endTime" label="Waktu Selesai">
          <TimePicker value={scheduleEndTime} onChange={(date) => setScheduleEndTime(date || moment())} />
        </Form.Item>
        <Form.Item name="quota" label="Kuota">
          <InputNumber value={quota} onChange={setQuota} />
        </Form.Item>
        <Form.Item name="location" label="Tempat">
          <Input name="location" value={scheduleLocation} onChange={(e) => setScheduleLocation(e.target.value)} />
        </Form.Item>
        <Form.Item name="openToPublic" label="Untuk umum?">
          <Switch checked={openToPublic} onChange={setOpenToPublic} />
        </Form.Item>

        <Form.Item name="registrationMethod" label="Metode Registrasi">
          <Select
            name="registrationMethod"
            defaultValue="Pilih Metode Registrasi"
            value={registrationMethod}
            onChange={setRegistrationMethod}
            style={{ width: '100%' }}
          >
            <Option value={REGISTRATION_METHOD.WITHOUT_REGISTRATION}>Tanpa registrasi</Option>
            <Option value={REGISTRATION_METHOD.EXTERNAL_LINK}>Link eksternal (gform atau lainnya)</Option>
            <Option value={REGISTRATION_METHOD.INTERNAL}>Registrasi melalui web BDC</Option>
          </Select>
        </Form.Item>
        {registrationMethod != REGISTRATION_METHOD.EXTERNAL_LINK || (
          <Form.Item name="registrationLink" label="Link Registrasi">
            <Input
              name="registrationLink"
              placeholder="Tulis link registrasi"
              value={registrationLink}
              onChange={(event) => setRegistrationLink(event.target.value)}
            />
          </Form.Item>
        )}
      </Modal>
    );
  };

  return (
    <div>
      <ContentWithErrorHandler isError={isError} isLoading={isFetching} withBox={true}>
        <Formik
          initialValues={initialData}
          onSubmit={async (values, actions) => {
            const hide = message.loading('Loading', 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              {renderScheduleModal()}
              <Form.Item name="photo" label="Foto Sampul">
                <Upload
                  beforeUpload={(file) => {
                    setFileList((oldState) => [...oldState, file]);
                    return false;
                  }}
                  onChange={handleUploadChange}
                  multiple={false}
                  listType="picture"
                  accept=".png, .jpg, .jpeg"
                  fileList={fileList}
                >
                  <Button>
                    <UploadOutlined /> Unggah
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item name="nama" label="Nama Acara/Event">
                <Input name="name" placeholder="Masukkan nama acara/event" />
              </Form.Item>
              <Form.Item name="isActive" label="Status">
                <Select name="isActive">
                  <Option value={true}>Aktif</Option>
                  <Option value={false}>Non aktif</Option>
                </Select>
              </Form.Item>
              <Form.Item name="shortDescription" label="Deksripsi singkat">
                <ReactQuill
                  modules={quillConfig}
                  style={{
                    background: 'white',
                  }}
                  onChange={(content) => setShortDescription(content)}
                  value={shortDescription}
                />
              </Form.Item>
              <Form.Item name="outline" label="Deksripsi lLngkap">
                <ReactQuill
                  modules={quillConfig}
                  style={{
                    background: 'white',
                  }}
                  onChange={(content) => setOutline(content)}
                  value={outline}
                />
              </Form.Item>
              {id == null || (
                <Form.Item name="schedule" label="Jadwal Acara/Event">
                  <Button key="addMedia" style={{ marginBottom: 10 }} onClick={() => setShowScheduleModal(!showScheduleModal)}>
                    + Tambah Jadwal
                  </Button>
                  <Table
                    loading={data?.eventSchedules == undefined}
                    dataSource={data?.eventSchedules}
                    bordered
                    size="small"
                    pagination={{ pageSize: 10 }}
                    columns={[
                      {
                        title: 'Tanggal',
                        dataIndex: 'date',
                        key: 'date',
                        width: '10%',
                        render: (date) => {
                          return moment.unix(date).format('DD/MM/YYYY');
                        },
                      },
                      {
                        title: 'Jam',
                        key: 'time',
                        width: '10%',
                        render: (text, record) => {
                          return (
                            <>
                              {moment(record?.startTime, 'HH:mm').format('HH:mm')} s/d {moment(record?.endTime, 'HH:mm').format('HH:mm')}
                            </>
                          );
                        },
                      },
                      {
                        title: 'Kuota',
                        key: 'quota',
                        dataIndex: 'quota',
                        width: '5%',
                      },
                      {
                        title: 'Tempat',
                        key: 'location',
                        dataIndex: 'location',
                        width: '20%',
                      },
                      {
                        title: 'Pendaftaran',
                        dataIndex: 'registrationMethod',
                        key: 'registrationMethod',
                        width: '10%',
                        render: (data) => {
                          return REGISTRATION_METHOD_TEXT[data];
                        },
                      },
                      {
                        title: 'Link',
                        key: 'registrationLink',
                        dataIndex: 'registrationLink',
                        width: '20%',
                        render: (data) => {
                          return data || '-';
                        },
                      },
                      {
                        title: 'Untuk Umum?',
                        key: 'openToPublic',
                        dataIndex: 'openToPublic',
                        width: '5%',
                        render: (data) => {
                          return data ? 'Ya' : 'Tidak';
                        },
                      },
                      {
                        title: 'Aksi',
                        key: 'x',
                        width: '15%',
                        render: (text, record) => (
                          <div>
                            <Button type="link" icon={<EditOutlined />} style={{ borderRadius: 5 }} onClick={() => handleOpenItemModal(record)} />
                            <Button
                              type="link"
                              icon={<CopyOutlined />}
                              style={{ borderRadius: 5 }}
                              onClick={() => handleOpenItemModal(record, true)}
                            />
                            <Popconfirm
                              title="Apakah anda yakin?"
                              onConfirm={() => {
                                const hideLoading = message.loading('Loading', 0);
                                deleteSchedule(record?.id || -1)
                                  .unwrap()
                                  .then(() => {
                                    hideLoading();
                                    message.success('Berhasil!');
                                  })
                                  .catch(() => {
                                    hideLoading();
                                    message.error('Gagal!');
                                  });
                              }}
                              okText="Hapus"
                              cancelText="Batal"
                            >
                              <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                            </Popconfirm>
                          </div>
                        ),
                      },
                    ]}
                  />
                </Form.Item>
              )}
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default EventDetail;
