import { EyeOutlined } from '@ant-design/icons';
import { Button, Card, Table, Tag } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { useDeleteEventMutation, useGetAllEventScheduleQuery } from './eventAPI';
import { REGISTRATION_METHOD_TEXT } from './types';

function EventScheduleList() {
  const navigate = useNavigate();
  const { data, error, isFetching } = useGetAllEventScheduleQuery();
  const [deleteGallery] = useDeleteEventMutation();

  return (
    <ContentWithErrorHandler isError={false} isLoading={false}>
      <Card title="Data Jadwal Acara" size="small">
        <Table
          loading={isFetching}
          dataSource={data}
          bordered
          size="small"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              title: 'Status',
              dataIndex: 'date',
              key: 'status',
              width: '10%',
              render: (date) => {
                let current = moment().unix();
                return date < current ? <Tag color="gray">Done</Tag> : <Tag color="blue">Upcoming</Tag>;
              },
            },
            {
              title: 'Nama',
              dataIndex: ['event', 'name'],
              key: 'event.name',
              width: '10%',
            },
            {
              title: 'Tanggal',
              dataIndex: 'date',
              key: 'date',
              width: '10%',
              render: (date) => {
                return moment.unix(date).format('DD/MM/YYYY');
              },
            },
            {
              title: 'Jam',
              key: 'time',
              width: '10%',
              render: (text, record) => {
                return (
                  <>
                    {moment(record?.startTime, 'HH:mm').format('HH:mm')} s/d {moment(record?.endTime, 'HH:mm').format('HH:mm')}
                  </>
                );
              },
            },
            {
              title: 'Kuota',
              key: 'quota',
              dataIndex: 'quota',
              width: '10%',
            },
            {
              title: 'Untuk umum?',
              key: 'openToPublic',
              dataIndex: 'openToPublic',
              width: '5%',
              render: (data) => {
                return data ? 'Ya' : 'Tidak';
              },
            },
            {
              title: 'Tempat',
              key: 'location',
              dataIndex: 'location',
              width: '20%',
            },
            {
              title: 'Pendaftaran',
              dataIndex: 'registrationMethod',
              key: 'registrationMethod',
              width: '15%',
              render: (data) => {
                return REGISTRATION_METHOD_TEXT[data];
              },
            },
            {
              title: 'Link',
              key: 'registrationLink',
              dataIndex: 'registrationLink',
              width: '30%',
              render: (data) => {
                return data || '-';
              },
            },
            {
              title: 'Aksi',
              key: 'x',
              width: '10%',
              render: (text, record) => (
                <div>
                  <Button type="link" icon={<EyeOutlined />} style={{ borderRadius: 5 }} onClick={() => navigate(`detail/${record.id}`)} />
                </div>
              ),
            },
          ]}
        />
      </Card>
    </ContentWithErrorHandler>
  );
}

export default EventScheduleList;
