import { message } from "antd";
import { Formik } from "formik";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import { useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import {
  useCreateAdminPositionMutation,
  useGetAdminPositionCodeQuery,
  useGetAdminPositionQuery,
  useUpdateAdminPositionMutation,
} from "./adminAPI";

const { Option } = Select;

function AdminPositionDetail() {
  const { id } = useParams();

  const {
    data: adminPositionData,
    isLoading: adminPositionDataIsLoading,
    isError: adminPositionDataIsError,
  } = useGetAdminPositionQuery(id || '', {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const [updateAdminPosition] = useUpdateAdminPositionMutation();
  const [createAdminPosition] = useCreateAdminPositionMutation();

  const validate = (value) => {
    let error;
    if (!value) {
      error = 'Tidak boleh kosong';
    }
    return error;
  };

  const save = async (value: any, closeLoading: any) => {
    let data: any = { ...value };

    let promise;
    if (id) {
      data.id = id;
      promise = updateAdminPosition(data);
    } else {
      promise = createAdminPosition(data);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success('Berhasil!');
      })
      .catch(() => {
        closeLoading();
        message.error('Gagal!');
      });
  };

  return (
    <div>
      <ContentWithErrorHandler isError={adminPositionDataIsError} isLoading={adminPositionDataIsLoading} withBox={true}>
        <Formik
          initialValues={{
            name: '',
          }}
          onSubmit={async (values, actions) => {
            const hide = message.loading('Loading', 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
        >
          {({ values, touched, errors, handleChange, handleBlur, isValid, submitForm }) => (
            <Form layout="vertical">
              <Form.Item name="name" label="Nama" validate={validate}>
                <Input name="name" placeholder="Masukkan Nama" />
              </Form.Item>
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        </Formik>
      </ContentWithErrorHandler>
    </div>
  );
}

export default AdminPositionDetail;
