import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { Formik } from "formik";
import { Form, Input, InputNumber, Select, SubmitButton } from "formik-antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import quillConfig from "../../common/quillConfig";
import { splitAndGetIndex } from "../../common/tokenizer";
import { useCreateGeneralInfoMutation, useGetGeneralInfoQuery, useUpdateGeneralInfoMutation } from "./generalInfoAPI";

const { Option } = Select;

interface InitialData {
  title: string;
  interest: number;
  viewOrder: number;
}

function GeneralInfoTypeDetail() {
  const { id, serviceType } = useParams();

  const [fileList, setFileList] = useState<any>([]);
  const [content, setContent] = useState("");
  const [initialData, setInitialData] = useState<InitialData>({
    title: "",
    interest: 0,
    viewOrder: 1,
  });

  const { data: generalInfo, isLoading: generalInfoIsLoading, isError: generalInfoIsError } = useGetGeneralInfoQuery(id || "", {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const [updateGeneralInfo] = useUpdateGeneralInfoMutation();
  const [createGeneralInfo] = useCreateGeneralInfoMutation();

  const interestContent = serviceType == "loan" ? "perbulan" : "pertahun";
  const serviceTypeContent = serviceType == "loan" ? "Pinjaman" : "Simpanan";

  useEffect(() => {
    if (generalInfo) {
      setInitialData({
        title: generalInfo.title,
        interest: generalInfo.interest,
        viewOrder: parseInt(splitAndGetIndex(generalInfo.type, 2)),
      });
      setContent(generalInfo.content);
      setFileList(
        generalInfo.imageUrl != null
          ? [
              {
                uid: "-1",
                name: generalInfo.title,
                status: "done",
                url: generalInfo.imageUrl,
              },
            ]
          : []
      );
    }
  }, [generalInfo]);

  const handleUploadChange = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(fileList);
  };

  const validate = (value) => {
    let error;
    if (!value) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  const save = async (value: InitialData, closeLoading: any) => {
    if (content.length == 0) {
      closeLoading();
      message.error("Konten tidak boleh kosong");
      return;
    }

    const formData = new FormData();
    await fileList.map((item) => {
      if (item.originFileObj) {
        formData.append("file", item.originFileObj);
      }
    });

    formData.append("title", value.title);
    formData.append("content", content);
    formData.append("interest", String(value.interest));
    formData.append("type", `${serviceType}.type.${value.viewOrder}`);
    formData.append("isHeader", String(false));

    let promise;
    if (id) {
      formData.append("id", id);
      promise = updateGeneralInfo(formData);
    } else {
      promise = createGeneralInfo(formData);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success("Berhasil!");
      })
      .catch(() => {
        closeLoading();
        message.error("Gagal!");
      });
  };

  return (
    <div>
      <ContentWithErrorHandler isError={generalInfoIsError} isLoading={generalInfoIsLoading} withBox={true}>
        <Formik
          initialValues={initialData}
          onSubmit={async (values, actions) => {
            const hide = message.loading("Loading", 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              <Form.Item name="photo" label="Foto Sampul">
                <Upload
                  beforeUpload={(file) => {
                    setFileList((oldState) => [...oldState, file]);
                    return false;
                  }}
                  onChange={handleUploadChange}
                  multiple={false}
                  listType="picture"
                  accept=".png, .jpg, .jpeg"
                  fileList={fileList}
                >
                  <Button>
                    <UploadOutlined /> Unggah
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item name="title" label={`Nama ${serviceTypeContent}`} validate={validate}>
                <Input name="title" placeholder={`Masukkan nama ${serviceTypeContent}`} />
              </Form.Item>
              <Form.Item name="interest" label={`Masukkan besaran bunga ${interestContent}`}>
                <Input name="interest" addonAfter="%" placeholder={`Masukkan besaran bunga ${interestContent}`} />
              </Form.Item>
              <Form.Item name="viewOrder" label="Urutan">
                <InputNumber name="viewOrder" placeholder="Masukkan urutan" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item name="content" label="Deskripsi">
                <ReactQuill
                  modules={quillConfig}
                  style={{
                    background: "white",
                  }}
                  onChange={(content) => setContent(content)}
                  value={content}
                />
              </Form.Item>
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default GeneralInfoTypeDetail;
