import { DeleteOutlined, DownloadOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Card, List, Modal, Popconfirm, Progress, Space, Table, Tag, Upload, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { TableColumnTextFilterConfig } from '../../common/TableHelper';
import { useCreateQuizQuestionMutation, useDeleteQuizQuestionMutation, useGetAllQuizQuestionQuery } from './quizAPI';
import { QuizQuestion } from './types';
import { useState } from 'react';
import { parse, unparse } from 'papaparse';

let sampleCsv = [
  {
    pertanyaan: 'Pertanyaan 1?',
    aktif: 'true',
    jawaban_benar: '1',
    pilihan_jawaban: 'Jawaban 1(benar).#Jawaban 2.#Jawaban 3.',
  },
  {
    pertanyaan: 'Pertanyaan 2?',
    aktif: 'true',
    jawaban_benar: '2',
    pilihan_jawaban: 'Jawaban 1.#Jawaban 2(benar).#Jawaban 3.',
  },
  {
    pertanyaan: 'Pertanyaan 3?',
    aktif: 'false',
    jawaban_benar: '3',
    pilihan_jawaban: 'Jawaban 1.#Jawaban 2.#Jawaban 3(benar).',
  },
];

function QuizQuestionList() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetAllQuizQuestionQuery();
  const [deleteQuiz] = useDeleteQuizQuestionMutation();
  const [createQuiz] = useCreateQuizQuestionMutation();

  const [modalVisible, setModalVisible] = useState(false);
  const [bulkData, setBulkData] = useState<any>([]);

  function Wait() {
    return new Promise((r) => setTimeout(r, 100));
  }

  const convertToCsv = () => {
    if (data == undefined || data == null) return [];
    let res: any[] = [];
    for (let datum of data) {
      let resDatum: any = {};
      resDatum.pertanyaan = datum.question;
      resDatum.aktif = datum.isActive;
      resDatum.jawaban_benar = datum.answers.findIndex((e) => e.isCorrectAnswer) + 1;
      resDatum.pilihan_jawaban = datum.answers.map((e) => e.text).join('#');
      res.push(resDatum);
    }
    return res;
  };

  const handleExport = async (data, name) => {
    const hide = message.loading('Loading', 0);
    if (!data) {
      hide();
      message.error('Gagal!');
    }

    var csvData = new Blob([unparse(data)], { type: 'text/csv;charset=utf-8;' });
    let url = window.URL.createObjectURL(csvData);
    let a = document.createElement('a');
    a.href = url;
    a.download = name + '.csv';
    a.click();
    hide();
  };

  const handleUploadBulk = async () => {
    let uploadProgress = 0;
    let totalProgress = bulkData.length + (data ? data.length : 0);
    const hide = message.open({
      content: (
        <Space>
          Loading
          <Progress percent={(uploadProgress / totalProgress) * 100} showInfo={false} steps={100} size="small" />
        </Space>
      ),
      duration: 0,
      key: 'upload-bulk-loading',
    });
    try {
      if (data != null) {
        for (let datum of data) {
          await deleteQuiz(datum.id);
          uploadProgress++;
          message.open({
            content: (
              <Space>
                Loading
                <Progress percent={(uploadProgress / totalProgress) * 100} showInfo={false} steps={100} size="small" />
              </Space>
            ),
            duration: 0,
            key: 'upload-bulk-loading',
          });
        }
      }

      for (let datum of bulkData) {
        await createQuiz(datum);
        await Wait();
        uploadProgress++;
        message.open({
          content: (
            <Space>
              Loading
              <Progress percent={(uploadProgress / totalProgress) * 100} showInfo={false} steps={100} size="small" />
            </Space>
          ),
          duration: 0,
          key: 'upload-bulk-loading',
        });
      }
    } catch (e) {
      console.log(e);
      hide();
      message.error('Gagal!');
      return;
    }
    hide();
    message.success('Berhasil!');
  };

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Modal title="Import Pertanyaan" open={modalVisible} onOk={handleUploadBulk} onCancel={(e) => setModalVisible(false)}>
        <Space direction="vertical">
          <Alert
            message="Perlu diperhatikan"
            description={
              <>
                <p>File dapat dibuka menggunakan excel namun harus diupload dalam bentuk ".csv"</p>
                Daftar kolom
                <ol style={{ paddingLeft: 16 }}>
                  <li>pertanyaan: teks pertanyaan</li>
                  <li>aktif: apakah pertanyaan ini aktif, berisi nilai true/false</li>
                  <li>jawaban_benar: index jawaban yg benar sesuai dengan pilihan jawaban, dimulai dari angka 1</li>
                  <li>pilihan_jawaban: daftar pilihan jawaban, antar jawaban dipisahkan dengan simbol #</li>
                </ol>
              </>
            }
            type="info"
            showIcon
          />
          <Button icon={<DownloadOutlined />} onClick={(e) => handleExport(sampleCsv, 'contoh_quiz')}>
            Download contoh csv
          </Button>
          <Alert message="Import data akan menghapus seluruh data yang telah ada sebelumnya" type="warning" showIcon />
          <Upload
            beforeUpload={(file) => {
              const reader = new FileReader();

              reader.onload = (e: any) => {
                const csv = parse(e.target?.result, {
                  header: true,
                  skipEmptyLines: true,
                });
                const parsedData: any = csv?.data;

                console.log(parsedData);
                let tempBulkData: any[] = [];
                for (let row of parsedData) {
                  let datum = {};
                  let correctAnswerIdx = -1;
                  console.log(row);
                  for (let objKey in row) {
                    console.log(objKey, row[objKey]);
                    if (objKey.toLowerCase() == 'pertanyaan') {
                      datum['question'] = row[objKey];
                    } else if (objKey.toLowerCase() == 'aktif') {
                      datum['isActive'] = row[objKey].toLowerCase() === 'true';
                    } else if (objKey.toLowerCase() == 'pilihan_jawaban') {
                      let answerList = row[objKey].split('#');
                      datum['answers'] = answerList.map((e) => {
                        return {
                          text: e,
                          isCorrectAnswer: false,
                        };
                      });
                    } else if (objKey.toLowerCase() == 'jawaban_benar') {
                      correctAnswerIdx = Number(row[objKey]);
                    }
                  }

                  console.log(datum);
                  datum['answers'][correctAnswerIdx - 1].isCorrectAnswer = true;
                  tempBulkData.push(datum);
                }
                console.log(tempBulkData);
                setBulkData(tempBulkData);
              };
              reader.readAsText(file);

              // Prevent upload
              return false;
            }}
            multiple={false}
            maxCount={1}
            listType="picture"
            accept=".csv"
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Space>
      </Modal>

      <Card
        title="Data Pertanyaan"
        size="small"
        extra={
          <Space>
            <Button icon={<UploadOutlined />} onClick={(e) => setModalVisible(true)}>
              Import Pertanyaan
            </Button>
            <Button icon={<DownloadOutlined />} onClick={() => handleExport(convertToCsv(), 'pertanyaan_quiz')}>
              Export Pertanyaan
            </Button>
            <Button style={{ borderRadius: 5 }}>
              <Link to="create">+ Tambah Baris</Link>
            </Button>
          </Space>
        }
      >
        <Table
          loading={isLoading}
          dataSource={data}
          bordered
          size="small"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              title: 'Status',
              dataIndex: 'isActive',
              key: 'isActive',
              render: (item) => {
                if (!item) {
                  return <Tag color="red">TIDAK AKTIF</Tag>;
                } else {
                  return <Tag color="geekblue">AKTIF</Tag>;
                }
              },
              width: '10%',
            },
            {
              title: 'Pertanyaan',
              dataIndex: 'question',
              key: 'question',
              width: '30%',
              ...TableColumnTextFilterConfig<QuizQuestion>('name'),
            },
            {
              title: 'Pilihan Jawaban',
              dataIndex: 'answers',
              key: 'anwers',
              width: '45%',
              render: (answers) => {
                return (
                  <>
                    {answers.map((e, i) => (
                      <List.Item
                        key={e.text}
                        style={{
                          border: `${e.isCorrectAnswer ? 'green' : 'lightgray'} 1px solid`,
                          paddingLeft: '6px',
                          paddingRight: '6px',
                          marginBottom: '6px',
                          background: e.isCorrectAnswer ? 'palegreen' : 'transparent',
                        }}
                      >
                        <span style={{ fontWeight: 'bold' }}>{i + 1}.</span>
                        {' ' + e.text}
                      </List.Item>
                    ))}
                  </>
                );
              },
            },
            {
              title: 'Aksi',
              key: 'x',
              width: '10%',
              render: (text, record) => (
                <div>
                  <Button type="link" icon={<EditOutlined />} style={{ borderRadius: 5 }} onClick={() => navigate(`detail/${record.id}`)} />
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading('Loading', 0);
                      deleteQuiz(record.id)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success('Berhasil!');
                        })
                        .catch(() => {
                          hideLoading();
                          message.error('Gagal!');
                        });
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
    </ContentWithErrorHandler>
  );
}

export default QuizQuestionList;
