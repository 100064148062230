import { MinusCircleOutlined, PlusSquareFilled } from "@ant-design/icons";
import { Button, Col, Divider, Row, Space } from "antd";
import { FieldArray } from "formik";
import { DatePicker, Form, Input, InputNumber, Select } from "formik-antd";
import { useEffect } from 'react';
import ContentWithErrorHandler from '../../../app/ContentWithErrorHandler';
import { IncomeType, SimpleIncomeData, SubmissionForm } from './types';

const DATE_FORMAT = 'DD/MM/YYYY';

const { Option } = Select;

type Props = {
  formValue: SubmissionForm;
  setFieldValue: any;
};

function IncomeDataForm(props: Props) {
  const calculateTotalIncome = (type: IncomeType) => {
    let total = 0;
    for (let item of props.formValue.incomeData.business.item) {
      if (item.type == type) total += item.amount;
    }
    for (let item of props.formValue.incomeData.job.item) {
      if (item.type == type) total += item.amount;
    }

    return total;
  };

  useEffect(() => {
    props.setFieldValue('incomeData.estimation.incomePerMonth', calculateTotalIncome(IncomeType.cash_in));
    props.setFieldValue('incomeData.estimation.expensesPerMonth', calculateTotalIncome(IncomeType.cash_out));
  }, [props.formValue.incomeData.job, props.formValue.incomeData.business]);

  return (
    <ContentWithErrorHandler isError={false} isLoading={false} withBox={false}>
      <Row gutter={[8, 0]}>
        <Col span={24}>
          <Divider plain>Estimasi Per Bulan</Divider>
          <Form.Item name="incomeData.estimation.incomePerMonth" label="Estimasi penghasilan bulanan usaha/pekerjaan (read-only)">
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              placeholder="Lengkapi estimasi penghasilan bulanan usaha/pekerjaan"
              name="incomeData.estimation.incomePerMonth"
              readOnly
            />
          </Form.Item>
          <Form.Item name="incomeData.estimation.expensesPerMonth" label="Estimasi pengeluaran bulanan (read-only)">
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              placeholder="Lengkapi estimasi pengeluaran bulanan"
              name="incomeData.estimation.expensesPerMonth"
              readOnly
            />
          </Form.Item>
          <Form.Item name="incomeData.estimation.otherIncomePerMonth" label="Estimasi penghasilan bulanan lain-lain (dapat diedit)">
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
              placeholder="Lengkapi estimasi penghasilan bulanan lain-lain"
              name="incomeData.estimation.otherIncomePerMonth"
            />
          </Form.Item>

          <Divider plain>Data Pekerjaan</Divider>
          <Form.Item name="incomeData.job.name" label="Nama perusahaan tempat bekerja">
            <Input fast placeholder="Lengkapi nama perusahaan tempat bekerja" name="incomeData.job.name" />
          </Form.Item>
          <Form.Item name="incomeData.job.position" label="Posisi Pekerjaan">
            <Input fast placeholder="Lengkapi posisi pekerjaan" name="incomeData.job.position" />
          </Form.Item>
          <Form.Item name="incomeData.job.startAt" label="Tanggal Mulai Pekerjaan">
            <DatePicker name="incomeData.job.startAt" />
          </Form.Item>
          <FieldArray name="incomeData.job.item">
            {({ push, remove }) => (
              <div>
                {props.formValue.incomeData.job.item.map((item: SimpleIncomeData, index) => {
                  const type = `incomeData.job.item[${index}].type`;
                  const text = `incomeData.job.item[${index}].text`;
                  const amount = `incomeData.job.item[${index}].amount`;
                  return (
                    <Row gutter={[4, 4]} key={item.id}>
                      <Col span={4}>
                        <Form.Item name={type} label="Tipe">
                          <Select fast name={type} value={item.type} style={{ width: '100%' }}>
                            <Option value={IncomeType.cash_in}>Pemasukan</Option>
                            <Option value={IncomeType.cash_out}>Pengeluaran</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name={text} label="Keterangan">
                          <Input fast name={text} value={item.text} placeholder="Lengkapi Keterangan" />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item name={amount} label="Jumlah">
                          <InputNumber
                            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                            name={amount}
                            value={item.amount}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <MinusCircleOutlined onClick={() => remove(index)} />
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  block
                  icon={<PlusSquareFilled />}
                  onClick={() => push({ id: new Date().getTime(), text: null, amount: 0, type: IncomeType.cash_in })}
                >
                  Tambah Item Pendapatan/Pengeluaran Pekerjaan
                </Button>
              </div>
            )}
          </FieldArray>

          <Divider plain>Data Bisnis/Usaha</Divider>
          <Form.Item name="incomeData.business.name" label="Nama bisnis/usaha">
            <Input fast placeholder="Lengkapi nama perusahaan tempat bekerja" name="incomeData.business.name" />
          </Form.Item>
          <Form.Item name="incomeData.business.type" label="Jenis bisnis">
            <Input fast placeholder="Lengkapi jenis bisnis" name="incomeData.business.type" />
          </Form.Item>
          <Form.Item name="incomeData.business.startAt" label="Tanggal Mulai Pekerjaan">
            <DatePicker name="incomeData.business.startAt" />
          </Form.Item>
          <FieldArray name="incomeData.business.item">
            {({ push, remove }) => (
              <div>
                {props.formValue.incomeData.business.item.map((item: SimpleIncomeData, index) => {
                  const type = `incomeData.business.item[${index}].type`;
                  const text = `incomeData.business.item[${index}].text`;
                  const amount = `incomeData.business.item[${index}].amount`;
                  return (
                    <Row gutter={[4, 4]} key={item.id}>
                      <Col span={4}>
                        <Form.Item name={type} label="Tipe">
                          <Select fast name={type} value={item.type} style={{ width: '100%' }}>
                            <Option value={IncomeType.cash_in}>Pemasukan</Option>
                            <Option value={IncomeType.cash_out}>Pengeluaran</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name={text} label="Keterangan">
                          <Input fast name={text} value={item.text} placeholder="Lengkapi Keterangan" />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item name={amount} label="Jumlah">
                          <InputNumber
                            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                            name={amount}
                            value={item.amount}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <MinusCircleOutlined onClick={() => remove(index)} />
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  block
                  icon={<PlusSquareFilled />}
                  onClick={() => push({ id: new Date().getTime(), text: null, amount: 0, type: IncomeType.cash_in })}
                >
                  Tambah Item Pendapatan/Pengeluaran Bisnis
                </Button>
              </div>
            )}
          </FieldArray>
        </Col>
      </Row>
    </ContentWithErrorHandler>
  );
}

export default IncomeDataForm;
