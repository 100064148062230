import { Layout, Spin } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { whoAmI } from "../features/auth/authAPI";
import { RootState } from "./store";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

interface Props {
  component: React.ComponentType;
  path?: string;
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const auth = useSelector((state: RootState) => state.auth);
  let location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.status == "idle" || auth.timestamp < moment().subtract(30, "minute").valueOf()) {
      dispatch(whoAmI());
    }
  });

  if (auth.status == "success" && auth.isAuthenticated) {
    return <RouteComponent />;
  }

  if (auth.status == "failed") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <Layout
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Spin indicator={antIcon} />
    </Layout>
  );
};
