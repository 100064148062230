import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, message, Space, Typography } from "antd";
import { FieldArray, Formik } from "formik";
import { Form, Input, InputNumber, Select, SubmitButton } from "formik-antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import {
  useCreateQuestionaireMutation,
  useGetAllQuestionaireQuery,
  useGetQuestionaireQuery,
  useUpdateQuestionaireMutation,
} from "./financialLiteracyAPI";
import { PreviousId, QuestionaireAnswer } from "./types";

const { Option } = Select;
const { Text } = Typography;

interface InitialAnswer {
  text: string;
  impact: {
    saving: number;
    expense: number;
    debt: number;
  };
}

interface InitialData {
  question: string;
  previousId: PreviousId[];
  answers: InitialAnswer[];
  type: string;
}

function FinancialLiteracyQuestionDetail() {
  const { id } = useParams();
  const [initialData, setInitialData] = useState<InitialData>({
    question: "",
    previousId: [],
    answers: [],
    type: "type",
  });

  const { data: allData, error: allDataError, isLoading: allDataIsLoading } = useGetAllQuestionaireQuery();
  const { data: questionaireData, isLoading: questionaireDataIsLoading, isError: questionaireDataIsError } = useGetQuestionaireQuery(
    id || "",
    {
      skip: id ? false : true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const [updateQuestionaire] = useUpdateQuestionaireMutation();
  const [createQuestionaire] = useCreateQuestionaireMutation();

  const validate = (value) => {
    let error;
    if (!value) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  useEffect(() => {
    if (questionaireData) {
      setInitialData({
        question: questionaireData.question,
        type: questionaireData.type,
        answers: parseAnswers(questionaireData.answers || []),
        previousId: parsePreviousId(questionaireData.previousId || ""),
      });
    }
  }, [questionaireData]);

  const parseAnswers = (answers: QuestionaireAnswer[]) => {
    let result: InitialAnswer[] = [];
    for (let answer of answers) {
      result.push({
        text: answer.text,
        impact: {
          debt: answer.impact?.debt || 0,
          saving: answer.impact?.saving || 0,
          expense: answer.impact?.expense || 0,
        },
      });
    }
    return result;
  };

  const parsePreviousId = (previousData: string) => {
    let result: PreviousId[] = [];

    if (previousData.length > 0) {
      let splitByQuestions: string[] = previousData.split("|");
      for (let splitByQuestion of splitByQuestions) {
        let split: string[] = splitByQuestion.split("#");
        result.push({
          questionId: Number(split[0]),
          answerIndex: split[1] != "ALL" ? Number(split[1]) : -100,
        });
      }
    }

    return result;
  };

  const stringifyPreviousId = (inputs: PreviousId[]) => {
    let res = "";
    for (let i = 0; i < inputs.length; i++) {
      res += inputs[i].questionId + (inputs[i].answerIndex == -100 ? "#ALL" : "#" + inputs[i].answerIndex);
      if (i + 1 < inputs.length) res += "|";
    }
    return res;
  };

  const save = async (value: InitialData, closeLoading: any) => {
    let promise;
    if (id) {
      promise = updateQuestionaire({ id, ...value, previousId: stringifyPreviousId(value.previousId) });
    } else {
      promise = createQuestionaire({ ...value, previousId: stringifyPreviousId(value.previousId) });
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success("Berhasil!");
      })
      .catch(() => {
        closeLoading();
        message.error("Gagal!");
      });
  };

  return (
    <div>
      <ContentWithErrorHandler isError={questionaireDataIsError} isLoading={questionaireDataIsLoading} withBox={true}>
        <Formik
          initialValues={initialData}
          onSubmit={async (values, actions) => {
            const hide = message.loading("Loading", 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={({ values }) => (
            <Form layout="vertical">
              <Form.Item name="question" label="Pertanyaan" validate={validate}>
                <Input name="question" placeholder="Masukkan pertanyaan" />
              </Form.Item>
              <Form.Item name="previousId" label="Terhubung dengan pertanyaan sebelumnya">
                <FieldArray
                  name="previousId"
                  render={(arrayHelpers) => (
                    <div>
                      {values.previousId.map((prevId, index) => (
                        <div key={index} style={{ width: "100%", display: "flex", marginBottom: "12px" }}>
                          <Select name={`previousId.${index}.questionId`} style={{ flexGrow: 1, marginRight: "6px" }}>
                            {allData?.map((data) => {
                              if (data.id != questionaireData?.id) {
                                return (
                                  <Option value={data.id} key={data.id}>
                                    {data.question}
                                  </Option>
                                );
                              }
                            })}
                          </Select>

                          <Select name={`previousId.${index}.answerIndex`} style={{ flexGrow: 1, marginRight: "6px" }}>
                            <Option value={-100} key={-100}>
                              Semua jawaban
                            </Option>
                            {allData?.map((data) => {
                              if (data.id == prevId.questionId) {
                                return data.answers.map((answer, index) => (
                                  <Option value={index} key={index}>
                                    {answer.text}
                                  </Option>
                                ));
                              }
                            })}
                          </Select>

                          <Button
                            icon={<MinusCircleOutlined />}
                            type="text"
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          />
                        </div>
                      ))}

                      <Button icon={<PlusOutlined />} onClick={() => arrayHelpers.push("")}>
                        Tambah
                      </Button>
                    </div>
                  )}
                />
              </Form.Item>

              <Form.Item name="answers" label="Jawaban">
                <FieldArray
                  name="answers"
                  render={(arrayHelpers) => (
                    <div>
                      {values.answers.map((answer, index) => (
                        <div key={index} style={{ width: "100%", display: "flex", marginBottom: "12px" }}>
                          <div style={{ border: "lightgray 1px solid", padding: 12 }}>
                            <Text>Jawaban {index + 1}</Text>
                            <Input.TextArea name={`answers.${index}.text`} />

                            <Text>Bobot</Text>
                            <Space>
                              <div>
                                <Text>Nilai untuk simpanan</Text>
                                <InputNumber style={{ width: "100%" }} name={`answers.${index}.impact.saving`} />
                              </div>
                              <div>
                                <Text>Nilai untuk pengeluaran</Text>
                                <InputNumber style={{ width: "100%" }} name={`answers.${index}.impact.expense`} />
                              </div>
                              <div>
                                <Text>Nilai untuk hutang</Text>
                                <InputNumber style={{ width: "100%" }} name={`answers.${index}.impact.debt`} />
                              </div>
                            </Space>
                          </div>
                          <Button
                            icon={<MinusCircleOutlined />}
                            type="text"
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          />
                        </div>
                      ))}

                      <Button
                        icon={<PlusOutlined />}
                        onClick={() =>
                          arrayHelpers.push({
                            text: "",
                            impact: {
                              saving: 0,
                              expense: 0,
                              debt: 0,
                            },
                          })
                        }
                      >
                        Tambah
                      </Button>
                    </div>
                  )}
                />
              </Form.Item>

              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default FinancialLiteracyQuestionDetail;
