import { CopyOutlined, DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Card, message, Popconfirm, Table, Tabs, Tooltip, Typography } from 'antd';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import ContentWithErrorHandler from '../../app/ContentWithErrorHandler';
import { basicSort, DynamicFilter } from '../../common/TableHelper';
import { useDeleteKpiMutation, useGetKpiListQuery } from './adminAPI';
import ManagePerformanceEdit from './ManagePerformanceEdit';
import ManagePerformanceView from './ManagePerformanceView';
import { IndicatorTypeText, KPIBaseEntity, KPITarget, OperatorTypeText, TargetType } from './types';

const { Text } = Typography;

function ManagePerformance() {
  return (
    <ContentWithErrorHandler isError={false} isLoading={false}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="View KPI" key="1">
          <ManagePerformanceView />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Manage KPI" key="2">
          <ManagePerformanceEdit />
        </Tabs.TabPane>
      </Tabs>
    </ContentWithErrorHandler>
  );
}

export default ManagePerformance;
