import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, message, Popconfirm, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { basicSort, basicSortDateString, TableColumnTextFilterConfig } from "../../common/TableHelper";
import { useDeleteNewsMutation, useGetAllNewsQuery } from "./newsAPI";
import { News } from "./types";

interface TagItem {
  text: string;
  value: string;
}

function NewsList() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetAllNewsQuery();
  const [deleteNews] = useDeleteNewsMutation();

  const [tags, setTags] = useState<TagItem[]>([]);

  useEffect(() => {
    if (data) {
      let tagList = data.flatMap((element) => element.tags);
      let tagListName = tagList.map((element) => element.name);
      tagListName = [...new Set(tagListName)];
      setTags(
        tagListName.map(
          (e: any): TagItem => {
            return { text: e, value: e };
          }
        )
      );
    }
  }, [data]);

  const getDate = (stringDate) => {
    return new Date(stringDate).toLocaleDateString("id-ID");
  };

  return (
    <ContentWithErrorHandler isError={error} isLoading={false}>
      <Card
        title="Data Berita"
        size="small"
        extra={
          <div>
            <Button style={{ borderRadius: 5 }}>
              <Link to="create">+ Tambah Baris</Link>
            </Button>
          </div>
        }
      >
        <Table
          loading={isLoading}
          dataSource={data}
          bordered
          size="small"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              title: "Status",
              dataIndex: "isPublished",
              key: "isPublished",
              render: (text, record) => {
                if (!record.isPublished) {
                  return <Tag color="red">DRAF</Tag>;
                } else {
                  return <Tag color="geekblue">TERBIT</Tag>;
                }
              },
              width: "5%",
            },
            {
              title: "Tanggal Publikasi",
              dataIndex: "createdAt",
              key: "createdAt",
              width: "10%",
              sorter: (a, b) => basicSortDateString(a.createdAt, b.createdAt),
              render: (createdAt) => {
                return <> {getDate(createdAt)} </>;
              },
            },
            {
              title: "Kategori",
              dataIndex: "tags",
              key: "tags",
              width: "25%",
              filters: tags,
              onFilter: (value: any, record) => record.tags.map((e) => e.name).includes(value),
              render: (tag) => (
                <>
                  {tag.map((element) => {
                    return (
                      <Tag color="blue" key={element}>
                        {element.name}
                      </Tag>
                    );
                  })}
                </>
              ),
            },
            {
              title: "Judul Berita",
              dataIndex: "title",
              key: "title",
              width: "40%",
              sorter: (a, b) => basicSort(a.title, b.title),
              ...TableColumnTextFilterConfig<News>("title"),
            },
            {
              title: "Aksi",
              key: "x",
              width: "10%",
              render: (text, record) => (
                <div>
                  <Button type="link" icon={<EditOutlined />} style={{ borderRadius: 5 }} onClick={() => navigate(`detail/${record.id}`)} />
                  <Popconfirm
                    title="Apakah anda yakin?"
                    onConfirm={() => {
                      const hideLoading = message.loading("Loading", 0);
                      deleteNews(record.id)
                        .unwrap()
                        .then(() => {
                          hideLoading();
                          message.success("Berhasil!");
                        })
                        .catch(() => {
                          hideLoading();
                          message.error("Gagal!");
                        });
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} style={{ borderRadius: 5 }} />
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </Card>
    </ContentWithErrorHandler>
  );
}

export default NewsList;
