import { message } from "antd";
import { Formik } from "formik";
import { Form, Input, InputNumber, Select, SubmitButton } from "formik-antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import quillConfig from "../../common/quillConfig";
import { splitAndGetIndex } from "../../common/tokenizer";
import { useCreateGeneralInfoMutation, useGetGeneralInfoQuery, useUpdateGeneralInfoMutation } from "./generalInfoAPI";

const { Option } = Select;

interface InitialData {
  viewOrder: number;
}

function GeneralInfoMilestoneDetail() {
  const { id, profileType } = useParams();

  const [fileList, setFileList] = useState<any>([]);
  const [content, setContent] = useState("");
  const [initialData, setInitialData] = useState<InitialData>({
    viewOrder: 1,
  });

  const { data: generalInfo, isLoading: generalInfoIsLoading, isError: generalInfoIsError } = useGetGeneralInfoQuery(id || "", {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const [updateGeneralInfo] = useUpdateGeneralInfoMutation();
  const [createGeneralInfo] = useCreateGeneralInfoMutation();

  useEffect(() => {
    if (generalInfo) {
      setInitialData({
        viewOrder: parseInt(splitAndGetIndex(generalInfo.type, 2)),
      });
      setContent(generalInfo.content);
      setFileList(
        generalInfo.imageUrl != null
          ? [
              {
                uid: "-1",
                name: generalInfo.title,
                status: "done",
                url: generalInfo.imageUrl,
              },
            ]
          : []
      );
    }
  }, [generalInfo]);

  const validate = (value) => {
    let error;
    if (!value) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  const save = async (value: InitialData, closeLoading: any) => {
    if (content.length == 0) {
      closeLoading();
      message.error("Deskripsi tidak boleh kosong");
      return;
    }

    const formData = new FormData();
    await fileList.map((item) => {
      if (item.originFileObj) {
        formData.append("file", item.originFileObj);
      }
    });

    formData.append("title", `${value.viewOrder}`);
    formData.append("content", content);
    formData.append("type", `${profileType}.milestone.${value.viewOrder}`);
    formData.append("isHeader", String(false));

    let promise;
    if (id) {
      formData.append("id", id);
      promise = updateGeneralInfo(formData);
    } else {
      promise = createGeneralInfo(formData);
    }

    promise
      .unwrap()
      .then(() => {
        closeLoading();
        message.success("Berhasil!");
      })
      .catch(() => {
        closeLoading();
        message.error("Gagal!");
      });
  };

  return (
    <div>
      <ContentWithErrorHandler isError={generalInfoIsError} isLoading={generalInfoIsLoading} withBox={true}>
        <Formik
          initialValues={initialData}
          onSubmit={async (values, actions) => {
            const hide = message.loading("Loading", 0);
            await save(values, hide);
            actions.setSubmitting(false);
          }}
          enableReinitialize={true}
          render={() => (
            <Form layout="vertical">
              <Form.Item name="viewOrder" label="Tahun">
                <InputNumber name="viewOrder" placeholder="Masukkan tahun" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item name="content" label="Deskripsi">
                <ReactQuill
                  modules={quillConfig}
                  style={{
                    background: "white",
                  }}
                  onChange={(content) => setContent(content)}
                  value={content}
                />
              </Form.Item>
              <SubmitButton>Simpan</SubmitButton>
            </Form>
          )}
        />
      </ContentWithErrorHandler>
    </div>
  );
}

export default GeneralInfoMilestoneDetail;
