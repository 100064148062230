import { Col, Divider, Row, Space } from 'antd';
import { Form, Input, InputNumber, Select } from 'formik-antd';
import { ceil, round } from 'lodash';
import ContentWithErrorHandler from '../../../app/ContentWithErrorHandler';
import { Member } from '../../member/types';
import { SubmissionForm } from './types';

const DATE_FORMAT = 'DD/MM/YYYY';

const { Option } = Select;

type Props = {
  formValue: SubmissionForm;
  selectedMember: undefined | Member;
};

function CfoAnalysisForm(props: Props) {
  const cfoAnalysisForm = props.formValue.cfoAnalysisForm;
  const incomeData = props.formValue.incomeData;

  const getSavingPercentage = () => {
    return round((cfoAnalysisForm.savingAbility.lastThreeMonth / incomeData.estimation.incomePerMonth) * 100, 2);
  };

  const getSavingPercentageFinalResult = () => {
    return round((getSavingPercentage() / cfoAnalysisForm.savingAbility.goalFl) * 100, 2);
  };

  const getIncomeEstimation = () => {
    return round((cfoAnalysisForm.savingAbility.lastThreeMonth / cfoAnalysisForm.savingAbility.goalFl) * 100);
  };

  const getRepaymentCapability = () => {
    return round((getIncomeEstimation() * cfoAnalysisForm.loanRepayment.goalFl) / 100);
  };

  const getRepaymentPercentage = () => {
    return round((getRepaymentCapability() / incomeData.estimation.incomePerMonth) * 100, 2);
  };

  const getRepaymentPercentageFinalResult = () => {
    return round((getRepaymentPercentage() / cfoAnalysisForm.loanRepayment.goalFl) * 100, 2);
  };

  const getConsumptionPercentage = () => {
    if (incomeData.estimation.expensesPerMonth < getIncomeEstimation()) {
      return round((incomeData.estimation.expensesPerMonth / getIncomeEstimation()) * 100, 2);
    } else {
      return round(((getIncomeEstimation() - incomeData.estimation.expensesPerMonth) / getIncomeEstimation()) * 100, 2);
    }
  };

  const getConsumptionPercentageFinalResult = () => {
    return round((getConsumptionPercentage() / cfoAnalysisForm.consumption.goalFl) * 100, 2);
  };

  return (
    <ContentWithErrorHandler isError={false} isLoading={false} withBox={false}>
      <Row gutter={[8, 0]}>
        <Col span={24}>
          <Form.Item name="cfoAnalysisForm.analysisDate" label="Tanggal Analisis format (DD/MM/YYYY) contoh (25/01/2022)">
            <Input fast name="cfoAnalysisForm.analysisDate" />
          </Form.Item>

          <Divider plain>Kemampuan Menabung</Divider>
          <Form layout="vertical">
            <Row gutter={[8, 8]}>
              <Col span={7}>
                <Form.Item name="cfoAnalysisForm.savingAbility.lastThreeMonth" label="Tabungan Masuk 3 Bulan Terakhir">
                  <InputNumber
                    formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                    name="cfoAnalysisForm.savingAbility.lastThreeMonth"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name="incomeData.estimation.incomePerMonth" label="Total Pendapatan">
                  <InputNumber
                    formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                    name="incomeData.estimation.incomePerMonth"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="dummy" label="Hasil (%)">
                  <InputNumber name="dummy" style={{ width: '100%' }} value={getSavingPercentage()} readOnly />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="cfoAnalysisForm.savingAbility.goalFl" label="Goal FL (%)">
                  <InputNumber name="cfoAnalysisForm.savingAbility.goalFl" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="dummy" label="Pencapaian (%)">
                  <InputNumber name="dummy" style={{ width: '100%' }} value={getSavingPercentageFinalResult()} readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Divider plain>Kemampuan Mengangsur</Divider>
          <Form layout="vertical">
            <Row gutter={[8, 8]}>
              <Col span={7}>
                <Form.Item name="dummy" label="Kesanggupan Mengangsur">
                  <InputNumber
                    formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                    name="dummy"
                    value={getRepaymentCapability()}
                    readOnly
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name="dummy" label="Total Estimasi Pendapatan">
                  <InputNumber
                    formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                    name="dummy"
                    value={getIncomeEstimation()}
                    readOnly
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="dummy" label="Hasil (%)">
                  <InputNumber name="dummy" style={{ width: '100%' }} value={getRepaymentPercentage()} readOnly />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="cfoAnalysisForm.loanRepayment.goalFl" label="Goal FL (%)">
                  <InputNumber name="cfoAnalysisForm.loanRepayment.goalFl" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="dummy" label="Pencapaian (%)">
                  <InputNumber name="dummy" style={{ width: '100%' }} value={getRepaymentPercentageFinalResult()} />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Divider plain>Tingkat Konsumsi</Divider>
          <Form layout="vertical">
            <Row gutter={[8, 8]}>
              <Col span={7}>
                <Form.Item name="incomeData.estimation.expensesPerMonth" label="Total Pengeluaran">
                  <InputNumber
                    formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                    name="incomeData.estimation.expensesPerMonth"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name="dummy" label="Total Estimasi Pendapatan">
                  <InputNumber
                    formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, '')}
                    name="dummy"
                    value={getIncomeEstimation()}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="dummy" label="Hasil (%)">
                  <InputNumber name="dummy" style={{ width: '100%' }} value={getConsumptionPercentage()} readOnly />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="cfoAnalysisForm.consumption.goalFl" label="Goal FL (%)">
                  <InputNumber name="cfoAnalysisForm.consumption.goalFl" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="dummy" label="Pencapaian (%)">
                  <InputNumber name="dummy" style={{ width: '100%' }} value={getConsumptionPercentageFinalResult()} />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Divider plain>TWTJ Pinjaman Sebelum (Error maks 2 kali)</Divider>
          <Form layout="vertical">
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item name="cfoAnalysisForm.twtjLastLoan.count" label="Total">
                  <InputNumber name="cfoAnalysisForm.twtjLastLoan.count" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="cfoAnalysisForm.twtjLastLoan.notes" label="Catatan">
                  <Select fast name="cfoAnalysisForm.twtjLastLoan.notes" defaultValue="good" style={{ width: '100%' }}>
                    <Option value="good">Baik</Option>
                    <Option value="bad">Buruk</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Divider plain>Dilihat Dari Simpanan</Divider>
          <Form.Item
            name="cfoAnalysisForm.savingAbility.savingType"
            label={`Rata-rata menabung 3 bulan terakhir sebesar Rp ${cfoAnalysisForm.savingAbility.lastThreeMonth} yang merupakan tabungan`}
          >
            <Select fast name="cfoAnalysisForm.savingAbility.savingType" defaultValue="personal" style={{ width: '100%' }}>
              <Option value="personal">Pribadi</Option>
              <Option value="family">Keluarga</Option>
            </Select>
          </Form.Item>
          <Form.Item name="dummy" label="Telah menjadi anggota sejak">
            <Input fast name="dummy" value={props.selectedMember?.joinDate || '-'} readOnly />
          </Form.Item>
          <Form.Item name="cfoAnalysisForm.savingAbility.notes" label="Catatan">
            <Input fast name="cfoAnalysisForm.savingAbility.notes" />
          </Form.Item>

          <Divider plain>Dilihat Dari Kemampuan</Divider>
          <Form.Item name="cfoAnalysisForm.loanRepayment.repaymentSource" label="Berdasarkan pendapatan yang diperoleh">
            <Select fast name="cfoAnalysisForm.loanRepayment.repaymentSource" defaultValue="personal" style={{ width: '100%' }}>
              <Option value="personal">Pribadi</Option>
              <Option value="family">Keluarga</Option>
            </Select>
          </Form.Item>
          <Form.Item name="cfoAnalysisForm.loanRepayment.isCapable" label="Maka anggota dinyatakan">
            <Select fast name="cfoAnalysisForm.loanRepayment.isCapable" defaultValue={false} style={{ width: '100%' }}>
              <Option value={true}>Sanggup</Option>
              <Option value={false}>Tidak Sanggup</Option>
            </Select>
          </Form.Item>

          <Divider plain>Dilihat Dari Rapor Sebelumnya</Divider>
          <Space>
            <Form.Item name="cfoAnalysisForm.others.badRecord" label="Catatan buruk selama melakukan cicilan pinjaman sebelumnya">
              <Select fast name="cfoAnalysisForm.others.badRecord" defaultValue={false} style={{ width: '100%' }}>
                <Option value={true}>Ada</Option>
                <Option value={false}>Tidak Ada</Option>
              </Select>
            </Form.Item>
            <Form.Item name="cfoAnalysisForm.others.notes" label="Catatan buruk">
              <Input fast name="cfoAnalysisForm.others.notes" />
            </Form.Item>
          </Space>

          <Divider plain>Administrasi & Catatan Lain</Divider>
          <Form.Item name="cfoAnalysisForm.others.socialRelationCollateral" label="Jaminan relasi sosial">
            <Select fast name="cfoAnalysisForm.others.socialRelationCollateral" defaultValue={false} style={{ width: '100%' }}>
              <Option value={true}>Ada</Option>
              <Option value={false}>Tidak Ada</Option>
            </Select>
          </Form.Item>
          <Form.Item name="cfoAnalysisForm.others.familySignature" label="Tanda tangan keluarga">
            <Select fast name="cfoAnalysisForm.others.familySignature" defaultValue={false} style={{ width: '100%' }}>
              <Option value={true}>Ada</Option>
              <Option value={false}>Tidak Ada</Option>
            </Select>
          </Form.Item>
          <Form.Item name="cfoAnalysisForm.others.addressOnGmaps" label="Alamat di google maps">
            <Select fast name="cfoAnalysisForm.others.addressOnGmaps" defaultValue={false} style={{ width: '100%' }}>
              <Option value={true}>Ada</Option>
              <Option value={false}>Tidak Ada</Option>
            </Select>
          </Form.Item>
          <Form.Item name="cfoAnalysisForm.others.referenceSignature" label="Tanda tangan referensi">
            <Select fast name="cfoAnalysisForm.others.referenceSignature" defaultValue={false} style={{ width: '100%' }}>
              <Option value={true}>Ada</Option>
              <Option value={false}>Tidak Ada</Option>
            </Select>
          </Form.Item>
          <Form.Item name="cfoAnalysisForm.other.basicCoopEducationScore" label="Skor pendidikan dasar">
            <InputNumber name="cfoAnalysisForm.others.basicCoopEducationScore" />
          </Form.Item>
          <Form.Item name="cfoAnalysisForm.others.financialLiteracy" label="Financial literacy">
            <InputNumber name="cfoAnalysisForm.others.financialLiteracy" />
          </Form.Item>
          <Form.Item name="cfoAnalysisForm.others.praRat" label="Partisipasi Pra RAT">
            <Select fast name="cfoAnalysisForm.others.praRat" defaultValue={false} style={{ width: '100%' }}>
              <Option value={true}>Ada</Option>
              <Option value={false}>Tidak Ada</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </ContentWithErrorHandler>
  );
}

export default CfoAnalysisForm;
