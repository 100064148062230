import { Card, Space, Statistic } from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { TransactionReportOverall } from "./types";

function numberToRupiah(num) {
  return "Rp " + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ",-";
}

type SummaryProps = {
  data: TransactionReportOverall | null;
  startDate: Moment | any;
  endDate: Moment | any;
};

function TransactionReportSummary(props: SummaryProps) {
  const [data, setData] = useState<TransactionReportOverall | null>(null);
  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  const renderTitle = () => {
    if (props.startDate && props.endDate) {
      if (props.startDate.format("DD MMMM YYYY") == props.endDate.format("DD MMMM YYYY")) {
        return "Ringkasan Hari Ini";
      } else {
        return `Ringkasan ${props.startDate.format("D MMMM YYYY")} sampai ${props.endDate.format("D MMMM YYYY")}`;
      }
    }

    return "Ringkasan";
  };

  return (
    <Card title={renderTitle()} bodyStyle={{ padding: 20, paddingBottom: 5 }} bordered={false}>
      <Space style={{ marginBottom: 15 }} wrap size={"large"}>
        <Statistic
          title="Total Transaksi"
          loading={!data}
          value={data ? data.numOfTransaction : "-"}
          suffix={<div style={{ fontSize: 16, color: "#6c757d" }}>transaksi</div>}
        />
        <Statistic title="Total Debit" loading={!data} value={data ? data.debit : "-"} prefix="Rp" />
        <Statistic title="Total Credit" loading={!data} value={data ? data.credit : "-"} prefix="Rp" />
      </Space>
    </Card>
  );
}

export default TransactionReportSummary;
