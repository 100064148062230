import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Divider, message, Modal, Row, Tag } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Formik } from "formik";
import { DatePicker, Form, Input, InputNumber, Select, SubmitButton } from "formik-antd";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContentWithErrorHandler from "../../app/ContentWithErrorHandler";
import { useCreateLoanMutation, useGetLoanQuery, useUpdateLoanMutation } from "./loanAPI";
import { LoanAdditionalInfo, LoanAdditionalInfoModel } from "./types";

const DATE_FORMAT = "DD/MM/YYYY";

interface InitialData {
  accountId: string;
  borrowDate: string;
  borrowAmount: number;
  dueDate: number;
  installment: number;
  lastTransaction: string;
  loanType: string;
  remainingAmount: number;
  member: {
    name: string;
    memberId: number;
    phone: string;
    group: string;
  };
}

const ADDITIONAL_INFO_TEXT_MAPPING = {
  restructurePolicy: "Kebijakan Resturktur",
  commitment: "Komitmen Anggota",
};

function LoanDetail() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [additionalInfo, setAdditionalInfo] = useState<LoanAdditionalInfo[]>([]);
  const [activeAdditionalInfo, setActiveAdditionalInfo] = useState<LoanAdditionalInfo | null>(null);
  const [initialData, setInitialData] = useState<InitialData>({
    accountId: "",
    borrowDate: "",
    borrowAmount: 0,
    dueDate: 0,
    installment: 0,
    lastTransaction: "",
    loanType: "",
    remainingAmount: 0,
    member: {
      name: "",
      memberId: 0,
      phone: "",
      group: "",
    },
  });

  const { data, isLoading: loanDataIsLoading, isError: loanDataIsError } = useGetLoanQuery(id || "", {
    skip: id ? false : true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (data) {
      setInitialData({
        accountId: data?.accountId || "",
        borrowDate: moment(data?.borrowDate).format("DD/MM/YYYY") || "",
        borrowAmount: data?.borrowAmount || 0,
        dueDate: data?.dueDate || 0,
        installment: data?.installment || 0,
        lastTransaction: moment(data?.lastTransaction).format("DD/MM/YYYY") || "",
        loanType: data?.loanType || "",
        remainingAmount: data?.remainingAmount || 0,
        member: {
          name: data?.member.name || "",
          memberId: data?.member.memberId || 0,
          phone: data?.member.phone.join(", ") || "",
          group: data?.member.group || "",
        },
      });

      let oldAdditionalInfo: LoanAdditionalInfo[] = [];
      let activeAdditionalInfo: Partial<LoanAdditionalInfo> | null = null;
      let nullSafeAdditionalInfo = data?.additionalInfo != null ? data.additionalInfo : [];

      nullSafeAdditionalInfo.map((element) => {
        let temp: Partial<LoanAdditionalInfo> = {};
        if (element.date != null || element.date != undefined) {
          temp.commitment = element.commitment;
          temp.isActive = element.isActive;
          temp.restructurePolicy = element.restructurePolicy;
          temp.date = moment(element.date, DATE_FORMAT);
        }

        if (element.isActive) {
          activeAdditionalInfo = temp;
        } else {
          temp.isActive = false;
          oldAdditionalInfo.push(temp as LoanAdditionalInfo);
        }
        return element;
      });

      setActiveAdditionalInfo(activeAdditionalInfo);
      setAdditionalInfo(oldAdditionalInfo);
    }
  }, [data]);

  const [updateLoan] = useUpdateLoanMutation();
  const [createLoan] = useCreateLoanMutation();

  const validate = (value) => {
    let error;
    if (!value) {
      error = "Tidak boleh kosong";
    }
    return error;
  };

  const save = async (value: InitialData, closeLoading: any) => {
    let additionalInfoModels: LoanAdditionalInfoModel[] = [];
    if (activeAdditionalInfo != null) {
      let activeAdditionalInfoModel: LoanAdditionalInfoModel = {
        commitment: activeAdditionalInfo.commitment,
        isActive: activeAdditionalInfo.isActive,
        restructurePolicy: activeAdditionalInfo.restructurePolicy,
        date: moment(activeAdditionalInfo.date).format("DD/MM/YYYY"),
      };

      additionalInfoModels = await additionalInfo.map((e) => {
        return {
          commitment: e.commitment,
          isActive: e.isActive,
          restructurePolicy: e.restructurePolicy,
          date: moment(e.date).format("DD/MM/YYYY"),
        };
      });

      additionalInfoModels = [...additionalInfoModels, activeAdditionalInfoModel];
      additionalInfoModels = await additionalInfoModels.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
    }

    let dataToUpdate = {
      id: id,
      installment: value.installment,
      dueDate: value.dueDate,
      remainingAmount: value.remainingAmount,
      additionalInfo: additionalInfoModels,
    };

    updateLoan(dataToUpdate)
      .unwrap()
      .then(() => {
        closeLoading();
        message.success("Berhasil!");
      })
      .catch(() => {
        closeLoading();
        message.error("Gagal!");
      });
  };

  const onAdditionalInfoDateChange = (index, value: Moment, isActive) => {
    if (isActive && activeAdditionalInfo) {
      setActiveAdditionalInfo({ ...activeAdditionalInfo, date: value });
      return;
    }

    let copyItem = [...additionalInfo];
    copyItem[index].date = value;
    setAdditionalInfo(copyItem);
  };

  const toggleIsActiveAdditionalInfoItem = (originalStateIsActive, id) => {
    if (originalStateIsActive) {
      if (activeAdditionalInfo && !activeAdditionalInfo?.isNewPolicy) {
        let copyAdditionalInfo: LoanAdditionalInfo[] = [...additionalInfo, { ...activeAdditionalInfo, isActive: false }];
        copyAdditionalInfo = copyAdditionalInfo.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf());
        setAdditionalInfo(copyAdditionalInfo);
      }
      setActiveAdditionalInfo(null);
    } else {
      if (activeAdditionalInfo == null || Object.keys(activeAdditionalInfo).length == 0) {
        let selectedItem = additionalInfo[id];
        let copyItem = [...additionalInfo];
        copyItem.splice(id, 1);
        setAdditionalInfo(copyItem);
        setActiveAdditionalInfo({ ...selectedItem, isActive: true });
      } else {
        message.error("Masih ada kebijakan yg berlaku, Tandai kebijakan tesebut sebagai 'tidak berlaku' terlebih dahulu");
      }
    }
  };

  const setAdditionalInfoItem = (id, type, value, isActive = false) => {
    if (isActive && activeAdditionalInfo) {
      let copyItem: LoanAdditionalInfo = { ...activeAdditionalInfo };
      copyItem[type] = value;
      setActiveAdditionalInfo(copyItem);
      return;
    }

    let copyItem = [...additionalInfo];
    copyItem[id][type] = value;
    if (type == "key") {
      copyItem[id].keyText = ADDITIONAL_INFO_TEXT_MAPPING[value];
    }
    setAdditionalInfo(copyItem);
  };

  const createAdditionalInfoItem = () => {
    if (activeAdditionalInfo == null || Object.keys(activeAdditionalInfo).length == 0) {
      setActiveAdditionalInfo({
        commitment: "",
        restructurePolicy: "",
        date: moment(),
        isActive: true,
        isNewPolicy: true,
        keyText: "",
      });
    } else {
      message.error("Masih ada kebijakan yg berlaku, Tandai kebijakan tesebut sebagai 'tidak berlaku' terlebih dahulu");
    }
  };

  return (
    <Formik
      initialValues={initialData}
      onSubmit={async (values, actions) => {
        const hide = message.loading("Loading", 0);
        await save(values, hide);
        actions.setSubmitting(false);
      }}
      enableReinitialize={true}
      render={() => (
        <Modal
          title={`Detail Pinjaman: ${data?.member.name} - ${data?.member.memberId} - ${data?.accountId}`}
          width={800}
          visible={true}
          destroyOnClose={true}
          onCancel={() => navigate("/loan")}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          footer={null}
        >
          <ContentWithErrorHandler isError={loanDataIsError} isLoading={loanDataIsLoading} withBox={false}>
            <Form layout="horizontal">
              <Alert
                type="info"
                style={{ marginBottom: "15px" }}
                message={
                  <Row gutter={[8, 16]}>
                    <Col span={12}>
                      Status Pinjaman <Tag color={data?.isPaid ? "blue" : "red"}> {data?.isPaid ? "LUNAS" : "Belum Lunas"}</Tag>
                    </Col>
                    <Col span={12}>
                      <span hidden={data?.isPaid}>
                        Status Debet <Tag color={data?.canDebet ? "green" : "red"}> {data?.canDebet ? "Saldo Cukup" : "Saldo Kurang"}</Tag>
                      </span>
                    </Col>
                  </Row>
                }
              />

              <Row gutter={[8, 0]}>
                <Col span={12}>
                  <Form.Item name="member.name" label="Nama Anggota">
                    <Input name="member.name" readOnly />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="member.memberId" label="Nomor Anggota">
                    <Input name="member.memberId" readOnly />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="accountId" label="Rekening">
                    <Input name="accountId" readOnly />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="installment" label="Cicilan">
                    <Input name="installment" addonAfter="kali" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="member.phone" label="Telepon">
                    <Input name="member.phone" readOnly />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="borrowAmount" label="Pinjaman Awal">
                    <InputNumber
                      name="borrowAmount"
                      formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, "")}
                      readOnly
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="remainingAmount" label="Sisa Saldo Pinjaman">
                    <InputNumber
                      formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value: any) => value?.replace(/Rp\s?|(,*)/g, "")}
                      name="remainingAmount"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="borrowDate" label="Tanggal Pinjam">
                    <Input name="borrowDate" readOnly />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="dueDate" label="Tanggal Jatuh Tempo">
                    <InputNumber name="dueDate" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="member.group" label="Ketua Kelompok">
                    <Input name="member.group" readOnly />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="lastTransaction" label="Transaksi Terakhir">
                    <InputNumber name="lastTransaction" style={{ width: "100%" }} readOnly />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Divider plain>Kebijakan Restruktur & Komitmen Aktif</Divider>
                </Col>

                {activeAdditionalInfo ? (
                  <>
                    <Col span={16}>
                      <Form.Item name="activeAdditionalInfo.date" label="Tanggal Kebijakan / Komitmen">
                        <DatePicker
                          name="activeAdditionalInfo.date"
                          value={activeAdditionalInfo.date}
                          format={DATE_FORMAT}
                          onChange={(date: any, dateString) => onAdditionalInfoDateChange(null, date, true)}
                        />
                      </Form.Item>
                    </Col>

                    <Col>
                      <Button onClick={() => toggleIsActiveAdditionalInfoItem(true, null)} danger icon={<MinusCircleOutlined />}>
                        {activeAdditionalInfo.isNewPolicy ? "Batal" : "Tandai tidak berlaku"}
                      </Button>
                    </Col>

                    <Col span={12}>
                      Kebijakan Restruktur
                      <TextArea
                        rows={4}
                        value={activeAdditionalInfo.restructurePolicy}
                        onChange={(event) => setAdditionalInfoItem(null, "restructurePolicy", event.target.value, true)}
                      />
                    </Col>

                    <Col span={12}>
                      Komitmen Anggota
                      <TextArea
                        rows={4}
                        value={activeAdditionalInfo.commitment}
                        onChange={(event) => setAdditionalInfoItem(null, "commitment", event.target.value, true)}
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}

                <Col span={24}>
                  <Button type="dashed" onClick={() => createAdditionalInfoItem()} block icon={<PlusOutlined />} style={{ marginTop: 20 }}>
                    Buat Kebijakan Restruktur / Komitmen Baru
                  </Button>
                </Col>

                <Col span={24}>
                  <Divider plain>Kebijakan Restruktur & Komitmen Sebelumnya</Divider>
                </Col>

                {additionalInfo.map((element, i) => {
                  return (
                    <>
                      <Col span={16}>
                        <Form.Item name="additionalInfo.date" label="Tanggal Kebijakan / Komitmen">
                          <DatePicker
                            name="additionalInfo.date"
                            value={element.date}
                            format={DATE_FORMAT}
                            onChange={(date: any, dateString) => onAdditionalInfoDateChange(i, date, false)}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Button onClick={() => toggleIsActiveAdditionalInfoItem(false, i)} danger icon={<PlusOutlined />}>
                          Tandai berlaku
                        </Button>
                      </Col>

                      <Col span={12}>
                        Kebijakan Restruktur
                        <TextArea
                          rows={3}
                          value={element.restructurePolicy}
                          onChange={(event) => setAdditionalInfoItem(i, "restructure", event.target.value)}
                        />
                      </Col>

                      <Col span={12}>
                        Komitmen Anggota
                        <TextArea
                          rows={3}
                          value={element.commitment}
                          onChange={(event) => setAdditionalInfoItem(i, "commitment", event.target.value)}
                        />
                      </Col>
                    </>
                  );
                })}
              </Row>
              <Row justify="end" style={{ marginTop: 20 }}>
                <SubmitButton>Simpan</SubmitButton>
              </Row>
            </Form>
          </ContentWithErrorHandler>
        </Modal>
      )}
    />
  );
}

export default LoanDetail;
